import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// @mui
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';


// Helpers
import { translateThis } from '../../../../helpers/language.helper';

// Services
import ApiCaller from '../../../../services/api/general';
import { getCurrentFacilityURL, getUniqueId } from '../../../../helpers/helper.functions';





// ----------------------------------------------------------------------


const CreateNewSystemForm = () => {
    const navigate = useNavigate()
    const apiCaller = new ApiCaller('systems');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formKey, setFormKey] = useState(getUniqueId('systems'));
    const [system, setSystem] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        getSubjects()
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getSubjects = () => {
        setIsLoading(true);
        const apiCaller = new ApiCaller('subject')
        apiCaller.getList()
            .then((data) => {
                setSubjects(data?.results);
            })
            .catch(() => showSnackbar().failure('Failed to fetch results'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (system) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            name: system,
            subjectIds: selectedSubjects
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    // navigate('/', { replace: true });
                    setSystem('');
                    setSelectedSubjects([]);
                    setFormKey(getUniqueId())

                    showSnackbar().success("System created successfully")
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    const onSelectSubject = (id) => {
        setSelectedSubjects((prv) => {
            if (prv.includes(id)) {
                prv = prv.filter((p) => p !== id)
            } else {
                prv.push(id)
            }
            return [...prv]
        })

    }

    /**
     * The signup form elements
     */


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='standard-form questionForm' spacing={3}>
                    <form key={formKey}>
                        <div className='eachQn-box f-wrp'>
                            <h3>{translateThis('Add system name')}</h3>
                            <TextInput
                                key={`createQN_Topic_Creator_add_title`}
                                name={`createQN_Topic_Creator_add_title`}
                                id={`createQN_Topic_Creator_add_title`}
                                value={system}
                                translationKey={translateThis('Write your system title here')}
                                type='text'
                                placeholder={translateThis('Write your system title here')}
                                className='custom-textfield maxWidth_550'

                                getValue={(_, value) => {
                                    setSystem(value);
                                }}
                                useDefaultValidation
                            />

                        </div>

                        <div className='eachQn-box f-wrp'>
                            <h3>{translateThis('Assign this system to the corresponding subjects')}</h3>
                            <p>{translateThis('Subjects')}</p>
                            <div className='flex-box assignSubBox'>
                                {(subjects && subjects.length > 0) ? subjects.map((sub) => (
                                    <button type='button' onClick={() => onSelectSubject(sub?.id)} key={sub?.id} className={`image-blk ${selectedSubjects.includes(sub?.id) ? 'active' : ''}`}>
                                        <img src={sub?.logo?.tempURL} alt='images' />
                                        <h3>{sub?.subjectName}</h3>
                                        <p>{sub.topics.length}&nbsp; {translateThis('topics')}</p>
                                    </button>
                                )) : null}
                            </div>

                        </div>

                        <div className='button-sec f-wrp'>
                            <div className='lft-btn-sec'>
                                <Button onClick={() => navigate(`/${getCurrentFacilityURL()}/create-new-item`)} className='cancel-btn'>{translateThis("cancel")}</Button>
                            </div>
                            <div className='ryt-btn-sec'>


                                <LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition='start'
                                    startIcon={<></>} // To remove Mui warning
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='fill-btn'
                                    onClick={onSubmit}>
                                    {translateThis("submit")}
                                </LoadingButton>

                            </div>
                        </div>
                    </form>
                </Stack>
                <br />


            </div>}
        </>
    );
}

CreateNewSystemForm.propTypes = {
    childId: propTypes.string
}

export default CreateNewSystemForm;