import * as React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Popover from '@mui/material/Popover';

import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import LogoutButton from '../../../components/buttons/logout.button';
import { translateThis } from '../../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';

const drawerWidth = 240;
const navItems = [
  {
    label: 'Características',
    link: 'características',
  },
  {
    label: 'Contacto',
    link: 'contacto',
  },
  {
    label: 'Testimonios',
    link: 'testimonios',
  },
  {
    label: 'Preguntas frecuentes',
    link: 'preguntas-frecuentes',
  }
];

function LandingDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const facilityURL = getCurrentFacilityURL();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'web-menu-popover' : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link className='mobLogo' to={`/${facilityURL}`}> <img src={AppIcons.mobLogo} alt='logo' /></Link>
      <List>
        {navItems.map((element, key) => (
          <ListItem key={key} disablePadding>
            <NavHashLink smooth to={`#${element.link}`} style={{ width: '100%' }} >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={element.label} />
              </ListItemButton>
            </NavHashLink>
          </ListItem>
        ))}
      </List>
      <List className='second-list'>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <span className='profile'><SVGIcons.ProfileIcon />Profile</span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <span className='logout'><SVGIcons.LogoutIcon /><LogoutButton /></span>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (

    <AppBar component="nav">
      <Box className="landing-navbar" sx={{ display: 'flex' }}>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Toolbar className='main-Toolbar'>
            <Box className="landing-logo" sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Link to={`/${facilityURL}`}>
                <img className='mainLogo' src={AppIcons.RDlandingLogo} alt='logo' />
                <img className='faqLogo' src={AppIcons.RDlandingLogo} alt='logo' />
              </Link>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              className='web-drawer'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Box>
        <Box>
          <Box className="nav-list" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            {navItems.map((element, key) => (
              <NavHashLink key={key} smooth to={`#${element.link}`} style={{ width: '100%' }} >
                <Button sx={{ color: '#fff' }}>
                  {element.label}
                </Button>
              </NavHashLink>
            ))}
          </Box>
          <Box className="landing-logo" sx={{ display: { xs: 'block', sm: 'none' } }}>
            {/* <Link to={'/'}> <img src={AppIcons.landingLogo} alt='logo' /></Link> */}
            <Link to={`/${facilityURL}`}>
              <img className='mainLogo' src={AppIcons.RDlandingLogo} alt='logo' />
              <img className='faqLogo' src={AppIcons.RDlandingLogo} alt='logo' />
            </Link>
          </Box>
        </Box>
        <Box className='profile-sec' sx={{ display: { xs: 'none', sm: 'block' } }}>
          <IconButton aria-describedby={id} className='prof-login-btn' onClick={openPopOver} sx={{ p: 0, display: { xs: 'none', sm: 'block' } }}>
            <img src={AppIcons.logo} alt='login' />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            className='web-menu-popover'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box sx={{ display: { xs: 'block', sm: 'block' } }} className="web-menu-wrp f-wrp">
              <List className='web-menu-PCLink'>
              {navItems.map((element, key) => (
                  <ListItem key={key} disablePadding onClick={handleClose}>
                    <NavHashLink smooth to={`#${element.link}`} style={{ width: '100%' }} >
                      <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={element.label} />
                      </ListItemButton>
                    </NavHashLink>
                  </ListItem>
                ))}
              </List>
              <List className='second-list'>
                <ListItem disablePadding onClick={handleClose}>
                  <ListItemButton sx={{ textAlign: 'center' }} onClick={() => { navigate(`/${facilityURL}/my-portal`) }} >
                    <span className='profile'><SVGIcons.ProfileIcon />{translateThis('XMed platform')}</span>
                  </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' }}>
                    <span className='logout'><SVGIcons.LogoutIcon /><LogoutButton /></span>
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Box>
          </Popover>
        </Box>
        <Box className='profile-sec' sx={{ display: { xs: 'block', sm: 'none' } }}>
          <IconButton sx={{ p: 0, display: { xs: 'block', sm: 'none' } }}>
            <Link onClick={handleDrawerToggle} className='prof-login-btn' >
              <img src={AppIcons.logo} alt='login' />
            </Link>
          </IconButton>
        </Box>
      </Box>
    </AppBar>
  );
}

LandingDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default LandingDrawer;