/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import './explanationBox.scss';
import { translateThis } from '../../../../helpers/language.helper';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';

const ExplanationBox = ({ useHighlighter = false, test, question, onClose }) => {

    const [index, setIndex] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState([]);
    

    return (
        <>
            <Box className='ExplanationBox-wrapper f-wrp'>
                <div className='ExplanationBox-header'>
                    <h3>{translateThis("Explanation")}</h3> <button type='button' onClick={() => onClose()}><SVGIcons.CrossIcon /></button>
                </div>
                <div className='ExplanationBox-content f-wrp'>
                    {question && question.explanation && <RichTextViewer
                    useHighlighter={useHighlighter} highlightId={`test-explanation-${question._id}`}
                     richText={question.explanation}
                      />}
                    <ul className='flex-box ww'>
                        {question?.explanationImages && question?.explanationImages.map((i, k) => <li key={`${i.fileKey}-${k}`}>
                            <span
                                onClick={() => {
                                    setSelectedPhoto(question?.explanationImages.map((i) => ({ src: i.tempURL })))
                                    setIndex(k)
                                }}>
                                <S3ImageViewerWithFallback key={`file-${i.fileKey}`} fileKey={i.fileKey} fileURL={i.tempURL} />
                            </span>
                        </li>)}

                    </ul>
                    {selectedPhoto && <Lightbox
                        slides={selectedPhoto}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        controller={{ closeOnBackdropClick: 'true' }}

                    />}
                </div>
            </Box>
        </>
    )
}
export default ExplanationBox;