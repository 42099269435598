import React, { useState, useEffect } from 'react';
// Components
import { useNavigate, Link } from 'react-router-dom';
import { translateThis } from '../../../../../../helpers/language.helper';
import AppTable from '../../../../../../components/table/index';
import ApiCaller from '../../../../../../services/api/general';
import { showSnackbar } from '../../../../../../components/snackbar/index';
import ModalPopUp from '../../../../../../components/modal/modal.popup';
import ConfirmDialog from '../../../../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../../../../assets/images/icons/svgIcons';
import QnAnswerPopup from './qnAnswerPopup';
import RichTextViewer from '../../../../../../components/rich-text-editor/TextViewer';
// import './previousTestList.scss';



const PreviousAttemptsQuestionList = () => {
    const apiCaller = new ApiCaller('questions');
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');

    const [qnAnswerOpen, setQnAnswerOpen] = React.useState(false);


    const onQnAnswerClose = () => {
        setQnAnswerOpen(false);
    };

    const onQnAnswerOpen = () => {
        setQnAnswerOpen(true);
    };


    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Question deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: '#',
            headerName: '#',
            minWidth: 50,
            flex: 0.1,
        },
        {
            field: 'slNo',
            headerName: 'ID',
            minWidth: 50,
            flex: 0.1,
        },
        {
            field: 'question',
            headerName: 'Question',
            minWidth: 150,
            flex: 0.7,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /></>
        },
        {
            field: 'answered',
            headerName: 'Answered',
            minWidth: 50,
            flex: 0.3,
            renderCell: ({ row }) => <>
                <span>A <SVGIcons.StatusActiveIcon /></span>
            </>
        },
        {
            field: 'correctAnswer',
            headerName: 'Correct answer',
            minWidth: 50,
            flex: 0.3,
            renderCell: ({ row }) => <>
                <span>A. Aadasd</span>
            </>
        },
        {
            field: 'action',
            headerName: '',
            minWidth: 30,
            flex: 0.1,
            renderCell: ({ row }) => <Link onClick={() => {
                // setSelectedQn(row);
                onQnAnswerOpen()
            }}><SVGIcons.ChevronRightIcon style={{ width: '15px' }} /></Link>
        },

    ]


    return (

        <div className='block-table-wrp provider-portal f-wrp'>
            <h3 style={{ marginTop: '0' }} className='title'>{translateThis('prevQuestionListTitle')}</h3>
            <div className='panel-table-sec provider-table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
            </div>

            {qnAnswerOpen ? <ModalPopUp
                className='preview-popup questionAndAnswer-Popup'
                isOpen={qnAnswerOpen}
                onClose={onQnAnswerClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                <QnAnswerPopup />
                {/* <Button onClick={onQnAnswerClose} className='fill-btn'>Close</Button> */}
            </ModalPopUp> : null}


            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete question`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this question?\nThis action is permanent and cannot be undone.`}
                okayButtonText={'Delete question'}
                onConfirm={deleteData}
            />}
        </div>

    )
}
PreviousAttemptsQuestionList.propTypes = {}

export default PreviousAttemptsQuestionList;
