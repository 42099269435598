import React from 'react';
import { getRecoil } from 'recoil-nexus';
import { useRecoilState } from 'recoil';
import { translateThis } from '../../helpers/language.helper';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';

import './btn.scss';


const ThemeSwitchButton = () => {
    
    const [myPortalInitialState, setMyPortalState] = useRecoilState(appConfigAtomState);
    const switchDarkMode = async () => setMyPortalState((prv) => {
        let classToAdd = 'dark-bg';
        if (prv.isDarkModeOn) {
            classToAdd = 'light-mode';
        }
        document.body.classList.remove('light-mode', 'dark-bg');
        document.body.classList.add(classToAdd);
        return { ...prv, ...{ isDarkModeOn: !prv.isDarkModeOn } }
    });



    return (
        <>
            {/* eslint-disable-next-line */}
            <span className='logout-btn' onClick={() => { switchDarkMode() }}>{myPortalInitialState?.isDarkModeOn ? `${translateThis('Light mode')}` : `${translateThis('Dark mode')}`}</span>
        </>
    )
}

export default ThemeSwitchButton;