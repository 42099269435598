import React from 'react';
import { Grid } from '@mui/material';

import { translateThis } from '../../../../helpers/language.helper';

import AppIcons from '../../../../assets/images/icons';



const ComparisonGraph = () => (

    <div className='statistics-info-wrapper graph-section-wrp'>
        <Grid className='basic-info-grid' container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
                <div className='each-statistics-box'>
                    <h3>{translateThis('Performance Percentile Rank')}</h3>
                    <div className='basic-detail-box line-chart'>
                        <span className='graph-img'><img src={AppIcons.graph2} alt='graph' /></span>
                        <div className='graph-content'>
                            <span>{translateThis('From')} 40 {translateThis('questions, you answered')}:</span>
                            <p>60% {translateThis('Correct')}</p>
                            <span>24 {translateThis('questions')}</span>

                            <p>10% {translateThis('Incorrect')}</p>
                            <span>4 {translateThis('questions')}</span>

                            <p>30% {translateThis('Omitted')}</p>
                            <span>12 {translateThis('questions')}</span>
                        </div>
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={7}>
                <div className='each-statistics-box'>
                    <h3>{translateThis('Time per question Percentile Rank')}</h3>
                    <div className='basic-detail-box line-chart'>
                        <span className='graph-img'><img src={AppIcons.graph2} alt='graph' /></span>
                        <div className='graph-content'>
                            <span>{translateThis('From')} 40 {translateThis('questions, you answered')}:</span>
                            <p>60% {translateThis('Correct')}</p>
                            <span>24 {translateThis('questions')}</span>

                            <p>10% {translateThis('Incorrect')}</p>
                            <span>4 {translateThis('questions')}</span>

                            <p>30% {translateThis('Omitted')}</p>
                            <span>12 {translateThis('questions')}</span>
                        </div>
                    </div>
                </div>
            </Grid>

        </Grid>
    </div>

)



ComparisonGraph.propTypes = {};

export default ComparisonGraph;