import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { LoadingButton } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Button, Stack, InputLabel } from '@mui/material';
import TextInput from '../../../../components/inputs/textInput/textInput';
import '../index.scss';
import { getAuthId } from '../../../../helpers/auth.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import { showSnackbar } from '../../../../components/snackbar/index';
import ProfilePicUploader from '../../../../components/awsS3ImageUploader/profilePic.uploader';

import { myPortalState } from '../../../../state/reducerAtoms/myPortal.atom';
import { getProfile, updateProfile, forgotPassword } from '../../../../services/api/auth';
import { translateThis } from '../../../../helpers/language.helper';

// ----------------------------------------------------------------------









export default function AdminPasswordReset() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = React.useState({});
    const facilityURL = getCurrentFacilityURL()
    const [otp, setOtp] = React.useState('')

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const onSubmit = () => {
        setIsLoading(true);
        forgotPassword({ otp }).then((data) => {
            if (data.status) {
                navigate(`/${facilityURL}/user-set-password`, { replace: true });
                showSnackbar().success(data.message)
            } else {
                showSnackbar().failure(data.message);
            }
            navigate(`/${facilityURL}/user-set-password`, { replace: true });
        }).finally(() => setIsLoading(false));
    };

    /**
   * updateData
   */
    // const updateData = () => {
    //     if (userId) {
    //         const postData = {
    //             firstName: data.firstName,
    //             lastName: data.lastName,
    //             profilePic: data?.profilePic?.fileKey
    //         }
    //         updateProfile(postData)
    //             .then(() => showSnackbar().success('Profile updated successfully'))
    //             .catch(() => showSnackbar().failure("Something went wrong!."))
    //             .finally(() => {
    //                 getData();
    //             })
    //     }

    // }

    // const onCancel = () => {
    //     setMyPortalState((prv) => ({ ...prv, ...{ selectedNavItem: 'parents', pageHeader: 'Parents' } }))

    // }
    /**
      * sets the form data 
      * @param {string} name the name of the field
      * @param {string} value  the value of the field
      */
    const setFormValue = (name, value) => {
        const formDataAs = { ...data };
        setData({ ...formDataAs, ...{ [name]: value } });
    }



    return (
        <>

            {/* {(isLoading) ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : (!isLoading && data) ?  */}
            <div className='profile-wrapper f-wrp'>
                <div className='profile-img-con'>
                    <ProfilePicUploader
                        key={`profile-pic`}
                        onUploadStart={() => null}
                        onUploadEnd={(img) => setFormValue("profilePic", img)}
                        // onRemoveFile={() => setFormValue("profilePic", {fileKey: ''})}
                        onRemoveFile={() => setFormValue("profilePic", '')}
                        defaultImage={data?.profilePic}
                    />
                </div>
                <div className='profile-details f-wrp'>

                    <form>
                        <div className='formBox f-wrp'>
                            <h3>{translateThis('passwordReset')}?</h3>
                            <Grid className='profile-form' container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <InputLabel shrink htmlFor="loginForm_password">
                                        {translateThis('weSentACodeTo')} example@gmail.com
                                    </InputLabel>
                                    <MuiOtpInput value={otp} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </div>
                        <Stack className='additional-btn f-wrp' variant='div' spacing={3}>
                            <p>{translateThis("didntReceiveTheEmail")}? <Link style={{float:'none'}} to={`/`}>{translateThis("clickToReset")}</Link></p>
                        </Stack>
                        <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                            <div className='ryt-btn-sec'>
                                {/* <Button onClick={onCancel} className='cancel-btn'>Cancel</Button>
                                <Button onClick={updateData} className='fill-btn'>Save changes</Button> */}
                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition='start'
                                    startIcon={<></>} // To remove Mui warning
                                    fullWidth
                                    disabled={!otp}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='fill-btn'
                                    onClick={onSubmit}>
                                    {translateThis("continue")}
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* : null} */}

        </>
    );
}

