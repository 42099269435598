import React from 'react';
import PropTypes from 'prop-types';


// import '../index.scss';
import { translateThis } from '../../../../../../helpers/language.helper';
import SVGIcons from '../../../../../../assets/images/icons/svgIcons';
import RichTextViewer from '../../../../../../components/rich-text-editor/TextViewer';





const QnAnswerPopup = ({ question = null }) =>
(

    <div className='questionAnswer-popup-wrp f-wrp'>
        <div className='header'>
            <h3>{translateThis('Question')}</h3>
        </div>

        <div className='qn-ans-grid'>
            <div className='header'>
                <div className='qn-block f-wrp'>
                    <p><RichTextViewer richText={question?.question} /></p>
                    <div className='flex-box'>
                        {(question && question?.img) && question?.img.map((img) => <img key={img.fileKey} src={img.tempURL} alt='answer pic' />)}
                        {(question && question.questionId?.img) && question.questionId?.img.map((img) => <img key={img.fileKey} src={img} alt='answer pic' />)}
                    </div>
                </div>
                <div className='ans-block f-wrp'>
                    <h3>{translateThis('Correct answer')}</h3>
                    <div className='ans-box'>
                        {/* <span className='option'>D</span> */}
                        <p>{question?.options.find((op) => op.isAnswer).value}</p>
                        <span className='icon'><SVGIcons.StatusActiveIcon /></span>
                    </div>
                </div>
                <div className='qn-block f-wrp'>
                    <br />
                    {(question && question?.populatedSubjects) && <div className='chip-list'>
                        <h3>{translateThis('subjects')}</h3>
                        <ul>
                            {(question && question?.populatedSubjects) && question?.populatedSubjects.map((sub) => <li key={sub._id} ><span className='chip-sub'>{sub.subjectName}</span></li>)}
                        </ul>
                    </div>}
                    <br />
                    {(question && question?.populatedSystems) && <div className='chip-list'>
                        <h3>{translateThis('systems')}</h3>
                        <ul>
                            {(question && question?.populatedSystems) && question?.populatedSystems.map((sub) => <li key={sub._id} ><span className='chip-sub'>{sub.systemName}</span></li>)}
                        </ul>
                    </div>}
                    <br />
                    {/* {(question && question?.populatedTopics) && <div className='chip-list'>
                        <h3>Topics</h3>
                        <ul>
                            {(question && question?.populatedTopics) && question?.populatedTopics.map((sub) => <li key={sub._id} ><span className='chip-sub'>{sub.topicName}</span></li>)}
                        </ul>
                    </div>} */}
                </div>
            </div>
            <div className='explanation-side'>
                {(question && question.explanation) && <div className='chip-list'>
                    <h3>{translateThis('Explanation')}</h3>
                    {question && question.explanation && <RichTextViewer richText={question.explanation} />}
                    <ul className='flex-box ww'>
                        {question?.explanationImages && question?.explanationImages.map((i, k) => <li key={`${i.fileKey}-${k}`}>
                            <span>
                                <img src={i.tempURL} alt={i.fileKey} />
                            </span>
                        </li>)}

                    </ul>
                </div>}
            </div>
        </div>

    </div>
)


QnAnswerPopup.propTypes = {
    question: PropTypes.any

}
export default QnAnswerPopup;

