import { atom } from "recoil";
import persistAtom from "../recoilPersister";

export const appConfigAtomState = atom({
  key: 'appConfigAtomState', // unique ID (with respect to other atoms/selectors)
  effects_UNSTABLE: [persistAtom],
  default: {
    profile: {},
    selectedLang: 'en',
    isDarkModeOn: false,
    translationJson: {}
  },
});
