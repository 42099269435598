import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';

import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';

import HeaderRightUserInfo from '../components/headerRightUserInfo';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { getCurrentFacilityURL, roundOff } from '../../../helpers/helper.functions';

export default function ProfilePlansRealPage() {
    const apiCaller = new ApiCaller('payment/plans');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    const facilityURL = getCurrentFacilityURL();
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then(({ data }) => setDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Formats the name as a user readable form
     * @param {String} name name of the plan
     * @returns {Object}  formatted plan
     */
    const formatPlanName = (name) => {
        let planName = '';
        let planShortDesc = '';
        switch (name) {
            case '1 month':
                planName = `${translateThis("Monthly")}`;
                planShortDesc = `${translateThis('per month')}`;
                break;
            case '3 month':
                planName = `${translateThis('Quarterly')}`;
                planShortDesc = `${translateThis('every 3 months')}`;
                break;

            case '6 month':
                planName = `${translateThis('Semi annual')}`;
                planShortDesc = `${translateThis('every 6 months')}`;
                break;

            case '1 year':
                planName = `${translateThis('Yearly')}`;
                planShortDesc = `${translateThis('per year')}`;
                break;
            default:
                planName = 'custom';
                planShortDesc = `${translateThis('per month')}`;
                break;
        }
        return { planName, planShortDesc };
    }
    /**
     * OnSelection of a plan
     * @param {String} priceId 
     */
    const onSelectPlan = (priceId) => {
        navigate(`${priceId}`, { replace: true });

    }

    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>

                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Plans")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content'>
                                {isLoading ? <span>Loading...</span> :

                                    <Grid className='plans-sec-wrp' container spacing={1}>

                                        {dataArr.map((price) => (
                                            <Grid key={price.id} item xs={12} sm={6} md={4} lg={3}>
                                                {facilityURL === 'dominicana' ? <div className='each-plan-box f-wrp'>
                                                <div className='price-box'>
                                                    <h1>${roundOff((price.unitAmount/100))}</h1>
                                                    <p>{translateThis('Acceso completo ENURM 2025')}</p>
                                                </div>
                                                <div className='plan-details'>
                                                    <h3 style={{textTransform: 'none'}}>{translateThis('Promo hasta Julio 15')}</h3>
                                                    <ul>
                                                        <li>{translateThis('Accede a todas las preguntas y funcionalidades')}</li>
                                                        <li>{translateThis('Pagas solo una vez y tienes acceso activo hasta la próxima fecha del ENURM en Marzo 2025')}</li>
                                                        <li style={{ color: '#000', fontWeight: '500' }}>{translateThis('Este precio estará disponible hasta Julio 15, luego aumenta.')}</li>
                                                    </ul>
                                                </div>
                                                <div className='foot-btn-sec'>
                                                    <Button onClick={() => onSelectPlan(price.id)} className='plan-btn'>{translateThis('Select plan')}</Button>
                                                </div>
                                            </div> : <div className='each-plan-box f-wrp'>
                                                    <div className='price-box'>
                                                        <h1>${price.unitAmount / 100}</h1>
                                                        <p>{formatPlanName(price.name)?.planShortDesc}</p>
                                                    </div>
                                                    <div className='plan-details'>
                                                        <h3>{formatPlanName(price.name)?.planName} {translateThis('plan')}</h3>
                                                        <ul>
                                                            <li>{translateThis('Access to all questions & features')}</li>
                                                            <li>{translateThis('Auto-renews')} {formatPlanName(price.name)?.planName.toLowerCase()}. {translateThis('Cancel anytime from your profile')}.</li>
                                                        </ul>
                                                    </div>
                                                    <div className='foot-btn-sec'>
                                                        <Button onClick={() => onSelectPlan(price.id)} className='plan-btn'>{translateThis('Select plan')}</Button>
                                                    </div>
                                                </div>}
                                            </Grid>
                                        )) }
                                    </Grid>
                                }
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}
