/* eslint-disable */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Button, Stack, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import AppIcons from '../../../../assets/images/icons';
// import SVGIcons from '../../../../assets/images/icons/svgIcons';

import '../index.scss';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import AppLoader from '../../../../components/appLoader';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { translateThis } from '../../../../helpers/language.helper';

import { DATE_FORMAT } from '../../../../constants';





const ClassesDetailsInfo = (props) => {
    const navigate = useNavigate();
    const { data = {}, isLoading = false, onJoinClass, onTabChange } = props
    const [isResuming, setIsResuming] = React.useState(false);
    const onCancelClick = () => {
        navigate(`/${getCurrentFacilityURL()}/classes`)
    }

    return (

        <div className='exam-preview-main-wrapper classes-preview-main-wrapper'>
            {isLoading ? <AppLoader /> : <Stack className='exam-preview-wrp classes-preview-wrp' spacing={3} style={{ border: 'none' }}>
                <div className='classes-cover-box f-wrp'>
                    <span className='cover-img'><S3ImageViewerWithFallback key={`file-${data?.coverImage}`} fileKey={data?.coverImage} /></span>
                    <div />
                    <div className='cover-content f-wrp'>
                        <h1>{data?.title}</h1>
                        <p>{data?.subtitle}</p>
                    </div>
                </div>
                <div className='exam-basic-info'>
                    <Button className='closebtn' onClick={onCancelClick} variant='text' type='button'  ><SVGIcons.BackIcon /> {translateThis('Back')} </Button>
                    <h3 style={{ marginTop: '10px' }}>{translateThis("Basic info")}</h3>
                    <ul className='basic-list f-wrp'>
                        <li>
                            <div className='each-basic-items'>
                                <img className='icons' src={AppIcons.CalendarPlusIcon} alt='icon' /><b>{translateThis("Creation")}: </b><span>{dayjs(data?.createdAt).format(DATE_FORMAT)}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='eachExam-detail-wrp' style={{ marginTop: '30px' }}>
                    {data?.subjectId ? <>
                        <ul className='chip-list'>
                            {data?.subjectId.map((subject) => <li key={subject._id}><span className='chip'>{subject.subjectName}</span></li>)}

                        </ul>
                    </>
                        : null}
                </div>
                <div className='exam-basic-info'>
                    <h3>{translateThis("Class description")}</h3>
                    <RichTextViewer richText={data?.description || ''} />
                </div>

                {/* <div className='content-section-wrp f-wrp'>
                    <div className='each-content-box f-wrp'>
                        <h3>{translateThis('Assign it to a subject')}</h3>
                        <div className='flex-box assignSubBox'>
                            <button type='button' onClick={() => null} key={null} className={`image-blk`}>
                                <img src={AppIcons.anatomy} alt='images' />
                                <h3>Task description</h3>
                                <p>2 word description</p>
                            </button>
                        </div>
                    </div>
                </div> */}

                <div className='button-sec f-wrp' style={{ paddingTop: '0px', paddingBottom: '15px', justifyContent: 'flex-end' }}>
                    <div className='lft-btn-sec'>
                        {data?.isJoined ? <Tooltip
                            title={data?.isValidToAttend ? data?.inValidToStartReason : ''}
                        >
                            <LoadingButton
                                // loading={isResuming}
                                // disabled={!data?.isValidToAttend}
                                type="submit"
                                className='fill-btn'
                                onClick={() => onTabChange('1')}
                            >
                                {translateThis("Next")}
                            </LoadingButton>
                        </Tooltip> : <LoadingButton
                            loading={isResuming}
                            // disabled={!data?.isValidToAttend}
                            type="submit"
                            className='fill-btn'
                            onClick={() => onJoinClass()}
                        >
                            {translateThis("Start")}
                        </LoadingButton>}
                    </div>
                </div>
            </Stack>}
        </div>
    );
}

ClassesDetailsInfo.propTypes = {
    exam: PropTypes.any,
    onJoinClass: PropTypes.func,
    onPrevExamsClick: PropTypes.func
}

export default ClassesDetailsInfo;