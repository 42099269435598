import React from 'react';
import './index.scss';

const ProviderDashboard = ()=> {
    const pageName = 'Dashboard';
    return (
        <div>
            {pageName}
        </div>
    )

}

ProviderDashboard.propTypes = {};

export default  ProviderDashboard;