import * as React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { translateThis } from '../../../../helpers/language.helper';
// import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';

import AppIcons from '../../../../assets/images/icons';
import '../index.scss';
import SelectedExam from './selectedExam';
import PreviousAttemptsTable from './previousAttempts';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar';
import AppLoader from '../../../../components/appLoader';





const ExamTabSection = ({requestType}) => {
    const apiCaller = new ApiCaller(`universityTests/student`)
    const [isLoading, setIsLoading] = React.useState(true);
    const [dataArr, setDataArr] = React.useState([]);
    const [selectedExam, setSelectedExam] = React.useState(null);
    const [selectedView, setSelectedView] = React.useState('list');
    // const facilityURL = getCurrentFacilityURL()
    React.useEffect(() => {
        getDataArr()
        // eslint-disable-next-line
    }, []);

    /**
     * Get Assigned Exam from Api
     */
    const getDataArr = () => {
        apiCaller.getList({query: `type=${requestType}`}).then((data) => setDataArr(data?.result))
            .catch(() => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))
    }


    return (
        <div className='exam-list-wrapper f-wrp'>
            {selectedView === 'list' ? <div className='row'>
                {isLoading ? <AppLoader/> : (!isLoading && dataArr.length > 0) ? <>
                    {
                        dataArr.map((exam, key) => (
                            // eslint-disable-next-line
                            <div onClick={() => {
                                setSelectedExam(exam)
                                setSelectedView('view');
                            }} className='col-lg-4 col-md-6 col-sm-12 col-xs-12' key={key}>
                                <div className={`each-examList-box f-wrp `}>
                                    {/* {<span className='complete-icon'>{AppIcons.iconDemo}</span>} */}
                                    <span className='logo-icon'><img src={AppIcons.iconDemo} alt='offer' /></span>
                                    <h4>{exam.name}</h4>
                                    <p>{exam.introduction}</p>
                                    <p>{exam.duration}&nbsp;{exam.durationMetric}{exam.duration > 1 ? 's' : ''}&nbsp;&#8226;&nbsp;{exam.questionCount} {translateThis('Questions')}</p>
                                </div>
                            </div>
                        ))
                    }</> : <div className='NoItem-box f-wrp'>
                            <span><img src={AppIcons.noExam} alt='No Exams' /></span>
                            <h3>{translateThis('No exams yet')}</h3>
                            <p>{translateThis('No exams text')}</p>
                            {/* <Link to={`/${facilityURL}/create-practice`} className='box-btn' >{translateThis('Try practice mode')}</Link> */}
                        
                        </div>}
            </div> : (selectedView === 'view') ? <SelectedExam
                onCancelClick={() => {
                    setSelectedView('list');
                    setSelectedExam(null)
                }}
                onPrevExamsClick={() => {
                    setSelectedView('prev-test-list');
                }}
                exam={selectedExam} 
                /> :
                (selectedView === 'prev-test-list') ? <PreviousAttemptsTable
                onCancelClick={() => {
                    setSelectedView('list');
                    setSelectedExam(null)
                }}
                 exam={selectedExam}  /> :
                    null}
        </div >
    )
}
ExamTabSection.propTypes = {
    requestType: PropTypes.string
}
export default ExamTabSection;