import * as React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, InputLabel, FormControlLabel } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import TextInput from '../../../../components/inputs/textInput/textInput';
import '../index.scss';
import { getAuthId } from '../../../../helpers/auth.helper';
import { getCurrentFacilityURL, usdFormatter } from '../../../../helpers/helper.functions';
import { showSnackbar } from '../../../../components/snackbar/index';
import ProfilePicUploader from '../../../../components/awsS3ImageUploader/profilePic.uploader';

import { getProfile, updateProfile } from '../../../../services/api/auth';
import { translateThis } from '../../../../helpers/language.helper';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import AppIcons from '../../../../assets/images/icons';
import ApiCaller from '../../../../services/api/general';
import { DATE_EXTEND_FORMAT } from '../../../../constants';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import UnSubPopup from './unSubPopup';




export default function StudentProfile() {
    const userId = getAuthId();
    const navigate = useNavigate();
    const [isOpenSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);
    const [isOpenResetDialog, setOpenResetDialog] = React.useState(false);
    const [isOpenUnSubDialog, setOpenUnSubDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    const [subscriptionData, setSubscriptionData] = React.useState({});
    const [resetSelectionArr, setResetSelectionArr] = React.useState(['Metrics', 'Tests']);
    // eslint-disable-next-line
    const facilityURL = getCurrentFacilityURL()

    /**
  the legendary use effect function
  This will run very first on render
   */
    React.useEffect(() => {
        getData()
        getSubscriptionData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getProfile()
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get subscription data from api
    const getSubscriptionData = () => {
        const apiCaller = new ApiCaller('auth')
        apiCaller.getData('subscription-details')
            .then((data) => setSubscriptionData(data))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...data };
        setData({ ...formDataAs, ...{ [name]: value } });
    }


    /**
   * updateData
   */
    const updateData = () => {
        if (userId) {
            const postData = {
                name: data.name,
                lastName: data.lastName,
                phone: data.phone,
                nationality: data.nationality,
                country: data.country,
                fieldOfStudy: data.fieldOfStudy,
                profileImg: data?.profileImg?.fileKey
            }
            updateProfile(postData)
                .then(() => showSnackbar().success('Profile updated successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getData();
                })
        }

    }

    const onCancel = () => {
        navigate(`/${facilityURL}/my-portal`);
    }

    const onSubscribeClick = () => {
        navigate(`/${facilityURL}/plans`);
    }


    const onUnsubscribe = () => {
        setOpenSubscribeDialog(true);
    }
    
    
    const onUnSubscribeConfirm = () => {
        const apiCaller = new ApiCaller('payment/cancel-subscription');
        apiCaller.postData({}).then(({ data }) => {
            setOpenSubscribeDialog(false);
            if (data.isShowUnSubPopup) {
                setOpenUnSubDialog(true);
            } else {
                showSnackbar().success('Unsubscribed successfully!.')
                window.location.reload();
            }


        })

    }

    /**
     * On reset account
     */
    const onResetButton = () => {
        if (resetSelectionArr.length > 0) {
            setOpenResetDialog(true);
        } else {
            showSnackbar().warning('Please select a option to reset')
        }
    }
    
    /**
     * On reset account
     */
    const onResetConfirm = () => {
        if (resetSelectionArr.length > 0) {
            if (userId) {
                const postData = {
                    selectedOptions: resetSelectionArr
                }
                const apiCaller = new ApiCaller('analytics/reset-logs')
                apiCaller.postData(postData)
                    .then(() => showSnackbar().success('Account reset successfully'))
                    .catch(() => showSnackbar().failure("Something went wrong!."))
                    .finally(() => {
                        setOpenResetDialog(false);
                        // setResetSelectionArr([])
                        getData();
                    })
            }
        } else {
            showSnackbar().warning('Please select a option to reset')
        }
    }



    return (
        <>

            {(isLoading) ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : (!isLoading && data) ?
                <div className='profile-wrapper f-wrp'>
                    <div className='profile-img-con'>
                        <ProfilePicUploader
                            key={`profile-pic`}
                            onUploadStart={() => null}
                            onUploadEnd={(img) => setFormValue("profileImg", img)}
                            onRemoveFile={() => setFormValue("profilePic", { fileKey: '' })}
                            defaultImage={data?.profileImg}
                        />
                    </div>
                    <div className='profile-details child-profile stud-profile f-wrp'>

                        <form>
                            {(data?.name) ? <div className='formBox f-wrp'>
                                <h3>{translateThis('Basic info')}</h3>
                                <Grid className='profile-form' container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel shrink htmlFor="first-Name">
                                            {translateThis('Name')}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`first-Name`}
                                            name='name'
                                            type='text'
                                            value={data?.name}
                                            getValue={setFormValue}
                                            required
                                            placeholder={translateThis('firstName')}
                                            className='custom-textfield'
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`last-Name`}
                                            name='lastName'
                                            type='text'
                                            value={data?.lastName}
                                            getValue={setFormValue}
                                            required
                                            placeholder={translateThis('lastName')}
                                            className='custom-textfield'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel shrink htmlFor="Email">
                                            {translateThis('Email')}
                                        </InputLabel>
                                        <TextInput
                                            key={`login-email`}
                                            name='email'
                                            type='email'
                                            value={data?.email}
                                            isDisabled
                                            placeholder={translateThis('loginForm_emailPlaceHolder')}
                                            className='custom-textfield'
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <InputLabel shrink htmlFor="Nationality">
                                            {translateThis('Nationality')}
                                        </InputLabel>
                                        <TextInput
                                            key={`studentProfileNationality`}
                                            name='nationality'
                                            type='text'
                                            value={data?.nationality}
                                            getValue={setFormValue}
                                            required
                                            placeholder={translateThis('Nationality')}
                                            className='custom-textfield'
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <InputLabel shrink htmlFor="Phone">
                                            {translateThis('Phone number')}
                                        </InputLabel>
                                        <TextInput
                                            key={`studentProfilePhone`}
                                            name='phone'
                                            type='number'
                                            value={data?.phone}
                                            getValue={setFormValue}
                                            required
                                            placeholder={translateThis('Phone')}
                                            className='custom-textfield'
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <InputLabel shrink htmlFor="Country of residence">
                                            {translateThis('City, State, Country, Zip code')}
                                        </InputLabel>
                                        <TextInput
                                            key={`studentProfileCountryOfResidence`}
                                            name='country'
                                            type='text'
                                            value={data?.country}
                                            getValue={setFormValue}
                                            required
                                            placeholder={translateThis('Santa Monica, CA, US 90291')}
                                            className='custom-textfield'
                                        />
                                    </Grid>



                                </Grid>
                            </div> : null}

                            <div className='formBox f-wrp' style={{ marginTop: '20px' }}>
                                <h3>{translateThis('Student info')}</h3>
                                <Grid className='profile-form' container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel shrink htmlFor="fieldOfStudy">
                                            {translateThis('fieldOfStudy')}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`fieldOfStudy`}
                                            name='fieldOfStudy'
                                            type='text'
                                            value={data?.fieldOfStudy}
                                            getValue={setFormValue}
                                            // required
                                            placeholder={translateThis('fieldOfStudy')}
                                            className='custom-textfield'
                                        />
                                    </Grid>


                                </Grid>
                            </div>



                            {subscriptionData ? <div className='formBox f-wrp' style={{ marginTop: '20px' }}>
                                <h3>{translateThis('Subscription info')}</h3>
                                <Grid className='profile-form' container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel shrink htmlFor="fieldOfStudy">
                                            {translateThis('subscription status')} : <span style={{ color: `${subscriptionData.status ? '#34B606' : '#E62A2A'}`, fontWeight: '400', fontSize: '16px' }}>{subscriptionData.status ? 'Active' : 'Inactive'}</span>
                                        </InputLabel>

                                        {subscriptionData.status ? <Button className='subscription-btn'
                                            onClick={() => onSubscribeClick()}
                                            type='button'>{translateThis('Upgrade subscription')}</Button> : <Button className='subscription-btn'
                                                onClick={() => onSubscribeClick()}
                                                type='button'>{translateThis('Subscribe')}</Button>}
                                    </Grid>

                                    {subscriptionData.status ? <>
                                        <Grid item xs={12} sm={12} md={12}>

                                            <InputLabel shrink htmlFor="fieldOfStudy">
                                                {translateThis('subscription start date')}: <span style={{ fontWeight: '600', fontSize: '16px' }}>{dayjs.unix(subscriptionData.startDate).format(DATE_EXTEND_FORMAT)}</span>
                                            </InputLabel>

                                            {subscriptionData.endDate && <InputLabel shrink htmlFor="fieldOfStudy">
                                                {translateThis('subscription renewal date')}: <span style={{ fontWeight: '600', fontSize: '16px' }}>{dayjs.unix(subscriptionData.endDate).format(DATE_EXTEND_FORMAT)}</span>
                                            </InputLabel>}

                                            <InputLabel shrink htmlFor="fieldOfStudy">
                                                {translateThis('subscription amount')}: <span style={{ fontWeight: '600', fontSize: '16px' }}>${usdFormatter(subscriptionData.amount / 100)}</span>
                                            </InputLabel>

                                            {(subscriptionData.invoice) && <InputLabel shrink htmlFor="fieldOfStudy">
                                                {translateThis('Invoice url')}: <Button className='subscription-btn' style={{ fontSize: '16px' }}
                                                    onClick={() => window.open(subscriptionData.invoice, '_blank')}
                                                    type='button'>{translateThis('Download')}</Button>
                                            </InputLabel>}

                                            {/* If cancel requested show a message and hide unsubscribe button */}
                                            {subscriptionData.cancelRequestedAt ? <InputLabel shrink htmlFor="fieldOfStudy">
                                                {`* As per your request, your subscription will be auto cancelled on ${dayjs.unix(subscriptionData.cancelAt).format(DATE_EXTEND_FORMAT)} `}
                                            </InputLabel> : <Button className='subscription-btn' style={{ color: '#E62A2A' }}
                                                onClick={() => onUnsubscribe()}
                                                type='button'>{translateThis('Unsubscribe')}</Button>}
                                        </Grid>


                                        {/* ABOVE Need later */}




                                    </> : null
                                    }

                                </Grid>
                            </div> : null}


                            <div className='formBox resetAccount-blk f-wrp' style={{ marginTop: '20px' }}>
                                <h3 style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{translateThis('Reset account')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}>{translateThis('Reset all selected data from your account.')} <b>{translateThis('This action can only be done once')}</b> </span>} placement="top">
                                    <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                </Tooltip></h3>
                                {(data && (data?.canResetMetrics || data?.canResetTests)) ? <Grid className='profile-form' container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {/* <InputLabel shrink htmlFor="fieldOfStudy">
                                            {translateThis('Select what you want to reset from your account')}
                                        </InputLabel> */}
                                        {/* <FormLabel className='main-head' id="accidentFormQn_7">{translateThis('accidentFormQn_7')}</FormLabel> */}
                                        <FormGroup
                                            row
                                            aria-labelledby={translateThis('Select what you want to reset from your account')}
                                            // defaultValue={data?.fieldOfStudy}
                                            id="provider_accident_form_accidentFormQn_5_part_of_the_body"
                                            name="provider_accident_form_accidentFormQn_5_part_of_the_body"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setResetSelectionArr((arr) => {
                                                        if (arr.includes(e.target.value)) {
                                                            return arr.filter((a) => a !== e.target.value)
                                                        }
                                                        arr.push(e.target.value);
                                                        return arr
                                                    })
                                                } else {
                                                    setResetSelectionArr((arr) => arr.filter((a)=>a!==e.target.value))
                                                }

                                            }}
                                        // value={data?.fieldOfStudy}
                                        >
                                            {(data) && <FormControlLabel  disabled checked value="Metrics" control={<Checkbox />} label={translateThis("Metrics & questions")} />}
                                            {(data) && <FormControlLabel disabled checked value="Tests" control={<Checkbox />} label={translateThis("Test history")} />}
                                        </FormGroup>

                                        <div className='button-sec f-wrp'>
                                            <div className='lft-btn-sec' style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                    onClick={onResetButton} className='fill-btn'>{translateThis('Reset account')}</Button>

                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>: <span>You have already reset your account.</span>}
                            </div>


                            {/* <Stack className='additional-btn f-wrp' variant='div' spacing={3}>
                            <Link to={`/${facilityURL}/user-forgot-password`} >{translateThis('Forgot your password')}</Link>
                        </Stack> */}
                            <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                                <div className='ryt-btn-sec'>
                                    <Button onClick={onCancel} className='cancel-btn'>{translateThis('Cancel')}</Button>
                                    <Button onClick={updateData} className='fill-btn'>{translateThis('Save changes')}</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                : null}
            {isOpenSubscribeDialog && <ConfirmDialog
                isOpen={isOpenSubscribeDialog}
                onClose={() => {
                    setOpenSubscribeDialog(false);
                }}
                title="Are you sure?"
                className={'delete-popup'}
                description={<span>{translateThis('Are you sure you want to cancel the subscription')}</span>}
                okayButtonText={`${translateThis('Unsubscribe')}`}
                onConfirm={onUnSubscribeConfirm}
            />}
            {isOpenResetDialog && <ConfirmDialog
                isOpen={isOpenResetDialog}
                onClose={() => {
                    setOpenResetDialog(false);
                }}
                title={`${translateThis('Reset account')}`}
                className={'delete-popup'}
                description={<><span>{`${translateThis('Are you sure you want to reset your account? All the information will be lost')}`}</span><span style={{ color: 'var(--Accent)' }}>{`${translateThis('This action cannot be undone')}`}</span></>}
                okayButtonText={`${translateThis('Confirm')}`}
                onConfirm={onResetConfirm}
            />}

            {isOpenUnSubDialog && <ConfirmDialog
                isOpen={isOpenUnSubDialog}
                onClose={() => {
                    setOpenUnSubDialog(false);
                }}
                // title=""
                className={'delete-popup subscription-popup unsub-popup'}
                description={<><UnSubPopup /></>}
                // okayButtonText={''}
                onConfirm={''}
            />}

        </>
    );
}