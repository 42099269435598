import * as React from 'react';
import { Box } from '@mui/material';
import TabLayout from '../../../components/tabs';

import './index.scss';


import { translateThis } from '../../../helpers/language.helper';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import BasicInfoPage from './userTabItems/basicInfo';
import StatisticsPage from './userTabItems/statistics';
import PlanAndPayment from './userTabItems/planAndPayment';
import HeaderRightUserInfo from '../components/headerRightUserInfo';

const UserTabSectionPage = () => {
    // const [showPopup, setShowPopup] = React.useState(false);

    const tabNames = [{name:"Basic info", icon:<SVGIcons.SingleUserIcon/>}, {name:"Statistics", icon:<SVGIcons.StatisticsIcon/>}, {name:"Plan & Payments", icon:<SVGIcons.CurrencyIcon/>}];
    const tabContents = [
        <span key={'to_Basic_info'}><BasicInfoPage /></span>,
        <span key={'to_Statistics'}><StatisticsPage /></span>,
        <span key={'to_Plan_Payments'}><PlanAndPayment /></span>,
    ];

    return (
        <>
            <AppLayout
                // pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            <div className='main-category-page user-tab-wrapper'>
                                <div className='main-category-header'>
                                    <h2>{translateThis("User")}</h2>
                                    <HeaderRightUserInfo />
                                </div>
                                <div className='main-category-content maxWidth_1200'>
                                    <TabLayout
                                        tabContents={tabContents}
                                        tabNames={tabNames}
                                    />
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </AppLayout>


        </>
    )
}

export default UserTabSectionPage;