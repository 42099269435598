import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import AppPopOverMenu from '../../../../components/appPopOverMenu/index';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import ModalPopUp from '../../../../components/modal/modal.popup';
import CreateReport from './createReport';


function ReportQuestionButton(props) {
    const { question } = props;
    const [openReportDialog, setOpenReportDialog] = useState(false);
    const [optionalReason, setOptionalReason] = useState('');
   
    return (
        <>
            <AppPopOverMenu icon={<Button style={{ flexDirection: 'row', gap: '5px' }} className='toolButton'>
                <span>{SVGIcons.WarningIcon()}</span>
                <p>{translateThis('Report')}</p></Button>} options={[
                    {
                        label: <h5 style={{ margin: '0', fontWeight: '600' }}>{translateThis('Report question')}</h5>,
                        onClick: () => null
                    },
                    {
                        label: <>{translateThis('The image has an issue')}</>,
                        onClick: () => {
                            setOptionalReason(translateThis('The image has an issue'))
                            setOpenReportDialog(true);
                        }
                    },
                    {
                        label: <>{translateThis('The spelling or grammar is incorrect')}</>,
                        onClick: () => {
                            setOptionalReason(translateThis('The spelling or grammar is incorrect'))
                            setOpenReportDialog(true);
                        }
                    },
                    {
                        label: <>{translateThis('The question is wrong')}</>,
                        onClick: () => {
                            setOptionalReason(translateThis('The question is wrong'))
                            setOpenReportDialog(true);
                        }
                    },
                    {
                        label: <>{translateThis('Other')}</>,
                        onClick: () => {
                            setOpenReportDialog(true);
                        }
                    },
                ]} />

            {openReportDialog && <ModalPopUp
                isOpen={openReportDialog}
                onClose={() => {
                    setOpenReportDialog(false);
                }}
                className={'modal-popup create-report-popup'}
                children={<div>
                    <CreateReport
                        optionalReason={optionalReason}
                        question={question}
                        onClose={() => setOpenReportDialog(false)}
                    />
                </div>}

            />}
        </>
    )
}
ReportQuestionButton.propTypes = {
    question: PropTypes.any
}

export default ReportQuestionButton;