import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';
// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';


const EarlyChildhoodHealthAssessmentRecordView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ECHARView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />
                {/* <div className='prof-sec'>
                    <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div>
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div> */}
                <div className='gap' />
                <h4>State of Connecticut Department of Education</h4>
                <h3><b>Early Childhood Health Assessment Record</b></h3>
                <i style={{ fontSize: '14px' }}>For Children ages birth-5</i>
                <div className='gap' />
                <p>To Parent or Guardian: <b> In order to provide the best experience, early childhood providers must understand your child’s health needs. This form requests information from you (Part 1) which will be helpful to the health care provider when he or she completes the health evaluation (Part 2) and oral health assessment (Part 3). State law requires complete primary immunizations and a health assessment by a physician, an advanced practice registered nurse, a physician assistant, or a legally qualified practitioner of medicine, an advanced practice registered nurse or a physician assistant stationed at any military base prior to entering an early childhood program in Connecticut.</b></p>
                <div className='gap' />

                <div className='flex-box'>
                    <p>Child’s Name (last, first, middle): <b>{answerGetter('early_childhood_health_assessment_form_question_name', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                    <p>Birth Date: <b>{answerGetter('early_childhood_health_assessment_form_Key_birthday', formData)?.answer || dayjs(child?.birthday).format(DATE_FORMAT) || '-'}</b></p>
                    <div className='check-box inline' style={{ marginBottom: '0' }}>
                        <ul>
                            <li className={answerGetter("early_childhood_health_assessment_form_Key_question_sex", formData)?.answer === 'male' ? 'checked' : ''}>Male</li>
                            <li className={answerGetter("early_childhood_health_assessment_form_Key_question_sex", formData)?.answer === 'female' ? 'checked' : ''}>Female</li>
                        </ul>
                    </div>
                </div>
                <div className='flex-box'>
                    <p>Address: <b>{answerGetter('early_childhood_health_assessment_form_question_address', formData)?.answer}, {answerGetter('early_childhood_health_assessment_form_question_city', formData)?.answer}, {answerGetter('early_childhood_health_assessment_form_question_zipcode', formData)?.answer}</b></p>
                    <p>Race/Ethnicity: <b>{answerGetter('early_childhood_health_assessment_form_question_raceEthnicity', formData)?.answer || '-'}</b></p>
                    <div className='width175'>&nbsp;</div>
                </div>

                <div className='auto-flex' style={{ justifyContent: 'space-between' }}>
                    <p>Parent/guardian name (last, first, middle): <b>{answerGetter('early_childhood_health_assessment_form_question_guardianName', formData)?.answer || '-'}</b></p>
                    <p>Home Phone: <b>{answerGetter('early_childhood_health_assessment_form_question_guardianHomePhone', formData)?.answer || '-'}</b></p>
                    <p style={{ textAlign: 'right' }}>Cell Phone: <b>{answerGetter('early_childhood_health_assessment_form_question_guardianCellPhone', formData)?.answer || '-'}</b></p>
                </div>

                <div className='gap' />

                <p>Early Childhood Program: <b>{answerGetter('early_childhood_health_assessment_form_question_ChildhoodName', formData)?.answer || '-'} </b></p>
                <p>Phone: <b>{answerGetter('early_childhood_health_assessment_form_Key_ChildhoodPhone', formData)?.answer || '-'} </b></p>
                <div className='auto-flex' style={{ justifyContent: 'space-between' }}>
                    <p>Primary Health Care Provider: <b>{answerGetter('early_childhood_health_assessment_form_question_PrimaryHealthCareProvider', formData)?.answer || '-'}</b></p>
                    <p>Name of Dentist: <b>{answerGetter('early_childhood_health_assessment_form_Key_dentistName', formData)?.answer || '-'}</b></p>
                </div>
                <p>Health Insurance Company/Number or Medicaid/Number: <b>{answerGetter('early_childhood_health_assessment_form_Key_medicaidNumber', formData)?.answer || '-'} </b></p>

                <div className='gap' />

                <div className="details-section" style={{ alignItems: 'center' }}>
                    <div className="lft-section">
                        <p>Does the child have health insurance? <b> {answerGetter('early_childhood_health_assessment_form_question_insuranceQN_1', formData)?.answer || '-'}</b></p>
                        <p>Does the child have dental insurance? <b> {answerGetter('early_childhood_health_assessment_form_question_insuranceQN_2', formData)?.answer || '-'}</b></p>
                        <p>Does the child have HUSKY insurance? <b> {answerGetter('early_childhood_health_assessment_form_question_insuranceQN_3', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        <p>If your child does not have health insurance, call: <br /><b>1-877-CT-HUSKY</b></p>
                    </div>
                </div>
                <div className='gap' />
                <h4 style={{ textAlign: 'center' }}>Part 1 — To be completed by parent/guardian.</h4>

                <div className='gap' />

                <div className='auto-grid custom-grid'>
                    <p>Any health concerns: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_1', formData)?.answer || '-'}</b></p>
                    <p>Frequent ear infections: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_9', formData)?.answer || '-'}</b></p>
                    <p>Asthma treatment: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_16', formData)?.answer || '-'}</b></p>
                    <p>Allergies to food, bee stings, insects: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_2', formData)?.answer || '-'}</b></p>
                    <p>Any speech issues: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_10', formData)?.answer || '-'}</b></p>
                    <p>Seizure: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_17', formData)?.answer || '-'}</b></p>
                    <p>Allergies to medication: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_3', formData)?.answer || '-'}</b></p>
                    <p>Any problems with teeth: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_11', formData)?.answer || '-'}</b></p>
                    <p>Diabetes: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_18', formData)?.answer || '-'}</b></p>
                    <p>Any other allergies: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_4', formData)?.answer || '-'}</b></p>
                    <p>Has your child had a dental examination in the last 6 months?: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_12', formData)?.answer || '-'}</b></p>
                    <p>Any heart problems: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_19', formData)?.answer || '-'}</b></p>
                    <p>Any daily/ongoing medications: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_5', formData)?.answer || '-'}</b></p>
                    <p>Emergency room visits: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_20', formData)?.answer || '-'}</b></p>
                    <p>Any problems with vision: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_6', formData)?.answer || '-'}</b></p>
                    <p>Very high or low activity level: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_13', formData)?.answer || '-'}</b></p>
                    <p>Any major illness or injury: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_21', formData)?.answer || '-'}</b></p>
                    <p>Uses contacts or glasses: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_7', formData)?.answer || '-'}</b></p>
                    <p>Weight concerns: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_14', formData)?.answer || '-'}</b></p>
                    <p>Any operations/surgeries: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_22', formData)?.answer || '-'}</b></p>
                    <p>Any hearing concerns: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_8', formData)?.answer || '-'}</b></p>
                    <p>Problems breathing or coughing: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_15', formData)?.answer || '-'}</b></p>
                    <p>Lead concerns/poisoning: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_23', formData)?.answer || '-'}</b></p>
                    <p>Sleeping concerns: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_24', formData)?.answer || '-'}</b></p>
                    <p>High blood pressure: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_25', formData)?.answer || '-'}</b></p>
                    <p>Eating concerns: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_26', formData)?.answer || '-'}</b></p>
                    <p>Toileting concerns: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_27', formData)?.answer || '-'}</b></p>
                    <p>Birth to 3 services: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_28', formData)?.answer || '-'}</b></p>
                    <p>Preschool Special Education: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_29', formData)?.answer || '-'}</b></p>

                </div>
                <div className='gap' />
                <p>Explain all “yes” answers or provide any additional information:</p>
                <p><b>{answerGetter('early_childhood_health_assessment_form_comments', formData)?.answer || '-'} </b></p>
                <div className='gap' />
                <p>Have you talked with your child’s primary health care provider about any of the above concerns? <b>Yes</b></p>
                <p>Please list any medications your child will need to take during program hours:</p>
                <p><b>{answerGetter('early_childhood_health_assessment_form_comments_2', formData)?.answer || '-'}</b></p>

                <i style={{ fontSize: '13px' }}>All medications taken in child care programs require a separate Medication Authorization Form signed by an authorized prescriber and parent/guardian.</i>

                <div className="signature-blk" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ paddingRight: '25px' }}>
                        <p style={{ color: '#4C483E' }}><b>I give my consent for my child’s health care provider and early childhood provider or health/nurse consultant/coordinator to discuss the information on this form for confidential use in meeting my child’s health and educational needs in the early childhood program.</b></p>
                    </div>

                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo?.parentSignature
                                ? data.additionalInfo?.parentSignature
                                : ''
                        }</h3>
                        <p>Parent / guardian signature</p>
                        <p>Submission time: <b>{
                            data.additionalInfo && data.additionalInfo?.parentSignedDate
                                ? data.additionalInfo?.parentSignedDate
                                : ''
                        }</b></p>
                    </div>
                </div>
                <div className='gap' />
                <p style={{ color: '#4C483E' }}><b>C.G.S. Section 10-16q, 10-206, 19a.79(a), 19a-87b(c); P.H. Code Section 19a-79-5a(a)(2), 19a-87b-10b(2); Public Act No. 18-168.</b></p>
            </div>
        </>
    )
}

EarlyChildhoodHealthAssessmentRecordView.propTypes = {
    data: PropTypes.any
}
export default EarlyChildhoodHealthAssessmentRecordView;