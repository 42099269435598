import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import TabLayout from '../../../../components/tabs';

import '../index.scss';

// import { translateThis } from '../../../../helpers/language.helper';
import UserDrawer from '../../../../components/drawer/drawer';
import AppLayout from '../../../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';
import ClassesDetailsDashboard from './classesDetailsDashboard';
import ClassesDetailsVideos from './classesDetailsVideos';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import SubscriptionPopup from '../../createTestSection/Forms/subscriptionPopup';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import ClassesDetailsInfo from './classesDetailsDashboardInfo';




const ClassesDetailPage = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true)
    const [data, setData] = React.useState({});
    const [isUserSubscribed, setIsUserSubscribed] = React.useState(false);
    const [isOpenSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);
    React.useEffect(() => {
        getSubscriptionStatus();        

        // eslint-disable-next-line
    }, [id])

    const getSubscriptionStatus = () => {
        const apiCaller = new ApiCaller('auth');
        apiCaller.getData('subscription-status').then(({ subscribed }) => {
            setIsUserSubscribed(subscribed);
        }).finally(()=> {
            if (id) {
                getData();
            }
        })
    }

    const getData = () => {
        const apiCaller = new ApiCaller('class');
        apiCaller.getData(id).then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))

    }

    
    const joinClass = () => {
        if (isUserSubscribed || !data?.isSubscriptionNeeded) {
            const apiCaller = new ApiCaller('class');
            apiCaller.customPostData('/join', { classId: data?._id }).then((d) => {
                if (d.success) {
                    getData();
                }
            }).catch(() => showSnackbar().failure('Something went wrong!. Please try again later'))
                .finally(() => setIsLoading(false))
        } else {
            setOpenSubscribeDialog(true)
        }
        
    }

    /**
     * When user clicks on subscribe
     */
    const onSubscribeConfirm = () => {
        navigate(`/${getCurrentFacilityURL()}/plans`, { replace: true })

    }
    const [activeTabIndex, setActiveTabIndex] = React.useState('0');
    const onTabChange = (id) => {
        setActiveTabIndex(id) ;
        window.scrollTo(0, 0);
    }
    const tabNames = [{ name: "Info", joinRequired: false }, { name: "class", joinRequired: false }, { name: "Videos", joinRequired: true }];
    const tabContents = [
        <div key={'to_Info'}>
            <ClassesDetailsInfo isLoading={isLoading} data={data} onJoinClass={joinClass} onTabChange = {onTabChange} />
        </div>,
        <div key={'to_Class'}>
        <ClassesDetailsDashboard isLoading={isLoading} data={data} onJoinClass={joinClass} onTabChange = {onTabChange} />
    </div>,
        <div key={'to_Videos'}>
            <ClassesDetailsVideos isLoading={isLoading} videos={data?.videoLinks || []} isJoined={data?.isJoined} onJoinClass={joinClass} />
        </div>,
    ];

    return (
        <>
            <AppLayout
                // pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            <div className='main-category-page classes-detail-page'>
                                <div className='main-category-header'>
                                    <h2>{data.title && `${data.title}`}</h2>
                                    <HeaderRightUserInfo />
                                </div>
                                <div className='main-category-content classes-tab-wrp'>
                                    <div className='classes-tab-outerBox f-wrp'>
                                        <div className='classes-tab-box maxWidth_1200'>
                                            <TabLayout
                                                tabContents={tabContents}
                                                tabNames={tabNames}
                                                activeTab = {activeTabIndex}
                                                onTabChange={(v)=>onTabChange(v)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                        {isOpenSubscribeDialog && <ConfirmDialog
                            isOpen={isOpenSubscribeDialog}
                            onClose={() => {
                                setOpenSubscribeDialog(false);
                            }}
                            // title=""
                            className={'delete-popup subscription-popup'}
                            description={<SubscriptionPopup />}
                            okayButtonText={'Subscribe'}
                            onConfirm={onSubscribeConfirm}
                        />}
                    </Box>
                </Box>
            </AppLayout>


        </>
    )
}

export default ClassesDetailPage;