import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { translateThis } from '../../../../helpers/language.helper';
import AppLayout from '../../../../layouts/app/AppLayout';
import UserDrawer from '../../../../components/drawer/drawer';
// import AppIcons from '../../../../assets/images/icons';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import { DATE_EXTEND_FORMAT } from '../../../../constants';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';


const ViewQuestionPage = () => {
    const apiCaller = new ApiCaller('questions');
    const charArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [questionMetrics, setQuestionMetrics] = useState([]); // QuestionMetrics

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then((data) => {
                setData(data);
                setQuestionMetricsItems(data)
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const setQuestionMetricsItems = (data) => {
        const questionMetrics = [
            {
                icon: SVGIcons.DateIcon(),
                title: 'Creation date',
                info: data.createdAt ? dayjs(data?.createdAt).format(DATE_EXTEND_FORMAT) : '',
            },
            {
                icon: SVGIcons.IDIcon(),
                title: 'Question ID',
                info: data?.questionNumber || '-',
            },
            {
                icon: SVGIcons.TimerIcon(),
                title: 'Times used',
                info: data?.timesUsed || 0,
            },
            {
                icon: SVGIcons.CorrectIcon(),
                title: 'Correct',
                info: data?.correctPercentage ? `${data?.correctPercentage}%` : '0%',
            },
            {
                icon: SVGIcons.InCorrectIcon(),
                title: 'Incorrect',
                info: data?.inCorrectPercentage ? `${data?.inCorrectPercentage}%` : '0%',
            },
            {
                icon: SVGIcons.OmittedIcon(),
                title: 'Omitted',
                info: data?.omittedPercentage ? `${data?.omittedPercentage}%` : '0%',
            },
        ];
        setQuestionMetrics(questionMetrics)

    }



    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                {isLoading ? <span>Loading...</span> : (data?._id) ? <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("View Question")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content'>

                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Basic info')}</h3>
                                    <ul className='basic-info-list'>
                                        {questionMetrics.map((element, key) => (
                                            <li key={key}><span>{element.icon}</span><b>{translateThis(element.title)} : </b> {translateThis(element.info)}</li>
                                        ))}
                                    </ul>
                                </div>

                                <Grid container spacing={0} style={{ marginLeft: '-16px', marginTop: '0px', paddingBottom: '24px', width: 'calc(100% + 16px)' }}>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={2} style={{ marginLeft: '0px', marginTop: '0px', width: '100%' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Question')}</h3>
                                                    {/* <p>{data?.question}</p> */}
                                                    {(data?.question) ? <RichTextViewer richText={data?.question} /> : null}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Images')}</h3>
                                                    <div className='added-question-list-items f-wrp'>
                                                        <div className='flex-box'>
                                                            {(data?.img && data?.img.filter((img) => img.tempURL).length > 0) ? data?.img.map((img) => (
                                                                <span key={img.fileKey} className='image-blk'><img src={img?.tempURL} alt='images' /></span>
                                                            )) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Explanation')}</h3>
                                                    <div className='added-question-list-items f-wrp'>
                                                        <div className='flex-box'>
                                                            {(data?.explanationImages && data?.explanationImages.filter((img) => img.tempURL).length > 0) ? data?.explanationImages.map((img) => (
                                                                <span key={img.fileKey} className='image-blk'><img src={img?.tempURL} alt='images' /></span>
                                                            )) : null}
                                                        </div>
                                                    </div>
                                                    {(data?.explanation) ? <RichTextViewer richText={data?.explanation} /> : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={2} style={{ marginLeft: '0px', marginTop: '0px', width: '100%' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Answer')}</h3>
                                                    <div className='added-question-list-items f-wrp'>
                                                        {data?.options.map((option, i) => <div className='each-answer-list f-wrp'>
                                                            <span className={`qn-number ${option.isAnswer ? 'active' : ''}`}>{charArr[i]}</span>
                                                            <p>{option.value}</p>
                                                        </div>)}

                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Correct answer')}</h3>
                                                    <div className='added-question-list-items f-wrp'>
                                                        <div className='flex-box'>
                                                            {data?.options.map((option, i) => (
                                                                <span className={`radio-label-option ${option.isAnswer ? 'active' : ''}`}>{charArr[i]}</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div className='eachQn-box accordian-box f-wrp'>
                                    <Accordion className='each-accordian-sec'>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="subjectAcc-content"
                                            id="subjectAcc-header"
                                        >
                                            <h3>{translateThis('Subjects')}</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='each-topic-sec'>
                                                <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                    <ul>
                                                        {(data?.populatedSubjects && data?.populatedSubjects.length > 0) ? data?.populatedSubjects.map((sub) => (
                                                            <li key={sub._id} className='active'><p>{sub.subjectName}</p></li>
                                                        )) : 'No Subjects'}
                                                    </ul>
                                                </div>

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div className='eachQn-box accordian-box f-wrp'>
                                    <Accordion className='each-accordian-sec'>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="systemAcc-content"
                                            id="systemAcc-header"
                                        >
                                            <h3>{translateThis('Systems')}</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div className='each-topic-sec'>
                                                <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                    <ul>
                                                        {(data?.populatedSystems && data?.populatedSystems.length > 0) ? data?.populatedSystems.map((sub) => (
                                                            <li key={sub._id} className='active'><p>{sub.systemName}</p></li>
                                                        )) : 'No Systems'}
                                                    </ul>
                                                </div>
                                            </div>

                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div className='eachQn-box accordian-box f-wrp'>
                                    <Accordion className='each-accordian-sec'>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="topicAcc-content"
                                            id="topicAcc-header"
                                        >
                                            <h3>{translateThis('Topics')}</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='each-topic-sec'>
                                                <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                    <ul>
                                                        {(data?.populatedTopics && data?.populatedTopics.length > 0) ? data?.populatedTopics.map((sub) => (
                                                            <li key={sub._id} className='active'><p>{sub.topicName}</p></li>
                                                        )) : 'No Topics'}
                                                    </ul>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div className='button-sec f-wrp' style={{ paddingBottom: '50px' }}>
                                    <div className='lft-btn-sec'>
                                        <Button onClick={() => navigate(`/${getCurrentFacilityURL()}/manage`)} className='cancel-btn'>{translateThis("Go back")}</Button>
                                    </div>
                                    <div className='ryt-btn-sec'>
                                        <Link to={`/${getCurrentFacilityURL()}/edit-question/${data?._id}`}><Button className='fill-btn'> {translateThis("Edit")}</Button> </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box> : null}
            </Box>
        </AppLayout>
    )
}



ViewQuestionPage.propTypes = {};

export default ViewQuestionPage;