import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TabLayout from '../../../../components/tabs';

import './labValueTab.scss';


import { translateThis } from '../../../../helpers/language.helper';

import SVGIcons from '../../../../assets/images/icons/svgIcons';
import SerumTab from './serumTab/serumTab';
import CerebrospinalTab from './cerebrospinalTab/cerebrospinalTab';
import BloodTab from './bloodTab/bloodTab';
import UrineBMITab from './urineBMITab/urineBMITab';

const LabValueTab = (props) => {
    // const [showPopup, setShowPopup] = React.useState(false);
    const tabNames = [{ name: <b>{translateThis('Serum')}</b>, icon: <SVGIcons.SerumIcon /> }, { name: <b>{translateThis('Cerebrospinal')}</b>, icon: <SVGIcons.BrainIcon /> }, { name: <b>{translateThis('Blood')}</b>, icon: <SVGIcons.BloodIcon /> }, { name: <b>{translateThis('Urine & BMI')}</b>, icon: <SVGIcons.UrineBmiIcon /> }];
    const tabContents = [
        <div key={'to_Serum'}><SerumTab /></div>,
        <span key={'to_Cerebrospinal'}><CerebrospinalTab /></span>,
        <span key={'to_Blood'}><BloodTab /></span>,
        <span key={'to_UrineBMI'}><UrineBMITab /></span>,
    ];

    return (
        <>
            <button className='mob-close-layout' type='button' onClick={() => props.onClose()} />
            <Box className='labValue-wrapper'>
                    <div className='labValue-header'>
                        <h3>{translateThis("Lab Values")}</h3> <button type='button' onClick={() => props.onClose()}><SVGIcons.CrossIcon/></button>
                    </div>
                    <div className='labValue-content f-wrp'>
                        <TabLayout
                            tabContents={tabContents}
                            tabNames={tabNames}
                        />
                    </div>
                
            </Box>

        </>
    )
}
LabValueTab.propTypes = {
    onClose: PropTypes.any,
}
export default LabValueTab;