/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react'
import { camelCase } from 'lodash'
import Draggable from 'react-draggable';
import CalcApp from './app/App'
import keypads from './constants/keypads'

import Decimal from './utils/decimal-custom'
import { translateThis } from '../../../../helpers/language.helper'
import SVGIcons from '../../../../assets/images/icons/svgIcons'

class CalcContainer extends Component {
    state = {
        /**
         * Display value is the current value shown on the display screen
         * @type {string}
         */
        displayValue: '0',

        /**
         * Current output is the stored value that will used as the first operand
         * in binary operations. When chaining multiple operations together, this
         * will be the result of the previous operation. On the first operation,
         * the displayValue when the operation key is pressed is stored as the
         * current output
         * @type {null|Decimal}
         */
        currentOutput: null,

        /**
         * The active operation. For binary operations, this is set when the
         * operation key is pressed. The user then enters the second input value
         * @type {null|string}
         */
        currentOperation: null,

        /**
         * Determines what to do when a number key is pressed. If this is false,
         * the number will be added to the current display value. When set to true,
         * the next number key press will reset display value and start a new
         * input. This is the case after pressing C, or after pressing an operation
         * key.
         * @type {boolean}
         */
        resetDisplayValueOnNextKeyPress: true,

        /**
         * Sets the mode: 'basic' or 'scientific' (currently only 'basic').
         * This will determine which keypad to use, as well as other mode-specific
         * behavior and styles.
         * @type {string} oneOf(['basic', 'scientific'])
         */
        mode: 'scientific',

        /**
         * Unit of measurement for angles
         * @type {string} oneOf(['deg', 'rad'])
         */
        trigUnit: 'deg',

        /**
         * the stored memory value.
         * @type {null|Decimal}
         */
        memory: null
    }

    // Set mode based on device orientation (only on small screens)
    componentDidMount() {
        const mobilePortrait = window.matchMedia('(max-width: 450px)')
        const mobileLandscape = window.matchMedia(
            '(max-width: 740px) and (max-height: 450px)'
        )
        const landscape = window.matchMedia('(orientation: landscape)')

        const setMode = landscape => {
            if (!(mobilePortrait.matches || mobileLandscape.matches)) return
            this.setState({ mode: landscape.matches ? 'scientific' : 'basic' })
        }
        landscape.addListener(landscape => setMode(landscape))
        setMode(landscape)
    }

    /**
     * Handle Number Key
     * Also handles the decimal key
     */
    // eslint-disable-next-line react/no-unused-class-component-methods
    handleNumberKey = key => {
        const {
            displayValue: prevDisplayValue,
            resetDisplayValueOnNextKeyPress
        } = this.state

        const number = key.textContent // get the number
        let displayValue = resetDisplayValueOnNextKeyPress ? '' : prevDisplayValue

        // Handle decimal key...
        // if display value already contains a decimal, the key has no effect
        if (key.id === 'decimal' && !displayValue.includes('.')) {
            // A leading 0 is shown when the decimal is added to 0
            displayValue = displayValue ? `${displayValue}.` : '0.'
        } else if (key.id !== 'decimal') {
            displayValue += number
        }

        this.setState({
            displayValue: displayValue.replace(/^0+(?!\.)/, ''), // strip leading zeroes
            resetDisplayValueOnNextKeyPress: false
        })
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    handleConstantKey = ({ id: constant }) => {
        this.setState({
            displayValue: Decimal[constant].toString(),
            resetDisplayValueOnNextKeyPress: true
        })
    }

    /**
     * Handle Binary Operations (operations with two input values)
     */
    // eslint-disable-next-line react/no-unused-class-component-methods
    handleBinaryOperationKey = key => {
        const { currentOperation, currentOutput, displayValue } = this.state
        const operation = key.id
        console.log(currentOutput, displayValue)
        if (currentOperation) {
            // When chaining multiple operations without pressing equals in between.
            // This first calls the equals handler, which processes the last operation,
            // then initiates the operation that was just selected, using the output
            // of the last operation as the first input value.
            // @todo sequential setState calls might be a problem
            this.handleEqualsKey()
        }

        this.setState(prevState => ({
            currentOperation: operation,
            currentOutput: prevState.displayValue,
            resetDisplayValueOnNextKeyPress: true
        }))
    }

    /**
     * Handle unary operations (operations with one input value).
     * Unary operations are performed directly on the display value. The
     * current display value is used as the input (operand), and the result of
     * the operation (output) becomes the new display value.
     */
    // eslint-disable-next-line react/no-unused-class-component-methods
    handleUnaryOperationKey = key => {
        const { displayValue, trigUnit } = this.state
        const operation = key.id
        let operand = new Decimal(displayValue)

        // Handle conversion between radians and degrees.
        if (/^(sin|cos|tan)$/.test(operation) && trigUnit === 'deg') {
            const PI = Decimal.acos(-1)
            operand = operand.times(new Decimal(PI).dividedBy(180))
        }

        // eslint-disable-next-line prefer-const
        let output = operand[operation]()

        this.setState({
            displayValue: output.toString(),
            resetDisplayValueOnNextKeyPress: true
        })
    }

    /**
     * Handle equals key
     * @todo support pressing equals multiple times to repeat the previous
     * operation.
     */
    handleEqualsKey = () => {
        const { currentOperation, currentOutput, displayValue } = this.state

        if (!currentOperation) {
            return
        }

        const firstOperand = new Decimal(currentOutput)
        const secondOperand = displayValue
        // eslint-disable-next-line prefer-const
        let output = firstOperand[currentOperation](secondOperand)

        this.setState({
            currentOperation: null,
            currentOutput: output,
            displayValue: output.toString(),
            resetDisplayValueOnNextKeyPress: true
        })
    }

    /**
     * Handle Clear key
     * This resets the app to initial state.
     */
    // eslint-disable-next-line react/no-unused-class-component-methods
    handleClearKey = key => {
        this.setState({
            currentOperation: null,
            currentOutput: null,
            displayValue: '0',
            resetDisplayValueOnNextKeyPress: true
        })
    }

    // eslint-disable-next-line consistent-return, react/no-unused-class-component-methods
    handleFunctionKey({ id: functionName }) {
        // eslint-disable-next-line default-case
        switch (functionName) {
            case 'trigUnit':
                return this.setState(prevState => ({
                    trigUnit: prevState.trigUnit === 'deg' ? 'rad' : 'deg'
                }))

            case 'memoryAdd':
                return this.setState(({ memory, displayValue }) => ({
                    memory: memory ? memory.plus(displayValue) : new Decimal(displayValue)
                }))

            case 'memorySubtract':
                return this.setState(({ memory, displayValue }) => ({
                    memory: memory ? memory.minus(displayValue) : null
                }))

            case 'memoryClear':
                return this.setState({ memory: null })

            case 'memoryRecall':
                return (
                    this.state.memory &&
                    this.setState(({ memory }) => ({
                        displayValue: memory.toString(),
                        resetDisplayValueOnNextKeyPress: true
                    }))
                )

            case 'random':
                return this.setState({
                    displayValue: new Decimal(Math.random()).toString(),
                    resetDisplayValueOnNextKeyPress: true
                })
        }
    }

    /**
     * Handles click event for all calculator keys
     */
    handleClick = (event, { type }) => {
        // Decide which method to call based on key type
        this[camelCase(`handle-${type}-Key`)].call(this, event.currentTarget)
    }

    render() {
        const dragHandlers = {
            onStart: this.onStart, onStop: this.onStop, controlledPosition: {
                x: '100vw', y: '100vh'
            }
        };
        const { controlledPosition } = this.state;
        return (
            <div className='drag-area f-wrp'>
                <Draggable
                    // axis="x"
                    handle=".handle"
                    defaultPosition={{ x: 0, y: 0 }}
                    position={controlledPosition}
                    // grid={[25, 25]}
                    scale={1}
                    bounds='parent'
                    {...dragHandlers}
                // onStart={this.handleStart}
                // onDrag={this.handleDrag}
                // onStop={this.handleStop}
                >
                    <div id="calculator-wrapper" className='calculator-wrapper'>
                        <div className='calc-head'>
                            <h4 className='handle'>{translateThis('Calculator')}</h4> <button type='button' onClick={() => this.props.onClose()}><SVGIcons.CrossIcon /></button>
                        </div>
                        <CalcApp
                            keys={keypads[this.state.mode]}
                            currentOperation={this.state.currentOperation}
                            mode={this.state.mode}
                            displayValue={(this.state.displayValue.split('.')[1] && this.state.displayValue.split('.')[1].length > 8)? Math.fround(this.state.displayValue).toFixed(10) : this.state.displayValue}
                            trigUnit={this.state.trigUnit}
                            memory={this.state.memory}
                            handleClick={this.handleClick}
                        />
                    </div>
                </Draggable>
            </div>



        )
    }
}

export default CalcContainer
