/* eslint-disable */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import dayjs from 'dayjs';
import { Button, Stack, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import AppIcons from '../../../../assets/images/icons';
// import SVGIcons from '../../../../assets/images/icons/svgIcons';

import '../index.scss';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import AppLoader from '../../../../components/appLoader';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { translateThis } from '../../../../helpers/language.helper';

import { DATE_FORMAT } from '../../../../constants';





const ClassesDetailsDashboard = (props) => {
    const navigate = useNavigate();
    const { data = {}, isLoading = false, onJoinClass, onTabChange } = props

    const [isResuming, setIsResuming] = React.useState(false);
    const [index, setIndex] = React.useState(-1);
    const [selectedPhoto, setSelectedPhoto] = React.useState([]);


    const onCancelClick = () => {
        navigate(`/${getCurrentFacilityURL()}/classes`)
    }

    const explanationImages = data?.images || [];
    const SelectedExplanationImages = () => {
        setSelectedPhoto(explanationImages.map((i) => ({ src: i })))
    }

    return (

        <div className='exam-preview-main-wrapper classes-preview-main-wrapper'>
            {isLoading ? <AppLoader /> : <Stack className='exam-preview-wrp classes-preview-wrp' spacing={3} style={{ border: 'none' }}>
                <div className='exam-basic-info'>
                    <Button className='closebtn' onClick={onCancelClick} variant='text' type='button'  ><SVGIcons.BackIcon /> {translateThis('Back')} </Button>
                </div>

                {(data?.isJoined) ? <div className='exam-basic-info'>
                    <h3>{translateThis("Class content")}</h3>
                    <RichTextViewer richText={data?.classContent || ''} />
                </div> : null}
                {(data?.isJoined) ? <div className='classes-image-section f-wrp'>
                    <ul className='flex-box'>
                        {explanationImages.map((img, k) => <li key={`${img}-${k}`}>
                            <span
                                onClick={() => {
                                    // setSelectedPhoto(explanationImages.map((i) => ({ src: img })))
                                    SelectedExplanationImages();
                                    setIndex(k)
                                }}>
                                <S3ImageViewerWithFallback key={`file-${img}`} fileKey={img} />
                            </span>
                        </li>)}
                    </ul>

                    {selectedPhoto && <Lightbox
                        slides={selectedPhoto}
                        plugins={[Fullscreen, Slideshow, Zoom]}
                        open={index >= 0}
                        render={{
                            slide: ({ slide }) => <S3ImageViewerWithFallback isShowRetry key={`file-${slide.src}`} fileKey={slide.src} />,
                        }}
                        index={index}
                        close={() => setIndex(-1)}
                        controller={{ closeOnBackdropClick: 'true' }}
                    />}
                </div> : null}
                <div className='button-sec f-wrp' style={{ paddingTop: '0px', paddingBottom: '15px', justifyContent: 'flex-end' }}>
                    <div className='lft-btn-sec'>
                        <LoadingButton
                            // loading={isResuming}
                            // disabled={!data?.isValidToAttend}
                            type="submit"
                            className='fill-btn'
                            onClick={() => onTabChange('2')}
                        >
                            {translateThis("Next")}
                        </LoadingButton>
                    </div>
                </div>
            </Stack>}
        </div>
    );
}

ClassesDetailsDashboard.propTypes = {
    exam: PropTypes.any,
    onJoinClass: PropTypes.func,
    onPrevExamsClick: PropTypes.func
}

export default ClassesDetailsDashboard;