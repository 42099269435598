/* eslint-disable react/prop-types */
import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getRecoil } from 'recoil-nexus';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';

ChartJS.register(ArcElement, Tooltip, Legend);


const getCenterText = (state) => {    
    return {
        id: 'centerText',
        beforeDatasetsDraw(chart) {
            const { ctx, data } = chart;
            const text = `${data.datasets[0].totalScore || 0}%`;
            const fillStyle = `${data.datasets[0].fillStyle || `${state?.isDarkModeOn ? ('rgba(255, 255, 255, 1)') : ('rgba(0, 0, 0, 1)')}`}`;
            ctx.save();
            const { x } = chart.getDatasetMeta(0).data[0];
            const { y } = chart.getDatasetMeta(0).data[0];

            // ctx.fillRect(x - 10, y - 10, 20, 20);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.whiteSpace = 'pre-line';
            ctx.fillStyle = fillStyle;
            ctx.font = data.datasets[0].fontStyle || 'bold 30px sans-serif';
            ctx.fillText(text, x, y-8);
        }
    }
}

// export const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: '# of Votes',
//       total: '100',
//       data: [12, 19, 3],
//       backgroundColor: [
//         '#195994', // correct
//         '#3872a8', // incorrect
//         '#b5d4f9', // omitted
//       ],
//       borderColor: [
//         '#195994', // correct
//         '#3872a8', // incorrect
//         '#b5d4f9', // omitted
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

export function AppDoughnutChart({ data = [] }) {
    const appState = getRecoil(appConfigAtomState);
    return <Doughnut
        data={data}

        plugins={[getCenterText(appState)]}
        options={{
            appState,
            plugins: {
                legend: {
                    display: false
                },
            },
            title: {
                display: false,
                text: ""
            },
            cutout: '70%'
        }} />;
}
