import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// @mui
import { Button, Stack, TextareaAutosize, Divider, IconButton, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RichTextEditor from '../../../../components/rich-text-editor';
import FileUploader from '../../../../components/awsS3ImageUploader/file.uploader';
// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
// Components
import { showSnackbar } from '../../../../components/snackbar';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { translateThis } from '../../../../helpers/language.helper';

// Services
import ApiCaller from '../../../../services/api/general';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';




// ----------------------------------------------------------------------


const CreateNewQuestionForm = () => {
    const apiCaller = new ApiCaller('questions');
    const navigate = useNavigate();
    const optionInputRef = useRef(null)
    const id = '';
    const charArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const parentId = getAuthId();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [images, setImages] = useState([]);
    const [explanationImages, setExplanationImages] = useState([]);
    const [answer, setAnswer] = useState('');
    const [option, setOption] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);

    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({
        question: "",
        explanation: "",
        complexity: "easy",
        img: [],
        explanationImages: [],
        options: [],
        subjectId: []
    });

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        getDataArr('subject');
        getQuestionNumber();
        if (id && parentId) {
            // getData()
        }
        // eslint-disable-next-line
    }, [parentId]);

    const getQuestionNumber = () => {
        const apiCaller = new ApiCaller(`questions`);
        apiCaller.getData('get-question-id').then((d) => setQuestionNumber(d?.questionNumber))
    }

    // get users from api
    const getDataArr = (resource, query = '') => {
        const apiCaller = new ApiCaller(`${resource}`)
        apiCaller.getList({query})
            .then((data) => {
                switch (resource) {
                    case 'subject':
                        setSubjects(data?.results)
                        break;

                    default:
                        break;
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })
    }

    /**
     * Add a new answer to question
     * @param {string} answer 
     */
    const addAnswer = () => {
        if (option) {
            const optionsArr = [...options];
            optionsArr.push(option);
            setOptions([...optionsArr])
            setOption('')
        }
    }

    /**
     * Add a new answer to question
     * @param {string} answer 
     */
    const onRemoveAnswer = (optionToDelete) => {
        if (optionToDelete) {
            const optionsArr = [...options].filter((op) => op !== optionToDelete);
            setOptions([...optionsArr])
        }
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.question && options.length > 1 && answer !== '' && formData.explanation) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            "questionNumber": `${questionNumber}`,
            "question": formData.question,
            "options": options.map((option, i) => ({
                "value": option,
                "optionChoice": charArr[i],
                "isAnswer": option === answer
            })),
            "img": images.map((img) => img.fileKey),
            "explanationImages": explanationImages.map((explanationImg) => explanationImg.fileKey),
            "explanation": formData.explanation,
            "complexity": formData.complexity,
            "subjectId": selectedSubjects.map((subject) => subject.id),
            "systemId": selectedSystems.map((system) => system._id),
            "topicId": selectedTopics.map((topic) => topic._id)
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    navigate(`/${getCurrentFacilityURL()}/manage`, { replace: true });
                    showSnackbar().success("Question created successfully!")
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().failure("Please fill all required fields")
        }

    };

    const onSelectSubject = (subject) => {
        setSelectedSubjects((prv) => {
            if (prv.find((s) => s.id === subject.id)) {
                prv = prv.filter((p) => p.id !== subject.id)
            } else {
                getDataArr(`systems`, `subjectId=${subject.id}`)
                prv.push(subject)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a system
     * @param {Object} system 
     */
    const onSelectSystem = (system) => {
        setSelectedSystems((prv) => {
            if (prv.find((s) => s._id === system._id)) {
                prv = prv.filter((p) => p._id !== system._id)
            } else {
                prv.push(system)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a topic
     * @param {Object} system 
     */
    const onSelectTopic = (topic) => {
        setSelectedTopics((prv) => {
            if (prv.find((s) => s._id === topic._id)) {
                prv = prv.filter((p) => p._id !== topic._id)
            } else {
                prv.push(topic)
            }
            return [...prv]
        })

    }

    /**
     * The signup form elements
     */


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='standard-form questionForm' spacing={3}>
                    <Formik
                        initialValues={formData}
                        validate={() => {
                            const errors = {};

                            return errors;
                        }}
                        onSubmit={(_, { setSubmitting }) => {
                            onSubmit()
                            setSubmitting(false);

                        }}
                    >
                        {({
                            errors,
                            touched,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form
                                onSubmit={handleSubmit}

                            >
                                <div className='eachQn-box f-wrp'>
                                    <p style={{display: 'flex', alignItems: 'center'}}>{translateThis('createQN_Text_1')} #{questionNumber === 0 ? <span className='sm-loader' style={{position: 'relative', top: '-3px', height: '20px'}}>{SVGIcons.LoaderIcon()}</span> : <>{questionNumber}</>}</p>
                                    <h3>{translateThis('createQN_Text_2')}</h3>

                                    <div className='text-editor-sec f-wrp'>
                                        <RichTextEditor placeholder={translateThis('Write your question here')} defaultValue={formData.question} onChange={(value) => setFormValue('question', JSON.stringify(value))} />
                                        {errors.question && touched.question && errors.question}
                                    </div>

                                    {/* <TextareaAutosize
                                        onChange={(e) => setFormValue('question', e.target.value)}
                                        onBlur={handleBlur}
                                        value={formData.question}
                                        name='question'
                                        id='question'
                                        maxRows={5}
                                        minRows={5}
                                        aria-label={translateThis('Write your question here')}
                                        placeholder={translateThis('Write your question here')}
                                        translate={translateThis('Write your question here')}
                                        itemType='text'
                                        className="no-padding-btm"
                                    />
                                    {errors.question && touched.question && errors.question} */}

                                </div>
                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Add answers')}</h3>
                                    <p>{translateThis('Write between 4 to 7 possible answers for your question')}</p>

                                    <div className='add-text-items'>
                                        <TextField
                                            ref={optionInputRef}
                                            key={`createQN_Question_Creator_add_answer`}
                                            name={`eachAnswer`}
                                            id={`createQN_Question_Creator_add_answer`}
                                            value={option}
                                            translationKey={translateThis('Write your question here')}
                                            type='text'
                                            placeholder={translateThis('Write your question here')}
                                            className='custom-textfield rounded-border'
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter' || e.keyCode === 13) {
                                                    addAnswer()
                                                }
                                            }}
                                            onChange={(e) => {
                                                setOption(e.target.value)
                                            }}
                                        />
                                        <Button
                                            disabled={!option}
                                            onClick={addAnswer}
                                            className='Add-new-item'
                                        >{SVGIcons.PlusIcon()}</Button>
                                    </div>
                                    <br />
                                    <Divider />
                                    <br />
                                    <div className='added-question-list-items f-wrp'>
                                        {options.map((option, i) => (
                                            <div key={`${option}+${i}`} className='each-answer-list f-wrp'>
                                                <span className='qn-number option-letter'>{charArr[i]}</span>
                                                <p>{option}</p>
                                                <IconButton style={{ position: 'absolute', right: 0, top: 0 }} onClick={() => onRemoveAnswer(option)}>
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Assign correct answer')}</h3>
                                    <p>{translateThis('Click on the corresponding option to mark it as the correct answer')}</p>

                                    <FormControl>
                                        <RadioGroup
                                            row
                                            className='radio-assign-option'
                                            aria-labelledby={translateThis('Assign correct answer')}
                                            onChange={(e) => setAnswer(e.target.value)}
                                            name={translateThis('Assign correct answer')}
                                        >
                                            {options.map((option, i) => (
                                                <FormControlLabel key={`${option}+${i}`} value={option} control={<Radio />} label={<span className='radio-label-option'>{charArr[i]}</span>} />
                                            ))}

                                        </RadioGroup>
                                    </FormControl>

                                </div>

                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Add images for your question')}</h3>
                                    <p>{translateThis('Image format and requirements go here')}</p>
                                    <div className='form-img-uploader f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadIcon={SVGIcons.UploadIcon()}
                                            uploadCaption={`${translateThis('Upload a file')}`}
                                            showPreview
                                            onUploadEnd={(file) => setImages((prev) => {
                                                const newImgArr = [...prev];
                                                newImgArr.push(file);
                                                return [...newImgArr];
                                            })}
                                        />
                                    </div>
                                    <div className='added-question-list-items f-wrp'>
                                        <div className='flex-box'>
                                            {images.map((img, i) => (
                                                <span key={`${i}-${img.fileKey}`} className='image-blk'><img src={img.tempURL} alt={img.fileKey} />
                                                    <IconButton onClick={() => setImages((prev) => {
                                                        const newImgArr = [...prev].filter((imgAs) => img.fileKey !== imgAs.fileKey);
                                                        return [...newImgArr];
                                                    })}>
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                </div>

                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Assign it to a subject')}</h3>
                                    <p>{translateThis('You can choose one or more subjects by clicking on them')}</p>
                                    <div className='flex-box assignSubBox'>
                                        {(subjects && subjects.length > 0) ? subjects.map((sub, i) => (<>
                                            <button type='button' onClick={() => onSelectSubject(sub)} key={sub?.id} className={`image-blk ${selectedSubjects.find((s) => s.id === sub.id)?.id ? 'active' : ''}`}>
                                                <img src={sub?.logo?.tempURL} alt='images' />
                                                <h3>{sub?.subjectName}</h3>
                                                <p>{sub.topics.length}&nbsp; {translateThis('topics')}</p>
                                            </button>
                                        </>
                                        )) : null}

                                    </div>
                                    <br />
                                    <Divider />
                                    <br />
                                    {(selectedSubjects.length > 0) ? selectedSubjects.map((subject) => (<div key={`subject-list-${subject.id}`} className='each-topic-sec f-wrp'>
                                        <div className='topic-head'>
                                            <span><img src={subject.logo?.tempURL} alt='topic images' /></span>
                                            <h5>{subject.subjectName}</h5>
                                        </div>
                                        <div className='topic-items-list'>
                                            <h6>{translateThis('Systems')}</h6>
                                            <ul>
                                                {(subject?.systems && subject?.systems.length > 0) ? subject?.systems.map((system) => (
                                                    // eslint-disable-next-line
                                                    <li onClick={() => {
                                                        onSelectSystem(system)
                                                    }} key={system._id} className={selectedSystems.find((s) => s._id === system._id)?._id ? 'active' : ''}><p>{system.systemName}</p></li>
                                                )) : 'No systems found'}
                                            </ul>
                                        </div>

                                        <div className='topic-items-list'>
                                            <h6>{translateThis('Topics')}</h6>
                                            <ul>
                                                {(subject?.topics && subject?.topics.length > 0) ? subject?.topics.map((topic) => (
                                                    // eslint-disable-next-line
                                                    <li onClick={() => onSelectTopic(topic)} key={topic._id} className={selectedTopics.find((s) => s._id === topic._id)?._id ? 'active' : ''}><p>{topic.topicName}</p></li>
                                                )) : 'No topics found'}
                                            </ul>
                                        </div>
                                    </div>
                                    )) : ''}
                                    <br />
                                    <Divider />

                                </div>

                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Add an explanation')}</h3>
                                    <p>{translateThis('You can describe why the correct answer is right as well as add why the incorrect options are wrong')}</p>

                                    <div className='form-img-uploader f-wrp'>
                                        <FileUploader
                                            acceptOnly='image/*'
                                            uploadIcon={SVGIcons.UploadIcon()}
                                            uploadCaption={`${translateThis('Upload a file')}`}
                                            showPreview
                                            onUploadEnd={(file) => setExplanationImages((prev) => {
                                                const newImgArr = [...prev];
                                                newImgArr.push(file);
                                                return [...newImgArr];
                                            })}
                                        />
                                    </div>
                                    <div className='added-question-list-items f-wrp'>
                                        <div className='flex-box' style={{marginBottom: '5px'}}>
                                            {explanationImages.map((explanationImg, i) => (
                                                <span key={`${i}-${explanationImg.fileKey}`} className='image-blk'><img src={explanationImg.tempURL} alt={explanationImg.fileKey} />
                                                    <IconButton onClick={() => setExplanationImages((prev) => {
                                                        const newImgArr = [...prev].filter((imgAs) => explanationImg.fileKey !== imgAs.fileKey);
                                                        return [...newImgArr];
                                                    })}>
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='text-editor-sec f-wrp'>
                                        <RichTextEditor defaultValue={formData.description} onChange={(value) => setFormValue('explanation', JSON.stringify(value))} />
                                    </div>

                                </div>

                                <div className='button-sec f-wrp'>
                                    <div className='lft-btn-sec'>
                                        <Button className='cancel-btn'>{translateThis("cancel")}</Button>
                                    </div>
                                    <div className='ryt-btn-sec'>

                                        <LoadingButton
                                            loading={isSubmitting}
                                            loadingPosition='start'
                                            startIcon={<></>} // To remove Mui warning
                                            fullWidth
                                            disabled={!isValidForm()}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            className='fill-btn'
                                            onClick={handleSubmit}>
                                            {translateThis("submit")}
                                        </LoadingButton>

                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Stack>
                <br />


            </div>}
        </>
    );
}

CreateNewQuestionForm.propTypes = {}

export default CreateNewQuestionForm;