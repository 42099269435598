import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import './btn.scss';

const AppButton = (props) => {
    const {
        isLoading = false,
        isDisabled = false,
        btnText = '',
        onClick = () => null,
        startIcon = <></>,
        style = {}
    } = props;
    return (
        <LoadingButton
            loading={isLoading}
            style={style}
            loadingPosition='start'
            startIcon={startIcon} // To remove Mui warning
            fullWidth
            disabled={isDisabled || isLoading}
            size="large"
            type="button"
            variant="contained"
            className='standard-btn app-btn-reusable'
            onClick={onClick}>
            {btnText}
        </LoadingButton>
    )
}

AppButton.propTypes = {
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    btnText: PropTypes.string,
    onClick: PropTypes.func,
    startIcon: PropTypes.any,
    style: PropTypes.object
}

export default AppButton;