import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Button, Stack, Grid, InputLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
// import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import AppPopOverMenu from '../../../../components/appPopOverMenu';
import { translateThis } from '../../../../helpers/language.helper';
// import './index.scss';
// import InboxView from './inboxView';
// import { DATE_FORMAT } from '../../../../constants/index';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import TextInput from '../../../../components/inputs/textInput/textInput';
import DateInput from '../../../../components/inputs/dateInput';
import { DATE_EXTEND_FORMAT } from '../../../../constants/index';
import { usdFormatter } from '../../../../helpers/helper.functions';






const PlanAndPayment = () => {
    const { id } = useParams();
    const apiCaller = new ApiCaller('university/students/plan-details');
    const navigator = useNavigate();
    const [openViewMessageDialog, setOpenViewMessageDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    // Only useful if manual subscription is opted
    const [referenceId, setReferenceId] = useState('');
    const [amount, setAmount] = useState('');
    const [startDate, setStartDate] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then((data) => setData(data?.result || {}))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'Student',
            headerName: 'Student',
            flex: 2,
            minWidth: 160,
        },
        {
            field: 'Date',
            headerName: 'Date',
            flex: 2,
            minWidth: 160,
            valueFormatter: ({ val }) => val || 'dsdsds'
        },
        {
            field: 'id',
            headerName: 'Amount',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => <AppPopOverMenu options={[
                {
                    label: `${translateThis('View')}`,
                    onClick: () => {
                        // setOpenViewMessageDialog(true);
                        // setSelectedMessage(row);
                        navigator('/gcare/view-system', { replace: true })

                    }
                },

            ]} />
        },
    ]


    /**
     * Updates the student subscription manually
     */
    const updatePlan = () => {
        const apiCaller = new ApiCaller('university/students/toggle-plan');
        let postData = null;
        if (data?.isSubscribed) {
            postData = { userId: id };
        } else if (referenceId && amount && startDate) {
            postData = {
                userId: id,
                referenceId,
                amount,
                startDate
            }
        } else {
            showSnackbar().info("Please enter valid details");
        }
        // We have a valid postData lets move
        if (postData) {
            apiCaller.postData(postData).then(({ data }) => {
                if (data?.isSuccess) {
                    showSnackbar().success("Plan updated successfully")
                    setOpenViewMessageDialog(false);
                    getData();
                } else {
                    showSnackbar().failure("Something went wrong!")
                }
            }).catch((e) => showSnackbar().failure("Something went wrong!"))
        }


    }

    return (
        <div className='block-table-wrp f-wrp'>
            {isLoading ? <span>Loading...</span> : <div className='panel-table-sec provider-table planAndPayment-table f-wrp'>
                <div className='current-plan-header'>
                    <h3 className='title'> {SVGIcons.PremiumIcon()} {translateThis("Currently")} {data?.name || ''} {translateThis("has a")} <b> {data?.isSubscribed ? translateThis("Premium") : translateThis("Free")} </b> {translateThis("plan")}.</h3>
                    <Button onClick={() => setOpenViewMessageDialog(true)} className='fill-btn'>{data?.isSubscribed ? translateThis("Mark as free plan") : translateThis("Mark as premium")}</Button>
                </div>


                {/* Subscription details */}

                {data?.subscriptionData ? <div className='formBox f-wrp' style={{ marginTop: '20px' }}>
                    <h3>{translateThis('Subscription info')}</h3>
                    <Grid className='profile-form' container spacing={1}>


                        {data?.subscriptionData.status ? <>
                            <Grid item xs={12} sm={12} md={12}>

                                <InputLabel shrink htmlFor="fieldOfStudy">
                                    {translateThis('subscription start date')}: <span style={{ fontWeight: '600', fontSize: '16px' }}>{dayjs.unix(data?.subscriptionData.startDate).format(DATE_EXTEND_FORMAT)}</span>
                                </InputLabel>

                                {data?.subscriptionData.endDate && <InputLabel shrink htmlFor="fieldOfStudy">
                                    {translateThis('subscription renewal date')}: <span style={{ fontWeight: '600', fontSize: '16px' }}>{dayjs.unix(data?.subscriptionData.endDate).format(DATE_EXTEND_FORMAT)}</span>
                                </InputLabel>}

                                <InputLabel shrink htmlFor="fieldOfStudy">
                                    {translateThis('subscription amount')}: <span style={{ fontWeight: '600', fontSize: '16px' }}>${usdFormatter(data?.subscriptionData.amount / 100)}</span>
                                </InputLabel>

                                {(data?.subscriptionData.invoice) && <InputLabel shrink htmlFor="fieldOfStudy">
                                    {translateThis('Invoice url')}: <Button className='subscription-btn' style={{ fontSize: '16px' }}
                                        onClick={() => window.open(data?.subscriptionData.invoice, '_blank')}
                                        type='button'>{translateThis('Download')}</Button>
                                </InputLabel>}

                                {/* If cancel requested show a message and hide unsubscribe button */}
                                {data?.subscriptionData.cancelRequestedAt ? <InputLabel shrink htmlFor="fieldOfStudy">
                                    {`* As per student/your request, the subscription will be cancelled on ${dayjs.unix(data?.subscriptionData.cancelAt).format(DATE_EXTEND_FORMAT)} `}
                                </InputLabel> : null}
                            </Grid>




                        </> : null
                        }

                    </Grid>
                </div> : null}




                {/* End Subscription details */}







                {/* <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                /> */}
            </div>}

            {(openViewMessageDialog && data) && <ConfirmDialog
                isOpen={openViewMessageDialog}
                onClose={() => {
                    setOpenViewMessageDialog(false);
                }}
                title='Are you sure you want to continue?'
                okayButtonText='Confirm'
                onConfirm={data?.isSubscribed ? () => updatePlan() : (referenceId && amount && startDate) ? () => updatePlan() : null}
                description={data?.isSubscribed ? '' : <Stack className='login-form standard-form' spacing={3}>
                    <TextInput
                        key={`subscription-id`}
                        label={translateThis('ReferenceId')}
                        name='referenceId'
                        type='text'
                        required
                        placeholder={translateThis('Please enter a reference Id')}
                        className='custom-textfield'
                        getValue={(_, value) => setReferenceId(value)}

                    />
                    <DateInput
                        label='Subscription date'
                        onChange={(v) => setStartDate(dayjs(v).unix())}
                    />
                    <TextInput
                        key={`subscription-amount`}
                        label={translateThis('Amount')}
                        name='amount'
                        type='number'
                        required
                        placeholder={translateThis('Please enter the amount')}
                        className='custom-textfield'
                        getValue={(_, value) => setAmount(value)}

                    />
                </Stack>}

                className={''}

            />}
        </div>
    )
}
PlanAndPayment.propTypes = {}

export default PlanAndPayment;
