import React, { useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from 'prop-types';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SVGIcons from "../../assets/images/icons/svgIcons";
import { translateThis } from "../../helpers/language.helper";

import StripeCheckoutForm from "./stripe/checkoutForm";
import './stripe.scss';

import ApiCaller from '../../services/api/general';




function InitiatePayment({ stripeKey }) {
  
  const stripePromise = loadStripe(stripeKey); // starts with pk_

  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeMsg, setPromoCodeMsg] = useState('');

  const onApplyPromoCode = () => {
    if (promoCode.length > 3) {
      setIsLoading(true)
      const apiCaller = new ApiCaller('payment/validate-coupon');
      apiCaller.postData({ code: promoCode }).then(({data}) => {
        if (data?.isValid) {
          setPromoCodeMsg(`Code applied successfully!. You will get ${data?.percentOff}% on your invoice.`)
        } else {
          setPromoCodeMsg(`Invalid coupon code`);
        }
      }).finally(()=> setIsLoading(false))
    }
  }




  return (
    <div className="creditCard-wrp">
      <div className="creditCard">
        <div className="card-icon-box">
          <span>{SVGIcons.CreditCardIcon()}</span>
          <p>{translateThis('Card')}</p>
        </div>
        {/* Style this will add functionality later */}
        <Grid spacing={2} container>
        <Grid item md={12} xl={12} sm={12}  >
            <span>{promoCodeMsg}</span>
          </Grid>
          <Grid item>
            <input onChange={(e) => {
              setPromoCode(e.target.value);
              setPromoCodeMsg('')

            }} className="applyCode" placeholder="Apply code" type="text" />
          </Grid>
          <Grid item>
            <button disabled={promoCode.length < 3} onClick={onApplyPromoCode} type="button">{isLoading ? 'APPLYING' : 'APPLY'}</button>
          </Grid>
          
        </Grid>

        <Elements stripe={stripePromise}>
          <StripeCheckoutForm appliedPromoCode={promoCode} />
        </Elements>
      </div>
    </div>
  );
}

InitiatePayment.propTypes = {
  stripeKey: PropTypes.any
}

export default InitiatePayment;