import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { getAuthRoleName } from '../../helpers/auth.helper';
import { STUDENT_ROLE, UNIVERSITY_ROLE, EMPLOYEE_ROLE, ADMIN_ROLE } from '../../constants/index';
import ProviderPortal from '../../features/providerPortal';
import ApiCaller from '../../services/api/general';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { showSnackbar } from '../../components/snackbar/index';
import { authCheck, getCurrentFacilityURL } from '../../helpers/helper.functions';
import StudentDashboard from '../../features/studentPortal/dashboard';
import { getProfile } from '../../services/api/auth';




const MyPortal = () => {
    const { facility = '' } = useParams();
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('university')
    const loggedInAuthType = getAuthRoleName();
    const [isLoading, setIsLoading] = useState(true)
    const [configState, setConfigState] = useRecoilState(myPortalState);

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilityInfo: { ...prv.facilityInfo, ...modifiedObj } } }));


    useEffect(() => {
        if (facility) {
            getFacilityInfo()
            authCheck();
            getProfile();
        }
        // eslint-disable-next-line
    }, [facility]);

    const getFacilityInfo = () => {
        if (configState) {
            apiCaller.getData('details').then((data) => {
                if (data) {
                    modifyConfigState(data);
                } else {
                    // showSnackbar().failure("Requested facility not found");
                    // navigator('/')
                }               
            }).finally(() => setIsLoading(false))
        }
    }

    return (
        <>
            {
                isLoading ? <span>Loading...</span> : <>
                    {loggedInAuthType === STUDENT_ROLE ? <StudentDashboard /> :
                loggedInAuthType === UNIVERSITY_ROLE ? <ProviderPortal /> :
                loggedInAuthType === ADMIN_ROLE ? <ProviderPortal /> :
                loggedInAuthType === EMPLOYEE_ROLE ? <ProviderPortal /> :
                    <Navigate to={`/${getCurrentFacilityURL()}/auth/login`} />}
                </>
            }

        </>
    )

}


export default MyPortal;