import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Grid } from '@mui/material';
import { translateThis } from '../../../../../helpers/language.helper';
import AppTable from '../../../../../components/table/index';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';
import ModalPopUp from '../../../../../components/modal/modal.popup';
import AppLayout from '../../../../../layouts/app/AppLayout';
import { DATE_FORMAT } from '../../../../../constants/index';
import { convertSecondsToHoursMins, getCurrentFacilityURL } from '../../../../../helpers/helper.functions';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../../../components/headerRightUserInfo';
import UserDrawer from '../../../../../components/drawer/drawer';
// import './previousTestList.scss';
import AppIcons from '../../../../../assets/images/icons';
import { AppDoughnutChart } from '../../../../../components/charts/donut.chart';
import RichTextViewer from '../../../../../components/rich-text-editor/TextViewer';
import AppLoader from '../../../../../components/appLoader';
// import PreviousTestQuestionList from './previousTestQuestionList';
import S3ImageViewer from '../../../../../components/awsS3ImageViewer/index.';
import QuestionAndAnswerPopup from './questionAndAnswerPopup';



const UnivExamView = () => {
    const apiCaller = new ApiCaller('universityExams');
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [isExamPercentileLoading, setIsExamPercentileLoading] = useState(true);
    const [percentileData, setPercentileData] = useState(null);

    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedQn, setSelectedQn] = React.useState(null);

    const onViewClose = () => {
        setViewOpen(false);
        setSelectedQn(null);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setData([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getData(`get-university-exam-result/${id}`)
            .then((data) => {
                setData(data);
                if (data?.examDetails?.examId) {
                    getExamPercentile(data?.examDetails?.examId)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Gets the percentile for this exam
     * @param {String} examId 
     */
    const getExamPercentile = (examId) => {
        setIsExamPercentileLoading(true);
        apiCaller.getData(`get-percentile/${examId}/${id}`)
            .then((data) => {
                setPercentileData(data);

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsExamPercentileLoading(false));
    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'questionNumber',
            headerName: 'Q-ID',
            flex: 0.4,
            minWidth: 70,
        },
        {
            field: 'question',
            headerName: 'Question',
            flex: 1,
            minWidth: 325,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /></>
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            minWidth: 160,
            flex: 2,
            valueFormatter: ({ value }) => (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
        },
        {
            field: 'status',
            headerName: 'Result',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span>{row?.status === 'correct' ? <SVGIcons.StatusActiveIcon /> : row?.status === 'incorrect' ? <SVGIcons.StatusBlockedIcon /> : <SVGIcons.StatusOmittedIcon />}</span>
            </>
        },
        {
            field: 'timeTakenInSeconds',
            headerName: 'Time',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span>{row?.timeTakenInSeconds} sec</span>
            </>
        },
        {
            field: 'action ',
            headerName: '',
            flex: 0.3,
            minWidth: 30,
            renderCell: ({ row }) => <Link onClick={() => {
                setSelectedQn(row);
                onViewOpen()
            }}><SVGIcons.ChevronRightIcon style={{ width: '15px' }} /></Link>
        },
    ]


    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{data?.examDetails?.name || ''}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content prev-attempts-view'>
                                <div className='block-table-wrp user-table f-wrp'>
                                    <div className='back-btn-list'>
                                        <div className='left-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/exams`}><SVGIcons.BackIcon /> {translateThis('Back')}</Link>
                                        </div>
                                        <div className='right-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/exams`}><SVGIcons.PrintIcon /> {translateThis('Print')}</Link>
                                            {/* <Link className='pln-button' onClick={onViewOpen} to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.QnListIcon /> {translateThis('Question list')}</Link> */}
                                            {/* <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.TestEyeIcon /> {translateThis('Test Revision')}</Link> */}
                                        </div>
                                    </div>

                                    {(data && data?.user) ? <div className='prev-view-boxes stud-home f-wrp' style={{ paddingBottom: '20px' }}>
                                        <div className='statistics-info-wrapper '>
                                            <Grid className='basic-info-grid' container spacing={1}>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Basic info')}</h3>
                                                        <div className='basic-detail-box basic-info'>
                                                            <div className='basic-info-box'>
                                                                <span className='profile-icon'>{(data?.user?.profileImg) ? <S3ImageViewer fileKey={data?.user?.profileImg} /> : <span>{`${data?.user?.name ? data?.user?.name[0] : ''} ${data?.user?.lastName ? data?.user?.lastName[0] : ''}`}</span>}</span>
                                                                <h4>{`${data?.user?.name} ${data?.user?.lastName}`}</h4>
                                                                <p>{data?.user?.email || ''}</p>
                                                                <h4 className='status'>{translateThis('Status')} <span>{translateThis('Active')}</span></h4>
                                                            </div>
                                                            <div className='graph-content'>
                                                                <ul>
                                                                    <li>
                                                                        <p>{SVGIcons.dateFill()} &nbsp; {dayjs(data?.result?.startTime).format(DATE_FORMAT)}</p>
                                                                        <span>{translateThis('Date taken')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p>{SVGIcons.timerFill()} &nbsp; {data?.result?.totalAvgtimeTakenInSeconds} {translateThis('sec')}</p>
                                                                        <span>{translateThis('Average question time')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p>{SVGIcons.clockFill()} &nbsp; {convertSecondsToHoursMins(data?.result?.timeTakenInSeconds)}</p>
                                                                        <span>{translateThis('Duration')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Performance')}</h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            <span className='graph-img'>
                                                                <h2 className='graph-label'>{translateThis('Correct')}</h2>
                                                                <AppDoughnutChart data={{
                                                                    labels: [`${translateThis('Correct')}`, `${translateThis('Incorrect')}`, `${translateThis('Omitted')}`],
                                                                    datasets: [
                                                                        {
                                                                            label: '',
                                                                            total: ``,
                                                                            totalScore: `${data?.result?.totalCorrectPercentage}`,
                                                                            fontStyle: 'bold 26px sans-serif',
                                                                            data: [`${data?.result?.totalCorrectPercentage}`, `${data?.result?.totalIncorrectPercentage}`, `${data?.result?.totalOmittedPercentage}`],
                                                                            backgroundColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }} />
                                                            </span>
                                                            <div className='graph-content'>
                                                                <span>{translateThis('From')} {data?.result?.noOfQuestions} {translateThis('questions, you answered')}:</span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.correct} alt='graph' /> &nbsp; {data?.result?.totalCorrectPercentage}% {translateThis('Correct')}</p>
                                                                        <span>{data?.result?.totalCorrect} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.incorrect} alt='graph' /> &nbsp; {data?.result?.totalIncorrectPercentage}% {translateThis('Incorrect')}</p>
                                                                        <span>{data?.result?.totalIncorrect} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.omitted} alt='graph' /> &nbsp; {data?.result?.totalOmittedPercentage}% {translateThis('Omitted')}</p>
                                                                        <span>{data?.result?.totalOmitted} {translateThis('questions')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    {!isExamPercentileLoading ? <div className='each-statistics-box'>
                                                        <h3>{translateThis('Score Percentile Rank')}</h3>
                                                        <div className='basic-detail-box line-chart'>
                                                            {/* <span className='graph-img'><img src={AppIcons.line48} alt='graph' /></span> */}
                                                            <div className='graph-img line-graph'>
                                                                <div className='PercentileIcon-wrp'>
                                                                    <span className='graph-wrp'>
                                                                        <img src={AppIcons.lineGraph} alt='graph' />
                                                                    </span>
                                                                    <div className={`graph-mark val${percentileData?.scorePercentile}`} style={{ width: `${percentileData?.scorePercentile}%` }}>
                                                                        <div className='value-box'>
                                                                            <p>{percentileData?.scorePercentile}{translateThis('th')} <b>{translateThis('percentile')}</b></p>
                                                                        </div>
                                                                        <p>{translateThis('You')}</p>
                                                                    </div>

                                                                    <div className={`graph-mark markFixed val50`} style={{ width: `50%` }}>
                                                                        <p>{translateThis('50th')}<b>{translateThis('percentile')}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='graph-content'>
                                                                <span><b>{translateThis('Performance rank')}:</b></span>
                                                                <ul>
                                                                    <li>
                                                                        <span>{translateThis('You rank on the')}</span>
                                                                        <p><img src={AppIcons.green} alt='graph' /> &nbsp;<span>{percentileData?.scorePercentile}{translateThis('th percentile with a score of')} {percentileData?.userScore}%</span></p>
                                                                    </li>
                                                                    <li>
                                                                        <span>{translateThis('Median score')}</span>
                                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp;<span>{percentileData?.medianPercentile}{translateThis('th percentile with a score of')} {percentileData?.medianScore}%</span></p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> : <AppLoader />}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div> : <AppLoader />}

                                    <Divider />

                                    <div className='header-wrp f-wrp'>
                                        <h3>{translateThis('Question pool')}</h3>
                                    </div>

                                    <div className='panel-table-sec provider-table PC-Table f-wrp' style={{ paddingTop: '20px' }}>
                                        <AppTable
                                            columns={tableColumns}
                                            isLoading={isLoading}
                                            data={data?.questions || []}
                                        />
                                        {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                                            <div className='mob-table-wrp childView f-wrp'>
                                                {/* <div className='mob-table-search'>
                                                    <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                                                </div> */}
                                                {data?.questions.length > 0 ? <ul>
                                                    {data?.questions.map((element) => (
                                                        <li key={element.id}>
                                                            <div className='each-sec-box' style={{ width: '50px' }}>
                                                                <h4>{element.questionNumber ? element.questionNumber : '-'}</h4>
                                                            </div>
                                                            <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                                                {(element.question) ? <h4 className='question'>
                                                                    <RichTextViewer richText={element.question} />
                                                                </h4> : '-'}

                                                                {(element.populatedSubjects && element.populatedSubjects.length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                                                    <p className='subjectList'><span>{translateThis('Subjects')} </span>
                                                                        {element.populatedSubjects.map((sub) => <i key={sub?._id}> {`${sub?.subjectName},`}</i>)}</p>
                                                                </div> : <span style={{ display: 'block' }}>{translateThis('Subjects')} - </span>}

                                                                {(element.populatedSystems && element.populatedSystems.length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                                                    <p className='subjectList'><span>{translateThis('Systems')} </span>
                                                                        {element.populatedSystems.map((sub) => <i key={sub?._id}> {`${sub?.systemName},`}</i>)}</p>
                                                                </div> : <span style={{ display: 'block' }}>{translateThis('Systems')} - </span>}
                                                                
                                                                <div className='flex-wrp auto-flex' style={{ paddingTop: '5px' }}>
                                                                    <span style={{ display: 'flex', flex: '1', minWidth: '70px' }}>{translateThis('Result')}: <b>{element?.status === 'correct' ? <SVGIcons.StatusActiveIcon /> : element?.status === 'incorrect' ? <SVGIcons.StatusBlockedIcon /> : <SVGIcons.StatusOmittedIcon />}</b></span>
                                                                    <span style={{ display: 'flex', flex: '1', minWidth: '70px' }}>{translateThis('Time')}: <b>{(element.timeTakenInSeconds) ? element.timeTakenInSeconds : '0'} {translateThis('sec')}</b></span>
                                                                    {(element.flags && element.flags.length > 0) ? <span style={{ display: 'flex', flex: '1', minWidth: '70px', justifyContent: 'flex-end' }}><SVGIcons.FlagIcon style={{ width: '16px', height: '16px', color: '#333' }} /> </span> : null}
                                                                </div>
                                                            </div>

                                                            <Link className='full-li-btn' onClick={() => { setSelectedQn(element); onViewOpen() }} />
                                                        </li >
                                                    ))}
                                                </ul> : <div className='empty-placeholder f-wrp'>
                                                    <div className='placeholder-con'>
                                                        <img src={AppIcons.placeholderTable} alt='empty' />
                                                        <h4>{translateThis('File not found')}</h4>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className='button-sec f-wrp' style={{ paddingTop: '20px' }}>
                                        <div className='lft-btn-sec'>
                                            <Button variant='contained' color='primary' className='fill-btn' onClick={() => navigate(`/${getCurrentFacilityURL()}/exam-review/${id}`)}>{translateThis('Exam review')}</Button>
                                        </div>
                                        <div className='ryt-btn-sec' />
                                    </div>

                                </div>
                            </div>


                            {(viewOpen && selectedQn) ? <ModalPopUp
                                className='preview-popup questionAndAnswer-Popup'
                                isOpen={viewOpen}
                                onClose={onViewClose}
                                aria-labelledby="table-view"
                                aria-describedby="table-view"
                            >
                                <QuestionAndAnswerPopup question={selectedQn} />

                            </ModalPopUp> : null}


                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    )
}
UnivExamView.propTypes = {}

export default UnivExamView;
