/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// @mui
import { Stack, Button } from '@mui/material';
import { CheckCircle, CloseRounded } from '@mui/icons-material';
// css
import '../../../../assets/css/form.scss';

import './index.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import ApiCaller from '../../../../services/api/general';

import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';
// ----------------------------------------------------------------------


const RealModeQuestion = ({ test = {}, questions = [], isRevisionMode = false,qnIndex, onSelectAnswer, selectedAnswer }) => {

    const charArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const [answer, setAnswer] = useState({})


    const question = questions[qnIndex] || null;

    useEffect(() => {
        if (selectedAnswer) {
            setAnswer(selectedAnswer)

        }
        if (selectedText) {
            setSelectedText([]);
        }
        onGetQuestion();

    }, [question, selectedAnswer]);


    const [index, setIndex] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState([])

    const [isStrike, setIsStrike] = useState([]);
    const [isFlagged, setIsFlagged] = useState();

    const handleClick = (e, id) => {
        e.preventDefault();
        if (e.type === 'contextmenu') {
            setIsStrike((prev) => {
                if (prev.includes(id)) {
                    return prev.filter((p) => p !== id)
                } else {
                    prev.push(id);
                    return prev
                }
            });
        }
    };

    const onGetQuestion = () => {
        if (question?._id && test.testId) {
            setSelectedPhoto(question?.img.map((i) => ({ src: i.fileKey })))
            const apiCaller = new ApiCaller('myExams');
            apiCaller.getData(`exam-question/flag-status/${test.testId}/${question._id}`).then(({ isFlagged }) => {
                setIsFlagged(isFlagged)
            }).catch(() => null)
        }
    }

    const onFlagQnClick = (questionId) => {
        if (test && test.testId && questionId) {
            const apiCaller = new ApiCaller('myExams');
            let apiEndPoint = isFlagged  ? 'unflag-question' : 'flag-question';
            apiCaller.customPutData(apiEndPoint, { questionId, testId: test.testId }).finally(() => {
                onGetQuestion();
            }).catch(() => null)
        }

    }

    const [selectedText, setSelectedText] = useState([]);

    // const handleMouseUp = () => {
    //     setSelectedText((prev)=> {
    //         console.log(prev)
    //         if (prev.includes(window.getSelection().toString())) {
    //             prev = prev.filter((p)=>p !== window.getSelection().toString())
    //         } else {
    //             if (typeof prev === 'object') {
    //                 prev.push(window.getSelection().toString());
    //             } else {
    //                 prev = [window.getSelection().toString()]
    //             }
                
    //         }
    //         return prev
    //     });
    // };
    const setSelectedClass = (element)=> {
        let optionClass = '';
        if (isRevisionMode && element._id === question?.answerId) {
            optionClass = 'selected';
        } else {
            if (answer.value) {
                if (element?.value === answer.value) {
                    optionClass = 'selected';
                }
            } else if(element._id === question?.selectedAnswer?._id) {
                optionClass = 'selected';
            }  else if(element._id === question?.answerId) {
                optionClass = 'selected';
            }
        }
        return optionClass;


    }
        
    return (
        <>
            {question && <div key={question._id}>
                <Stack className='questionForm question-preview' spacing={2}>
                    <div className='eachQn-box f-wrp' style={{ display: 'flex', flexDirection: 'column'}}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {<Button onClick={() => onFlagQnClick(question._id)} className='setFlag'>{(isFlagged) ? SVGIcons.FlagIcon() : SVGIcons.EmptyFlagIcon()}</Button>}
                        </div>
                        {/* <p>{question?.question}</p> */}
                        
                        <RichTextViewer useHighlighter highlightId={`exam-${test.testId}-${question._id}`} richText={question?.question} />
                        {/* <p className='question-para' onMouseUp={handleMouseUp}
                        // onContextMenu={(e)=>handleMouseDown(e)}
                        ><Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[`${selectedText}`]}
                            // searchWords={selectedText || []}
                            caseSensitive={true}
                            autoEscape={true}
                            textToHighlight={question?.question}
                        /></p> */}

                        {question?.img.length > 0 && <ul className='flex-box ww'>
                            {question?.img.map((i, k) => <li key={`${i.fileKey}-${k}`}>
                                <span
                                    onClick={() => {                                        
                                        setIndex(k)
                                    }}>
                                        <S3ImageViewerWithFallback key={`file-${i.fileKey}`} fileKey={i.fileKey} fileURL={i.tempURL} />
                                </span>
                            </li>)}

                        </ul>}
                        {selectedPhoto && <Lightbox
                            slides={selectedPhoto}
                            render={{
                                slide: ({ slide }) => <S3ImageViewerWithFallback isShowRetry key={`file-${slide.src}`} fileKey={slide.src} />,
                              }}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                            controller={{ closeOnBackdropClick: 'true' }}

                        />}

                    </div>
                    <div className='eachQn-box no-bg f-wrp'>
                        <div className='added-question-list-items f-wrp' >
                            {question?.options.map((element, key) => (
                                <div onClick={() => {
                                    if (!isRevisionMode) {
                                        setAnswer(element);
                                        onSelectAnswer(element);
                                    }
                                }}
                                    onContextMenu={(e)=>handleClick(e, element._id)}
                                    style={{ cursor: 'pointer' }}
                                    className={`each-answer-list f-wrp ${setSelectedClass(element)} ${question.actualAnswerId === element._id ? 'correct' : 'incorrect'} ${isStrike.includes(element._id) ? 'strike' : ''}`} key={key}>
                                    <span className='qn-number option-letter'>{charArr[key]}</span>
                                    <p>{element.value}</p>
                                    {/* To show answer in practice mode */}
                                    {(question.showAnswer || isRevisionMode) ? <span className='icon'>
                                        {question.actualAnswerId === element._id ? <CheckCircle fontSize='small' color='success' /> :
                                            <CloseRounded fontSize='small' color='error' />
                                        }

                                    </span> : null}

                                </div>
                            ))}
                        </div>
                    </div>
                </Stack>
                {/* <Stack className='test-button-wrp button-sec f-wrp hide' spacing={2}>
                    <div className='left-btn-wrp'>
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={isSubmitting}
                            size="small"
                            type="submit"
                            variant="contained"
                            className='submit-btn'
                            onClick={onSubmitAnswer}>
                            {SVGIcons.CircleTickIcon()} {translateThis('Submit')}
                        </LoadingButton>

                    </div>
                    <div className='right-btn-wrp'>
                        <Button disabled={qnIndex === 0} onClick={() => onNav('previous')} className='pln-btn'>{SVGIcons.ChevronLeftIcon()}</Button>
                        <Button disabled={qnIndex === (questions.length - 1)} onClick={() => onNav('next')} className='pln-btn'>{SVGIcons.ChevronRightIcon()}</Button>
                    </div>
                </Stack> */}
            </div>}
        </>
    );
}

RealModeQuestion.propTypes = {
    test: PropTypes.any,
    questions: PropTypes.any,
    test: PropTypes.any,
    isSubmitting: PropTypes.bool,
    isExamMode: PropTypes.bool,
    isRevisionMode: PropTypes.bool
}

export default RealModeQuestion;