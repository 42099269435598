import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import AppLayout from '../../layouts/app/AppLayout';
import TabLayout from '../../components/tabs';
import './home.scss';
import { translateThis } from '../../helpers/language.helper';
import DrawerAppBar from './mainHeader/mainHeaderDrawer';
import MainFooter from './mainFooter';
import TermsAndConditions from './termsAndConditions/termsAndConditions';

import { getCurrentFacilityURL } from '../../helpers/helper.functions';







const TermsAndConditionsXmed = () => {

    // custom home functions here
    const pageName = "Home";
    const facilityURL = getCurrentFacilityURL();
    const tabNames = [{ name: "" }];
    const tabContents = [
        <div key={'view_XMed'}><TermsAndConditions /></div>,
    ];


    return (
        <AppLayout
            pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName='home-main-wrp landing-main-wrp faq-main-wrp privacyPolicy-main-wrp f-wrp'
        >
            <div className='content-main-wrapper f-wrp'>
                <div className='landing-main-nav f-wrp'>
                    <DrawerAppBar />
                </div>
                <div className='faq-banner-wrp f-wrp'>
                    <div className='faq-banner-con f-wrp'>
                        <h1>{translateThis('XMed Pro terms and conditions')}</h1>
                        {/* <p>{translateThis('faqBannerText')}</p> */}
                    </div>
                </div>
                <div className='faq-tab-wrp f-wrp'>
                    <div className='tab-sec-wrapper f-wrp'>
                        <TabLayout
                            tabContents={tabContents}
                            tabNames={tabNames}
                        />
                    </div>
                </div>
                <div className='getInTouch-wrapper f-wrp'>
                    <p>
                        {/* {translateThis('Still can’t find what you are looking for?')}  */}
                        <NavHashLink smooth to={`/${facilityURL}/#Contact`} style={{ width: '100%' }} >
                            {translateThis('Get in touch')}
                        </NavHashLink>
                    </p>
                </div>
                <div className='main-foot-wrp f-wrp'> 
                    <MainFooter />
                </div>
            </div>
        </AppLayout>
    )
}

export default TermsAndConditionsXmed;