import { showSnackbar } from '../components/snackbar/index';
import { getCurrentFacilityURL } from "./helper.functions";
import { ADMIN_ROLE } from '../constants';

/**
 * Returns the token key
 * @returns string || ''
 */
const getTokenKey = () => getCurrentFacilityURL()

/**
 * returns true if logged in
 * @returns Bool {is logged in}
 */
export const isLoggedIn = () => window.localStorage.getItem(getTokenKey()) !== null;

/**
 * returns the token if logged in
 * @returns {string} the token
 */
export const getAuthToken = () => window.localStorage.getItem(getTokenKey());

/**
 * sets the auth Token
 * @param {string} token
 * @returns {boolean} success
 */
export const setAuthToken = (token) => window.localStorage.setItem(getTokenKey(), token);

/**
 * logs out the user silently
 * @param {string} token
 * @returns {boolean} success
 */
export const logoutUserSilent = () => window.localStorage.removeItem(getTokenKey());

/**
 * logs the user out
 * @param {string} alertMessage Optional alert message to show
 * @returns void
 */
export const logoutUser = (alertMessage = '') => {
    if (alertMessage) {
        showSnackbar({ timeout: 1000 }, () => {
            window.localStorage.removeItem(getTokenKey());
            window.location.href = `/${getTokenKey()}/auth/login`
        }).info(alertMessage);
    } else {
        // remove local storage and redirect
        window.localStorage.removeItem(getTokenKey());
        window.location.href = `/${getTokenKey()}/auth/login`
    }

};


/**
 * Get the logged in user details
 * @returns Object
 */
export const getAuthId = () => {
    try {
        const authToken = getAuthToken();
        let details = {};
        if (authToken) {
            details = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        return details.userId || ''
    } catch (e) {
        return '';
    }
};

/**
 * Get the logged in user role name
 * @returns {string}
 */
export const getAuthRoleName = () => {
    try {
        const authToken = getAuthToken();
        let extractedToken = {};
        if (authToken) {
            extractedToken = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        const role = extractedToken?.userType
        return role || ''
    } catch (e) {
        return '';
    }
};

/**
 * Get the logged in user role name
 * @param {string} key the key of the value needed. Eg: to get email pass email
 * @returns {any} || boolean Incase key is not found, will return empty string
 */
export const getAuthDetailsByKey = (key) => {
    let result = '';
    try {
        const authToken = getAuthToken();
        let extractedToken = {};
        if (authToken) {
            extractedToken = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        if (key && extractedToken[key]) {
            result = extractedToken[key];
        }
        if (key === 'ALL') {
            result = extractedToken;
        }
        return result;
    } catch (e) {
        return result;
    }
};
/**
 * Check if the logged in user has the access
 * @param {string} accessKey The permission to check
 * @returns boolean
 */
export const hasAccess = (accessKey = '') => {
    let access = false;
    const authDetails = getAuthDetailsByKey('ALL');
    if (accessKey && authDetails) {
        switch (accessKey) {
            case "bulletin":
                access = authDetails.status === 'active';
                break;
            case "forms":
                access = authDetails.status === 'active';
                break;
            default:
                access = false;
                break;
        }
    }
    return access

}


/**
 * Check if the logged in user has the access
 * @param {string} accessKey The permission to check
 * @returns boolean
 */
export const accessFiltered = (accessArr = []) => {
    const authDetails = getAuthDetailsByKey('ALL');
    if (authDetails?.isSubAdmin) {
        const accesses = authDetails?.accesses || [];
        return accessArr.filter((access)=>accesses.includes(access?.accessId))
    }
    return accessArr

}

/**
 * Check if the logged in user has the access
 * @param {string} accessKey The permission to check
 * @returns boolean
 */
export const accessGuard = (access) => {
    let hasAccess = true;
    const authDetails = getAuthDetailsByKey('ALL');
    if (authDetails?.userType === ADMIN_ROLE) {
        if (authDetails?.isSubAdmin) {
            const accessArr = authDetails?.accesses || [];
            hasAccess = accessArr.includes(access);
        }
    }
    return hasAccess;

}
