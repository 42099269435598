import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import '../index.scss';
import { translateThis } from '../../../../../helpers/language.helper';
import AppTable from '../../../../../components/table';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';
import AppIcons from '../../../../../assets/images/icons';
import '../../index.scss';




const StudentListPopup = ({selectedIds = [], onSelectStudents=()=>null}) => {
    const apiCaller = new ApiCaller('university/studentsz');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data?.result.map((res, i) => ({ ...res, slNo: i + 1 })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    const tableColumns = [

        {
            field: 'name',
            sortable: false,
            headerName: 'Name',
            flex:1,

        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1.3,
            minWidth: 160,
            // renderCell: ({ row }) => <RichTextViewer richText={row.message} />,
        },
        {
            field: 'isSubscribed',
            headerName: 'Subscription',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => row?.isSubscribed ? 'Yes' : 'No'
            // renderCell: ({ row }) => <AppPopOverMenu options={[
            //     {
            //         label: `${translateThis('View')}`,
            //         onClick: () => {
            //             setOpenViewMessageDialog(true);
            //             setSelectedMessage(row);
            //         }
            //     },

            // ]} />
        }
    ]


    return (

        <div className='select-student-popup-wrp f-wrp'>
            <div className='header'>
                <h3><img className='icons' src={AppIcons.testMode} alt='icon' /> {translateThis('Students')}</h3>
            </div>
            <div className='select-student-table panel-table-sec provider-table PC-Tables f-wrp'>
                <AppTable
                checkboxSelection
                selectedIds={selectedIds}
                onSelectRows={(ids)=>onSelectStudents(ids)}
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={filteredDataArr}
                />
            </div>


        </div>
    )
}

StudentListPopup.propTypes = {
    selectedIds: PropTypes.array,
    onSelectStudents: PropTypes.func,
}
export default StudentListPopup;

