import React, { useRef, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Box, Button, LinearProgress, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import './index.scss';

import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import { translateThis } from '../../../../helpers/language.helper';

import SVGIcons from '../../../../assets/images/icons/svgIcons';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';




const TestFooter = ({
    questions = [],
    isValidToSubmit = true,
    qnIndex,
    testDetails,
    timer = 0,
    remainingTime = 0,
    finishBtnName,
    onEndRevision,
    onFinishTest,
    onNavigate,
    onSubmitAnswer,
    onPauseExam,
    isSubmitting,
    getFooterHeight,
    isRevisionMode = false, // We will hide some elements if its revision
    isExamMode = false, // We will hide some elements if its exam mode
    isFinishing
}) => {
    const [isOpenResumeDialog, setIsOpenResumeDialog] = useState(false);

    // eslint-disable-next-line
    function fmtMSS(e) {
        const h = Math.floor(e / 3600).toString().padStart(2, '0');
        const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0');
        const s = Math.floor(e % 60).toString().padStart(2, '0');

        // return h + ':' + m + ':' + s;
        return `${h}:${m}:${s}`;
    }

    const onNav = (to) => {
        onNavigate(to);
    }

    const footerHeight = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            getFooterHeight(footerHeight.current.clientHeight);
        }, 1200);
        // eslint-disable-next-line
    }, []);
    const navigate = useNavigate();

    const onClickPauseExam = () => {
        setIsOpenResumeDialog(true);
    }

    // Function to normalise the values (MIN / MAX could be integrated)
const roundOfTimer = (value) => ((value - 1) * 100) / (14400 - 1);
    return (
        <>
            <Box sx={{ display: 'flex' }} className={`${isRevisionMode ? 'RevisionMode' : ''} Test-main-footer f-wrp`} ref={footerHeight}>
                <div className='test-portal-footer f-wrp'>
                    <div className='test-portal-footer-grid f-wrp'>
                        <div className='foot-portal-wrp'>
                            {/* <div className='color-ref-box'>
                                    <h4>{translateThis('Color references')}</h4>
                                    <ul className='reference-list'>
                                        <li className='answered'><p>{translateThis('Answered')}</p></li>
                                        <li className='unanswered'><p>{translateThis('Unanswered')}</p></li>
                                        <li className='omitted'><p>{translateThis('Omitted')}</p></li>
                                    </ul>
                                </div> */}

                            <Stack className='test-button-wrp button-sec f-wrp' spacing={2}>
                                <div className='left-btn-wrp'>
                                    {(isRevisionMode ? <Button onClick={() => onEndRevision()} className='end-revision-btn end-button'>{translateThis('End revision')}</Button> :
                                        <LoadingButton
                                            loading={isSubmitting}
                                            loadingPosition='start'
                                            startIcon={SVGIcons.CircleTickIcon()} // To remove Mui warning
                                            fullWidth
                                            disabled={!isValidToSubmit}
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            className='submit-btn'
                                            onClick={onSubmitAnswer}
                                        >
                                            {translateThis('Submit')}
                                        </LoadingButton>)}
                                </div>
                                <div className='right-btn-wrp'>
                                    <Button disabled={qnIndex === 0} onClick={() => onNav('previous')} className='pln-btn'>{SVGIcons.ChevronLeftIcon()}</Button>
                                    <Button disabled={qnIndex === (questions.length - 1)} onClick={() => onNav('next')} className='pln-btn'>{SVGIcons.ChevronRightIcon()}</Button>
                                </div>
                            </Stack>

                        </div>

                        <div className='time-button-wrp f-wrp'>
                            {!isRevisionMode ?<div className='foot-portal-wrp'>
                                <Button onClick={() => onClickPauseExam()} className='submit-btn suspend-btn'> {SVGIcons.SuspendIcon()}&nbsp;{translateThis('Pause')}</Button>
                            </div> : null}


                            {(!isRevisionMode) ? <>
                                <div className='pc-timer'>
                                    <div className={`timer-set-box ${(remainingTime > 0 && testDetails?.timer === 'limit') && 'withTimer'} `}>
                                        <p><b>{translateThis('Elapsed time')}:</b> {fmtMSS(timer)} <LinearProgress variant="determinate"  value={roundOfTimer(timer)} /></p>
                                        {(remainingTime > 0 && testDetails?.timer === 'limit') && <p><b>{translateThis('Remaining time')}:</b> {fmtMSS(remainingTime)} <LinearProgress variant="determinate" value={roundOfTimer(remainingTime)} /></p>}
                                        <p><b>{translateThis('Questions answered')}:</b> {questions.filter((q) => !['unanswered', 'omitted'].includes(q.status)).length}/{questions.length}</p>
                                    </div>
                                </div>

                                <div className='usmle-lock-btn' style={{ display: 'none' }}>
                                    <Button onClick={() => onClickPauseExam()} className='usmle-suspend-btn'> {SVGIcons.UsmleLock()}{translateThis('Lock')}</Button>
                                </div>


                                <div className='mob-timer'>
                                    <div className='timer-set-box'>
                                        <div className='flex-box'>
                                            <p><b>{translateThis('Elapsed time')}:</b> {fmtMSS(timer)} </p>
                                            <p><b>{translateThis('Questions answered')}:</b> {questions.filter((q) => !['unanswered', 'omitted'].includes(q.status)).length}/{questions.length}</p>
                                        </div>
                                        <LinearProgress variant="determinate"  value={roundOfTimer(timer)} />
                                        {(remainingTime > 0 && testDetails?.timer === 'limit') && <p><b style={{ whiteSpace: 'nowrap' }}>{translateThis('Remaining time')}:</b> {fmtMSS(remainingTime)} <LinearProgress variant="determinate" value={roundOfTimer(remainingTime)} style={{ marginLeft: '4px' }} /></p>}
                                    </div>
                                </div>

                            </>
                                : <div className='timer-set-box'>
                                    {/* Revision mOde */}
                                    <p><b>{translateThis('Questions answered')}:</b> {questions.filter((q) => q.answerId).length}/{questions.length}</p>
                                </div>}
                            <div className='time-end-button'>
                                {!isRevisionMode && <LoadingButton
                                    loading={isFinishing}
                                    loadingPosition='start'
                                    startIcon={SVGIcons.EndTestIcon()} // To remove Mui warning
                                    fullWidth
                                    disabled={isFinishing}
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    className='submit-btn'
                                    onClick={onFinishTest}>
                                    {translateThis(finishBtnName || 'End test')}
                                </LoadingButton>}
                                {(isRevisionMode) && (testDetails.testTheme === 'usmle') ? <LoadingButton
                                    loading={isFinishing}
                                    loadingPosition='start'
                                    startIcon={SVGIcons.EndTestIcon()} // To remove Mui warning
                                    fullWidth
                                    disabled={isFinishing}
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    className='submit-btn'
                                    onClick={onEndRevision}>
                                    {translateThis('End revision')}
                                </LoadingButton>: null }

                            </div>
                        </div>
                    </div>
                </div>

                {isOpenResumeDialog && <ConfirmDialog
                    isOpen={isOpenResumeDialog}
                    onClose={() => {
                        setIsOpenResumeDialog(false);
                    }}
                    title="Warning - This block is incomplete!"
                    className={`delete-popup ${testDetails.testTheme !== 'usmle' ? 'lock-popup-box' : 'usmle-lock-popup'}`}
                    description={<><p>{translateThis("You are about to lock the exam and take and Unauthorized Break. An Unautorized Break may be reported as an irregularity for the exam")}</p></>}
                    okayButtonText={`${translateThis("Unauthoorized Break")}`}
                    cancelButtonText={`${translateThis('Return to Exam')}`}
                    onConfirm={() => {
                        onPauseExam();
                    }}
                />}
            </Box>
        </>
    )
}
TestFooter.propTypes = {
    questions: propTypes.array,
    isValidToSubmit: propTypes.bool,
    isRevisionMode: propTypes.bool,
    isExamMode: propTypes.bool,
    timer: propTypes.any,
    remainingTime: propTypes.any,
    qnIndex: propTypes.any,
    isFinishing: propTypes.bool,
    onNavigate: propTypes.func,
    finishBtnName: propTypes.any,
    onFinishTest: propTypes.any,
    onEndRevision: propTypes.any,
    isSubmitting: propTypes.any,
    onSubmitAnswer: propTypes.any,
    onPauseExam: propTypes.any,
    getFooterHeight: propTypes.any,
    testDetails: propTypes.any,
}
export default TestFooter;