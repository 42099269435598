import * as React from 'react';
import { Box } from '@mui/material';
import TabLayout from '../../../components/tabs';

import './index.scss';

// import ProviderPortalHeader from '../components/header';
// import ProfileList from './employeeProfileList';
// import DailyCheckInList from './dailyCheckInList';
// import ModalPopUp from '../../../components/modal/modal.popup';
// import AddEditEmployeeForm from './addEditEmployee';
import { translateThis } from '../../../helpers/language.helper';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import QuestionBlockTable from './questionBlock/questionBlockTable';
import TopicBlockTable from './topicBlock/topicBlockTable';
import SubjectBlockTable from './subjectBlock/subjectBlockTable';
import SystemBlockTable from './systemBlock/systemBlockTable';
import ReportedQuestionTable from './reportedQuestions/reportedQuestionTable';
import UniversityCreatedExamsList from './UniversityCreatedExams';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import ClassTabSection from './classesBlock';

const BlockSectionPage = () => {
    // const [showPopup, setShowPopup] = React.useState(false);

    const tabNames = [
        { name: <b>{translateThis('Classes')}</b>, icon: <SVGIcons.ClassesIcon /> },
        { name: <b>{translateThis('Questions')}</b>, icon: <SVGIcons.QuestionIcon /> },
        { name: <b>{translateThis('SubjectsTab')}</b>, icon: <SVGIcons.SubjectIcon /> },
        { name: <b>{translateThis('SystemsTab')}</b>, icon: <SVGIcons.SystemIcon /> },
        { name: <b>{translateThis('TopicsTab')}</b>, icon: <SVGIcons.TopicIcon /> },
        { name: <b>{translateThis('Reported questions')}</b>, icon: <SVGIcons.ReportIcon /> },
        { name: <b>{translateThis('Exams')}</b>, icon: <SVGIcons.QuestionIcon /> }
    ];
    const tabContents = [
        <span key={'to_Classes'}><ClassTabSection /></span>,
        <span key={'to_Questions'}><QuestionBlockTable /></span>,
        <span key={'to_Subjects'}><SubjectBlockTable /></span>,
        <span key={'to_Systems'}><SystemBlockTable /></span>,
        <span key={'to_Topics'}><TopicBlockTable /></span>,
        <span key={'to_Reports'}><ReportedQuestionTable /></span>,
        <span key={'to_created_exams'}><UniversityCreatedExamsList /></span>,
    ];

    return (
        <>
            <AppLayout
                // pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            <div className='main-category-page'>
                                <div className='main-category-header'>
                                    <h2>{translateThis("Manage")}</h2>
                                    <HeaderRightUserInfo />
                                </div>
                                <div className='main-category-content manage-tabs'>
                                    <TabLayout
                                        tabContents={tabContents}
                                        tabNames={tabNames}
                                    />
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </AppLayout>


        </>
    )
}

export default BlockSectionPage;