import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

// @mui
import Box from '@mui/material/Box';

// css
import '../index.scss';

// Components
import AppLayout from '../../layouts/app/AppLayout';
import UserDrawer from '../../components/drawer/drawer';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import Profile from '../profile';
import ProviderDashboard from './dashboard';
import ProviderPaymentSection from './paymentSection';
import DashboardHomePage from './homePage';
import MainCategoryTopics from './mainCategoryTopics';
import MyMessagesList from '../messages/list';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';
import { accessGuard } from '../../helpers/auth.helper';




export default function ProviderPortal() {

    const pageName = "Provider portal";
    const navigate = useNavigate()
    const [content, setContent] = React.useState(<></>);
    const { selectedNavItem = 'question', facilityInfo = {} } = useRecoilValue(myPortalState);
    const { design = {} } = facilityInfo;
    React.useEffect(() => {
        if (!accessGuard('home')) {
            navigate(`/${getCurrentFacilityURL()}/profile`)
        }
        if (selectedNavItem) {
            onSelectNav(selectedNavItem)
        }
        // eslint-disable-next-line
    }, [selectedNavItem]);

    /**
     * Sets the content to display based on the current selection
     * @param {string} item 
     */
    const onSelectNav = (item) => {
        let contentSelected = <span>question</span>
        switch (item) {
            case 'question':
                contentSelected = <MainCategoryTopics />;
                break;
            case 'dashHome':
                contentSelected = <DashboardHomePage />;
                break;
            case 'dashboard':
                contentSelected = <ProviderDashboard />;
                break;

            case 'inbox':
                contentSelected = <MyMessagesList />;
                break;
            // case 'parents':
            //     contentSelected = <ParentSection />;
            //     break;
            case 'payments':
                contentSelected = <span><ProviderPaymentSection />  </span>;
                break;
            case 'pricing':
                // contentSelected = <ProviderPricingSectionTab />;
                break;
            case 'account':
                contentSelected = <Profile />;
                break;
            default:
                contentSelected = <DashboardHomePage />;
                break;
        }
        setContent(contentSelected);
    }

    return (
        <>
            <AppLayout
                pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal ${design.theme || 'default-bg'} ${design.buttonTheme || 'default-clr'} f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            {content}
                        </Box>
                    </Box>
                </Box>
            </AppLayout>
        </>
    );
}