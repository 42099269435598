import { useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// css
import '../../../assets/css/form.scss';
import SVGIcons from '../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';


// Helpers
import { translateThis } from '../../../helpers/language.helper';

// Services
import ApiCaller from '../../../services/api/general';
import { getAuthDetailsByKey } from '../../../helpers/auth.helper';

// ----------------------------------------------------------------------


const ContactUsForm = (props) => {
    const apiCaller = new ApiCaller('inbox');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isLoading = false;
    const [formData, setFormData] = useState({
        name: `${getAuthDetailsByKey('name')} ${getAuthDetailsByKey('lastName')}`,
        phoneNumber: getAuthDetailsByKey('phoneNumber'),
        email: getAuthDetailsByKey('email'),
        message: '',
    });


    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.name
            && formData.phoneNumber
            && formData.message
            && formData.email
        ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {...formData, type: 'inbox-message',};        
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Thank you for contacting us!.");
                    setFormData({...formData, message: ''})
                    props.onClose()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='contactUs-form standard-form' spacing={1}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextInput
                                    label={translateThis('Name')}
                                    name='name'
                                    id='contact_form_name'
                                    value={formData.name}
                                    translationKey='Name'
                                    type='text'
                                    isRequired
                                    placeholder={translateThis('Name')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextInput
                                    label={translateThis('Phone')}
                                    name='phoneNumber'
                                    id='contact_form_phone'
                                    translationKey='Phone'
                                    type='number'
                                    value={formData.phoneNumber}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    placeholder={translateThis('Phone')}
                                    className='custom-textfield'

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('E-mail')}
                                    name='email'
                                    id='contact_form_email'
                                    value={formData.email}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    translationKey='E-mail'
                                    type='email'
                                    placeholder={translateThis('E-mail')}
                                    className='custom-textfield'

                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    label={translateThis('Your message')}
                                    name='message'
                                    id='message'
                                    onChange={(e) => setFormValue('message', e.target.value)}
                                    type='text'
                                    rows={4}
                                    multiline
                                    placeholder='Your message'
                                    className='custom-textfield'
                                    
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='login-btn fill-btn'
                            onClick={onSubmit}>
                            {translateThis('Contact')}
                        </LoadingButton>
                    </form>
                </Stack>
            </div>}
        </>
    );
}

ContactUsForm.propTypes = {
    onClose: propTypes.func
}

export default ContactUsForm;