import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translateThis } from '../../../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';

import AppIcons from '../../../../assets/images/icons';
import './index.scss';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar';
import AppLoader from '../../../../components/appLoader';

import SVGIcons from '../../../../assets/images/icons/svgIcons';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';





const ClassTabSection = ({ requestType }) => {
    const apiCaller = new ApiCaller(`class`)
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const facilityURL = getCurrentFacilityURL();
    useEffect(() => {
        getDataArr()
        // eslint-disable-next-line
    }, []);

    /**
     * Get Assigned Exam from Api
     */
    const getDataArr = () => {
        apiCaller.getList({ query: `type=${requestType}` }).then((data) => setDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))
    }


    const paraRef = useRef([]);
    const [copied, setCopied] = useState(false);
    const handleCopy = (code, key) => {
        navigator.clipboard.writeText(code).then(() => {
            setCopied(prev => ({ ...prev, [key]: true }));
            setTimeout(() => {
                setCopied(prev => ({ ...prev, [key]: false }));
            }, 1000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Class deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    const toggleStatus = (id, status) => {
        if (id) {
            apiCaller.customPutData(id, { status: status === 'DRAFT' ? 'ACTIVE' : 'DRAFT' })
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Class ${status === 'DRAFT' ? 'published' : 'unpublished'} successfully`);
                    } else {
                        showSnackbar().failure("Failed to update class")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }




    return (
        <div className='exam-list-wrapper class-list-wrapper f-wrp' style={{ padding: '0 !important' }}>
            <div className='row'>

                {isLoading ? <AppLoader /> : (!isLoading && dataArr.length > 0) ? <>
                    <div className='btn-wrp f-wrp'>
                        <Link className='add-btn' to={`/${facilityURL}/create-classes`}><SVGIcons.PlusIcon /> {translateThis('New Class')}</Link>
                    </div>
                    {
                        dataArr.map((exam, key) => (
                            // eslint-disable-next-line
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12' key={key}>
                                <div className={`each-examList-box f-wrp `}>
                                    <div className='class-btn-box f-wrp'>
                                        <ul>
                                            <li><Link onClick={() => {
                                                toggleStatus(exam?._id, exam?.status)
                                            }} className='status-btn' link='#'>{exam?.status === 'DRAFT' ? <SVGIcons.StatusInactiveIcon /> : <SVGIcons.StatusActiveIcon />}</Link>
                                            </li>
                                            <li><Link className='edit-btn' to={`/${facilityURL}/edit-class/${exam._id}`}><SVGIcons.EditIcon /></Link></li>


                                            <li><Link onClick={() => {
                                                setSelectedId(exam._id);
                                                setOpenDeleteDialog(true)
                                            }} className='delete-btn' link='#'><SVGIcons.DeleteBlueIcon /></Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='content-details f-wrp'>
                                        <h4>{exam?.title} <span className='class-type'>{exam?.isPodCast ? 'Webinar' : 'Class'}</span></h4>
                                        <p>{exam?.subtitle}</p>
                                        {/* <p className='link-text'>
                                            <span ref={paraRef}>{exam?.code}</span>
                                            <button type='button' onClick={handleCopy}><SVGIcons.CopyIcon />
                                                {copied && (
                                                    <i className="tooltip">{translateThis('Link copied to clipboard')}</i>
                                                )}
                                            </button>
                                        </p> */}
                                        {exam?.code && <p className='link-text'>
                                            <span ref={paraRef.current[key]}>{exam?.code}</span>
                                            <button type='button' onClick={() => handleCopy(exam?.code, key)}><SVGIcons.CopyIcon />
                                                {copied[key] && (
                                                    <i className="tooltip">{translateThis('Link copied to clipboard')}</i>
                                                )}
                                            </button>
                                        </p>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }</> : <div className='NoItem-box f-wrp'>
                    <span><img src={AppIcons.noClasses} alt='No Classes' /></span>
                    <h3>{translateThis('No classes yet')}</h3>
                    <p>{translateThis('No classes text')}</p>
                    <Link to={`/${facilityURL}/create-classes`} className='box-btn' >{translateThis('Create a class')}</Link>

                </div>}
            </div>
            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete class`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this class?\nThis action is permanent and cannot be undone.`}
                okayButtonText={'Delete class'}
                onConfirm={deleteData}
            />}
        </div >
    )
}
ClassTabSection.propTypes = {
    requestType: PropTypes.string
}
export default ClassTabSection;