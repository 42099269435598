import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';


import { translateThis } from '../../../helpers/language.helper';
import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';

import AppLayout from '../../../layouts/app/AppLayout';
import { DATE_FORMAT } from '../../../constants/index';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';
// import SVGIcons from '../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import UserDrawer from '../../../components/drawer/drawer';
import './previousTestList.scss';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppIcons from '../../../assets/images/icons';
import AppPopOverMenu from '../../../components/appPopOverMenu';


const PreviousTestTable = () => {
    const apiCaller = new ApiCaller('tests');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data.result.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'slNo',
            headerName: 'Practices',
            minWidth: 90,
            maxWidth: 90,
            align: 'center',
            flex: 0.6,
        },
        {
            field: 'name',
            headerName: "Name",
            maxWidth: 200,
            flex: 0.5,
        },
        {
            field: 'totalQuestionsGiven',
            headerName: "Q's",
            minWidth: 50,
            maxWidth: 50,
            flex: 0.5,
        },
        {
            field: 'Score',
            headerName: 'Score',
            minWidth: 80,
            maxWidth: 100,
            flex: 0.85,
            renderCell: ({ row }) => <>
                <span className='score'>{(row.totalScore && row.totalScore !== 'NaN') ? row.totalScore : 0}%</span>
            </>
        },

        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            minWidth: 135,
            maxWidth: 135,
            renderCell: ({ row }) => dayjs(row.createdAt).format(DATE_FORMAT)
        },
        // {
        //     field: 'testMode',
        //     headerName: 'Mode',
        //     flex: 1,
        //     minWidth: 125,
        //     maxWidth: 125,
        //     renderCell: ({ row }) => <>{row.testMode === "practice"? 'Practice' : 'Real' }</>
        // },
        {
            field: 'timer',
            headerName: 'Timer',
            flex: 1,
            minWidth: 125,
            maxWidth: 125,
            renderCell: ({ row }) => <>{row.timer === "limit" ? `${translateThis('Time limit')}` : `${translateThis('No time limit')}`}</>
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            flex: 0.1,
            minWidth: 100,
            maxWidth: 250,
            // valueFormatter: ({ value }) => {return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-' }
            // renderCell: ({ row }) => (row.populatedSubjects && row.populatedSubjects.length > 0) ? row.populatedSubjects.map((sub) => <span className='chip-sub' key={sub?._id}> {`${sub?.subjectName}`}</span>) : '-'
            renderCell: ({ row }) => (row.populatedSubjects && row.populatedSubjects.length > 0) ? <div className='flex area'>
                <span className='chip-sub' style={{ maxWidth: '150px' }}  > {`${row.populatedSubjects[0].subjectName}`}</span>
                {(row.populatedSubjects.length > 1) ? <Tooltip title={<>{row.populatedSubjects.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.subjectName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
        },
        // {
        //     field: 'topics',
        //     headerName: 'Topics',
        //     minWidth: 160,
        //     flex: 2,
        // },

        {
            field: 'idVal',
            headerName: 'Action',
            flex: 1,
            minWidth: 115,
            maxWidth: 115,
            sortable: false,
            renderCell: ({ row }) => <>
                <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    <Link className='label-button' to={`/${getCurrentFacilityURL()}/test/${row.id}`}>{translateThis('View')}</Link>
                    {row.status !== 'FINISH' && <Link className='label-button' to={`/${getCurrentFacilityURL()}/start-test/${row.id}`}>{translateThis('Resume')}</Link>}
                </div>
            </>
        },
    ]




    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Previous practices")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content'>
                                <div className='block-table-wrp f-wrp'>
                                    <div className='panel-table-sec user-table provider-table PC-Table previousTestTable f-wrp'>
                                        <AppTable
                                            columns={tableColumns}
                                            isLoading={isLoading}
                                            data={dataArr}
                                        />
                                        {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                                            <div className='mob-table-wrp childView prevTest f-wrp'>
                                                <div className='mob-table-search'>
                                                    <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                                                </div>
                                                {filteredDataArr.length > 0 ? <ul>
                                                    {filteredDataArr.map((element) => (
                                                        <li key={element.id}>
                                                            <div className='each-sec-box'>
                                                                <div className='status-with-btn'>
                                                                    <span className='score'>{(element.totalScore && element.totalScore !== 'NaN') ? element.totalScore : 0}%</span>
                                                                    {element.status !== 'FINISH' ?
                                                                        <AppPopOverMenu icon={SVGIcons.StatusMobArrow()} options={[
                                                                            {
                                                                                label: <>{element.status !== 'FINISH' && <Link className='label-button' to={`/${getCurrentFacilityURL()}/start-test/${element.id}`}>{translateThis('Resume')}</Link>}</>,
                                                                                onClick: () => {
                                                                                    navigator(`/${getCurrentFacilityURL()}/start-test/${element.id}`)
                                                                                }
                                                                            },
                                                                        ]} /> : null}
                                                                </div>

                                                                <span>{element.slNo}</span>
                                                            </div>
                                                            <div className='each-sec-box'>
                                                                <h4>{dayjs(element.createdAt).format(DATE_FORMAT)}</h4>
                                                                {(element.populatedSubjects && element.populatedSubjects.length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                                                    <span>{translateThis('Subject')}: </span>
                                                                    {element.populatedSubjects.map((sub) => <span key={sub?._id}> {`${sub?.subjectName},`}</span>)}
                                                                </div> : <span>{translateThis('Subject')}: - </span>}
                                                                <div className='flex-wrp'>
                                                                    <span style={{ flex: '1' }}>{translateThis('Timer')}: {element.timer}</span>
                                                                    <span style={{ flex: '1' }}>{translateThis('Qs')}: {element.totalQuestionsGiven ? element.totalQuestionsGiven : '-'}</span>
                                                                </div>
                                                            </div>
                                                            <Link className='full-li-btn' to={`/${getCurrentFacilityURL()}/test/${element.id}`} onClick={() => { navigator(`/${getCurrentFacilityURL()}/test/${element.id}`) }} />
                                                        </li >
                                                    ))}
                                                </ul> : <div className='empty-placeholder f-wrp'>
                                                    <div className='placeholder-con'>
                                                        <img src={AppIcons.placeholderTable} alt='empty' />
                                                        <h4>{translateThis('File not found')}</h4>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    )
}
PreviousTestTable.propTypes = {}

export default PreviousTestTable;
