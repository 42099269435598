import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link, useParams } from 'react-router-dom';
import { Box, Divider, Grid } from '@mui/material';
import { translateThis } from '../../../../../helpers/language.helper';
import AppTable from '../../../../../components/table/index';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';

import AppLayout from '../../../../../layouts/app/AppLayout';
import { DATE_FORMAT } from '../../../../../constants/index';
import { convertSecondsToHoursMins, getCurrentFacilityURL } from '../../../../../helpers/helper.functions';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../../../components/headerRightUserInfo';
import UserDrawer from '../../../../../components/drawer/drawer';
// import './previousTestList.scss';
import AppIcons from '../../../../../assets/images/icons';
import { AppDoughnutChart } from '../../../../../components/charts/donut.chart';
import RichTextViewer from '../../../../../components/rich-text-editor/TextViewer';
import AppLoader from '../../../../../components/appLoader';
// import PreviousTestQuestionList from './previousTestQuestionList';
import S3ImageViewer from '../../../../../components/awsS3ImageViewer/index.';


const UnivExamStudentAttemptView = () => {
    const apiCaller = new ApiCaller('universityExams');
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [isExamPercentileLoading, setIsExamPercentileLoading] = useState(true);
    const [percentileData, setPercentileData] = useState(null);



    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setData([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getData(`get-university-exam-attempt/${id}`)
            .then((data) => {
                setData(data);
                if (data?.examDetails?.examId) {
                    getExamPercentile(data?.examDetails?.examId, data?.user?.userId)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Gets the percentile for this exam
     * @param {String} examId 
     */
    const getExamPercentile = (examId, userId) => {
        setIsExamPercentileLoading(true);
        apiCaller.getData(`university-get-exam-percentile/${userId}/${examId}/${id}`)
            .then((data) => {
                setPercentileData(data);

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsExamPercentileLoading(false));

    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'questionNumber',
            headerName: 'Q-ID',
            flex: 0.4,
            minWidth: 70,
        },
        {
            field: 'question',
            headerName: 'Question',
            flex: 1,
            minWidth: 325,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /></>
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            minWidth: 160,
            flex: 2,
            valueFormatter: ({ value }) => {
                return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
            }
        },
        {
            field: 'status',
            headerName: 'Result',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span>{row?.status === 'correct' ? <SVGIcons.StatusActiveIcon /> : row?.status === 'incorrect' ? <SVGIcons.StatusBlockedIcon /> : <SVGIcons.StatusOmittedIcon />}</span>
            </>
        },
        {
            field: 'timeTakenInSeconds',
            headerName: 'Time',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span>{row?.timeTakenInSeconds} sec</span>
            </>
        }
    ]

    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{data?.examDetails?.name || ''}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content prev-attempts-view'>
                                <div className='block-table-wrp user-table f-wrp'>
                                    <div className='back-btn-list'>
                                        <div className='left-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/exam-logs/${data?.examDetails?.examId || ''}`}><SVGIcons.BackIcon /> {translateThis('Back')}</Link>
                                        </div>
                                        <div className='right-btn-sec'>
                                            {/* <Link className='pln-button' to={`/${getCurrentFacilityURL()}/exams`}><SVGIcons.PrintIcon /> {translateThis('Print')}</Link> */}
                                            {/* <Link className='pln-button' onClick={onViewOpen} to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.QnListIcon /> {translateThis('Question list')}</Link> */}
                                            {/* <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.TestEyeIcon /> {translateThis('Test Revision')}</Link> */}
                                        </div>
                                    </div>

                                    {(data && data?.user) ? <div className='prev-view-boxes stud-home f-wrp' style={{ paddingBottom: '20px' }}>
                                        <div className='statistics-info-wrapper '>
                                            <Grid className='basic-info-grid' container spacing={1}>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Basic info')}</h3>
                                                        <div className='basic-detail-box basic-info'>
                                                            <div className='basic-info-box'>
                                                                <span className='profile-icon'>{(data?.user?.profileImg) ? <S3ImageViewer fileKey={data?.user?.profileImg} /> : <span>{`${data?.user?.name ? data?.user?.name[0] : ''} ${data?.user?.lastName ? data?.user?.lastName[0] : ''}`}</span>}</span>
                                                                <h4>{`${data?.user?.name} ${data?.user?.lastName}`}</h4>
                                                                <p>{data?.user?.email || ''}</p>
                                                                <h4 className='status'>{translateThis('Status')} <span>{translateThis('Active')}</span></h4>
                                                            </div>
                                                            <div className='graph-content'>
                                                                <ul>
                                                                    <li>
                                                                        <p>{SVGIcons.dateFill()} &nbsp; {dayjs(data?.result?.startTime).format(DATE_FORMAT)}</p>
                                                                        <span>{translateThis('Date taken')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p>{SVGIcons.timerFill()} &nbsp; {data?.result?.totalAvgtimeTakenInSeconds} {translateThis('sec')}</p>
                                                                        <span>{translateThis('Average question time')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p>{SVGIcons.clockFill()} &nbsp; {convertSecondsToHoursMins(data?.result?.timeTakenInSeconds)}</p>
                                                                        <span>{translateThis('Duration')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Performance')}</h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            <span className='graph-img'>
                                                                <h2 className='graph-label'>{translateThis('Correct')}</h2>
                                                                <AppDoughnutChart data={{
                                                                    labels: [`${translateThis('Correct')}`, `${translateThis('Incorrect')}`, `${translateThis('Omitted')}`],
                                                                    datasets: [
                                                                        {
                                                                            label: '',
                                                                            total: ``,
                                                                            totalScore: `${data?.result?.totalCorrectPercentage}`,
                                                                            fontStyle: 'bold 26px sans-serif',
                                                                            data: [`${data?.result?.totalCorrectPercentage}`, `${data?.result?.totalIncorrectPercentage}`, `${data?.result?.totalOmittedPercentage}`],
                                                                            backgroundColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }} />
                                                            </span>
                                                            <div className='graph-content'>
                                                                <span>{translateThis('From')} {data?.result?.noOfQuestions} {translateThis('questions, you answered')}:</span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.correct} alt='graph' /> &nbsp; {data?.result?.totalCorrectPercentage}% {translateThis('Correct')}</p>
                                                                        <span>{data?.result?.totalCorrect} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.incorrect} alt='graph' /> &nbsp; {data?.result?.totalIncorrectPercentage}% {translateThis('Incorrect')}</p>
                                                                        <span>{data?.result?.totalIncorrect} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.omitted} alt='graph' /> &nbsp; {data?.result?.totalOmittedPercentage}% {translateThis('Omitted')}</p>
                                                                        <span>{data?.result?.totalOmitted} {translateThis('questions')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    {!isExamPercentileLoading ? <div className='each-statistics-box'>
                                                        <h3>{translateThis('Score Percentile Rank')}</h3>
                                                        <div className='basic-detail-box line-chart'>
                                                            {/* <span className='graph-img'><img src={AppIcons.line48} alt='graph' /></span> */}
                                                            <div className='graph-img line-graph'>
                                                                <div className='PercentileIcon-wrp'>
                                                                    <span className='graph-wrp'>
                                                                        <img src={AppIcons.lineGraph} alt='graph' />
                                                                    </span>
                                                                    <div className={`graph-mark val${Math.round(percentileData?.scorePercentile)}`} style={{ width: `${percentileData?.scorePercentile}%` }}>
                                                                        <div className='value-box'>
                                                                            <p>{percentileData?.scorePercentile}{translateThis('th')} <b>{translateThis('percentile')}</b></p>
                                                                        </div>
                                                                        <p>{translateThis('You')}</p>
                                                                    </div>

                                                                    <div className={`graph-mark markFixed val50`} style={{ width: `50%` }}>
                                                                        <p>{translateThis('50th')}<b>{translateThis('percentile')}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='graph-content'>
                                                                <span><b>{translateThis('Performance rank')}:</b></span>
                                                                <ul>
                                                                    <li> 
                                                                        <span>{translateThis('You rank on the')}</span>
                                                                        <p><img src={AppIcons.green} alt='graph' /> &nbsp;<span>{percentileData?.scorePercentile}{translateThis('th percentile with a score of')} {percentileData?.userScore}%</span></p> 
                                                                    </li>
                                                                    <li>
                                                                        <span>{translateThis('Median score')}</span>
                                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp;<span>{percentileData?.medianPercentile} {translateThis('with a score of')} {percentileData?.medianScore}%</span></p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> : <AppLoader />}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div> : <AppLoader />}

                                    <Divider />

                                    <div className='header-wrp f-wrp'>
                                        <h3>{translateThis('Question pool')}</h3>
                                    </div>

                                    <div className='panel-table-sec 11 provider-table f-wrp' style={{ paddingTop: '20px' }}>
                                        <AppTable
                                            columns={tableColumns}
                                            isLoading={isLoading}
                                            data={data?.questions || []}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    )
}
UnivExamStudentAttemptView.propTypes = {}

export default UnivExamStudentAttemptView;
