import { Outlet } from 'react-router-dom';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from 'recoil';

// components
import AppHeader from '../../components/header';
import AppFooter from '../../components/footer';
import config from '../../config/config';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';


// ----------------------------------------------------------------------

const AppLayout = (props) => {
  const { appName } = config;
  const appState = useRecoilValue(appConfigAtomState);
  const { facilityInfo } = useRecoilValue(myPortalState);
  const { design = {} } = facilityInfo;

  const {
    pageName = "",
    isLoadHeader = true,
    isLoadFooter = true,
    headerClassName = "",
    bodyClassName = "",
    footerClassName = "",
    customHeader = null,
    customFooter = null,
    children = <></>
  } = props;

  const addThemeClass = (mode='') => {
    document.body.classList.remove('light-mode', 'dark-bg');
    document.body.classList.add(mode, design.buttonTheme);
    return ''
  };

  return (
    <>
      <Helmet>
        <title> {pageName} | {appName} </title>
      </Helmet>
      {(isLoadHeader) && <div className={`app-header ${headerClassName}`}>
        {customHeader ? <>{customHeader}</> : <AppHeader />}
      </div>}

      <div key={`${appState?.isDarkModeOn ? 'dark-key': 'light-key'}-${appState?.selectedLang}`} title={`${appState?.isDarkModeOn ? addThemeClass('dark-bg') : addThemeClass('light-mode')}`} className={`app-body ${design.theme || ''} ${design.buttonTheme || ''} ${bodyClassName}`} >
        {children}
        <Outlet />
      </div>
      {(isLoadFooter) && <div className={`app-footer ${footerClassName}`}>
        {customFooter ? <>{customFooter}</> : <AppFooter />}
      </div>}


    </>
  );
}
AppLayout.propTypes = {
  pageName: propTypes.string,
  isLoadHeader: propTypes.bool,
  isLoadFooter: propTypes.bool,
  customHeader: propTypes.element,
  customFooter: propTypes.element,
  children: propTypes.any,
  headerClassName: propTypes.string,
  bodyClassName: propTypes.string,
  footerClassName: propTypes.string,
}

export default AppLayout;