/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Box, Button, Divider, Grid, IconButton, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';

import ReactPlayer from 'react-player';
import Dialog from '@mui/material/Dialog';

// css
import '../../../../assets/css/form.scss';
import './index.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
// Components
import { showSnackbar } from '../../../../components/snackbar';

// Helpers
import { translateThis } from '../../../../helpers/language.helper';

// Services
import ApiCaller from '../../../../services/api/general';
import AppLayout from '../../../../layouts/app/AppLayout';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import UserDrawer from '../../../../components/drawer/drawer';
import RichTextEditor from '../../../../components/rich-text-editor';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';
import FileUploader from '../../../../components/awsS3ImageUploader/file.uploader';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';





// ----------------------------------------------------------------------


const CreateClasses = () => {
    const apiCaller = new ApiCaller('class');
    const navigate = useNavigate();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmittingAs, setIsSubmitting] = useState(false);
    const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [formError, setFormError] = useState({});
    const [file, setFile] = useState({});
    const [images, setImages] = useState([]);
    const [uniqueCode, setUniqueCode] = useState('');
    const [showCustomCodeButtons, setShowCustomCodeButtons] = useState(false);
    const [isCodeAvailable, setIsCodeAvailable] = useState(false);
    const [isUniqueCodeLoading, setIsUniqueCodeLoading] = useState(false);


    // for video preview and popup

    const [open, setOpen] = useState(false);
    const [optionName, setOptionName] = useState('');
    const [option, setOption] = useState('');
    const [options, setOptions] = useState([]);
    const [videoLink, setVideoLink] = useState('');
    const handleClickOpen = (option) => {
        setVideoLink(option);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const addAnswer = () => {
        if (option) {
            const optionsArr = [...options];
            optionsArr.push({ name: optionName, link: option });
            setOptions([...optionsArr])
            setOption('');
            setOptionName('');
        }
    }

    /**
     * Add a new answer to question
     * @param {string} answer 
     */
    const onRemoveAnswer = (optionToDelete) => {
        if (optionToDelete) {
            const optionsArr = [...options].filter((op) => op.name !== optionToDelete);
            setOptions([...optionsArr])
        }
    }

    // for copy link on clipboard

    const paraRef = useRef(null);
    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        const text = paraRef.current.textContent;
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };


    const [formData, setFormData] = useState({
        title: "",
        subtitle: "",
        shortDescription: "",
        description: "",
        classContent: "",
        videoLink: "",
        liveVideoLink: "",
        coverImage: "",
        images: [],
    });

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {

        getDataArr('subject');
        if (id) {
            getData();
        } else {
            getUniqueCode();
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Get Assigned Exam from Api
     */
    const getData = () => {
        setIsLoading(true)
        apiCaller.getData(`${id}`).then((data) => {
            const initialData = {
                title: data?.title,
                subtitle: data?.subtitle,
                accessType: data?.isSubscriptionNeeded ? 'subscription' : 'public',
                type: data?.isPodCast ? 'podcast' : 'class',
                shortDescription: data?.shortDescription,
                description: data?.description,
                classContent: data?.classContent,
                status: data?.status,
                liveVideoLink: data?.liveVideoLink
            }
            const videoLinks = data?.videoLinks || [];
            setOptions(videoLinks);
            setUniqueCode(data?.code)
            const images = data?.images ? data?.images.map((i) => ({ ...i, fileKey: i })) : [];
            setImages(images);
            const file = data?.coverImage ? { fileKey: data?.coverImage } : {};
            setFile(file)

            setFormData(initialData)
        })
            .catch((_) => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))
    }

    /**
     * Get setUniqueCode Exam from Api
     */
    const getUniqueCode = () => {
        apiCaller.getData(`class-code`).then((data) => {
            setIsCodeAvailable(typeof data?.code === 'string' && data?.code?.length)
            setUniqueCode(data?.code)
        })
            .catch((_) => showSnackbar().failure('Something went wrong!. Please try again later'))
    }

    /**
     * Check code availability
     */
    const checkUniqueCode = () => {
        if (uniqueCode) {
            setIsUniqueCodeLoading(true);
            apiCaller.getData(`class-code?&code=${uniqueCode}`).then((data) => {
                setIsCodeAvailable(data?.available);
            })
                .catch((_) => showSnackbar().failure('Something went wrong!. Please try again later'))
                .finally(() => setIsUniqueCodeLoading(false))
        }

    }



    // get users from api
    const getDataArr = (resource, query = '') => {
        const apiCaller = new ApiCaller(`${resource}${query}`)
        apiCaller.getList()
            .then((data) => {
                switch (resource) {
                    case 'subject':
                        setSubjects(data?.results)
                        break;
                    default:
                        break;
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        const isError = validateForm(formData);
        if (!Object.keys(isError).length) {
            isValid = true;
        }
        validateForm(formData);
        return isValid;
    }


    /**
     * On submit the form
     */
    const onSubmit = async (status) => {
        const postData = {
            "title": formData.title,
            "subtitle": formData.subtitle,
            "code": uniqueCode,
            "shortDescription": formData.shortDescription,
            "description": formData.description,
            "classContent": formData.classContent,
            "images": images.map((i) => i.fileKey),
            "coverImage": file?.fileKey || '',
            "videoLinks": options,
            "liveVideoLink": formData.liveVideoLink,
            "subjectId": selectedSubjects.map((s) => s.id),
            "systemId": selectedSystems.map((s) => s.id),
            "topicId": selectedTopics.map((s) => s.id),
            "status": status,
            "isSubscriptionNeeded": formData.accessType === 'subscription',
            "isPodCast": formData.type === 'podcast',
        }
        setIsSubmitBtnClicked(true);
        validateForm(formData);

        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            let data = {};
            if (id) {
                data = await apiCaller.customPutData(id, postData);
            } else {
                data = await apiCaller.postData(postData);
            }
            if (data.success) {
                if (status !== "DRAFT") {
                    navigate(`/${getCurrentFacilityURL()}/manage`, { replace: true });
                }
            } else {
                showSnackbar().failure(data.message)
            }
            setIsSubmitting(false)

        } else {
            showSnackbar().failure("Please fill all required fields")
        }

    };



    const onSelectSubject = (subject) => {
        setSelectedSubjects((prv) => {
            if (prv.find((s) => s.id === subject.id)) {
                prv = prv.filter((p) => p.id !== subject.id)
            } else {
                prv.push(subject)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a system
     * @param {Object} system 
     */
    const onSelectSystem = (system) => {
        setSelectedSystems((prv) => {
            if (prv.find((s) => s.id === system.id)) {
                prv = prv.filter((p) => p.id !== system.id)
            } else {
                prv.push(system)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a topic
     * @param {Object} system 
     */
    const onSelectTopic = (topic) => {
        setSelectedTopics((prv) => {
            if (prv.find((s) => s.id === topic.id)) {
                prv = prv.filter((p) => p.id !== topic.id)
            } else {
                prv.push(topic)
            }
            return [...prv]
        })

    }
    /**
   * set the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })
        setTimeout(() => {
            validateForm({ ...formData, ...{ [name]: value } });
        }, 500);

    }


    /**
     * 
     * @param {Object} f 
     */
    const validateForm = (f) => {
        const error = {}
        if (f) {
            if (!f.title) {
                error.title = 'Please enter title'
            }
            if (!f.subtitle) {
                error.subtitle = 'Please enter subtitle'
            }
            if (!uniqueCode) {
                error.uniqueCode = 'Code is required'
            }
            if (!f.accessType) {
                error.accessType = 'Please select access type'
            }
            if (!f.type) {
                error.type = 'Please select a type'
            }
            if (!f.description) {
                error.description = 'Please enter description'
            }
            if (!f.classContent) {
                error.classContent = 'Please enter content'
            }
            setFormError(error)
        }

        return error;
    }

    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header' style={{ marginBottom: '20px' }}>
                                <h2>{translateThis("Create a class")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content create-test-page create-class-page'>
                                {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
                                {!isLoading && <div>
                                    <Stack className='standard-form questionForm full-wrp' spacing={3}>


                                        <Grid container spacing={2} style={{ paddingBottom: '10px' }}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className='eachQn-box f-wrp'>
                                                    <div className='exam-create grid-box create-class'>
                                                        <div className='each-grid-items grid-items-one'>
                                                            <h3>{translateThis('Class title')}</h3>
                                                            <TextField
                                                                key={`add_class_name`}
                                                                name='title'
                                                                id={`add_class_name`}
                                                                value={formData?.title}
                                                                type='text'
                                                                placeholder={translateThis('add a title...')}
                                                                className='custom-textfield'
                                                                onChange={(e) => {
                                                                    setFormValue('title', e.target.value)
                                                                }}
                                                            />
                                                            {(isSubmitBtnClicked && formError?.title) ? <p className='errText'>{translateThis(formError?.title)}</p> : null}
                                                        </div>
                                                        <div className='each-grid-items grid-items-two'>
                                                            <h3>{translateThis('Class subtitle')}</h3>
                                                            <TextField
                                                                key={`add_class_subTitle`}
                                                                name='subtitle'
                                                                id={`add_class_subTitle`}
                                                                value={formData?.subtitle}
                                                                type='text'
                                                                placeholder={translateThis('add a subtitle...')}
                                                                className='custom-textfield'
                                                                onChange={(e) => {
                                                                    setFormValue('subtitle', e.target.value)
                                                                }}
                                                            />
                                                            {(isSubmitBtnClicked && formError?.subtitle) ? <p className='errText'>{translateThis(formError?.subtitle)}</p> : null}
                                                        </div>
                                                        <div className='each-grid-items grid-items-three'>
                                                            <span style={{ visibility: 'hidden', height: '0', display: 'block' }} ref={paraRef}>{uniqueCode}</span>
                                                            <div className='link-text'>
                                                                <TextField
                                                                    key={`add_class_uniqueCode`}
                                                                    name='uniqueCode'
                                                                    id={`add_class_Code`}
                                                                    value={uniqueCode}
                                                                    type='text'
                                                                    // placeholder={uniqueCode}
                                                                    placeholder={translateThis('Enter a code here')}
                                                                    className='custom-textfield link-textfield'
                                                                    onChange={(e) => {
                                                                        setUniqueCode(e.target.value);
                                                                        setIsCodeAvailable(false);
                                                                        setShowCustomCodeButtons(true)
                                                                    }}
                                                                />
                                                                {(isSubmitBtnClicked && formError?.uniqueCode) ? <p className='errText'>{translateThis(formError?.uniqueCode)}</p> : null}
                                                                {showCustomCodeButtons ? <div className='info-text'>
                                                                    {isCodeAvailable ? <span>Code available</span> : null}
                                                                    {!isCodeAvailable && uniqueCode ? <button type='button' onClick={checkUniqueCode}>Check availability</button> : null}
                                                                    <button type='button' onClick={getUniqueCode}>Generate new code</button>
                                                                </div> : null}
                                                                {isUniqueCodeLoading ? <span>Validating new code...</span> : null}
                                                                {uniqueCode ? <button type='button' className='copy-btn' onClick={handleCopy}><SVGIcons.CopyIcon />
                                                                    {copied && (
                                                                        <i className="tooltip">{translateThis('Link copied to clipboard')}</i>
                                                                    )}
                                                                </button> : null}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className='eachQn-box f-wrp noPadding-UL accessType'>
                                                    <h3>{translateThis('Access type')}</h3>
                                                    <div className='each-topic-sec'>
                                                        <div className='topic-items-list no-bg'>
                                                            <ul>
                                                                <li onClick={() => {
                                                                    setFormValue('accessType', "public")
                                                                }} className={formData.accessType === 'public' ? 'active' : ''}><p>{translateThis('Public')}</p></li>
                                                                <li onClick={() => setFormValue('accessType', "subscription")} className={formData.accessType === 'subscription' ? 'active' : ''}><p>{translateThis('Subscription required')}</p></li>
                                                            </ul>
                                                            {(isSubmitBtnClicked && formError?.accessType) ? <p className='errText'>{translateThis(formError?.accessType)}</p> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='eachQn-box f-wrp noPadding-UL tabType'>
                                                    <h3>{translateThis('Type')}</h3>
                                                    <div className='each-topic-sec'>
                                                        <div className='topic-items-list no-bg'>
                                                            <ul>
                                                                <li onClick={() => {
                                                                    setFormValue('type', "class")
                                                                }} className={formData.type === 'class' ? 'active' : ''}><p>{translateThis('Class')}</p></li>
                                                                <li onClick={() => setFormValue('type', "podcast")} className={formData.type === 'podcast' ? 'active' : ''}><p>{translateThis('Webinar')}</p></li>
                                                            </ul>
                                                            {(isSubmitBtnClicked && formError?.type) ? <p className='errText'>{translateThis(formError?.type)}</p> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className='eachQn-box f-wrp top-37'>
                                                    <h3>
                                                        {/* <span className='icon'><img src={AppIcons.Text} alt='icon' /></span> */}
                                                        {translateThis('Short description')}</h3>
                                                    <TextField
                                                        key={`class_short_description`}
                                                        name='shortDescription'
                                                        id={`class_short_description`}
                                                        value={formData?.shortDescription}
                                                        type='text'
                                                        rows={2}
                                                        multiline
                                                        placeholder={translateThis('Here you can set the basic information of the class')}
                                                        className='custom-textfield'
                                                        onChange={(e) => {
                                                            setFormValue('shortDescription', e.target.value)
                                                        }}

                                                    />
                                                    {(isSubmitBtnClicked && formError?.shortDescription) ? <p className='errText'>{translateThis(formError?.shortDescription)}</p> : null}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                                <div className='eachQn-box cover-image f-wrp'>
                                                    <h3>{translateThis('Cover image')}</h3>
                                                    <div className='form-img-uploader f-wrp'>
                                                        <FileUploader
                                                            uploadIcon={SVGIcons.UploadIcon()}
                                                            uploadCaption='Upload'
                                                            acceptOnly='image/*'
                                                            onUploadEnd={(file) => setFile(file)}
                                                            showPreview
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            {file?.fileKey && <Grid item xs={12} sm={6} md={3} lg={3}>
                                                <div className='eachQn-box coverImg-preview f-wrp'>
                                                    <div className='added-question-list-items f-wrp' style={{ paddingTop: '35px' }}>
                                                        <div className='flex-box'>
                                                            <span className={`image-blk`}>
                                                                <IconButton onClick={() => setFile('')}>
                                                                    <Delete />
                                                                </IconButton>
                                                                <S3ImageViewerWithFallback fileKey={file.fileKey} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Grid>}

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Description')}</h3>
                                                    <RichTextEditor defaultValue={formData?.description} onChange={(value) => setFormValue('description', JSON.stringify(value))} />
                                                    {(isSubmitBtnClicked && formError?.description) ? <p className='errText'>{translateThis(formError?.description)}</p> : null}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Assign it to a subject')}</h3>
                                                    <p>{translateThis('You can choose one or more subjects by clicking on them')}</p>
                                                    <div className='flex-box assignSubBox'>
                                                        {(subjects && subjects.length > 0) ? subjects.map((sub) => (<>
                                                            <button type='button' onClick={() => onSelectSubject(sub)} key={sub?.id} className={`image-blk ${selectedSubjects.find((s) => s.id === sub.id)?.id ? 'active' : ''}`}>
                                                                <img src={sub?.logo?.tempURL} alt='images' />
                                                                <h3>{sub?.subjectName}</h3>
                                                                <p>{sub.topics.length}&nbsp; {translateThis('topics')}</p>
                                                            </button>
                                                        </>
                                                        )) : null}

                                                    </div>
                                                    {(isSubmitBtnClicked && formError?.subject) ? <p className='errText'>{translateThis(formError?.subject)}</p> : null}

                                                    {(selectedSubjects.length > 0) ? selectedSubjects.map((subject) => (<>
                                                        <br />
                                                        <Divider />
                                                        <br />
                                                        <div key={`subject-list-${subject.id}`} className='each-topic-sec f-wrp'>
                                                            <div className='topic-head'>
                                                                <span><img src={subject.logo?.tempURL} alt='topic images' /></span>
                                                                <h5>{subject.subjectName}</h5>
                                                            </div>
                                                            <div className='topic-items-list'>
                                                                <h6>{translateThis('Systems')}</h6>
                                                                <ul>
                                                                    {(subject?.systems && subject?.systems.length > 0) ? subject?.systems.map((system) => (
                                                                        // eslint-disable-next-line
                                                                        <li onClick={() => {
                                                                            onSelectSystem({ ...system, id: system._id })
                                                                        }} key={system._id} className={selectedSystems.find((s) => s._id === system._id)?._id ? 'active' : ''}><p>{system.systemName}</p></li>
                                                                    )) : 'No systems found'}
                                                                </ul>
                                                            </div>

                                                            <div className='topic-items-list'>
                                                                <h6>{translateThis('Topics')}</h6>
                                                                <ul>
                                                                    {(subject?.topics && subject?.topics.length > 0) ? subject?.topics.map((topic) => (
                                                                        // eslint-disable-next-line
                                                                        <li onClick={() => onSelectTopic({ ...topic, id: topic._id })} key={topic._id} className={selectedTopics.find((s) => s._id === topic._id)?._id ? 'active' : ''}><p>{topic.topicName}</p></li>
                                                                    )) : 'No topics found'}
                                                                </ul>
                                                            </div>
                                                        </div></>
                                                    )) : ''}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className='eachQn-box cover-image f-wrp'>
                                                    <h3>{translateThis('Add images for your class')}</h3>
                                                    {/* <p>{translateThis('Image format and requirements go here')}</p> */}
                                                    <div className='form-img-uploader classImg-upload f-wrp'>
                                                        <FileUploader
                                                            acceptOnly='image/*'
                                                            uploadIcon={SVGIcons.UploadIcon()}
                                                            uploadCaption={`${translateThis('Upload a file')}`}
                                                            showPreview
                                                            onUploadEnd={(file) => setImages((prev) => {
                                                                const newImgArr = [...prev];
                                                                newImgArr.push(file);
                                                                return [...newImgArr];
                                                            })}
                                                        />
                                                    </div>
                                                    <div className='added-question-list-items f-wrp'>
                                                        <div className='flex-box'>
                                                            {images.map((img, i) => (
                                                                <span key={`${i}-${img.fileKey}`} className='image-blk'><S3ImageViewerWithFallback fileKey={img.fileKey} />
                                                                    <IconButton onClick={() => setImages((prev) => {
                                                                        const newImgArr = [...prev].filter((imgAs) => img.fileKey !== imgAs.fileKey);
                                                                        return [...newImgArr];
                                                                    })}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>

                                                </div>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className='eachQn-box add-video-box f-wrp'>
                                                    <h3>{translateThis('Add')}</h3>
                                                    <div className='form-vid-uploader flex-box f-wrp'>
                                                        <div className='each-flex-box video-flex-blk flex-box f-wrp'>
                                                            <TextField
                                                                key={`class_video_name}`}
                                                                name='videoName'
                                                                id={`class_video_name`}
                                                                value={optionName}
                                                                type='text'
                                                                placeholder={translateThis('Enter video name')}
                                                                className='custom-textfield videoName'
                                                                onChange={(e) => {
                                                                    setOptionName(e.target.value)
                                                                }}
                                                            />
                                                            <TextField
                                                                key={`class_video_url}`}
                                                                name='classVideoUrl'
                                                                id={`class_video_url`}
                                                                value={option}
                                                                type='text'
                                                                placeholder={translateThis('Enter video url')}
                                                                className='custom-textfield videoUrl'
                                                                onKeyUp={(e) => {
                                                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                                                        addAnswer()
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setOption(e.target.value)
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={!optionName || !option}
                                                                onClick={addAnswer}
                                                            className='videoAdd'
                                                            >add</Button>
                                                        </div>
                                                        <div className='each-flex-box flex-box f-wrp'>
                                                            <TextField
                                                                key={`class_youTube_url`}
                                                                name='classUrl'
                                                                id={`class_youTube_url`}
                                                                value={formData?.classUrl}
                                                                type='text'
                                                                placeholder={translateThis('Enter zoom link')}
                                                                className='custom-textfield'
                                                                onChange={(e) => {
                                                                    setFormValue('liveVideoLink', e.target.value)
                                                                }}
                                                            />
                                                            <Button
                                                            // disabled={!option}
                                                            // onClick={setFormValue('classUrl', formData?.classUrl)}
                                                            // className='Add-new-item'
                                                            >Save</Button>
                                                        </div>
                                                    </div>
                                                    <div className='form-vid-preview f-wrp'>
                                                        <ul>

                                                            {options.map((option, i) => (
                                                                <li key={`${option.name}+${i}`} style={{ position: 'relative' }}>
                                                                    <button type="button" className='preview-btn' onClick={() => handleClickOpen(option.link)}>
                                                                        <ReactPlayer width={'100%'} height={'auto'} controls url={option.link} />
                                                                    </button>
                                                                    <IconButton style={{ position: 'absolute', right: 0, top: 0 }} onClick={() => onRemoveAnswer(option.name)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className='form-vid-popup'>
                                                        <Dialog id="class-tutorial" open={open} onClose={handleClose}>
                                                            <button type='button' className='close-btn' onClick={handleClose}>
                                                                <svg width="25" height="25" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.53341 25.8334L6.66675 23.9667L14.1334 16.5001L6.66675 9.03341L8.53341 7.16675L16.0001 14.6334L23.4667 7.16675L25.3334 9.03341L17.8667 16.5001L25.3334 23.9667L23.4667 25.8334L16.0001 18.3667L8.53341 25.8334Z" fill="#FFFFFF" />
                                                                </svg>
                                                            </button>
                                                            <span className='video-wrapper f-wrp'>
                                                                <ReactPlayer width={'100%'} height={'auto'} controls url={videoLink} />
                                                            </span>
                                                        </Dialog>
                                                    </div>
                                                </div>
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3>{translateThis('Class content')}</h3>
                                                    <RichTextEditor defaultValue={formData?.classContent} onChange={(value) => setFormValue('classContent', JSON.stringify(value))} />
                                                    {(isSubmitBtnClicked && formError?.classContent) ? <p className='errText'>{translateThis(formError?.classContent)}</p> : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className='button-sec createExamButtonSet f-wrp'>
                                            <div className='lft-btn-sec'>
                                                <div className='loadingBtn2'>
                                                    <Button style={{ padding: '0' }}
                                                        onClick={() => navigate(`/${getCurrentFacilityURL()}/manage`, { replace: true })}
                                                        className='cancel-btn red-btn'>{translateThis("Cancel")}</Button>
                                                </div>
                                            </div>
                                            <div className='ryt-btn-sec'>
                                                <div className='loadingBtn2'>
                                                    {!id ? <>
                                                        <LoadingButton
                                                            loading={isSubmittingAs}
                                                            disabled={!isCodeAvailable}
                                                            type="submit"
                                                            className='fill-btn'
                                                            onClick={() => onSubmit('DRAFT')}>
                                                            {translateThis("Save as draft")}
                                                        </LoadingButton>

                                                        <LoadingButton
                                                            loading={isSubmittingAs}
                                                            disabled={!isCodeAvailable}
                                                            type="submit"
                                                            className='fill-btn'
                                                            onClick={() => onSubmit('ACTIVE')}>
                                                            {translateThis("Publish")}
                                                        </LoadingButton>
                                                    </> : <LoadingButton
                                                        loading={isSubmittingAs}
                                                        type="submit"
                                                        className='fill-btn'
                                                        onClick={() => onSubmit(formData?.status)}>
                                                        {translateThis("Update")}
                                                    </LoadingButton>}
                                                </div>

                                            </div>
                                        </div>






                                    </Stack>
                                    <br />
                                </div>}
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>

    );
}

CreateClasses.propTypes = {}

export default CreateClasses;