/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Box, Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ModalPopUp from '../../../components/modal/modal.popup';
// import Grid from '@mui/material/Grid';
// css
import '../../../assets/css/form.scss';
import SVGIcons from '../../../assets/images/icons/svgIcons';
// Components
import { showSnackbar } from '../../../components/snackbar';

// Helpers
import { translateThis } from '../../../helpers/language.helper';

// Services
import AppIcons from '../../../assets/images/icons';
import ApiCaller from '../../../services/api/general';
import AppLayout from '../../../layouts/app/AppLayout';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';
import UserDrawer from '../../../components/drawer/drawer';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import RichTextEditor from '../../../components/rich-text-editor';
import { DATE_FORMAT, TIME_12HR_FORMAT } from '../../../constants/index';
import TimeInput from '../../../components/inputs/timeInput';
import QuestionAndAnswerTable from './QnAnswerTable/questionAndAnswerTable';
import SelectStudentPopup from './selectStudentPopup/selectStudentPopup';
import HeaderRightUserInfo from '../components/headerRightUserInfo';





// import NoQuestionsToShowPopup from './noQuestionsPopup';
import AppLoader from '../../../components/appLoader/index';




// ----------------------------------------------------------------------


const CreateNewExamForm = () => {
    const apiCaller = new ApiCaller('universityTests');
    const questionModes = ["unused", "correct", "incorrect", "omitted", "flagged"];
    const navigate = useNavigate();
    const { id } = useParams();

    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isSubmittingAs, setIsSubmitting] = useState(false);
    const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false);
    const [isOpenSubscribeDialog, setOpenSubscribeDialog] = useState(false);
    const [isOpenNoQuestionsDialog, setIsOpenNoQuestionsDialog] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [systems, setSystems] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [allStudents, setAllStudents] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [formError, setFormError] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        introduction: "",
        description: "",
        difficulty: '',
        noOfAttempts: '',
        numberOfQuestions: "",
        dateType: "",
        startDate: "",
        endDate: "",
        durationType: "",
        accessType: "",
        duration: "",
        questionPool: "",
        questionScore: "",
        whoCanTakeExam: "",
        questionPoolMode: "",
        startTime: "",
        endTime: "",
        durationMetric: "",
        customEmailIds: "",
        status: "ACTIVE",
        studentIds: [],
        questionIds: [],
    });

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        getDataArr('subject');
        getDataArr('systems');
        getDataArr('university/student-list');
        getDataArr('topics');
        getDataArr('questions');
        if (id) {
            getData();
        }
        // eslint-disable-next-line
    }, []);
    /**
         * Get Assigned Exam from Api
         */
    const getData = () => {
        apiCaller.getData(`${id}`).then((data) => setFormData({ ...data?.testDetails, accessType: data?.testDetails?.isSubscriptionNeeded ? 'subscription' : 'public', }))
            .catch((e) => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))
    }



    // get users from api
    const getDataArr = (resource, query = '') => {
        const apiCaller = new ApiCaller(`${resource}${query}`)
        apiCaller.getList()
            .then((data) => {
                switch (resource) {
                    case 'subject':
                        setSubjects(data?.results)
                        // setSelectedSubjects(data?.results)
                        break;
                    case 'systems':
                        setSystems(data?.result);
                        // setSelectedSystems(data?.result);
                        break;
                    case 'topics':
                        setTopics(data?.result);
                        // setSelectedTopics(data?.result);
                        break;
                    case 'university/student-list':
                        setAllStudents(data?.result);
                        // setSelectedTopics(data?.result);
                        break;

                    case 'questions':
                        setAllQuestions(data);
                        // setSelectedTopics(data?.result);
                        break;

                    default:
                        break;
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        const isError = validateForm(formData);
        if (!Object.keys(isError).length) {
            isValid = true;
        }
        validateForm(formData);
        return isValid;
    }


    /**
     * On submit the form
     */
    const onSubmit = async () => {
        const postData = {
            "name": formData.name,
            "introduction": formData.introduction,
            "description": formData.description,
            "difficulty": formData.difficulty,
            "noOfAttempts": formData.noOfAttempts,
            "numberOfQuestions": `${formData.numberOfQuestions.toString()}` || `${formData.questionIds.length}`,
            "dateType": formData.dateType,
            "startDate": formData.startDate || '',
            "endDate": formData.endDate || '',
            "durationType": formData.durationType,
            "duration": `${formData.duration}`,
            "questionPool": formData.questionPool,
            "questionScore": formData.questionScore,
            "whoCanTakeExam": formData.whoCanTakeExam,
            "studentIds": formData.studentIds,
            "customEmailIds": formData.customEmailIds,
            "questionIds": formData.questionIds,
            "questionPoolMode": formData.questionPoolMode,
            "startTime": formData.startTime || '',
            "endTime": formData.endTime || '',
            "status": formData.status,
            "isSelfAssessment": formData.isSelfAssessment,
            "isSubscriptionNeeded": formData.accessType === 'subscription',
            "durationMetric": formData.durationMetric
        }
        setIsSubmitBtnClicked(true);
        validateForm(formData);

        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            let data = {};
            if (id) {
                data = await apiCaller.customPutData(id, postData);
            } else {
                data = await apiCaller.postData(postData);
            }
            if (data.success) {
                if (formData.status !== "DRAFT") {
                    navigate(`/${getCurrentFacilityURL()}/exam-logs`, { replace: true });
                }
            } else {
                showSnackbar().failure(data.message)
            }
            setIsSubmitting(false)

        } else {
            showSnackbar().failure("Please fill all required fields")
        }

    };

    /**
     * When user clicks on subscribe
     */
    const onSubscribeConfirm = () => {
        navigate(`/${getCurrentFacilityURL()}/plans`, { replace: true })

    }

    const onSelectSubject = (subject) => {
        setSelectedSubjects((prv) => {
            if (prv.find((s) => s.id === subject.id)) {
                prv = prv.filter((p) => p.id !== subject.id)
            } else {
                // getDataArr(`systems`, `subjectId=${subject.id}&`)
                prv.push(subject)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a system
     * @param {Object} system 
     */
    const onSelectSystem = (system) => {
        setSelectedSystems((prv) => {
            if (prv.find((s) => s.id === system.id)) {
                prv = prv.filter((p) => p.id !== system.id)
            } else {
                prv.push(system)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a topic
     * @param {Object} system 
     */
    const onSelectTopic = (topic) => {
        setSelectedTopics((prv) => {
            if (prv.find((s) => s.id === topic.id)) {
                prv = prv.filter((p) => p.id !== topic.id)
            } else {
                prv.push(topic)
            }
            return [...prv]
        })

    }
    /**
   * set the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })
        setTimeout(() => {
            validateForm({ ...formData, ...{ [name]: value } });
        }, 500);

    }

    const setDraftMode = async () => {
        setFormValue('status', 'DRAFT');
    }

    /**
   * sets the setQuestionMode data 
   * @param {string} val  the value of the field
   */
    const setQuestionMode = (val) => {
        let arr = formData.questionMode;
        if (arr.includes(val)) {
            arr = arr.filter((v) => v !== val);
        } else if (val === 'all') {
            arr = questionModes;
        } else {
            arr.push(val);
        }
        setFormData({ ...formData, ...{ questionMode: arr } })
    }

    const [showRandom, setShowRandom] = useState(false);
    const [showFixed, setShowFixed] = useState(false);
    const [openViewStudentSelect, setOpenViewStudentSelect] = useState(false);

    const showRandomButton = () => {
        setShowRandom(!showRandom);
        setShowFixed(false);
    };
    const showFixedButton = () => {
        setShowFixed(!showFixed);
        setShowRandom(false);
    };
    /**
     * 
     * @param {Object} f 
     */
    const validateForm = (f) => {
        const error = {}
        if (f) {
            if (f.whoCanTakeExam) {
                if (f.whoCanTakeExam === 'students' && (f.studentIds.length === 0 && !f.customEmailIds)) {
                    error.whoCanTakeExam = 'Please select students'
                }
            } else {
                error.whoCanTakeExam = 'Please select one'
            }
            if (f.dateType) {
                if (f.dateType === 'within range' && (!f.startDate && !f.endDate)) {
                    error.dateType = 'Please enter start and end date and time'
                }
            } else {
                error.dateType = 'Please select one';
            }
            if (f.durationType) {
                if (!f.duration || !f.durationMetric) {
                    error.durationType = 'Please select duration and type'
                }
            } else {
                error.durationType = 'Please select one';
            }
            if (f.questionPool) {
                if ((f.questionPool === 'fixed' || (f.questionPoolMode === 'Pre selected pool')) && (f.questionIds.length === 0)) {
                    error.questionPool = 'Please select questions'
                }
            } else {
                error.questionPool = 'Please select one';
            }
            if (!f.isSelfAssessment) {
                error.isSelfAssessment = 'Please select one'
            }
            if (!f.accessType) {
                error.accessType = 'Please select one'
            }
            setFormError(error)
        }

        return error;
    }
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header' style={{ marginBottom: '20px' }}>
                                <h2>{translateThis("Create an exam")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content create-test-page create-exam-page'>
                                {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
                                {!isLoading && <div>
                                    <Stack className='standard-form questionForm full-wrp' spacing={3}>
                                        <Formik
                                            initialValues={formData}
                                            onSubmit={(_, { setSubmitting }) => {
                                                onSubmit()
                                                setSubmitting(false);

                                            }}
                                        >
                                            {({
                                                handleSubmit
                                            }) => (
                                                <form
                                                    onSubmit={handleSubmit}
                                                >

                                                    <Grid container spacing={2} style={{ paddingBottom: '10px' }}>


                                                        <Grid item xs={12} sm={12} md={6} lg={5}>
                                                            <div className='eachQn-box f-wrp'>
                                                                <div className='exam-create grid-box'>
                                                                    <div className='each-grid-items grid-items-one'>
                                                                        <h3><span className='icon'><img src={AppIcons.Text} alt='icon' /></span>{translateThis('Name')}</h3>
                                                                        <TextField
                                                                            key={`create_Exam_Creator_add_answer`}
                                                                            name='name'
                                                                            id={`create_Exam_Creator_add_answer`}
                                                                            value={formData?.name}
                                                                            translationKey={translateThis('Write a name')}
                                                                            type='text'
                                                                            placeholder={translateThis('Write a name')}
                                                                            className='custom-textfield'
                                                                            onChange={(e) => {
                                                                                setFormValue('name', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className='each-grid-items grid-items-two'>
                                                                        <h3><span className='icon'><img src={AppIcons.Text} alt='icon' /></span>{translateThis('Introduction')} <span className='optional'>{translateThis('optional')}</span></h3>
                                                                        <TextField
                                                                            key={`Exam_Introduction`}
                                                                            name='introduction'
                                                                            id={`Exam_Introduction`}
                                                                            value={formData?.introduction}
                                                                            translationKey={translateThis('Write a brief introduction...')}
                                                                            type='text'
                                                                            rows={2}
                                                                            multiline
                                                                            placeholder={translateThis('Write a brief introduction...')}
                                                                            className='custom-textfield'
                                                                            onChange={(e) => {
                                                                                setFormValue('introduction', e.target.value)
                                                                            }}

                                                                        />
                                                                    </div>
                                                                    <div className='each-grid-items grid-items-three'>
                                                                        <h3><span className='icon'><img src={AppIcons.Description} alt='icon' /></span>{translateThis('Description')} <span className='optional'>{translateThis('optional')}</span></h3>
                                                                        <RichTextEditor onChange={(value) => setFormValue('description', JSON.stringify(value))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={7}>
                                                            <Grid container spacing={2} style={{ paddingBottom: '16px' }}>
                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                    <div className='flex-box' style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', paddingBottom: '16px' }}>
                                                                        <div className='eachQn-box f-wrp' style={{ margin: '0', flex: '1' }}>
                                                                            <h3><span className='icon'><img src={AppIcons.AccessType} alt='icon' /></span>{translateThis('Access type')}</h3>
                                                                            <div className='each-topic-sec'>
                                                                                <div className='topic-items-list no-bg' style={{ paddingTop: '0', paddingBottom: '0' }}>
                                                                                    <ul>
                                                                                        <li onClick={() => setFormValue('accessType', 'subscription')} className={formData.accessType === 'subscription' ? 'active' : ''}><p>{translateThis('Subscription required')}</p></li>
                                                                                        <li onClick={() => setFormValue('accessType', 'public')} className={formData.accessType === 'public' ? 'active' : ''}><p>{translateThis('Public')}</p></li>
                                                                                    </ul>
                                                                                    {(isSubmitBtnClicked && formError?.accessType) ? <p className='errText'>{translateThis(formError?.accessType)}</p> : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className='flex-box' style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                                                                        <div className='eachQn-box f-wrp' style={{ margin: '0', flex: '1' }}>
                                                                            <h3><span className='icon'><img src={AppIcons.number} alt='icon' /></span>{translateThis('Questions')}</h3>
                                                                            <TextField style={{ maxWidth: '64px' }} onWheel={(e) => e.target.blur()}
                                                                                key={`Number_of_questions`}
                                                                                name={`numberOfQuestions`}
                                                                                id={`Number_of_questions`}
                                                                                value={formData?.questionIds.length || formData?.numberOfQuestions}
                                                                                type='text'
                                                                                aria-readonly={formData.questionPool === 'Fixed'}
                                                                                min='2'
                                                                                max='9999'
                                                                                // placeholder={translateThis('00')}
                                                                                className='custom-textfield'
                                                                                onChange={(e) => {
                                                                                    if (e.target.value <= 10000) {
                                                                                        setFormValue('numberOfQuestions', e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className='eachQn-box f-wrp' style={{ margin: '0', flex: '1' }}>
                                                                            <h3><span className='icon'><img src={AppIcons.Attempts} alt='icon' /></span>{translateThis('Attempts')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Attempts')}:</b> {translateThis('max number of attempts allowed per student.')}</span>} placement="top">
                                                                                <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                            </Tooltip></h3>
                                                                            <TextField style={{ maxWidth: '48px' }} onWheel={(e) => e.target.blur()}
                                                                                key={`Number_of_Attempts`}
                                                                                name={`noOfAttempts`}
                                                                                id={`Number_of_Attempts`}
                                                                                value={formData?.noOfAttempts}
                                                                                type='number'
                                                                                min='2'
                                                                                max='100'
                                                                                placeholder={translateThis('00')}
                                                                                className='custom-textfield'
                                                                                onChange={(e) => {
                                                                                    if (e.target.value <= 100) {
                                                                                        setFormValue('noOfAttempts', e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>



                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                    <div className='eachQn-box f-wrp noPadding-UL'>
                                                                        <h3><span className='icon'><img src={AppIcons.Students} alt='icon' /></span>{translateThis('Students')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Select students:')}</b> {translateThis('select the participants from a list.')}<br /><b>{translateThis('Anyone with the link:')}</b> {translateThis('Any student with the link can access.')}</span>} placement="top">
                                                                            <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                        </Tooltip></h3>
                                                                        {/* <p>{translateThis('Explanatory text coming up')}</p> */}
                                                                        <div className='each-topic-sec'>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <ul>
                                                                                    <li onClick={() => {
                                                                                        setFormValue('whoCanTakeExam', "students")
                                                                                        setOpenViewStudentSelect(true)
                                                                                    }} className={formData.whoCanTakeExam === 'students' ? 'active' : ''}><p>{translateThis('Select students')}</p></li>
                                                                                    <li onClick={() => setFormValue('whoCanTakeExam', "Anyone with the link")} className={formData.whoCanTakeExam === 'Anyone with the link' ? 'active' : ''}><p>{translateThis('Anyone with the link')}</p></li>
                                                                                </ul>
                                                                                {(isSubmitBtnClicked && formError?.whoCanTakeExam) ? <p className='errText'>{translateThis(formError?.whoCanTakeExam)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                    <div className='eachQn-box f-wrp noPadding-UL'>
                                                                        <h3><span className='icon'><img src={AppIcons.Date} alt='icon' /></span>{translateThis('Date')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>explanation</b></span>} placement="top">
                                                                            <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                        </Tooltip></h3>
                                                                        <div className='each-topic-sec'>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <ul>
                                                                                    <li onClick={() => setFormValue('dateType', "Indefinitely into the future")} className={formData.dateType === 'Indefinitely into the future' ? 'active' : ''}><p>{translateThis('Indefinitely into the future')}</p></li>
                                                                                    <div className='inline-btn-wrp'>
                                                                                        <li onClick={() => setFormValue('dateType', "within range")} className={formData.dateType === 'within range' ? 'active' : ''}><p>{translateThis('Within a date range')}</p></li>
                                                                                        {formData.dateType === 'within range' ? <><button className='topic-btn active' type='button'>{translateThis('Student time')}</button>
                                                                                            <button className='topic-btn' type='button'>{translateThis('University time')}</button></> : null}
                                                                                    </div>
                                                                                </ul>
                                                                                {formData.dateType === 'within range' ? <div className='date-time-wrp f-wrp' style={{ paddingTop: '20px' }}>
                                                                                    <div className='each-dateTime-items'>
                                                                                        <span className='dateTime-label'>{translateThis('Start')}</span>
                                                                                        <div className='datetime-options-box'>
                                                                                            <LocalizationProvider className="date-selector" dateAdapter={AdapterDayjs}>
                                                                                                <DemoContainer className="date-demo-container" components={['DatePicker']}>
                                                                                                    <DatePicker
                                                                                                        value={formData?.startDate ? dayjs(formData?.startDate) : undefined}
                                                                                                        defaultValue={formData?.startDate ? dayjs(formData?.startDate) : undefined}
                                                                                                        className='date-picker'
                                                                                                        slots={{ openPickerIcon: SVGIcons.CalendarIcon }}
                                                                                                        slotProps={{
                                                                                                            inputAdornment: {
                                                                                                                position: 'start'
                                                                                                            }
                                                                                                        }}
                                                                                                        onChange={(v) => {
                                                                                                            setFormValue('startDate', dayjs(v).format(DATE_FORMAT))
                                                                                                        }
                                                                                                        }
                                                                                                        label={translateThis("Select date")}
                                                                                                    />
                                                                                                </DemoContainer>
                                                                                            </LocalizationProvider>
                                                                                        </div>
                                                                                        <div className='datetime-options-box'>
                                                                                            <div className='timeWithIcon'>
                                                                                                <span className='timeIcon'><SVGIcons.ClockIcon /></span>
                                                                                                <TimeInput
                                                                                                    label={translateThis('Select time')}
                                                                                                    // required = {false}
                                                                                                    className="timeWithIcon-input"
                                                                                                    // defaultValue={''}
                                                                                                    defaultValue={formData?.startTime ? dayjs(formData?.startTime, 'hh:mm a') : undefined}
                                                                                                    onChange={(v) => {
                                                                                                        setFormValue('startTime', dayjs(v).format(TIME_12HR_FORMAT))
                                                                                                    }}
                                                                                                    useDefaultValidation
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='each-dateTime-items'>
                                                                                        <span className='dateTime-label'>{translateThis('End')}</span>
                                                                                        <div className='datetime-options-box'>
                                                                                            <LocalizationProvider className="date-selector" dateAdapter={AdapterDayjs}>
                                                                                                <DemoContainer className="date-demo-container" components={['DatePicker']}>
                                                                                                    <DatePicker
                                                                                                        value={formData?.endDate ? dayjs(formData?.endDate) : undefined}
                                                                                                        defaultValue={formData?.endDate ? dayjs(formData?.endDate) : undefined}
                                                                                                        className='date-picker'
                                                                                                        slots={{ openPickerIcon: SVGIcons.CalendarIcon }}
                                                                                                        slotProps={{
                                                                                                            inputAdornment: {
                                                                                                                position: 'start'
                                                                                                            }
                                                                                                        }}
                                                                                                        onChange={(v) => {
                                                                                                            setFormValue('endDate', dayjs(v).format(DATE_FORMAT))
                                                                                                        }
                                                                                                        }
                                                                                                        label={translateThis("Select date")}
                                                                                                    />
                                                                                                </DemoContainer>
                                                                                            </LocalizationProvider>
                                                                                        </div>
                                                                                        <div className='datetime-options-box'>
                                                                                            <div className='timeWithIcon'>
                                                                                                <span className='timeIcon'><SVGIcons.ClockIcon /></span>
                                                                                                <TimeInput
                                                                                                    label={translateThis('Select time')}
                                                                                                    // required
                                                                                                    className="timeWithIcon-input"
                                                                                                    // defaultValue={''}
                                                                                                    defaultValue={formData?.endTime ? dayjs(formData?.endTime, 'hh:mm a') : undefined}
                                                                                                    onChange={(v) => {
                                                                                                        setFormValue('endTime', dayjs(v).format(TIME_12HR_FORMAT))
                                                                                                    }}
                                                                                                    useDefaultValidation
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : null}

                                                                                {(isSubmitBtnClicked && formError?.dateType) ? <p className='errText'>{translateThis(formError?.dateType)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                    <div className='eachQn-box f-wrp noPadding-UL'>
                                                                        <h3><span className='icon'><img src={AppIcons.Time} alt='icon' /></span>{translateThis('Duration (?)')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('By question:')}</b> {translateThis('set the duration based on the inputted time and the number of questions.')} <br /><b>{translateThis('By exam:')}</b> {translateThis('set the exam duration directly')}</span>} placement="top">
                                                                            <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                        </Tooltip></h3>
                                                                        <div className='each-topic-sec'>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <div className='topic-dropdown'>
                                                                                    <ul>
                                                                                        <div className='inline-btn-wrp' style={{ alignItems: 'center' }}>
                                                                                            <li onClick={() => setFormValue('durationType', "per question")} className={formData.durationType === 'per question' ? 'active' : ''}><p>{translateThis('By question')}</p></li>
                                                                                            {formData.durationType === 'per question' ? <>
                                                                                                {/* <span className='duration-label' style={{ fontSize: '14px' }}>{translateThis('Select question duration')}</span> */}
                                                                                                <div className='duration-options-box'>
                                                                                                    <TextField style={{ maxWidth: '60px', marginTop: '0' }} onWheel={(e) => e.target.blur()}
                                                                                                        key={`question_Duration`}
                                                                                                        name={`durationType`}
                                                                                                        id={`question_Duration`}
                                                                                                        value={formData?.duration}
                                                                                                        type='number'
                                                                                                        min='1'
                                                                                                        max='999'
                                                                                                        placeholder={translateThis('00')}
                                                                                                        className='custom-textfield'
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.value <= 999) {
                                                                                                                setFormValue('duration', e.target.value)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='duration-options-box'>
                                                                                                    <FormControl fullWidth className='dropDown-box'>
                                                                                                        <Select
                                                                                                            style={{ maxWidth: '80px' }}
                                                                                                            key={`question_DurationType`}
                                                                                                            labelId="question_DurationType"
                                                                                                            className='dropDown-select'
                                                                                                            id="durationMetric"
                                                                                                            value={formData?.durationMetric}
                                                                                                            placeholder={translateThis('sec')}
                                                                                                            onChange={(e) => setFormValue('durationMetric', e.target.value)}
                                                                                                        >
                                                                                                            <MenuItem value={'sec'}>{translateThis('sec')}</MenuItem>
                                                                                                            <MenuItem value={'min'}>{translateThis('min')}</MenuItem>
                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                </div></> : null}
                                                                                        </div>
                                                                                    </ul>
                                                                                </div>
                                                                                <br />
                                                                                <div className='topic-dropdown'>
                                                                                    <ul>
                                                                                        <div className='inline-btn-wrp' style={{ alignItems: 'center' }}>
                                                                                            <li onClick={() => setFormValue('durationType', "per exam")} className={formData.durationType === 'per exam' ? 'active' : ''}><p>{translateThis('By exam')}</p></li>
                                                                                            {formData.durationType === 'per exam' ? <>
                                                                                                {/* <span className='duration-label' style={{ fontSize: '14px' }}>{translateThis('Select question duration')}</span> */}
                                                                                                <div className='duration-options-box'>
                                                                                                    <TextField style={{ maxWidth: '60px', marginTop: '0' }} onWheel={(e) => e.target.blur()}
                                                                                                        key={`customQuestion_Duration`}
                                                                                                        name={`customQuestion_Duration`}
                                                                                                        id={`customQuestion_Duration`}
                                                                                                        value={formData?.duration}
                                                                                                        type='number'
                                                                                                        min='1'
                                                                                                        max='999'
                                                                                                        placeholder={translateThis('00')}
                                                                                                        className='custom-textfield'
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.value <= 999) {
                                                                                                                setFormValue('duration', e.target.value)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='duration-options-box'>
                                                                                                    <FormControl fullWidth className='dropDown-box'>
                                                                                                        <Select
                                                                                                            style={{ maxWidth: '80px' }}
                                                                                                            key={`customQuestion_DurationType`}
                                                                                                            labelId="customQuestion_DurationType"
                                                                                                            className='dropDown-select'
                                                                                                            id="customQuestion_DurationType"
                                                                                                            value={formData?.durationMetric}
                                                                                                            // label={translateThis('Select question duration')}
                                                                                                            placeholder={translateThis('hour')}
                                                                                                            onChange={(e) => setFormValue('durationMetric', e.target.value)}
                                                                                                        >
                                                                                                            <MenuItem value={'min'}>{translateThis('min')}</MenuItem>
                                                                                                            <MenuItem value={'hour'}>{translateThis('hour')}</MenuItem>
                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                </div> </> : null}
                                                                                        </div>
                                                                                    </ul>
                                                                                </div>

                                                                                {(isSubmitBtnClicked && formError?.durationType) ? <p className='errText'>{translateThis(formError?.durationType)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <div className='eachQn-box f-wrp noPadding-UL'>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                                                        <div className='each-topic-sec'>
                                                                            <h3><span className='icon'><img src={AppIcons.testMode} alt='icon' /></span>{translateThis('Question pool')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Random:')}</b> {translateThis('questions are randomly selected, and presented in any sequence')} <br /><b>{translateThis('Fixed:')}</b> {translateThis('select the questions from a list and arrange them in your preferred order.')}</span>} placement="top">
                                                                                <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                            </Tooltip></h3>

                                                                            <div className='topic-items-list no-bg'>
                                                                                <div className='topic-dropdown'>
                                                                                    <div className='drop-sec' style={{ flex: '3' }}>
                                                                                        <ul>
                                                                                            <div className='inline-btn-wrp'>
                                                                                                <li onClick={() => setFormValue('questionPool', 'Random')} className={formData.questionPool === 'Random' ? 'active' : ''}><p>{translateThis('Random')}</p></li>
                                                                                                {formData.questionPool === 'Random' && <div className='drop-sec' style={{ flex: 'auto' }}>
                                                                                                    <div className='drop-options'>
                                                                                                        <div className='drop-options-box' style={{ maxWidth: '205px', minWidth: '200px' }}>
                                                                                                            <FormControl fullWidth className='dropDown-box'>
                                                                                                                <InputLabel id="select-question_Duration">{translateThis('Random selection mode')} </InputLabel>
                                                                                                                <Select
                                                                                                                    key={`question_pool_mode`}
                                                                                                                    labelId="question_pool_mode"
                                                                                                                    className='dropDown-select'
                                                                                                                    id="question_pool_mode"
                                                                                                                    value={formData?.questionPoolMode || undefined}
                                                                                                                    label={translateThis('Random selection mode')}
                                                                                                                    placeholder={translateThis('Select question mode')}
                                                                                                                    onChange={(e) => setFormValue('questionPoolMode', e.target.value)}
                                                                                                                >
                                                                                                                    {/* <MenuItem value={'Random selection mode'}>{translateThis('Random selection mode')}</MenuItem> */}
                                                                                                                    <MenuItem value={'All questions'}>{translateThis('All questions')}</MenuItem>
                                                                                                                    <MenuItem value={'Pre selected pool'}>{translateThis('Pre-selected pool')}</MenuItem>
                                                                                                                </Select>
                                                                                                            </FormControl>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>}
                                                                                            </div>

                                                                                            <li onClick={() => {
                                                                                                setFormValue('questionPool', 'Fixed')
                                                                                                showFixedButton()
                                                                                            }} className={formData.questionPool === 'Fixed' ? 'active' : ''}><p>{translateThis('Fixed')}</p></li>
                                                                                        </ul>

                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            {(isSubmitBtnClicked && formError?.questionPool) ? <p className='errText'>{translateThis(formError?.questionPool)}</p> : null}

                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                                                        <div className='each-topic-sec'>
                                                                            <h3><span className='icon'><img src={AppIcons.score} alt='icon' /></span>{translateThis('Question score')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>explanation</b></span>} placement="top">
                                                                                <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                            </Tooltip></h3>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <ul>

                                                                                    <li onClick={() => setFormValue('questionScore', "Auto")} className={formData.questionScore === 'Auto' ? 'active' : ''}><p>{translateThis('Auto scoring')}</p></li>

                                                                                    <li onClick={() => setFormValue('questionScore', "Manual")} className={formData.questionScore === 'Manual' ? 'active' : ''}><p>{translateThis('Manual scoring')}</p></li>

                                                                                </ul>
                                                                                {(isSubmitBtnClicked && formError?.questionScore) ? <p className='errText'>{translateThis(formError?.questionScore)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                                                        <div className='each-topic-sec'>
                                                                            <h3><span className='icon'><img src={AppIcons.Difficulty} alt='icon' /></span>{translateThis('Difficulty')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px', whiteSpace: 'pre-line' }}><b>{translateThis('Difficulty:')}</b> {translateThis('tag used for ease of filtering. Doesn’t affect the exam')}</span>} placement="top">
                                                                                <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                            </Tooltip></h3>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <ul>

                                                                                    <li onClick={() => setFormValue('difficulty', "Easy")} className={formData.difficulty === 'Easy' ? 'active' : ''}><p>{translateThis('Easy')}</p></li>

                                                                                    <li onClick={() => setFormValue('difficulty', "Intermediate")} className={formData.difficulty === 'Intermediate' ? 'active' : ''}><p>{translateThis('Intermediate')}</p></li>

                                                                                    <li onClick={() => setFormValue('difficulty', "Hard")} className={formData.difficulty === 'Hard' ? 'active' : ''}><p>{translateThis('Hard')}</p></li>

                                                                                </ul>
                                                                                {(isSubmitBtnClicked && formError?.difficulty) ? <p className='errText'>{translateThis(formError?.difficulty)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={6} lg={2}>
                                                                        <div className='each-topic-sec'>
                                                                            <h3><span className='icon'><img src={AppIcons.SelfAssess} alt='icon' /></span>{translateThis('Self assessment')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px', whiteSpace: 'pre-line' }}><b>{translateThis('Self assessment')}:</b> {translateThis('is this exam a “Self assessment”?')}</span>} placement="top">
                                                                                <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                            </Tooltip></h3>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <ul>
                                                                                    <li onClick={() => setFormValue('isSelfAssessment', "Yes")} className={formData.isSelfAssessment === 'Yes' ? 'active' : ''}><p>{translateThis('Yes')}</p></li>
                                                                                    <li onClick={() => setFormValue('isSelfAssessment', "No")} className={formData.isSelfAssessment === 'No' ? 'active' : ''}><p>{translateThis('No')}</p></li>
                                                                                </ul>
                                                                                {(isSubmitBtnClicked && formError?.isSelfAssessment) ? <p className='errText'>{translateThis(formError?.isSelfAssessment)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    {/* <Grid item xs={12} sm={12} md={6} lg={2}>
                                                                        <div className='each-topic-sec'>
                                                                            <h3><span className='icon'><img src={AppIcons.Difficulty} alt='icon' /></span>{translateThis('Access type')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px', whiteSpace: 'pre-line' }}><b>{translateThis('Self assessment')}:</b> {translateThis('Is subscription needed?')}</span>} placement="top">
                                                                                <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                                            </Tooltip></h3>
                                                                            <div className='topic-items-list no-bg'>
                                                                                <ul>
                                                                                    <li onClick={() => setFormValue('accessType', 'subscription')} className={formData.accessType === 'subscription' ? 'active' : ''}><p>{translateThis('Subscription required')}</p></li>
                                                                                    <li onClick={() => setFormValue('accessType', 'public')} className={formData.accessType === 'public' ? 'active' : ''}><p>{translateThis('Anyone ')}</p></li>
                                                                                </ul>
                                                                                {(isSubmitBtnClicked && formError?.accessType) ? <p className='errText'>{translateThis(formError?.accessType)}</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </Grid> */}

                                                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                        <div className='each-topic-sec'>
                                                                            <div className='search-wrp f-wrp'>
                                                                                <div className='mob-table-search'>
                                                                                    <input className='mob-table-input' placeholder={translateThis('Search')} />
                                                                                </div>
                                                                                <div className='add-qn-sec'>
                                                                                    <button type='button' className='full-li-btn' onClick={() => navigate(`/${getCurrentFacilityURL()}/create-question`, { replace: true })} >+ {translateThis('Create new question')}</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Grid> */}
                                                                </Grid>
                                                                <div className='each-topic-sec'>
                                                                    {showRandom && <div className='accordian-sec-wrp f-wrp' style={{ paddingTop: '24px' }}>
                                                                        <div className='eachQn-box accordian-box no-bg f-wrp'>
                                                                            <Accordion className='each-accordian-sec'>
                                                                                <AccordionSummary
                                                                                    expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                    aria-controls="subjectAcc-content"
                                                                                    id="subjectAcc-header"
                                                                                >
                                                                                    <h3><span className='icon'><img src={AppIcons.sub} alt='icon' /></span>{translateThis('Filter by Subject')} </h3>
                                                                                    <p>{translateThis('Filter by Subject Text')}</p>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <div className='each-topic-sec'>
                                                                                        <div className='flex-box assignSubBox'>
                                                                                            {(subjects && subjects.length > 0) ? subjects.map((sub) => (
                                                                                                <button type='button' onClick={() => onSelectSubject(sub)} key={sub?.id} className={`image-blk ${selectedSubjects.find((s) => s.id === sub.id)?.id ? 'active' : ''}`}>
                                                                                                    <img src={sub?.logo?.tempURL} alt='images' />
                                                                                                    <h3>{sub?.subjectName}</h3>
                                                                                                    <p>{sub?.topics.length}&nbsp; {translateThis('topics')}</p>
                                                                                                </button>
                                                                                            )) : null}
                                                                                        </div>

                                                                                    </div>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </div>

                                                                        <div className='eachQn-box accordian-box no-bg f-wrp'>
                                                                            <Accordion className='each-accordian-sec'>
                                                                                <AccordionSummary
                                                                                    expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                    aria-controls="systemAcc-content"
                                                                                    id="systemAcc-header"
                                                                                >
                                                                                    <h3><span className='icon'><img src={AppIcons.sys} alt='icon' /></span> {translateThis('Filter by System')}</h3>
                                                                                    <p>{translateThis('Filter by System Text')}</p>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>

                                                                                    <div className='each-topic-sec'>
                                                                                        <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                                                            <ul>
                                                                                                {(systems && systems.length > 0) ? systems.map((system) => (
                                                                                                    // eslint-disable-next-line
                                                                                                    <li onClick={() => {
                                                                                                        onSelectSystem(system)
                                                                                                    }} key={system.id} className={selectedSystems.find((s) => s.id === system.id)?.id ? 'active' : ''}><p>{system.systemName}</p></li>
                                                                                                )) : 'No systems found'}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>

                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </div>

                                                                        <div className='eachQn-box accordian-box no-bg f-wrp'>
                                                                            <Accordion className='each-accordian-sec'>
                                                                                <AccordionSummary
                                                                                    expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                    aria-controls="topicAcc-content"
                                                                                    id="topicAcc-header"
                                                                                >
                                                                                    <h3><span className='icon'><img src={AppIcons.topic} alt='icon' /></span>{translateThis('Filter by Topic')}</h3>
                                                                                    <p>{translateThis('Filter by Topic Text')}</p>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <div className='each-topic-sec'>
                                                                                        <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                                                            <ul>
                                                                                                {(topics && topics.length > 0) ? <>
                                                                                                    {topics.map((topic) => (
                                                                                                        // eslint-disable-next-line
                                                                                                        <li onClick={() => onSelectTopic(topic)} key={topic.id} className={selectedTopics.find((s) => s.id === topic.id)?.id ? 'active' : ''}><p>{topic.topicName}</p></li>
                                                                                                    ))}
                                                                                                </> : 'No topics found'}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </div>
                                                                    </div>}
                                                                    {(formData?.questionPool === 'Fixed' || formData?.questionPoolMode === 'Pre selected pool') && <div className='appTable-wrp f-wrp' style={{ paddingTop: '24px' }}>
                                                                        {allQuestions.length > 0 ? <QuestionAndAnswerTable questions={allQuestions} selectedIds={formData?.questionIds} onSelectQuestions={(ids) => setFormValue('questionIds', ids)} /> : null}
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                            <div className='button-sec createExamButtonSet f-wrp'>
                                                                <div className='lft-btn-sec'>
                                                                    <div className='loadingBtn2'>
                                                                        <Button style={{ padding: '0' }}
                                                                            onClick={() => navigate(`/${getCurrentFacilityURL()}/exam-logs`, { replace: true })}
                                                                            className='cancel-btn red-btn'><span style={{ lineHeight: '1' }}>{SVGIcons.DeleteBlueIcon()}</span>{translateThis("Discard exam")}</Button>

                                                                        <Button style={{ padding: '0' }}
                                                                            onClick={() => navigate(`/${getCurrentFacilityURL()}/exam-logs`, { replace: true })}
                                                                            className='cancel-btn red-btn'>{translateThis("Cancel")}</Button>
                                                                    </div>
                                                                </div>
                                                                <div className='ryt-btn-sec'>
                                                                    {/* <Button
                                                                        // loading={isSubmittingAs}
                                                                        // disabled={!isValidForm() || isSubmittingAs}
                                                                        type="submit"
                                                                        className='pln-btn'
                                                                        onClick={() => navigate(`/${getCurrentFacilityURL()}/exam-preview`, { replace: true })}
                                                                    >

                                                                        {translateThis("Preview")}
                                                                    </Button> */}
                                                                    <div className='loadingBtn2'>
                                                                        <LoadingButton
                                                                            loading={isSubmittingAs}
                                                                            // disabled={!isValidForm() || isSubmittingAs}
                                                                            type="submit"
                                                                            className='fill-btn'
                                                                            onClick={() => setDraftMode().then(() => handleSubmit())}>
                                                                            {translateThis("Save draft")}
                                                                        </LoadingButton>

                                                                        <LoadingButton
                                                                            loading={isSubmittingAs}
                                                                            // disabled={!isValidForm() || isSubmittingAs}
                                                                            type="submit"
                                                                            className='fill-btn'
                                                                            onClick={handleSubmit}>
                                                                            {translateThis("Save & Publish")}
                                                                        </LoadingButton>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )}
                                        </Formik>
                                    </Stack>
                                    <br />
                                    {isOpenSubscribeDialog && <ConfirmDialog
                                        isOpen={isOpenSubscribeDialog}
                                        onClose={() => {
                                            setOpenSubscribeDialog(false);
                                        }}
                                        // title=""
                                        className={'delete-popup subscription-popup'}
                                        description={"SubscriptionPopup"}
                                        okayButtonText={'Subscribe'}
                                        onConfirm={onSubscribeConfirm}
                                    />}

                                    {isOpenNoQuestionsDialog && <ConfirmDialog
                                        isOpen={isOpenNoQuestionsDialog}
                                        onClose={() => {
                                            setIsOpenNoQuestionsDialog(false);
                                        }}
                                        // title=""
                                        className={'delete-popup subscription-popup'}
                                        description={"NoQuestionsToShowPopup"}
                                        okayButtonText={`${translateThis("Refine filters")}`}
                                        onConfirm={() => {
                                            setIsOpenNoQuestionsDialog(false);
                                        }}
                                    />}


                                    {openViewStudentSelect && <ModalPopUp
                                        isOpen={openViewStudentSelect}
                                        onClose={() => {
                                            setOpenViewStudentSelect(false);
                                        }}
                                        className={'modal-popup preview-popup provider-portal select-student-popup'}
                                        children={<div>
                                            {allStudents.length > 0 ? <SelectStudentPopup
                                                students={allStudents}
                                                selectedIds={formData?.studentIds}
                                                onSelectStudents={(ids) => setFormValue('studentIds', ids)}
                                                customEmailIds={formData?.customEmailIds}
                                                onEnterCustomEmailIds={(ids) => setFormValue('customEmailIds', ids)}
                                            /> : <AppLoader />}
                                        </div>}

                                    />}




                                </div>}
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>

    );
}

CreateNewExamForm.propTypes = {}

export default CreateNewExamForm;