import * as React from 'react';
import { Box } from '@mui/material';
import TabLayout from '../../../components/tabs';

import './index.scss';

import { translateThis } from '../../../helpers/language.helper';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';
import SVGIcons from '../../../assets/images/icons/svgIcons';
// import QuestionAndAnswerTable from './questionAndAnswer/questionAndAnswerTable';
// import SubjectTab from './subjectTab/subjectTab';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import ActiveExamTab from './examTabs';
import SelfAssessmentTab from './selfAssessment';
import AppIcons from '../../../assets/images/icons';


const UniversityExamPage = () => {
    // const [showPopup, setShowPopup] = React.useState(false);
    // const tabNames = [{name:"Questions & answers", icon:<SVGIcons.ChatIcon/>}, {name:"Subjects", icon:<SVGIcons.SubBlockIcon/>} ];

    const tabNames = [{ name: "Active"}];
    const tabContents = [
        <div key={'to_Exams'}>
            <ActiveExamTab key={'exams'} requestType='exams' />
            
        </div>,
        <div key={'to_inactive'}>
            {/* <SelfAssessmentTab /> */}
            {/* <SelfAssessmentTab key={'inactive'} requestType='inactive' /> */}
        </div>,
    ];

    return (
        <>
            <AppLayout
                // pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            <div className='main-category-page'>
                                <div className='main-category-header'>
                                    <h2>{translateThis("Exams")}</h2>
                                    <HeaderRightUserInfo />
                                </div>
                                <div className='main-category-content univExam-wrp'>
                                    <TabLayout
                                        tabContents={tabContents}
                                        tabNames={tabNames}
                                    />
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </AppLayout>


        </>
    )
}

export default UniversityExamPage;