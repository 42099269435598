import React, { useState, useEffect } from 'react';
// Components
import { Link, useParams } from 'react-router-dom';
import { Button, Divider, Grid } from '@mui/material';

import { translateThis } from '../../../../helpers/language.helper';
import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';

import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

import AppIcons from '../../../../assets/images/icons';
// import PreviousTestQuestionList from './previousTestQuestionList';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import './index.scss';
import { AppDoughnutChart } from '../../../../components/charts/donut.chart';
import ModalPopUp from '../../../../components/modal/modal.popup';
import QuestionAndAnswerPopupResult from './questionAndAnswerPopupResult';


const ResultPage = () => {
    const apiCaller = new ApiCaller('analytics/student');
    const { id } = useParams();

    const [stats, setStats] = useState({});
    useEffect(() => {
        getStats();
        // eslint-disable-next-line
    }, []);

    const getStats = () => {
        apiCaller.getData('').then((data) => {
            setStats(data);

        }).finally(() => setIsLoading(false))

    }


    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getTestDetails();
        // eslint-disable-next-line
    }, [id]);

    const getTestDetails = () => {
        if (id) {
            setIsLoading(true);
            const apiCallerAs = new ApiCaller('tests');
            apiCallerAs.getData(id).then((data) => {
                setExamResult(data?.testDetails);
                if (data?.questions && data?.questions.length > 0) {
                    applyDataArr(data?.questions.map((q) => ({ ...q, id: q._id })));
                }
            }).finally(() => setIsLoading(false))
        }
    }


    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [examResult, setExamResult] = React.useState({});
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedQn, setSelectedQn] = React.useState(null);

    const onViewClose = () => {
        setViewOpen(false);
        setSelectedQn(null);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'questionNumber',
            headerName: 'ID',
            minWidth: 50,
            maxWidth: 50,
            flex: 0.4,
            renderCell: ({ row }) => (row.questionId?.questionNumber) ? <span>{row.questionId?.questionNumber}</span> : '-'
        },
        {
            field: 'questionId',
            headerName: 'Subject',
            flex: 0.7,
            minWidth: 165,
            renderCell: ({ row }) => (row.questionId && row.questionId?.subjectId && row.questionId.subjectId.length > 0) ? row.questionId.subjectId.map((sub) => <span className='chip-sub' key={sub?._id}> {`${sub?.subjectName}`}</span>) : '-'
        },

        // {
        //     field: 'system',
        //     headerName: 'System',
        //     flex: 0.7,
        //     minWidth: 165,
        //     renderCell: ({ row }) => (row.questionId && row.questionId?.systemId && row.questionId.systemId.length > 0) ? row.questionId.systemId.map((sub) => <span className='chip-sub' key={sub?._id}> {`${sub?.systemName}`}</span>) : '-'
        // },
        {
            field: 'questions',
            headerName: 'Question',
            flex: 2,
            minWidth: 200,
            renderCell: ({ row }) => (row.questionId) ? <RichTextViewer richText={row.questionId?.question} /> : '-'
        },
        // {
        //     field: 'topics',
        //     headerName: 'Topics',
        //     minWidth: 160,
        //     flex: 2,
        // },
        {
            field: 'result',
            headerName: 'Result',
            minWidth: 70,
            maxWidth: 70,
            flex: 0.5,
            renderCell: ({ row }) => <>
                {row.actualAnswerId === row.answerId ? <span><SVGIcons.StatusActiveIcon /></span> :
                    (!row.answerId) ? <span><SVGIcons.StatusOmittedIcon /></span> : <span><SVGIcons.StatusBlockedIcon /></span>}
            </>
        },
        // {
        //     field: 'system',
        //     headerName: 'System',
        //     flex: 0.7,
        //     minWidth: 165,
        //     valueFormatter: ({ value }) => {
        //         return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
        //     }
        // },

        // {
        //     field: 'topics',
        //     headerName: 'Topics',
        //     minWidth: 160,
        //     flex: 2,
        // },

        {
            field: 'others',
            headerName: 'Others',
            minWidth: 70,
            maxWidth: 70,
            flex: 0.5,
            renderCell: ({row}) => <>
                <span style={{ display: 'flex', gap: '2px' }}><SVGIcons.StatusActiveIcon /> {row.questionId?.analytics?.university?.correctPercentage ?? '-'}%</span>
            </>
        },
        {
            field: 'timeTakenInSeconds',
            headerName: 'Time',
            minWidth: 75,
            maxWidth: 75,
            flex: 0.5,
            renderCell: ({ row }) => <span>{row?.timeTakenInSeconds || '0'} sec</span>
        },
        {
            field: 'action ',
            headerName: '',
            flex: 0.3,
            minWidth: 30,
            renderCell: ({ row }) => <Link onClick={() => {
                setSelectedQn(row);
                onViewOpen()
            }}><SVGIcons.ChevronRightIcon style={{ width: '15px' }} /></Link>
        },
    ]

    return (
        <>
            <div className='main-category-content prev-test-view result-overview-wrp f-wrp'>
                {isLoading ? <span>Loading...</span> : <div className='block-table-wrp user-table f-wrp'>
                    <div className='back-btn-list result-overview-head'>
                        <div className='left-btn-sec '>
                            <p>{translateThis('Result overview')}</p>
                            <h3>{examResult?.name || 'Test number'}</h3>
                        </div>

                    </div>

                    <div className='prev-view-boxes f-wrp' style={{ paddingBottom: '10px' }}>
                        <div className='statistics-info-wrapper '>
                            <Grid className='basic-info-grid' container spacing={1}>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <div className='each-statistics-box'>
                                        <h3>{translateThis('Score')}</h3>
                                        <div className='basic-detail-box pie-chart'>
                                            {examResult && <span className='graph-img'>
                                                <h2 className='graph-label'>{translateThis('Correct')}</h2>
                                                <AppDoughnutChart data={{
                                                    labels: [`${translateThis('Correct')}`, `${translateThis('Incorrect')}`, `${translateThis('Omitted')}`],
                                                    datasets: [
                                                        {
                                                            label: '',
                                                            total: `${examResult?.totalQuestionsGiven}`,
                                                            totalScore: examResult?.totalScore,
                                                            fontStyle: 'bold 26px sans-serif',
                                                            fillStyle: 'rgba(0, 0, 0, 1)',
                                                            data: [examResult.correct, examResult.inCorrect, examResult.omitted],
                                                            backgroundColor: [
                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                            ],
                                                            borderColor: [
                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                            ],
                                                            borderWidth: 1,
                                                        },
                                                    ],
                                                }} />
                                            </span>}
                                            <div className='graph-content'>
                                                <span>{translateThis('From')} {examResult?.totalQuestionsGiven || 0} {translateThis('questions, you answered')}:</span>
                                                <ul>
                                                    <li>
                                                        <p><img src={AppIcons.correct} alt='graph' /> &nbsp; {examResult.correctPercentage}% {translateThis('Correct')}</p>
                                                        <span>{examResult.correct} {translateThis('questions')}</span>
                                                    </li>
                                                    <li>
                                                        <p><img src={AppIcons.incorrect} alt='graph' /> &nbsp; {examResult.inCorrectPercentage}% {translateThis('Incorrect')}</p>
                                                        <span>{examResult.inCorrect} {translateThis('questions')}</span>
                                                    </li>
                                                    <li>
                                                        <p><img src={AppIcons.omitted} alt='graph' /> &nbsp;{examResult.omittedPercentage}% {translateThis('Omitted')}</p>
                                                        <span>{examResult.omitted} {translateThis('questions')}</span>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <div className='each-statistics-box'>
                                        <h3>{translateThis('Performance Percentile Rank')}</h3>
                                        <div className='basic-detail-box line-chart'>
                                            {/* <span className='graph-img'><img src={AppIcons.line48} alt='graph' /></span> */}
                                            <div className='graph-img line-graph'>
                                                <div className='PercentileIcon-wrp'>
                                                    <span className='graph-wrp'>
                                                        <img src={AppIcons.lineGraph} alt='graph' />
                                                    </span>
                                                    <div className={`graph-mark val${(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}` : 0}`} style={{ width: `${(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}` : 0}%` }}>
                                                        <div className='value-box'>
                                                            <p>{(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}` : 0}{translateThis('th')} <b>{translateThis('percentile')}</b></p>
                                                        </div>
                                                        <p>{translateThis('You')}</p>
                                                    </div>

                                                    <div className={`graph-mark markFixed val50`} style={{ width: `50%` }}>
                                                    <p>{translateThis('50th')}<b>{translateThis('percentile')}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='graph-content'>
                                            <span><b>{translateThis('Performance rank')}:</b></span>
                                                <ul>
                                                    <li>
                                                    <span>{translateThis('You rank on the')}</span>
                                                        <p><img src={AppIcons.green} alt='graph' /> &nbsp;<span>{(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}${translateThis('th percentile')}` : 0}</span></p>
                                                    </li>
                                                    {/* <li>
                                                        <span>Median score</span>
                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp;<span>{(stats?.university?.performancePercentile?.median && stats?.university?.performancePercentile?.median !== 'NaN') ? `${stats?.university?.performancePercentile?.median}% correct` : 0}</span></p>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <div className='each-statistics-box'>
                                        <h3>{translateThis('Settings')}</h3>
                                        <div className='basic-detail-box settings-list-box'>
                                            <div className='each-settings-list'>
                                                <ul>
                                                    <li><p>{translateThis('Test mode')}</p></li>
                                                    <li><Button className={examResult?.testMode === 'real' ? 'selected' : ''}>{translateThis('Real Mode')}</Button></li>
                                                    <li><Button className={examResult?.testMode === 'practice' ? 'selected' : ''}>{translateThis('Practice Mode')}</Button></li>
                                                </ul>
                                            </div>
                                            <div className='each-settings-list'>
                                                <ul>
                                                    <li><p>{translateThis('Timer')}</p></li>
                                                    <li><Button className={examResult?.timer === 'no limit' ? 'selected' : ''}>{translateThis('No time limit')}</Button></li>
                                                    <li><Button className={examResult?.timer === 'limit' ? 'selected' : ''}>{translateThis('Time limit')}</Button></li>
                                                </ul>
                                            </div>
                                            <div className='each-settings-list'>
                                                <ul>
                                                    <li><p>{translateThis('Number of questions')} : &nbsp; <span className='numberBox'>{examResult?.totalQuestionsGiven}</span> </p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <Divider />

                    <div className='panel-table-sec provider-table PC-Table f-wrp' style={{ paddingTop: '10px' }}>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                        {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                            <div className='mob-table-wrp childView f-wrp'>
                                <div className='mob-table-search' style={{ marginTop: '10px' }}>
                                    <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                                </div>
                                {filteredDataArr.length > 0 ? <ul>
                                    {filteredDataArr.map((element) => (
                                        <li key={element.id}>
                                            <div className='each-sec-box' style={{ width: '50px' }}>
                                                <h4>{element.questionId.questionNumber ? element.questionId.questionNumber : '-'}</h4>
                                            </div>
                                            <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                                {(element.questionId.question) ? <h4 className='question'>
                                                    <RichTextViewer richText={element.questionId.question} />
                                                </h4> : '-'}

                                                {(element.questionId.subjectId && element.questionId.subjectId.length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                                    <p className='subjectList'><span>{translateThis('Subjects')} </span>
                                                        {element.questionId.subjectId.map((sub) => <i key={sub?._id}> {`${sub?.subjectName},`}</i>)}</p>
                                                </div> : <span style={{ display: 'block' }}>{translateThis('Subjects')} - </span>}
                                                <div className='flex-wrp auto-flex' style={{ justifyContent: 'space-between' }}>
                                                    <span>{element.actualAnswerId === element.answerId ? <span><SVGIcons.StatusActiveIcon /></span> :
                                                        (!element.answerId) ? <span><SVGIcons.StatusOmittedIcon /></span> : <span><SVGIcons.StatusBlockedIcon /></span>}</span>
                                                    <span>{element?.timeTakenInSeconds || '0'} {translateThis('sec')}</span>
                                                    <span style={{ display: 'flex', gap: '2px' }}>{translateThis('Others')}: 55%</span>
                                                </div>
                                            </div>
                                            <Link className='full-li-btn' onClick={() => { setSelectedQn(element); onViewOpen() }} />
                                        </li >
                                    ))}
                                </ul> : <div className='empty-placeholder f-wrp'>
                                    <div className='placeholder-con'>
                                        <img src={AppIcons.placeholderTable} alt='empty' />
                                        <h4>{translateThis('File not found')}</h4>
                                    </div>
                                </div>
                                }
                            </div>
                        }

                        {(viewOpen && selectedQn) ? <ModalPopUp
                            className='preview-popup questionAndAnswer-Popup'
                            isOpen={viewOpen}
                            onClose={onViewClose}
                            aria-labelledby="table-view"
                            aria-describedby="table-view"
                        >
                            <QuestionAndAnswerPopupResult question={selectedQn} />
                        </ModalPopUp> : null}
                    </div>
                    <div className='back-btn-list result-overview-footer f-wrp'>
                        <div className='left-btn-sec '>
                            {(examResult.totalScore <= 39) && <h1 className='scoring'>“{translateThis("keep_working_hard")} {examResult.totalScore}%”</h1>}
                            {(examResult.totalScore > 39 && examResult.totalScore <= 74) && <h1 className='scoring'>“{translateThis("nice_effort_keep_it_up")} {examResult.totalScore}%”</h1>}
                            {(examResult.totalScore > 74) && <h1 className='scoring'>“{translateThis("excellent_work")} {examResult.totalScore}%”</h1>}
                        </div>
                        <div className='right-btn-sec'>
                            <Link className='box-button' style={{ backgroundColor: 'transparent' }} to={`/${getCurrentFacilityURL()}/my-portal`}>{translateThis('Home')}</Link>
                            <Link className='box-button' to={`/${getCurrentFacilityURL()}/test-review/${id}`}>{translateThis('Test review')}</Link>
                        </div>
                    </div>
                </div>}
            </div>

        </>
    )
}
ResultPage.propTypes = {}

export default ResultPage;
