import React, { useState, useEffect } from 'react';

// Components
import { Link } from 'react-router-dom';
// import { IconButton, MenuItem, Popover } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import AppTable from '../../../../../components/table/index';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';

import ConfirmDialog from '../../../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import ModalPopUp from '../../../../../components/modal/modal.popup';
// import QuestionAndAnswerPopup from '../questionAndAnswerPopup';
import { translateThis } from '../../../../../helpers/language.helper';
import AppIcons from '../../../../../assets/images/icons';
import QuestionAndAnswerPopup from '../../QnAnswerTable/questionAndAnswerPopup';
import '../../index.scss';
import RichTextViewer from '../../../../../components/rich-text-editor/TextViewer';




const QnPoolListPopup = ({selectedIds = [], onSelectQuestions=()=>null}) => {
    const apiCaller = new ApiCaller('questionsz');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedQn, setSelectedQn] = React.useState(null);
    // const [open, setOpen] = useState(null);

    // const handleOpenMenu = (event) => {
    //     setOpen(event.currentTarget);
    // };
    // const handleCloseMenu = () => {
    //     setOpen(null);
    // };
    // const anchorId = open ? 'simple-popover' : undefined;


    const onViewClose = () => {
        setViewOpen(false);
        setSelectedQn(null);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get data from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * Resource delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Subject deleted successfully`);
                    } else {
                        showSnackbar().failure(`Failed to delete subject`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'flags',
            headerName: <SVGIcons.FlagIcon style={{ width: '16px', height: '16px', position: 'relative', top: '2px' }} />,
            minWidth: 40,
            flex: 0.2,
            renderCell: ({ row }) => (row.flags && row.flags.length > 0) ? <SVGIcons.FlagIcon style={{ width: '16px', height: '16px' }} /> : ''
        },
        {
            field: 'questionNumber',
            headerName: 'ID',
            minWidth: 50,
            flex: 0.5,
        },
        {
            field: 'question',
            headerName: 'Question',
            minWidth: 190,
            flex: 2,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /></>
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            minWidth: 200,
            renderCell: ({ row }) => (row.populatedSubjects && row.populatedSubjects.length > 0) ? <div className='flex area'>
                <span className='chip-sys' style={{ maxWidth: '150px' }}  > {`${row.populatedSubjects[0].subjectName}`}</span>
                {(row.populatedSubjects.length > 1) ? <Tooltip title={<>{row.populatedSubjects.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.subjectName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
        },
        {
            field: 'populatedSystems',
            headerName: 'Systems',
            flex: 2,
            minWidth: 175,
            renderCell: ({ row }) => (row.populatedSystems && row.populatedSystems.length > 0) ? <div className='flex area'>
                <span className='chip-sys' style={{ maxWidth: '100px' }}  > {`${row.populatedSystems[0].systemName}`}</span>
                {(row.populatedSystems.length > 1) ? <Tooltip title={<>{row.populatedSystems.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.systemName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
        },
        {
            field: 'analytics',
            headerName: 'Attempts',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => (row.analytics && row.analytics?.student && row.analytics?.student?.attempts) ? row.analytics?.student?.attempts : '0'
        },
        {
            field: 'Score',
            headerName: 'Score',
            flex: 0.5,
            minWidth: 100,
            renderCell: () => <>
                <span style={{ display: 'flex' }}>40</span>
            </>
        },
        {
            field: 'Correct',
            headerName: 'Correct',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}><SVGIcons.StatusActiveIcon style={{ width: '21px', height: '21px' }} /> &nbsp; {(row.analytics && row.analytics?.student) ? row.analytics?.student?.correctPercentage : '0'}%</span>
            </>
        },
        {
            field: 'Incorrect',
            headerName: 'Incorrect',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>
                <span className='incorrect' style={{ display: 'flex' }}><SVGIcons.CrossIcon style={{ width: '14px', height: '21px' }} /> &nbsp; {(row.analytics && row.analytics?.student) ? row.analytics?.student?.inCorrectPercentage : '0'}%</span>
            </>
        },
        {
            field: 'Omitted',
            headerName: 'Omitted',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}><SVGIcons.StatusOmittedIcon style={{ width: '21px', height: '21px' }} /> &nbsp; {(row.analytics && row.analytics?.student) ? row.analytics?.student?.omittedPercentage : '0'}%</span>
            </>
        },
        {
            field: 'Time ',
            headerName: 'Avg. Time',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>{(row.analytics && row.analytics?.student) ? row.analytics?.student?.averageTime : '0'} sec</>
        }
    ]
    return (
        <div className='block-table-wrp select-student-popup-wrp qnPool-popup f-wrp'>
            <div className='header'>
                <h3><img className='icons' src={AppIcons.testMode} alt='icon' /> {translateThis('Question pool list')}</h3>
            </div>
            <div className='eachQn-box'>
                <div className='each-topic-sec'>
                    <div className='topic-items-list'>
                        <ul>
                            <li className='active'>Fixed</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='panel-table-sec provider-table PC-Table f-wrp'>
                <AppTable
                checkboxSelection
                selectedIds={selectedIds}
                onSelectRows={(ids)=>onSelectQuestions(ids)}
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <h4>{element.questionNumber ? element.questionNumber : '-'}</h4>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                        {(element.question) ? <h4 className='question'>
                                                <RichTextViewer richText={element.question} />
                                            </h4> : '-'}

                                        {(element.populatedSubjects && element.populatedSubjects.length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                            <p className='subjectList'><span>{translateThis('Subjects')} </span>
                                                {element.populatedSubjects.map((sub) => <i key={sub?._id}> {`${sub?.subjectName},`}</i>)}</p>
                                        </div> : <span style={{ display: 'block' }}>{translateThis('Subjects')} - </span>}

                                        {(element.populatedSystems && element.populatedSystems.length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                            <p className='subjectList'><span>{translateThis('Systems')} </span>
                                                {element.populatedSystems.map((sub) => <i key={sub?._id}> {`${sub?.systemName},`}</i>)}</p>
                                        </div> : <span style={{ display: 'block' }}>{translateThis('Systems')} - </span>}
                                        <div className='flex-wrp auto-flex' style={{ justifyContent: 'space-around', paddingTop: '5px', paddingBottom: '5px' }}>
                                            <span style={{ display: 'flex', minWidth: '70px' }}><SVGIcons.StatusActiveIcon style={{ width: '18px', height: '18px' }} /> &nbsp; <b>{(element.analytics && element.analytics?.student) ? element.analytics?.student?.correctPercentage : '0'}%</b></span>
                                            <span className='incorrect' style={{ display: 'flex', minWidth: '70px' }}><SVGIcons.CrossIcon style={{ width: '14px', height: '18px' }} /> &nbsp; <b>{(element.analytics && element.analytics?.student) ? element.analytics?.student?.inCorrectPercentage : '0'}%</b></span>
                                            <span style={{ display: 'flex', minWidth: '70px' }}><SVGIcons.StatusOmittedIcon style={{ width: '18px', height: '18px' }} /> &nbsp;<b>{(element.analytics && element.analytics?.student) ? element.analytics?.student?.omittedPercentage : '0'}%</b></span>
                                        </div>
                                        <div className='flex-wrp auto-flex' >
                                            <span style={{ display: 'flex', flex: '1', minWidth: '70px' }}>{translateThis('Attempts')}: <b>{(element.analytics && element.analytics?.student && element.analytics?.student?.attempts) ? element.analytics?.student?.attempts : '0'}</b></span>
                                            <span style={{ display: 'flex', flex: '1', minWidth: '70px' }}>{translateThis('Avg Time')}: <b>{(element.analytics && element.analytics?.student) ? element.analytics?.student?.averageTime : '0'} sec</b></span>
                                            {(element.flags && element.flags.length > 0) ? <span style={{ display: 'flex', flex: '1', minWidth: '70px', justifyContent: 'flex-end' }}><SVGIcons.FlagIcon style={{ width: '16px', height: '16px', color: '#333' }} /> </span> : null}
                                        </div>
                                    </div>

                                    <Link className='full-li-btn' onClick={() => { setSelectedQn(element); onViewOpen() }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }

            </div>

            {openDeleteDialog ? <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete subject`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this subject?\nThis action is permanent and cannot be undone.`}
                okayButtonText={`Delete subject`}
                onConfirm={deleteData}
            /> : null}
            {(viewOpen && selectedQn) ? <ModalPopUp
                className='preview-popup questionAndAnswer-Popup'
                isOpen={viewOpen}
                onClose={onViewClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                <QuestionAndAnswerPopup question={selectedQn} />

            </ModalPopUp> : null}
        </div>
    )
}
QnPoolListPopup.propTypes = {}

export default QnPoolListPopup;
