import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';


const SummerParentProviderAgreementView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView f-wrp">
                <div className='prof-sec'>
                    {/* <div className='prof-img'>
                    <span><img src={AppIcons.registerBG} alt='profile' /></span>
                </div> */}
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div>
                <div className='gap' />
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="lft-section">
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.c4k} alt='profile' /></span>
                    </div>
                    <div className="ryt-section" style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>Summer Parent-Provider Agreement Form</b></h2>
                        <p>This form tells us about the child care arrangement.</p>
                    </div>
                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>SECTION 1: PARENT INFORMATION</b></h3>
                    <div className="flex-box">
                        <p>Parent Name: <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_1', formData)?.answer || '-'}</b></p>
                        <p style={{ textAlign: 'center' }}>C4K Case Number: <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_2', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Address: <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_3', formData)?.answer || '-'}</b></p>
                        <p>City: <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_4', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter("c4k_summerPPAgreement_sectionOne_form_QN_5", formData)?.answer || '-'}</b></p>
                        <p>Zipcode: <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_6', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Telephone Number (Primary): <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_7', formData)?.answer || '-'}</b></p>
                        <p>Telephone Number (Secondary): <b>{answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_8', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline'>
                        <p>Reason for submitting this form:  </p>
                        <ul>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionOne_form_Key_QN_9", formData)?.answer === 'Part of my Application or Redetermination' ? 'checked' : ''}>Part of my Application or Redetermination</li>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionOne_form_Key_QN_9", formData)?.answer === 'Reporting changes or a new provider' ? 'checked' : ''}>Reporting changes or a new provider</li>
                        </ul>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>SECTION 2: CHILD CARE PROVIDER INFORMATION</b></h3>

                    <div className="details-section">
                        <div className="lft-section">
                            <div className='check-box'>
                                <p>What type of child care provider are you?  </p>
                                <ul>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Unlicensed Individual (relative)' ? 'checked' : ''}>Unlicensed Individual (relative)</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Family Child Care Home' ? 'checked' : ''}>Licensed Family Child Care Home</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Child Care Center' ? 'checked' : ''}>Licensed Child Care Center</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Group Child Care Home' ? 'checked' : ''}>Licensed Group Child Care Home</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Youth Camp' ? 'checked' : ''}>Licensed Youth Camp</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Exempt Youth Camp' ? 'checked' : ''}>Exempt Youth Camp</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Exempt Center Based Program' ? 'checked' : ''}>Exempt Center Based Program</li>
                                </ul>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div className='check-box'>
                                <p>Are you accredited by any of the following? (check if yes)  </p>
                                <ul>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('National Assoc. for the Education of Young Children (NAEYC)') ? 'checked' : ''}>National Assoc. for the Education of Young Children (NAEYC)</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('Council on Accreditation (COA)') ? 'checked' : ''}>Council on Accreditation (COA)</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('New England Assoc. of Schools and Colleges (NEASC)') ? 'checked' : ''}>New England Assoc. of Schools and Colleges (NEASC)</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('National Assoc. for Family Child Care (NAFCC)') ? 'checked' : ''}>National Assoc. for Family Child Care (NAFCC)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>SECTION 2A: LICENSED CHILD CARE PROVIDERS/EXEMPT PROGRAMS</b></h3>

                    <div className="flex-box">
                        <p>Provider Name: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Center Name: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_2', formData)?.answer || '-'}</b></p>
                        <p>Licensed Home: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_3', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Address where child care is provided: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_5', formData)?.answer || '-'}</b></p>
                        <p>City: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_6', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_7', formData)?.answer || '-'}</b></p>
                        <p>Zipcode: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_8', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Telephone Number: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_9', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Date of Birth: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_10', formData)?.answer || '-'}</b></p>
                        <p>C4K Provider ID: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_11', formData)?.answer || '-'}</b></p>
                        <p>License Number: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_12', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <p style={{ whiteSpace: 'nowrap' }}>Family Home Providers Only:  </p>
                        <ul>
                            <li className='checked'><b>I understand I must complete the pre-service training requirement prior to becoming eligible for payment. For more information, visit www.ctcare4kids.com.</b></li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <p>Please list the address you would like notices to be mailed if different from the address where child care is provided:</p>
                    <div className='auto-flex'>
                        <p>Address: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_14', formData)?.answer || '-'}</b></p>
                        <p>City: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_15', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_16', formData)?.answer || '-'}</b></p>
                        <p>Zipcode: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_17', formData)?.answer || '-'}</b></p>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>SECTION 2B: UNLICENSED RELATIVE CHILD CARE PROVIDERS</b></h3>
                    <p><b>You must be related to the child by blood, marriage, or adoption. </b> This means the child is your grandchild, great grandchild, niece, nephew, or sibling. If you are not related, you must have a license from the Office of Early Childhood Division of Licensing to provide child care.</p>

                    <div className='gap' />

                    <div className="flex-box">
                        <p>Provider Name: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Home Address: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_4', formData)?.answer || '-'}</b></p>
                        <p>City: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_5', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_6', formData)?.answer || '-'}</b></p>
                        <p>Zipcode: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_7', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Telephone Number: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_8', formData)?.answer || '-'}</b></p>
                        <p>C4K Provider ID: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_9', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Date of Birth: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_2', formData)?.answer || '-'}</b></p>
                        <div className='check-box inline'>
                            <p>Gender:  </p>
                            <ul>
                                <li className='checked'>F (Female)</li>
                                <li>M (Male)</li>
                            </ul>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <ul>
                            <li className='checked'><b>I understand I must complete the pre-service training requirement prior to becoming eligible for payment. For more information, visit www.ctcare4kids.com.</b></li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <p>Are you self-employed or do you have another job?  </p>
                        <ul>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10", formData)?.answer === 'Yes' ? 'checked' : ''}>yes</li>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <div className='auto-flex'>
                        <p>Name of your other job: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_11', formData)?.answer || '-'}</b></p>
                        <p>Address of your other job: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_12', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>Telephone of your other job: <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_13', formData)?.answer || '-'}</b></p>
                    </div>
                    <p>If yes, list your work schedule at your other job in the table below.</p>
                    <div className="gap" />
                    <h4 style={{ textAlign: 'center' }}>Providers: Use this table to list the hours and days you normally work your other job (circle AM or PM).</h4>
                    <div className='table-wrp'>
                        <table>
                            <thead>
                                <tr>
                                    <th>TIME</th>
                                    <th>SUNDAY</th>
                                    <th>MONDAY</th>
                                    <th>TUESDAY</th>
                                    <th>WEDNESDAY</th>
                                    <th>THURSDAY</th>
                                    <th>FRIDAY</th>
                                    <th>SATURDAY</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Start</th>
                                    {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={`from-${day}`}>{answerGetter(`weekly_schedule_three_${day}_from`, formData)?.answer || '-'}</td>)}
                                </tr>
                                <tr>
                                    <th>End</th>
                                    {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={`to-${day}`}>{answerGetter(`weekly_schedule_three_${day}_to`, formData)?.answer || '-'}</td>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className='gap' />
                    <div className='check-box inline'>
                        <p>Where do you provide child care for the children listed on this agreement form?  </p>
                        <ul>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_QN_14", formData)?.answer === "Child's home" ? 'checked' : ''}>Child's home</li>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_QN_14", formData)?.answer === "Provider's home" ? 'checked' : ''}>Provider's home</li>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_QN_14", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                    </div>
                    <p>Provider’s secret batcave</p>
                    <div className='auto-flex'>
                        <div className='check-box inline'>
                            <p>Is there a working telephone at this care location?  </p>
                            <ul>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        <p>Telephone Number: <b>{answerGetter('superVisingName', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <div className='check-box inline'>
                            <p>Is there a working smoke detector?  </p>
                            <ul>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>Do you have immediate access to a fire extinguisher?  </p>
                            <ul>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                    </div>
                    <p>What is the total number of children in your care at the same time on any day, <span style={{ textDecoration: 'underline' }}>including your own children?</span> <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1', formData)?.answer || '-'}</b></p>
                    <p>How many of these children are under the age of 2, including your own children? <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1', formData)?.answer || '-'}</b></p>

                    <div className='check-box inline'>
                        <p style={{ display: 'inline', whiteSpace: 'normal' }}>Are you under investigation by the Department of Children and Families (DCF) for child abuse or child neglect or do you have a record of child abuse or child neglect in Connecticut or any other state?
                            <ul style={{ display: 'inline-block', paddingLeft: '25px' }}>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </p>
                    </div>

                    <div className='check-box inline'>
                        <p>Were you ever arrested or do you have an arrest warrant or criminal charge pending against you?</p>
                        <ul>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <p>What crime(s) were you charged with? When and where? <b>{answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1', formData)?.answer || '-'}</b></p>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <p>Have you ever been convicted of any of the crimes listed below?  </p>
                        <ul>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <ul className='bullet-list'>
                        <li>Abandonment, injury or risk of injury to a minor.</li>
                        <li>Cruelty to persons or animals, stalking, obscenity, public indecency, reckless endangerment, arson, robbery, burglary, home invasion.</li>
                        <li>Use of force against another person, including murder, assault, manslaughter, kidnapping, unlawful restraint.</li>
                        <li>Crimes involving a weapon, explosives, or a firearm.</li>
                        <li>Sex crimes including sexual assault, rape, prostitution, child pornography, and other related sex crimes.</li>
                        <li>Sale, manufacture, or possession of narcotics or other illegal drugs or controlled substances.</li>
                    </ul>

                    <div className='gap' />
                    <div className='gap' />
                    <p style={{ textAlign: 'center' }}><b>For a complete crime list please visit www.ctcare4kids.com <br />
                        NOTE: All Unlicensed Relative Providers are subject to child abuse/neglect, sex offender, and criminal background checks. If the results of the background check confirms you are ineligible, you will be required to repay Care 4 Kids benefits issued to you.</b></p>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>SECTION 3: CHILDREN IN CARE</b></h3>
                    <p><b>Complete for each child needing Care 4 Kids assistance.</b> If there are more than 3 children in your care, make a copy of this page or download and print another copy of this page from the Care 4 Kids website at www.ctcare4kids.com.</p>

                    <div className='gap' />
                    <div className='each-child'>

                        <h4>CHILD #1</h4>
                        <div className='auto-flex'>
                            <p>Last Name: <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_3', formData)?.answer || '-'}</b></p>
                            <p>First Name: <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_1', formData)?.answer || '-'}</b></p>
                            <p>M.I.: <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_2', formData)?.answer || '-'}</b></p>
                            <p>Date of Birth: <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_4', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Date care started: <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_5', formData)?.answer || '-'}</b></p>
                            <p>How much is the parent charged per week? <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_6', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>Are you charging a mandatory registration fee for this child at this time?</p>
                                <ul>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_7", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_7", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                </ul>
                            </div>
                            <p>If yes, how much is the registration fee? <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_7_1', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>Are you related to this child?</p>
                                <ul>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_8", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_8", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                </ul>
                            </div>
                            <div className='check-box'>
                                <p>If related, specify your relationship to the child:</p>
                                <ul style={{ display: 'flex' }}>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === "Grandparent/Great Grandparent" ? 'checked' : ''}>Grandparent/Great Grandparent</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === 'Aunt/Uncle' ? 'checked' : ''}>Aunt/Uncle</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === 'Sibling' ? 'checked' : ''}>Sibling</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === 'Other' ? 'checked' : ''}>Other: <b>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_8_3', formData)?.answer || '-'}</b></li>
                                </ul>
                            </div>
                        </div>
                        <div className='gap' />
                        <h4 style={{ textAlign: 'center' }}>CHILD’S CARE SCHEDULE: Fill in the time the child is in your care (circle AM or PM)</h4>
                        <div className='table-wrp'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>TIME</th>
                                        <th>SUNDAY</th>
                                        <th>MONDAY</th>
                                        <th>TUESDAY</th>
                                        <th>WEDNESDAY</th>
                                        <th>THURSDAY</th>
                                        <th>FRIDAY</th>
                                        <th>SATURDAY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Schedule 1 Begin Time</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_one_${day}_from`, formData)?.answer || '-'}</td>)}
                                    </tr>
                                    <tr>
                                        <th>Schedule 1 End Time</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_one_${day}_to`, formData)?.answer || '-'}</td>)}
                                    </tr>

                                    <tr>
                                        <th>Schedule 2 Begin Time</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_two_${day}_from`, formData)?.answer || '-'}</td>)}
                                    </tr>
                                    <tr>
                                        <th>Schedule 2 End Time</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_two_${day}_to`, formData)?.answer || '-'}</td>)}
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div className="flex-box">
                            <div className='check-box inline'>
                                <p>Is this child care schedule the same each week?</p>
                                <ul>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_9", formData)?.answer === "Yes" ? 'checked' : ''}>Yes</li>
                                    <li className={answerGetter("c4k_summerPPAgreement_sectionThree_form_Key_QN_9", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                </ul>
                            </div>
                            <p>If no, explain how the care schedule varies:</p>
                        </div>
                        <p>{answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_9_1', formData)?.answer}</p>

                    </div>

                    <div className='gap' />
                    <hr />

                </div>
                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box' style={{ display: 'block', float: 'left' }}>
                    <div className='gap' />
                    <div className='gap' />
                    <h3><b>SECTION 4: PROVIDER CERTIFICATION</b></h3>
                    <p><b>I certify that:</b></p>
                    <ul>
                        <li>1) I am the individual or program that is providing care to the children listed on this form. I am at least 20 years of age and capable of providing safe and competent child care services. I do not have a disability, impairment or health problem that would prevent me from caring for the children.</li>
                        <li>2) Care will be given at the location specified on the form. I am responsible for reporting changes in the hours of care, the amount I charge for services, if the child stops attending care, and changes in the location where care is given. I must also inform Care 4 Kids of any changes in my criminal or child abuse/neglect history. Changes must be reported within 10 days.</li>
                        <li>3) For each child in my care, I have the name of the child’s primary care physician and health insurance provider and proof that each child is up to date with his or her immunizations and health screening exams.</li>
                        <li>4) I understand and agree that the Office of Early Childhood and Care 4 Kids may verify information listed on this form independently without prior authorization, including criminal and child abuse/neglect background checks.</li>
                        <li>5) I understand that this agreement is between the parent and the provider. It is not a contract with Care 4 Kids or the State of Connecticut. Neither Care 4 Kids nor the State of Connecticut employ me. I am an independent contractor and will receive a 1099 tax form for monies received from Care 4 Kids.</li>
                        <li>6) Care 4 Kids may not cover my total charges. The parent is responsible for any costs that are not paid by Care 4 Kids.</li>
                        <li>7) I may be required to repay benefits that were paid to me in error. I may also be subject to criminal or civil charges if I knowingly omit, misrepresent or provide false information to Care 4 Kids or if I do not report changes in a timely manner that affect payments or my eligibility for this program. I may be liable for all penalties associated with crimes, including, but not limited to, larceny by defrauding a public community, conspiracy to commit larceny by defrauding a public community, vendor fraud, forgery, false statement and other relevant crimes pursuant to Title 53a of the Connecticut General Statutes.</li>
                        <li>8) I must submit a completed invoice to receive payment. Invoices are issued to me when payment is approved and monthly thereafter. I will have <b>120 days</b> to submit the completed invoice in order to be paid.</li>
                        <li>9) To be eligible for payments, (1) I will abide by State of Connecticut health and safety regulations as applied to me (either as a licensed or unlicensed provider), and (2) I will cooperate with the State of Connecticut and its designees in program audits and fraud prevention activities, including any site visits that may be conducted to my home, child care site or place of employment.</li>
                        <li>10) I understand I must complete the orientation and annual training requirements in order to be eligible for payment. For more information on specific provider requirements, visit www.ctcare4kids.com.</li>
                        <li>11) I have read and understand the information contained in this form and certify that all of the information I have provided is true and correct to the best of my knowledge.</li>
                        <li>12) I understand that if I am licensed, I must report any child fatalities and any injuries that result in a child being admitted to a hospital that occur while a child is in my care to The Office of Early Childhood, Licensing Division at 1-800-282-6063.</li>
                    </ul>
                    <div className="signature-blk">
                        <div className="details-section">
                            <div className="ryt-section">
                                <div style={{ textAlign: 'center', float: 'right' }}>
                                    <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                    <p>Provider signature</p>
                                    <p>Date: <b>{data.additionalInfo && data.additionalInfo?.providerSignature && data.additionalInfo?.providerSignedDate ? data.additionalInfo?.providerSignedDate : ''}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='each-section-box' style={{ display: 'block', float: 'left' }}>
                    <div className='gap' />
                    <div className='gap' />
                    <h3><b>SECTION 5: PARENT CERTIFICATION</b></h3>
                    <p><b>I certify that:</b></p>
                    <ul>
                        <li>1) I have selected the provider identified above to care for my children while I work or attend an approved activity.</li>
                        <li>2) I will report any changes in child care arrangements, household income that exceeds 85% of the State Median Income guidelines, loss of a job or ending of an approved activity, if the child receiving Care 4 Kids benefits is no longer in the home, or my residential address to Care 4 Kids within 10 days of a change.</li>
                        <li>3) I am responsible to pay the provider any costs not covered by Care 4 Kids.</li>
                        <li>4) I understand and agree that Care 4 Kids may contact the provider listed above and the provider may contact Care 4 Kids concerning my eligibility and payment amounts.</li>
                        <li>5) I may be required to repay benefits that were paid in error on my behalf. I may also be subject to criminal or civil charges if I knowingly omit, misrepresent or provide false information to Care 4 Kids or if I do not report changes in a timely manner that affect payments or my eligibility for this program. I may be liable for all penalties associated with crimes, including, but not limited to, larceny by defrauding a public community, conspiracy to commit larceny by defrauding a public community, vendor fraud, forgery, false statement and other relevant crimes pursuant to Title 53a of the Connecticut General Statutes.</li>
                    </ul>
                    <div className='gap' />
                    <div className="signature-blk">
                        <div className="details-section">
                            <div className="lft-section">
                                <div style={{ textAlign: 'center', float: 'right' }}>
                                    <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                    <p>Parent | Guardian signature</p>
                                    <p>Date: <b>{data.additionalInfo && data.additionalInfo?.parentSignedDate ? data.additionalInfo?.parentSignedDate : ''}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='gap' />
                </div>

            </div>
        </>
    )
}

SummerParentProviderAgreementView.propTypes = {
    data: PropTypes.any
}

export default SummerParentProviderAgreementView;