import React from 'react';
import { Box } from '@mui/material';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';
import TabLayout from '../../../components/tabs/index';
import UserList from './userTable';
import SubAdminsList from './subAdminsTable';
import { accessFiltered } from '../../../helpers/auth.helper';
import HeaderRightUserInfo from '../components/headerRightUserInfo';

export default function UserSectionPage() {

    const tabNames = [{
        name: <b>{translateThis('users')}</b>,
        accessId: 'users',
        icon: ''
    },
    {
        name: <b>{translateThis('Admin users')}</b>,
        accessId: 'adminUsers',
        icon: ''
    }];
    const tabContents = [
        <span key={'profile'}><UserList /></span>,
        <span key={'to_Subjects'}><SubAdminsList /></span>,
    ];

    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>

                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Users")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content'>
                                <div className='user-main-wrp f-wrp'>
                                    <TabLayout
                                        tabContents={tabContents}
                                        tabNames={accessFiltered(tabNames)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}
