import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthDetailsByKey } from "../../../helpers/auth.helper";
import ApiCaller from '../../../services/api/general';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';


function StripeCheckoutForm({appliedPromoCode = ''}) {

  // collect data from the user
  const { id: priceId } = useParams();
  const email = getAuthDetailsByKey('email');
  const name = getAuthDetailsByKey('name');
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  // main function
  const createSubscription = async () => {
    try {
      setIsLoading(true);
      setError('');
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement) || '',
        billing_details: {
          name,
          email,
        },
      });

      if (paymentMethod?.paymentMethod?.id) {
        // call the backend to create subscription
        const apiCaller = new ApiCaller('payment/initiate-subscription')
        const response = await apiCaller.postData({
          paymentMethod: paymentMethod?.paymentMethod?.id,
          name,
          email,
          priceId,
          appliedPromoCode
        }).then(({ data }) => data);
        const confirmPayment = await stripe?.confirmCardPayment(
          response.clientSecret
        );

        if (confirmPayment?.error) {
          setIsLoading(false)
          alert(confirmPayment.error.message);
        } else {
          navigate(`/${getCurrentFacilityURL()}/payment-success`)
          // alert("Success! Check your email for the invoice.");
        }
      } else {
        setIsLoading(false);
        setError(paymentMethod?.error?.message)
      }


    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{
      width: '100%',
      maxWidth: '650px',
      display: "flex",
      justifyContent: 'space-evenly',
      flexDirection: 'column'
    }} className="grid gap-4 m-auto">
      <CardElement />
      {!isLoading ? <button type="button" onClick={createSubscription} disabled={!stripe}>
        Subscribe
      </button> : <span>Subscribing...</span>}
      {error && <span style={{color: 'red'}} >{error}</span>}
    </div>
  );
}
StripeCheckoutForm.propTypes = {
  appliedPromoCode: PropTypes.any
}
export default StripeCheckoutForm;