import React, { useState, useEffect } from 'react';

// Components
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import AppPopOverMenu from '../../../../components/appPopOverMenu';

import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import AppIcons from '../../../../assets/images/icons';




const SubjectBlockTable = () => {
    const apiCaller = new ApiCaller('subject');
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get data from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * Resource delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Subject deleted successfully`);
                    } else {
                        showSnackbar().failure(`Failed to delete subject`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'logo',
            headerName: '',
            flex: 0.5,
            minWidth: 55,
            maxWidth: 55,
            renderCell: ({ row }) => (row.logo.tempURL && <span className='tab-img'><img src={row.logo.tempURL} alt='subject' /></span> || '-')
        },
        {
            field: 'subjectName',
            headerName: 'Subjects',
            minWidth: 150,
            maxWidth: 200,
            flex: 1,
            renderCell: ({ row }) => (row.subjectName && <span className='chip-sub'>{row.subjectName}</span> || '-')
        },
        {
            field: 'systems',
            headerName: 'Systems',
            flex: 2,
            minWidth: 200,
            maxWidth: 200,
            // renderCell: ({ row }) => (row.systems && row.systems.length > 0) ? row.systems.map((sub) => <span className='chip-sys' key={sub?._id}> {`${sub?.systemName}`}</span>) : '-'
            renderCell: ({ row }) => (row.systems && row.systems.length > 0) ? <div className='flex area'>
                <span className='chip-sys' style={{ maxWidth: '150px' }}  > {`${row.systems[0].systemName}`}</span>
                {(row.systems.length > 1) ? <Tooltip title={<>{row.systems.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.systemName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
        },
        {
            field: 'topics',
            headerName: 'Topics',
            flex: 7,
            width: '100%',
            minWidth: 300,
            maxWidth: '100%',
            renderCell: ({ row }) => (row.topics && row.topics.length > 0) ? <div className='clipAlign-box'>{row.topics.map((sub) => <span className='chip-topic' key={sub?._id}> {`${sub?.topicName}`}</span>)}
                {(row.topics.length > 9) ? <Tooltip title={<>{row.topics.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.topicName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
            // renderCell: ({ row }) => (row.topics && row.topics.length > 0) ? <div className='flex area'>
            //     <span className='chip-topic' style={{ maxWidth: '150px' }}  > {`${row.topics[0].topicName}`}</span>
            //     {(row.topics.length > 1) ? <Tooltip title={<>{row.topics.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.topicName},`}</span>)}</>} placement="top">
            //         <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
            //     </Tooltip> : null}
            // </div> : '-'
        },
        {
            field: 'questionCount',
            headerName: 'Qty',
            flex: 0.5,
            minWidth: 90,
            maxWidth: 90,
            renderCell: ({ row }) => (row.questionCount !== 0 && <span style={{ minWidth: '20px', textAlign: 'center' }}>{row.questionCount}</span> || <span style={{ minWidth: '20px', textAlign: 'center' }}>-</span>)
        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 0.5,
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            sortable: false,
            renderCell: ({ row }) => <AppPopOverMenu
                preventClose
                options={[
                    {
                        label: `${translateThis('View')}`,
                        onClick: () => {
                            navigator(`/${getCurrentFacilityURL()}/view-subject/${row.id}`, { state: { data: row } });
                        }
                    },
                    {
                        label: `${translateThis('Edit')}`,
                        onClick: () => {
                            navigator(`/${getCurrentFacilityURL()}/edit-subject/${row.id}`, { state: { data: row } });
                        }
                    },
                    {
                        label: `${translateThis('Delete')}`,
                        onClick: () => {
                            setSelectedId(row.id)
                            setOpenDeleteDialog(true)
                        }
                    },

                ]} />
        },
    ]

    return (
        <div className='block-table-wrp f-wrp'>
            <div className='panel-table-sec provider-table PC-Table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id}>

                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                        <div className='flex-wrp' style={{ paddingBottom: '5px' }}>
                                            {(element.subjectName) ? <h4 className='question' style={{ paddingBottom: '0' }}>
                                                {element.subjectName}
                                            </h4> : '-'}
                                            {(element.questionCount) ? <span className='time-pill' style={{ minWidth: 'max-content' }}>{translateThis("Q's")}: {element.questionCount}</span> : null}
                                        </div>

                                        <div className='flex-wrp' >
                                            <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 0px)', overflow: 'hidden' }}>
                                                {(element.systems && element.systems.length > 0) ? <div className='flex-wrp' style={{ display: 'block', width: '100%' }}>
                                                    <p className='subjectList'><span>{translateThis('Systems')} </span>
                                                        {element.systems.map((sub) => <i key={sub?._id}> {`${sub?.systemName},`}</i>)}</p>
                                                </div> : <span style={{ display: 'block' }}>{translateThis('Systems')} - </span>}
                                                {(element.topics && element.topics.length > 0) ? <div className='flex-wrp' style={{ display: 'block', width: '100%' }}>
                                                    <p className='subjectList'><span>{translateThis('Topics')} </span>
                                                        {element.topics.map((sub) => <i key={sub?._id}> {`${sub?.topicName},`}</i>)}</p>
                                                </div> : <span style={{ display: 'block' }}>{translateThis('Topics')} - </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn' style={{ height: '25px' }}>
                                            <AppPopOverMenu
                                                icon={SVGIcons.MoreHorizontalFill()}
                                                preventClose
                                                options={[
                                                    {
                                                        label: 'Edit',
                                                        onClick: () => {
                                                            navigator(`/${getCurrentFacilityURL()}/edit-subject/${element.id}`, { state: { data: element } });
                                                        }
                                                    },
                                                    {
                                                        label: 'Delete',
                                                        onClick: () => {
                                                            setSelectedId(element.id)
                                                            setOpenDeleteDialog(true)
                                                        }
                                                    },

                                                ]} />
                                        </div>
                                    </div>
                                    <button type='button' className='full-li-btn' onClick={() => navigator(`/${getCurrentFacilityURL()}/view-subject/${element.id}`, { state: { data: element } })} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>

            {openDeleteDialog ? <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete subject`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this subject?\nThis action is permanent and cannot be undone.`}
                okayButtonText={`Delete subject`}
                onConfirm={deleteData}
            /> : null}
        </div>
    )
}
SubjectBlockTable.propTypes = {}

export default SubjectBlockTable;
