import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// @mui
// import { Stack, Divider, Button } from '@mui/material';
import { Stack, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { translateThis } from '../../../helpers/language.helper';
// components
import { login } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/snackbar/index';
import TextInput from '../../../components/inputs/textInput/textInput';
import { isLoggedIn } from '../../../helpers/auth.helper';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';


// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const currentFacilityURL = getCurrentFacilityURL();
    const location = useLocation();
    const isAuth = isLoggedIn();
    const { from } = location.state || { from: { pathname: `/${currentFacilityURL}/my-portal` } };

    if (isAuth) {
        navigate(from, { replace: true });
    }

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(false);
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState({
        eightCharacter: false,
        containNumber: false,
        containUpperCase: false,
        containSpecialChar: false
    });

    const onSubmit = () => {
        setIsLoading(true);
        login({ email, password, isRememberMe }).then((data) => {
            if (data.success) {
                navigate(from, { replace: true });
                showSnackbar().success(data.message)
            } else {
                showSnackbar().failure(data.message)

            }
        }).finally(() => setIsLoading(false))
            ;
    };

    /**
     * To validate password requirement
     * @param {String} password 
     */
    const validatePassword = (password) => {
        const rules = {
            eightCharacter: false,
            containNumber: false,
            containUpperCase: false,
            containSpecialChar: false
        }

        if (password.length > 7) { // To check if password contain 8 chars
            rules.eightCharacter = true;
        }
        if (/\d/.test(password)) { // To check if password contain numbers
            rules.containNumber = true;
        }
        if (password !== password.toLowerCase()) { // To check if password contain uppercase
            rules.containUpperCase = true;
        }
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (format.test(password)) { // To check if password contain special chars
            rules.containSpecialChar = true;
        }
        setValidPassword(rules)
        if (rules.containNumber &&
            rules.containSpecialChar &&
            rules.containUpperCase &&
            rules.eightCharacter
        ) {
            setPassword(password)
        } else {
            setPassword('')
        }
    }

    return (
        <>
            
            <Stack className='login-form standard-form' spacing={3}>
                <TextInput
                    key={`login-email`}
                    label={translateThis('loginForm_email')}
                    name='email'
                    type='email'
                    required
                    placeholder={translateThis('loginForm_emailPlaceHolder')}
                    className='custom-textfield'
                    getValue={(_, value) => setEmail(value)}

                />

                <TextInput
                    key={`login-password`}
                    label={translateThis('loginForm_password')}
                    name='password'
                    type='password'
                    isRequired={false}
                    placeholder={translateThis('loginForm_passwordPlaceHolder')}
                    className='custom-textfield'
                    // getValue={(_, value) => setPassword(value)}
                    getValue={(_, value) => validatePassword(value)}

                />
                <Stack className='password-requirement-points' variant='div' spacing={3} style={{ marginTop: '0' }}>
                    <ul>
                        <li className={validPassword.eightCharacter ? 'checked' : ''}>{translateThis('At least 8 characters long')}</li>
                        <li className={validPassword.containNumber ? 'checked' : ''}>{translateThis('1 number')}</li>
                        <li className={validPassword.containUpperCase ? 'checked' : ''}>{translateThis('1 uppercase character')}</li>
                        <li className={validPassword.containSpecialChar ? 'checked' : ''}>{translateThis('1 special symbol')}</li>
                    </ul>
                </Stack>
                <Stack className='additional-btn' variant='div' spacing={3} style={{ marginTop: '0' }}>
                <FormControlLabel control={<Checkbox color='primary' onChange={(e)=>setIsRememberMe(e.target.checked)} />} label="Remember me" />
                    <p style={{ textAlign: 'right' }}><Link to={`/${currentFacilityURL}/auth/forgot-password`} replace>{translateThis("forgotYourPassword")}</Link></p>
                </Stack>
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!email || !password}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    {translateThis("loginButton")}
                </LoadingButton>

            </Stack>

            <Stack className='additional-btn' variant='div' spacing={3}>                
                <p>{translateThis("dontHaveAnAccountYet")} <Link to={`/${currentFacilityURL}/auth/register`} >{translateThis("signUp")}</Link></p>
            </Stack>
        </>
    );
}
