import React from 'react';
import { Editor, EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { PropTypes } from 'prop-types';
import QHighlighter from './QuilHighlighter';
import { getUniqueId } from '../../helpers/helper.functions';
import TextHighlighter from './TextHighlighter';


const RichTextViewer = (props) => {
  const { richText = '', useHighlighter = false, highlightId = getUniqueId() } = props;
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const itemText = (isJsonString(richText)) ? EditorState.createWithContent(convertFromRaw(JSON.parse(richText))) : '';
  return (
    <div className="rich-text-viewer">
      {(itemText && !useHighlighter) ? <div className="readonly-editor"> <Editor editorState={itemText} readOnly /> </div>:
        (itemText && useHighlighter) ? <QHighlighter highlightId={highlightId} initialContent={itemText.getCurrentContent()} /> :
        (useHighlighter && highlightId) ? <TextHighlighter id={`normal-${highlightId}`}><p className="readonly-editor">{richText}</p></TextHighlighter> : 
          richText}
    </div>
  );
}
RichTextViewer.propTypes = {
  richText: PropTypes.string,
  highlightId: PropTypes.string,
  useHighlighter: PropTypes.bool
}
export default RichTextViewer;