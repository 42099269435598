import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const DisabilityVerificationView = (props) => {
    const { data = {} } = props;
    const { formData = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView disabilityVerificationView f-wrp">
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>Disability Verification</b></h2>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <div className='flex-box'>
                        <p>Parent name: <b>{answerGetter('disability_verification_form_question_parentName', formData)?.answer || '-'}</b></p>
                        <p>DOB: <b>{answerGetter('disability_verification_form_Key_DOB', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <p><b>Patient authorization to release information</b></p>
                    <p>I hereby authorize the release of the requested medical/psychiatric information to the State of Connecticut Office of Early Childhood Care 4 Kids program.</p>

                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'right', textAlign: 'center' }}>
                            <p>Parent | Guardian signature</p>
                            <h3>{answerGetter('disability_verification_form_question_signatureName', formData)?.answer || '-'}</h3>
                            <p>Date: <b>{answerGetter('disability_verification_form_Key_DateOFApplication', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

DisabilityVerificationView.propTypes = {
    data: PropTypes.any
}

export default DisabilityVerificationView;