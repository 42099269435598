import React from 'react';
// import './subscriptionPopup.scss';
import { translateThis } from '../../../../../helpers/language.helper';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';

const DiscardExamPopup = () => { 
    return (
        <>
            <div className='subscription-popup-wrp f-wrp'>
                <span style={{maxWidth: "250px"}}>{SVGIcons.Discard()}</span>
                <h2>{translateThis("Discard exam?")}</h2>
                <p>{translateThis("Are you sure you want to discard this exam? All progress will be lost.")}</p>
            </div>
        </>
    )
}

export default DiscardExamPopup;