import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid } from '@mui/material';
import { translateThis } from '../../../helpers/language.helper';
import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';

import AppLayout from '../../../layouts/app/AppLayout';
import { DATE_FORMAT } from '../../../constants/index';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import UserDrawer from '../../../components/drawer/drawer';
import './previousTestList.scss';
import AppIcons from '../../../assets/images/icons';
import ModalPopUp from '../../../components/modal/modal.popup';
import PreviousTestQuestionList from './previousTestQuestionList';



const PreviousTestView = () => {
    const apiCaller = new ApiCaller('questions');
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [viewOpen, setViewOpen] = React.useState(false);


    const onViewClose = () => {
        setViewOpen(false);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Question deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'populatedSubjects',
            headerName: 'Subject',
            flex: 1,
            minWidth: 225,
            valueFormatter: ({ value }) => {
                return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
            }
        },
        {
            field: 'system',
            headerName: 'System',
            flex: 1,
            minWidth: 325,
            valueFormatter: ({ value }) => {
                return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
            }
        },
        {
            field: 'topics',
            headerName: 'Topics',
            minWidth: 160,
            flex: 2,
        },
        {
            field: 'slNo',
            headerName: 'ID',
            minWidth: 50,
            flex: 0.5,
        },
        {
            field: 'result',
            headerName: 'Result',
            minWidth: 70,
            flex: 0.8,
            renderCell: ({ row }) => <>
                <span><SVGIcons.StatusActiveIcon /></span>
            </>
        },
        {
            field: 'other',
            headerName: 'Others',
            minWidth: 70,
            flex: 0.8,
            renderCell: ({ row }) => <>
                <span><SVGIcons.StatusActiveIcon /> 55%</span>
            </>
        },
        {
            field: 'time',
            headerName: 'Time',
            minWidth: 70,
            flex: 0.8,
            renderCell: ({ row }) => <>
                <span>138 sec</span>
            </>
        },
    ]


    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("prevTestViewHead")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content prev-test-view'>
                                <div className='block-table-wrp user-table f-wrp'>
                                    <div className='back-btn-list'>
                                        <div className='left-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/my-practices`}><SVGIcons.BackIcon /> {translateThis('Back')}</Link>
                                        </div>
                                        {/* <div className='right-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.PrintIcon /> {translateThis('Print')}</Link>
                                            <Link className='pln-button' onClick={onViewOpen} to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.QnListIcon /> {translateThis('Question list')}</Link>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.TestEyeIcon /> {translateThis('Test Revision')}</Link>
                                        </div> */}
                                    </div>

                                    <div className='prev-view-boxes f-wrp' style={{ paddingBottom: '20px' }}>
                                        <div className='statistics-info-wrapper '>
                                            <Grid className='basic-info-grid' container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12} lg={5}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Performance')}</h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            <span className='graph-img'><img src={AppIcons.graph1} alt='graph' /></span>
                                                            <div className='graph-content'>
                                                                <span>{translateThis('From')} 40 {translateThis('questions, you answered')}:</span>
                                                                <p>60% {translateThis('Correct')}</p>
                                                                <span>24 {translateThis('questions')}</span>

                                                                <p>10% {translateThis('Incorrect')}</p>
                                                                <span>4 {translateThis('questions')}</span>

                                                                <p>30% {translateThis('Omitted')}</p>
                                                                <span>12 {translateThis('questions')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={5}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Settings')}</h3>
                                                        <div className='basic-detail-box settings-list-box'>
                                                            <div className='each-settings-list'>
                                                                <ul>
                                                                    <li><p>{translateThis('Test mode')}</p></li>
                                                                    <li><Button className='selected'>{translateThis('Real Mode')}</Button></li>
                                                                    <li><Button>{translateThis('Practice Mode')}</Button></li>
                                                                </ul>
                                                            </div>
                                                            <div className='each-settings-list'>
                                                                <ul>
                                                                    <li><p>{translateThis('Timer')}</p></li>
                                                                    <li><Button className='selected'>{translateThis('Real Mode')}</Button></li>
                                                                </ul>
                                                            </div>
                                                            <div className='each-settings-list'>
                                                                <ul>
                                                                    <li><p>{translateThis('Question difficulty')}</p></li>
                                                                    <li><Button className='selected'>{translateThis('Real Mode')}</Button></li>
                                                                </ul>
                                                            </div>
                                                            <div className='each-settings-list'>
                                                                <ul>
                                                                    <li><p>{translateThis('Number of questions')}</p></li>
                                                                    <li><Button className='selected'>{translateThis('Real Mode')}</Button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                    <Divider />

                                    <div className='panel-table-sec  provider-table f-wrp' style={{ paddingTop: '20px' }}>
                                        <AppTable
                                            columns={tableColumns}
                                            isLoading={isLoading}
                                            data={dataArr}
                                        />
                                    </div>

                                    {viewOpen ? <ModalPopUp
                                        className='preview-popup previousTestList-popup'
                                        isOpen={viewOpen}
                                        onClose={onViewClose}
                                        aria-labelledby="table-view"
                                        aria-describedby="table-view"
                                    >
                                        <PreviousTestQuestionList />
                                        {/* <Button onClick={onViewClose} className='fill-btn'>Close</Button> */}
                                    </ModalPopUp> : null}




                                    {openDeleteDialog && <ConfirmDialog
                                        isOpen={openDeleteDialog}
                                        onClose={() => {
                                            setOpenDeleteDialog(false);
                                            setSelectedId('');
                                        }}
                                        title={`Delete question`}
                                        className={'delete-popup'}
                                        description={`Are you sure you want to delete this question?\nThis action is permanent and cannot be undone.`}
                                        okayButtonText={'Delete question'}
                                        onConfirm={deleteData}
                                    />}
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    )
}
PreviousTestView.propTypes = {}

export default PreviousTestView;
