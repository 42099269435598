/* eslint-disable arrow-body-style */
import * as React from 'react';
import { Box } from '@mui/material';

import { translateThis } from '../../../../../helpers/language.helper';

const CerebrospinalTab = () => {
    
    return (
        <>
            
            <Box className='SerumTab-wrapper'>
                    <div className='SerumTab-content f-wrp'>
                        <table>
                            <thead>
                                <th>{translateThis('Cerebrospinal')}</th>
                                <th>{translateThis('Reference range')}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>{translateThis('Cell count')}</p></td>
                                    <td><p>{translateThis('0-5/mm3')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Chloride')}</p></td>
                                    <td><p>{translateThis('118-132 mEq/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Gamma globulin')}</p></td>
                                    <td><p>{translateThis('3%-12% total proteins')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Glucose')}</p></td>
                                    <td><p>{translateThis('40-70 mg/dL ins')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Pressure')}</p></td>
                                    <td><p>{translateThis('70-180 mm H2O')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Proteins, total')}</p></td>
                                    <td><p>{translateThis('<40 mg/dL')}</p></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                
            </Box>

        </>
    )
}

export default CerebrospinalTab;