import React from 'react'
import scaleDisplayFontSize from '../utils/scaleDisplayFontSize'
import './Display.css'

const Display = ({ value = '0', trigUnit, mode }) => {
  // scales fontSize based on length of display value
  // eslint-disable-next-line prefer-const
  // let fontSize = scaleDisplayFontSize(value, mode)

  const maxCharsAtFullSize = 14;
  const scaleFactor = '16px';
  let scaleDown = null;
  scaleDown = (`${value}`.length) > maxCharsAtFullSize ? scaleFactor : '25px';
  return (
    <section className="Display">
      {mode === 'scientific' && <div style={{ display: 'none' }} className="Display-unit">{trigUnit}</div>}
      <div className="Display-value" style={{ fontSize: scaleDown }}>
        {value}
      </div>
    </section>
  )
}

export default Display
