import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { DATE_FORMAT } from '../../constants';



const NapTimeCommunicationFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp providerCommunicationView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />

                <h2 style={{ marginBottom: '0' }}>DAILY COMMUNICATION</h2>
                <h3>Nap time</h3>
                <div className='gap' />
                <div className="flex-box padding_btm15">
                    <p>Child name: <b>{child?.firstName}&nbsp;{child?.lastName}</b></p>
                </div>
                <div className="flex-box padding_btm15">
                    <p>Communication date: <b>{dayjs(data.logDate).format(DATE_FORMAT)}</b></p>
                    <p>Submission date: <b>{dayjs(data.submissionDate).subtract(5, 'hours').format(DATE_FORMAT)}</b></p>
                </div>
                <div className='gap' />

                <div className="basic-details padding_btm15">
                    <h3>Nap hours</h3>
                    <div className="gap" />
                    <div className='time-list-wrp panel-form f-wrp'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Time </th>
                                    <th>Asleep</th>
                                    <th>Staff member</th>
                                    <th>oz of milk</th>
                                </tr>
                            </thead>
                            <tbody>
                                {['12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55', '1:00', '1:05', '1:10', '1:15', '1:20', '1:25', '1:30', '1:35', '1:40', '1:45', '1:50', '1:55', '2:00', '2:05', '2:10', '2:15', '2:20', '2:25', '2:30', '2:35', '2:40', '2:45', '2:50', '2:55'].map((time) => (
                                    <tr key={time}>
                                        <td style={{ maxWidth: '120px' }}>
                                            <span>{time} PM</span>
                                        </td>

                                        <td style={{ width: '100px' }}>
                                            <span>{answerGetter(`nap_time_form_Key_Time_${time}_asleep`, formData)?.answer ? 'Yes' : '-'}</span>

                                        </td>
                                        <td style={{ maxWidth: '120px' }}>
                                            <span>{answerGetter(`nap_time_form_Key_Time_${time}_staff_member`, formData)?.answer || '-'}</span>

                                        </td>
                                        <td style={{ width: '100px' }}>
                                            <span>{answerGetter(`nap_time_form_Key_Time_${time}_oz_milk`, formData)?.answer || '-'}</span>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="auto-flex padding_btm15">
                    <span>Comments:</span>
                    <p><b>{answerGetter('nap_time_form_comments', formData)?.answer || '-'}</b></p>
                </div>

                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                <p>Provider signature</p>
                                <p>Submission date and time: <b>{data.additionalInfo?.providerSignedDate}</b></p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                <p>Parent | Guardian signature</p>
                                <p>Submission date and time: <b>{data.additionalInfo?.parentSignedDate}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

NapTimeCommunicationFormView.propTypes = {
    data: PropTypes.any
}
export default NapTimeCommunicationFormView;