import React from 'react';
// import { getRecoil } from 'recoil-nexus';
// import { useRecoilState } from 'recoil';
import { translateThis } from '../../helpers/language.helper';
import ConfirmDialog from '../dialogs/confirm.dialog';
import { logoutUser } from '../../helpers/auth.helper';
// import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';

import './btn.scss';


const LogoutButton = ()=> {
    // const appState = getRecoil(appConfigAtomState);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    // const [myPortalInitialState, setMyPortalState] = useRecoilState(appConfigAtomState);
    // const switchDarkMode = async () => setMyPortalState((prv) => ({ ...prv, ...{ isDarkModeOn: false } }));

    const deleteFunction = () => {
        logoutUser('Logged out successfully!')
        // switchDarkMode();
    };

    return (
        <>
        {/* eslint-disable-next-line */}
        <span className='logout-btn' onClick={()=>setOpenDeleteDialog(true)}>{`${translateThis('Logout')}`}</span>
        {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                title="Confirm logout"
                className={'delete-popup logout-popup'}
                description={<span>{translateThis('Are you sure you want to logout?')}</span>}
                okayButtonText={`${translateThis('Logout')}`}
                onConfirm={() =>deleteFunction()}
            />}
        </>
    )
}

export default LogoutButton;