import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './home.scss';
import ApiCaller from '../../services/api/general';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { showSnackbar } from '../../components/snackbar/index';
import HomePage from './home';
import RepDomHomePage from '../homeRepDom/home';
import HomePageGeneral from '../homeGeneral/home';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';
import SVGIcons from '../../assets/images/icons/svgIcons';



const FacilityHomePage = () => {
    const { facility = '' } = useParams();
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('university')
    const [isLoading, setIsLoading] = useState(true)
    const [facilityData, setFacilityData] = useState({});
    const [configState, setConfigState] = useRecoilState(myPortalState);
    const appStateAs = useRecoilValue(appConfigAtomState);
    const [appState, setAppState] = useState({
        selectedLang: appStateAs?.appStateAs || ''
    })


    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilityInfo: { ...prv.facilityInfo, ...modifiedObj } } }));


    useEffect(() => {
        if (facility) {
            getFacilityInfo()
        }
        setAppState(appStateAs);
        setTimeout(() => {
            document.body.classList.add('letAnimate');
        }, 500);
        // eslint-disable-next-line
    }, [facility, appStateAs]);

    const getFacilityInfo = () => {
        if (facilityData) {
            // Do something later
        }
        if (configState) {
            apiCaller.getData('details').then((data) => {
                if (data) {
                    setFacilityData(data)
                    modifyConfigState(data);
                } else {
                    // showSnackbar().failure("Requested facility not found");
                    // navigator('/')
                }
            }).finally(() => setIsLoading(false))
        }
    }

    // custom home functions here
    return (
        <> {isLoading ? <><span className='loader white-loader' style={{width: '100vw', height: '100vh', background: 'rgba(0, 0, 0, 0.6)'}}>{SVGIcons.LoaderIcon()}</span></> : <>
            {(facilityData?.url && facilityData?.url.toLowerCase() === "dominicana") ? <RepDomHomePage key={`dominicana-${appState?.selectedLang}`} /> :
            (facilityData?.url && facilityData?.url.toLowerCase() === "enurm.rd") ? <RepDomHomePage key={`enurm.rd-${appState?.selectedLang}`} /> :
            (facilityData?.url && facilityData?.url.toLowerCase() === "usmle") ? <HomePage key={`usmleHome-${appState?.selectedLang}`} /> :
            (facilityData?.url && facilityData?.url.toLowerCase() === "xmedpro") ? <HomePageGeneral key={`homeGeneral-${appState?.selectedLang}`} /> :
                <HomePageGeneral />
            }
        </>}
        </>
    )
}

export default FacilityHomePage;