import React, { useState, useEffect } from 'react';
// Components
import { useNavigate } from 'react-router-dom';
import { translateThis } from '../../../helpers/language.helper';
import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';

import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import './previousTestList.scss';
import RichTextViewer from '../../../components/rich-text-editor/TextViewer';



const PreviousTestQuestionList = () => {
    const apiCaller = new ApiCaller('questions');
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Question deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'slNo',
            headerName: '#',
            minWidth: 50,
            flex: 0.5,
        },
        {
            field: 'slNo',
            headerName: 'ID',
            minWidth: 50,
            flex: 0.5,
        },
        
       
        {
            field: 'question',
            headerName: 'Question',
            minWidth: 200,
            flex: 1,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /></>
        },
        {
            field: 'answered',
            headerName: 'Answered',
            minWidth: 120,
            flex: 0.8,
            renderCell: ({ row }) => <>
                <span>A <SVGIcons.StatusActiveIcon /></span>
            </>
        },
        {
            field: 'correctAnswer',
            headerName: 'Correct answer',
            minWidth: 200,
            flex: 1,
            renderCell: ({ row }) => <>
                <span>A. Aadasd</span>
            </>
        },

    ]


    return (

        <div className='block-table-wrp f-wrp'>
            <h3 style={{marginTop: '0'}} className='title'>{translateThis('prevQuestionListTitle')}</h3>
            <div className='panel-table-sec user-table provider-table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
            </div>

            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete question`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this question?\nThis action is permanent and cannot be undone.`}
                okayButtonText={'Delete question'}
                onConfirm={deleteData}
            />}
        </div>

    )
}
PreviousTestQuestionList.propTypes = {}

export default PreviousTestQuestionList;
