import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, InputLabel } from '@mui/material';
import TextInput from '../../../../components/inputs/textInput/textInput';
import '../index.scss';
import { isLoggedIn } from '../../../../helpers/auth.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import { showSnackbar } from '../../../../components/snackbar/index';
import ProfilePicUploader from '../../../../components/awsS3ImageUploader/profilePic.uploader';


import { register } from '../../../../services/api/auth';
import { translateThis } from '../../../../helpers/language.helper';

// ----------------------------------------------------------------------









export default function AdminSetNewPassword() {
    const navigate = useNavigate();

    const [data, setData] = React.useState({});
    const facilityURL = getCurrentFacilityURL()

    const location = useLocation();
    const isAuth = isLoggedIn();
    const currentFacilityURL = getCurrentFacilityURL();
    const { from } = location.state || { from: { pathname: `/${currentFacilityURL}/profile` } };

    if (isAuth) {
        navigate(from, { replace: true });
    }
    // state variables for handling input fields values
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    })

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.password && formData.confirmPassword) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            register(formData).then((data) => {
                if (data.success) {
                    navigate(from, { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message);
                }
            }).finally(() => setIsLoading(false));

        }

    };
    
    return (
        <>

            {/* {(isLoading) ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : (!isLoading && data) ?  */}
            <div className='profile-wrapper f-wrp'>
                <div className='profile-img-con'>
                    <ProfilePicUploader
                        key={`profile-pic`}
                        onUploadStart={() => null}
                        onUploadEnd={(img) => setFormValue("profilePic", img)}
                        // onRemoveFile={() => setFormValue("profilePic", {fileKey: ''})}
                        onRemoveFile={() => setFormValue("profilePic", '')}
                        defaultImage={data?.profilePic}
                    />
                </div>
                <div className='profile-details child-profile f-wrp'>

                    <form>
                        <div className='formBox f-wrp'>
                            <h3>{translateThis('setTheNewPassword')}?</h3>
                            <Grid className='profile-form' container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <InputLabel shrink htmlFor="loginForm_password">
                                        {translateThis('mustBeAtLeast8Characters')}
                                    </InputLabel>
                                    <TextInput
                                        key={`login-email`}
                                        name='password'
                                        type='password'
                                        required
                                        placeholder={translateThis('passwordResetForm_passwordPlaceHolder')}
                                        className='custom-textfield'
                                        getValue={setFormValue}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                   
                                    <TextInput
                                        key={`login-email`}
                                        name='confirmPassword'
                                        type='password'
                                        required
                                        placeholder={translateThis('passwordResetForm_confirmPasswordPlaceHolder')}
                                        className='custom-textfield'
                                        getValue={setFormValue}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                            <div className='ryt-btn-sec'>
                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition='start'
                                    startIcon={<></>} // To remove Mui warning
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='fill-btn'
                                    onClick={onSubmit}>
                                    {translateThis('save')}
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* : null} */}

        </>
    );
}

