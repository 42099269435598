import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
// import CountdownTimer from "react-component-countdown-timer";
import ReactPlayer from 'react-player/youtube';
import AppLayout from '../../layouts/app/AppLayout';
import './home.scss';
import { translateThis } from '../../helpers/language.helper';
import AppIcons from '../../assets/images/icons';
import DrawerAppBar from './mainHeader/mainHeaderDrawer';
import TestimonialsList from './TestimonialsList/TestimonialsList';
import ContactUsForm from './contactForm/contactUsForm';
import MainFooter from './mainFooter';
import SVGIcons from '../../assets/images/icons/svgIcons';
// import XmedTutorial from '../../assets/images/XmedTutorial.mp4';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';



const HomePage = () => {

    // custom home functions here
    const pageName = "USMLE";


    const [windowSize, setWindowSize] = useState([
        window.innerHeight,
    ]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const offerItems = [
        {
            image: AppIcons.landingOffer7,
            label: 'Simulated exams',
            content: 'Interactive exam preparation tools.'
        },
        {
            image: AppIcons.landingOffer8,
            label: 'Expert-curated content',
            content: 'Curated knowledge by doctors for doctors.'
        },
        {
            image: AppIcons.landingOffer9,
            label: 'New questions',
            content: 'Up to a 100+ new questions every month.'
        },
        {
            image: AppIcons.landingOffer4,
            label: 'Mobile ready',
            content: 'Seamless learning, anytime, anywhere.'
        },
        {
            image: AppIcons.landingOffer5,
            label: 'Progress tracking',
            content: 'Personalized progress tracking.'
        },
        {
            image: AppIcons.landingOffer10,
            label: 'Peer Ranking',
            content: 'Check how you rank versus your peers.'
        },
    ];

    const planItems = [
        {
            class: 'current-plan',
            promo: 'Pre-launch promo',
            planPer: 'per month',
            oldPrice: '$59',
            newPrice: '$29',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'MONTHLY',
            features: <ul><li>{translateThis('Access to all questions & features.')}</li></ul>,
            button: 'Subscribe',
        },
        {
            class: 'current-plan',
            promo: 'Pre-launch promo',
            planPer: 'per 3 months',
            oldPrice: '$139',
            newPrice: '$59',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'QUARTERLY',
            features: <ul><li>{translateThis('Access to all questions & features.')}</li></ul>,
            button: 'Subscribe',
        },
        {
            class: 'current-plan',
            promo: 'Pre-launch promo',
            planPer: 'per 6 months',
            oldPrice: '$249',
            newPrice: '$99',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'SEMIANNUAL',
            features: <ul><li>{translateThis('Access to all questions & features.')}</li></ul>,
            button: 'Subscribe',
        },
        {
            class: 'best-plan',
            promo: 'Pre-launch promo',
            planPer: 'per year',
            oldPrice: '$319',
            newPrice: '$169',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'YEARLY',
            features: <ul><li>{translateThis('Access to all questions & features.')}</li></ul>,
            button: 'Subscribe',
        },
    ];

    const facilityURL = getCurrentFacilityURL()





    return (
        <AppLayout
            pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName='home-main-wrp landing-main-wrp f-wrp'
        >
            <div className='content-main-wrapper f-wrp'>
                <div className='landing-main-nav f-wrp'>
                    <DrawerAppBar />
                </div>
                <div className='main-banner-wrp f-wrp' style={{ minHeight: `${windowSize}px` }}>
                    <span className='banner-BG'>
                        <img className='bannerPC' src={AppIcons.landingBanner} alt='X-med banner' />
                        <img className='bannerMOB' src={AppIcons.mobBanner} alt='X-med banner' />
                    </span>
                    <div className='banner-con-sec f-wrp'>
                        <div className='banner-con-box'>
                            <h1>{translateThis('BannerMainText')}</h1>
                            {/* <h4>{translateThis('XMed is the smart move')}</h4> */}
                            <p>{translateThis('BannerTitle')}<br /><b>{translateThis('XMed is the smart move')}</b></p>
                            <Link to={`/${facilityURL}/my-portal`}>{translateThis('Access XMed')}</Link>
                        </div>
                    </div>
                    <div className='scroll-wrapper f-wrp'>
                        <p>{translateThis('aboutText')}</p>
                    </div>
                </div>

                <div className='about-main-wrp f-wrp' id='about'>
                    <div className='about-section-wrp hide f-wrp'>
                        <div className='container'>
                            <div className='about-main-header f-wrp'>
                                <h1>{translateThis('About')}</h1>
                                <p>{translateThis('aboutText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='offer-section-wrp f-wrp'>
                        <div className='container'>
                            <div className='offer-main-header f-wrp'>
                                <h1>{translateThis('offerHead')}</h1>
                                <p>{translateThis('offerText')}</p>
                            </div>
                            <div className='offer-con-wrp f-wrp'>
                                <div className='row'>
                                    {offerItems.map((element, key) => (
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12' key={key}>
                                            <div className='each-offer-box f-wrp'>
                                                <span><img src={element.image} alt='offer' /></span>
                                                <h4>{translateThis(element.label)}</h4>
                                                <p>{translateThis(element.content)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='appDemo-wrapper f-wrp' id='Features'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <div className='app-demo-con f-wrp'>
                                    <div className='appDemo-main-header f-wrp'>
                                        <h1>{translateThis('appDemoHead')}</h1>
                                        <p>{translateThis('appDemoText')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                                <div className='video-wrp f-wrp'>
                                    <span>
                                        {/* <ReactPlayer width={'100%'} height={'100%'} file playsinline controls url={'https://youtu.be/xBICEAPX9og'} /> */}
                                        <ReactPlayer
                                            className='react-player'
                                            url='https://youtu.be/xBICEAPX9og'
                                            width='100%'
                                            height='calc(100vh - 140px)'
                                            style={{ minHeight: '350px', maxHeight: '600px' }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='testimonial-main-wrp f-wrp' id='Testimony'>
                    <div className='testimonials-main-header f-wrp'>
                        <h1>{translateThis('What our students say')}</h1>
                    </div>
                    <div className='testimonial-wrapper f-wrp'>
                        <div className='testimonials-section-wrp f-wrp'>
                            {/* <div className='testimonials-main-header f-wrp'>
                                <h1>{translateThis('What our students say')}</h1>
                            </div> */}
                        </div>
                        <div className='testimonial-sec f-wrp'>
                            <TestimonialsList />
                        </div>
                    </div>
                </div>
                <div className='plan-section-wrp f-wrp' id='plans'>
                    <div className='container'>
                        <div className='plan-main-header f-wrp'>
                            <h1>{translateThis('Choose your plan')}</h1>
                            <p>{translateThis('planText')}</p>
                        </div>
                        <div className='plans-sec-wrp f-wrp'>
                            <div className='row'>
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    modules={[Navigation]}
                                    navigation
                                    loop
                                    className='childListForm'
                                    breakpoints={{
                                        300: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        700: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        900: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1200: {
                                            slidesPerView: 4,
                                            spaceBetween: 20,
                                        },
                                    }}>
                                    {planItems.map((element, key) => (
                                        <SwiperSlide key={key} className="f-wrp">
                                            <div className={`each-plan-box f-wrp ${element.class} `}>
                                                <div className='promo-box'>
                                                    <span>{translateThis(element.promo)}</span>
                                                </div>
                                                <div className='price-box'>
                                                    <h3>{element.oldPrice}</h3> <h1>{element.newPrice}</h1>
                                                    <p>{translateThis(element.planPer)}</p>
                                                    {/* <span>{translateThis(element.validity)}</span> */}
                                                </div>

                                                <div className='plan-details'>
                                                    <h3>{translateThis(element.period)}</h3>
                                                    {element.features}
                                                </div>
                                                <div className='foot-btn-sec'>
                                                    <Button className='plan-btn'>{translateThis(element.button)}</Button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
                <div id='contact' className='f-wrp' />
                <div className='contact-section-blk f-wrp' id='Contact'>
                    <div className='container'>
                        <div className='contact-con-block f-wrp'>
                            <div className='row'>
                                <div className='col-lg-7 col-md-6 col-sm-12 col-xs-12'>
                                    <div className='contact-con-box f-wrp'>
                                        <h3>{translateThis('Get in touch')}</h3>
                                        <h1>{translateThis('Ready to take the next step? contact us.')}</h1>
                                        <p>{translateThis('We believe your questions are the key to meaningful learning')}</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-6 col-sm-12 col-xs-12'>
                                    <div className='contact-form-block f-wrp'>
                                        <ContactUsForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='faq-section-wrapper f-wrp' id="FAQ's">
                    <div className='container'>
                        <div className='faq-main-header f-wrp'>
                            <h1>{translateThis('FAQ’s')}</h1>
                            <p>{translateThis('faqText')}</p>
                        </div>
                        <div className='faq-con-wrp f-wrp'>
                            <div className='faq-con-list f-wrp'>
                                <ul>
                                    <li><Link to={`/${facilityURL}/faq`}>{translateThis('How does XMed Pro enhance my learning experience?')} <SVGIcons.RightArrowIcon /></Link></li>
                                    <li><Link to={`/${facilityURL}/faq`}>{translateThis('What sets XMed Pro apart from other medical education platforms?')} <SVGIcons.RightArrowIcon /></Link></li>
                                    <li><Link to={`/${facilityURL}/faq`}>{translateThis('Can I access XMed Pro on different devices?')} <SVGIcons.RightArrowIcon /></Link></li>
                                </ul>
                                <Link to={`/${facilityURL}/faq`} className='faq-plan-btn'>{translateThis('Explore more questions here')} <SVGIcons.RightArrowIcon /></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='main-foot-wrp f-wrp'>
                    <MainFooter />
                </div>

            </div>
        </AppLayout>
    )
}

export default HomePage;