import React, { useState, useEffect } from 'react';
// Components
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
// import { translateThis } from '../../../../../../helpers/language.helper';
import AppTable from '../../../../../../components/table/index';
import ApiCaller from '../../../../../../services/api/general';
import { showSnackbar } from '../../../../../../components/snackbar/index';

// import SVGIcons from '../../../../../../assets/images/icons/svgIcons';
// import AppIcons from '../../../../../../assets/images/icons';
import ModalPopUp from '../../../../../../components/modal/modal.popup';




const SubjectPerformanceTable = () => {
    const apiCaller = new ApiCaller('questions');
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [viewOpen, setViewOpen] = React.useState(false);


    const onViewClose = () => {
        setViewOpen(false);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Question deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'Subjects',
            headerName: 'Subjects',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => <div className='performance-progress-box'>
                <span style={{display: 'block'}}>Behavioral science</span>
                <span style={{display: 'block'}}><LinearProgress variant="determinate" value={30} /></span>
                
            </div>
        },
        {
            field: 'Total Q',
            headerName: 'Total Q',
            flex: 0.3,
            minWidth: 80,
            renderCell: ({ row }) => <>
                <span>33</span>
            </>
        },
        {
            field: 'Correct',
            headerName: 'Correct',
            minWidth: 80,
            flex: 0.3,
            renderCell: ({ row }) => <>
                <span>26 (55%)</span>
            </>
        },
        {
            field: 'Incorrect',
            headerName: 'Incorrect',
            minWidth: 80,
            flex: 0.3,
            renderCell: ({ row }) => <>
                <span>7 (21%)</span>
            </>
        },
        {
            field: 'Ommited',
            headerName: 'Ommited',
            minWidth: 80,
            flex: 0.3,
            renderCell: ({ row }) => <>
                <span>0 (0%)</span>
            </>
        },
        // {
        //     field: 'time',
        //     headerName: 'Time',
        //     minWidth: 100,
        //     flex: 0.4,
        //     renderCell: ({ row }) => <>
        //         <span>138 sec</span>
        //     </>
        // },
        // {
        //     field: 'action ',
        //     headerName: '',
        //     flex: 0.3,
        //     minWidth: 30,
        //     renderCell: ({ row }) => <Link onClick={() => {
        //         // setSelectedQn(row);
        //         // onViewOpen()
        //     }}><SVGIcons.ChevronRightIcon style={{ width: '15px' }} /></Link>
        // },
    ]


    return (
        <>
            <div className='panel-table-sec  provider-table f-wrp' style={{ paddingTop: '20px' }}>
                <AppTable
                    columns={tableColumns}
                    // isLoading={isLoading}
                    data={dataArr}
                />
            </div>
            {viewOpen ? <ModalPopUp
                className='preview-popup previousTestList-popup'
                isOpen={viewOpen}
                onClose={onViewClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                ssssssssssssssssssssssssssss
            </ModalPopUp> : null}
        </>
    )
}
SubjectPerformanceTable.propTypes = {}

export default SubjectPerformanceTable;
