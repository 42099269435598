import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

// @mui
import { Button, Grid, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// import Grid from '@mui/material/Grid';
// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
// Components
import { showSnackbar } from '../../../../components/snackbar';

// Helpers
import { translateThis } from '../../../../helpers/language.helper';

// Services
import ApiCaller from '../../../../services/api/general';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import SubscriptionPopup from './subscriptionPopup';
import AppIcons from '../../../../assets/images/icons';
import NoQuestionsToShowPopup from './noQuestionsPopup';




// ----------------------------------------------------------------------


const CreateNewTestForm = () => {
    const apiCaller = new ApiCaller('tests');
    const questionModes = ["unused", "correct", "incorrect", "omitted", "flagged"];
    const navigate = useNavigate();

    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isUserSubscribed, setIsUserSubscribed] = useState(false);
    const [isSubmittingAs, setIsSubmitting] = useState(false);
    const [isOpenSubscribeDialog, setOpenSubscribeDialog] = useState(false);
    const [isOpenNoQuestionsDialog, setIsOpenNoQuestionsDialog] = useState(false);
    const [numberOfQuestionsFound, setNumberOfQuestionsFound] = useState(0);
    const [subjects, setSubjects] = useState([]);
    const [systems, setSystems] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [questionCounts, setQuestionCounts] = useState({
        unused: 0,
        correct: 0,
        incorrect: 0,
        omitted: 0,
        flagged: 0
    });
    const [formData, setFormData] = useState({
        testName: "",
        testMode: "",
        timer: "",
        testTheme: "",
        questionMode: [],
        noOfQuestions: "",
        subjectId: [],
        systemId: [],
        topicId: []
    });

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        getSubscriptionStatus();
        getDataArr('subject');
        getDataArr('systems');
        getDataArr('topics');
        getQuestionCounts();
        // eslint-disable-next-line
    }, []);

    const getSubscriptionStatus = () => {
        const apiCaller = new ApiCaller('auth');
        apiCaller.getData('subscription-status').then(({ subscribed }) => {
            setIsUserSubscribed(subscribed);
        })
    }


    // get users from api
    const getDataArr = (resource, query = '') => {
        const apiCaller = new ApiCaller(`${resource}${query}`)
        apiCaller.getList()
            .then((data) => {
                switch (resource) {
                    case 'subject':
                        setSubjects(data?.results)
                        // setSelectedSubjects(data?.results)
                        break;
                    case 'systems':
                        setSystems(data?.result);
                        // setSelectedSystems(data?.result);
                        break;
                    case 'topics':
                        setTopics(data?.result);
                        // setSelectedTopics(data?.result);
                        break;

                    default:
                        break;
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const getQuestionCounts = () => {
        const apiCallerAs = new ApiCaller('myExams/count')
        apiCallerAs.getData('').then((student) => {
            const data = {
                unused: student.unUsedQuestionIds,
                correct: student.correctQuestionIds,
                incorrect: student.inCorrectQuestionIds,
                omitted: student.omittedQuestionsIds,
                flagged: student.flagQuestionIds
            }
            setQuestionCounts(data)
        })
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.testMode &&
            formData.questionMode &&
            formData.testTheme &&
            formData.timer &&
            formData.noOfQuestions
        ) {
            isValid = true;
        }
        return isValid;
    }
    /**
     * to check the err text
     */
    const [validTestMode, setValidTestMode] = useState();
    const [validTimer, setValidTimer] = useState();
    const [validNoOfQuestions, setValidNoOfQuestions] = useState();
    const [validTestTheme, setValidTestTheme] = useState();

    const validateFields = () => {
        if (formData.testMode) {
            setValidTestMode(true);
        }
        else {
            setValidTestMode(false);
        };
        if (formData.timer) {
            setValidTimer(true);
        }
        else {
            setValidTimer(false);
        };
        if (formData.noOfQuestions) {
            setValidNoOfQuestions(true);
        }
        else {
            setValidNoOfQuestions(false);
        };
        if (formData.testTheme) {
            setValidTestTheme(true);
        }
        else {
            setValidTestTheme(false);
        }

    }
    
    
    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isUserSubscribed) {
            const postData = {
                "name": formData.testName,
                "testMode": formData.testMode,
                "timer": formData.timer,
                "testTheme": formData.testTheme,
                "questionMode": formData.questionMode,
                "numberOfQuestions": formData.noOfQuestions,
                "subjectId": selectedSubjects.map((t) => t.id),
                "systemId": selectedSystems.map((t) => t.id),
                "topicId": selectedTopics.map((t) => t.id)
            }
            postForm(postData)
        } else {
            setOpenSubscribeDialog(true);
        }
    };
    
    
    /**
     * On confirm proceed with selected filters the form
     */
    const onConfirmProceedWithSelectedFilters = () => {
        if (numberOfQuestionsFound) {
            const postData = {
                "name": formData.testName,
                "testMode": formData.testMode,
                "timer": formData.timer,
                "testTheme": formData.testTheme,
                "questionMode": formData.questionMode,
                "numberOfQuestions": String(numberOfQuestionsFound),
                "subjectId": selectedSubjects.map((t) => t.id),
                "systemId": selectedSystems.map((t) => t.id),
                "topicId": selectedTopics.map((t) => t.id)
            }
            postForm(postData);
            
        } else {
            setIsOpenNoQuestionsDialog(false);
        }
    };


    /**
     * Send the form to backend
     */
    const postForm = (postData)=> {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                setNumberOfQuestionsFound(0);
                if (data.success) {
                    if (formData.testTheme === 'usmle') {
                        navigate(`/${getCurrentFacilityURL()}/usmle-view/${data?.data?.test?.testId}`, { replace: true });
                    } else {
                        navigate(`/${getCurrentFacilityURL()}/start-test/${data?.data?.test?.testId}`, { replace: true });
                    }

                } else if (data?.message?.isError === false) {
                    setNumberOfQuestionsFound(data?.message?.numberOfQuestions);
                    setIsOpenNoQuestionsDialog(true);
                    // showSnackbar().failure(data.message);                        
                } else {
                    showSnackbar().failure("Something went wrong please try again later");
                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().failure("Please fill all required fields")
        }

    }

    /**
     * On Clicking close popup
     */
    const onCloseNoQuestionsPopup = ()=> {
        setIsOpenNoQuestionsDialog(false);
        setNumberOfQuestionsFound(0)
    }

    /**
     * When user clicks on subscribe
     */
    const onSubscribeConfirm = () => {
        navigate(`/${getCurrentFacilityURL()}/plans`, { replace: true })

    }

    const onSelectSubject = (subject) => {
        setSelectedSubjects((prv) => {
            if (prv.find((s) => s.id === subject.id)) {
                prv = prv.filter((p) => p.id !== subject.id)
            } else {
                // getDataArr(`systems`, `subjectId=${subject.id}&`)
                prv.push(subject)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a system
     * @param {Object} system 
     */
    const onSelectSystem = (system) => {
        setSelectedSystems((prv) => {
            if (prv.find((s) => s.id === system.id)) {
                prv = prv.filter((p) => p.id !== system.id)
            } else {
                prv.push(system)
            }
            return [...prv]
        })

    }

    /**
     * When selecting a topic
     * @param {Object} system 
     */
    const onSelectTopic = (topic) => {
        setSelectedTopics((prv) => {
            if (prv.find((s) => s.id === topic.id)) {
                prv = prv.filter((p) => p.id !== topic.id)
            } else {
                prv.push(topic)
            }
            return [...prv]
        })

    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })
    }

    /**
   * sets the setQuestionMode data 
   * @param {string} val  the value of the field
   */
    const setQuestionMode = (val) => {
        let arr = formData.questionMode;
        if (arr.includes(val)) {
            arr = arr.filter((v) => v !== val);
        } else if (val === 'all') {
            arr = questionModes;
        } else {
            arr.push(val);
        }
        setFormData({ ...formData, ...{ questionMode: arr } })
    }

    /**
     * The signup form elements
     */


    // const [expanded, setExpanded] = useState(false);

    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    const currentFacilityURL = getCurrentFacilityURL()

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='standard-form questionForm full-wrp' spacing={3}>
                    <Formik
                        initialValues={formData}
                        validate={() => {
                            const errors = {};

                            return errors;
                        }}
                        onSubmit={(_, { setSubmitting }) => {
                            onSubmit()
                            setSubmitting(false);

                        }}
                    >
                        {({
                            handleSubmit
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                            >

                                <Grid container spacing={0} style={{ marginLeft: '-16px', marginTop: '0px', paddingBottom: '24px', width: 'calc(100% + 16px)' }}>

                                    {/* <Grid item xs={12} sm={12} md={12} spacing={0} style={{paddingLeft: '16px'}}>
                                    <p className='waring-box'> <SVGIcons.AccidentIcon /> <b>Attention:</b> based on your selected parameters the exam will be limited to num questions. </p>
                                </Grid> */}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={2} style={{ marginLeft: '0px', marginTop: '0px', width: '100%' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3><span className='icon'><img src={AppIcons.Text} alt='icon' /></span>{translateThis('Name your Practice')} <span className='optional'>{translateThis('optional')}</span></h3>
                                                    <TextField
                                                        key={`createQN_Question_Creator_add_answer`}
                                                        name='testName'
                                                        id={`createQN_Question_Creator_add_answer`}
                                                        value={formData?.testName}
                                                        translationKey={translateThis('Test #2')}
                                                        type='text'
                                                        placeholder={translateThis('Test #2')}
                                                        className='custom-textfield maxWidth250'
                                                        onChange={(e) => {
                                                            setFormValue('testName', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <div className='eachQn-box f-wrp noPadding-UL'>
                                                    <h3><span className='icon'><img src={AppIcons.testMode} alt='icon' /></span>{translateThis('Select test mode')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Real mode')}</b>: {translateThis('get explanations and feedback of your performance at the end of the exam')}.<br /><b>{translateThis('Practice mode')}</b>: {translateThis('get explanations and feedback instantly right after each question')}.</span>} placement="top">
                                                        <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                    </Tooltip></h3>
                                                    {/* <p>{translateThis('Explanatory text coming up')}</p> */}
                                                    <div className='each-topic-sec'>
                                                        <div className='topic-items-list no-bg'>
                                                            <ul>
                                                                {/* eslint-disable-next-line */}
                                                                <li onClick={() => setFormValue('testMode', "real")} className={formData.testMode === 'real' ? 'active' : ''}><p>{translateThis('Real mode')}</p></li>

                                                                {/* {(currentFacilityURL === 'usmle') ? <li onClick={() => setFormValue('testMode', "usmle")} className={formData.testMode === 'usmle' ? 'active' : ''}><p>{translateThis('USMLE mode')}</p></li> : null} */}
                                                                {/* eslint-disable-next-line */}
                                                                <li onClick={() => setFormValue('testMode', "practice")} className={formData.testMode === 'practice' ? 'active' : ''}><p>{translateThis('Practice mode')}</p></li>

                                                            </ul>
                                                            {validTestMode === false ? <p className='errText'>{translateThis('Required')}</p> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <div className='eachQn-box f-wrp noPadding-UL'>
                                                    <h3><span className='icon'><img src={AppIcons.brush} alt='icon' /></span>{translateThis('Appearance')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}>
                                                        {(currentFacilityURL === 'usmle') ? <><b>{translateThis('Classic view')}</b>: {translateThis('classic modern view')}<br />
                                                        <b>{translateThis('UMSLE mode')}</b>: {translateThis('view accurately reflecting USMLE')}</> : <>
                                                        <b>{translateThis('Classic view')}</b>: {translateThis('classic modern style')}<br />
                                                        <b>{translateThis('Retro view')}</b>: {translateThis("retro 2000's style")}</> }</span>} placement="top">
                                                        <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                    </Tooltip></h3>
                                                    {/* <p>{translateThis('Explanatory text coming up')}</p> */}
                                                    <div className='each-topic-sec'>
                                                        <div className='topic-items-list no-bg'>
                                                            <ul>
                                                                {/* eslint-disable-next-line */}
                                                                <li onClick={() => setFormValue('testTheme', "classic")} className={formData.testTheme === 'classic' ? 'active' : ''}><p>{translateThis('Classic view')}</p></li>
                                                                {/* eslint-disable-next-line */}
                                                                <li onClick={() => setFormValue('testTheme', "usmle")} className={formData.testTheme === 'usmle' ? 'active' : ''}><p>{(currentFacilityURL === 'usmle') ? <>{translateThis('USMLE mode')}</> : <>{translateThis('Retro view')}</>}</p></li>
                                                            </ul>
                                                            {validTestTheme === false ? <p className='errText'>{translateThis('Required')}</p> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={2} style={{ marginLeft: '0px', marginTop: '0px', width: '100%' }}>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='eachQn-box f-wrp noPadding-UL'>
                                                    <h3><span className='icon'><img src={AppIcons.filter} alt='icon' /></span>{translateThis('Filter by question status')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Unused')}</b>: {translateThis('never included on a test')}.<br />
                                                        <b>{translateThis('Correct')}</b>: {translateThis('answered correctly')}.<br />
                                                        <b>{translateThis('Incorrect')}</b>: {translateThis('answered incorrectly')}.<br />
                                                        <b>{translateThis('Omitted')}</b>: {translateThis('no answer selected')}.<br />
                                                        <b>{translateThis('Flagged')}</b>: {translateThis('ONLY flagged questions')}.</span>} placement="top">
                                                        <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                    </Tooltip></h3>
                                                    <div className='each-topic-sec'>
                                                        <div className='topic-items-list no-bg'>
                                                            <ul>
                                                                {/* eslint-disable-next-line */}
                                                                {/* <li onClick={() => setQuestionMode("all")} className={formData.questionMode.length === 5 ? 'active' : ''}><p>{translateThis('Select all')}</p></li> */}
                                                                {questionModes.map((mode) => (
                                                                    <>
                                                                        {/* eslint-disable-next-line */}
                                                                        <li onClick={() => setQuestionMode(mode)} className={formData.questionMode.includes(mode) ? 'active' : ''}><p>{translateThis(mode)} <span style={{ color: '#195994' }}>{questionCounts[mode]}</span> </p></li>
                                                                    </>
                                                                ))}
                                                            </ul>
                                                            {/* {validQuestionMode===false? <p className='errText'>{translateThis('Required')}</p> : null} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <div className='eachQn-box f-wrp'>
                                                    <h3><span className='icon'><img src={AppIcons.number} alt='icon' /></span>{translateThis('Set the number of questions')}</h3>
                                                    <div className='textfieldWithText'>
                                                        <TextField style={{ maxWidth: '48px' }} onWheel={(e) => e.target.blur()}
                                                            key={`Number_of_questions`}
                                                            name={`noOfQuestions`}
                                                            id={`Number_of_questions`}
                                                            value={formData?.noOfQuestions}
                                                            type='number'
                                                            min='2'
                                                            max='40'
                                                            placeholder={translateThis('00')}
                                                            className='custom-textfield'
                                                            onChange={(e) => {
                                                                if (e.target.value <= 40) {
                                                                    setFormValue('noOfQuestions', e.target.value)
                                                                }
                                                            }}
                                                        />
                                                        <p>{translateThis('With a minimum of 2 and a maximum of 40')}</p>
                                                    </div>
                                                    {validNoOfQuestions === false ? <p className='errText'>{translateThis('Required')}</p> : null}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6}>

                                                <div className='eachQn-box f-wrp noPadding-UL'>
                                                    <h3><span className='icon'><img src={AppIcons.Time} alt='icon' /></span>{translateThis('Set a time limit')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('No time limit')}</b>: {translateThis('test lasts indefinitely')}.<br /><b>{translateThis('Time Limit')}</b>: {translateThis('test duration will be calculated at 1.5 minutes per question, based on your selected number of question')}.</span>} placement="top">
                                                        <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                                    </Tooltip></h3>
                                                    {/* <p>{translateThis('Explanatory text coming up')}</p> */}
                                                    <div className='each-topic-sec'>
                                                        <div className='topic-items-list no-bg'>
                                                            <ul>
                                                                {/* eslint-disable-next-line */}
                                                                <li onClick={() => setFormValue('timer', "no limit")} className={formData.timer === 'no limit' ? 'active' : ''} style={{padding: '7px 20px'}}><p>{translateThis('No time limit')}</p></li>
                                                                {/* eslint-disable-next-line */}
                                                                <li onClick={() => setFormValue('timer', "limit")} className={formData.timer === 'limit' ? 'active' : ''} style={{padding: '7px 20px'}}><p>{translateThis('Time limit')}</p></li>

                                                            </ul>
                                                            {validTimer === false ? <p className='errText'>{translateThis('Required')}</p> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                                <div className='eachQn-box accordian-box f-wrp'>
                                    <Accordion className='each-accordian-sec'>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="subjectAcc-content"
                                            id="subjectAcc-header"
                                        >
                                            <h3><span className='icon'><img src={AppIcons.sub} alt='icon' /></span>{translateThis('Filter by Subject')} </h3>
                                            <p>{translateThis('Filter by Subject Text')}</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='each-topic-sec'>
                                                <div className='flex-box assignSubBox'>
                                                    {(subjects && subjects.filter((sub) => sub?.logo?.tempURL).length > 0) ? subjects.map((sub, i) => (
                                                        <button type='button' onClick={() => onSelectSubject(sub)} key={i + sub?.logo.fileKey} className={`image-blk ${selectedSubjects.find((s) => s.id === sub.id)?.id ? 'active' : ''}`}>
                                                            <img src={sub?.logo?.tempURL} alt='images' />
                                                            <h3>{sub?.subjectName}</h3>
                                                            <p>{sub?.topics.length}&nbsp; {translateThis('topics')}</p>
                                                        </button>
                                                    )) : null}
                                                </div>

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div className='eachQn-box accordian-box f-wrp'>
                                    <Accordion className='each-accordian-sec'>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="systemAcc-content"
                                            id="systemAcc-header"
                                        >
                                            <h3><span className='icon'><img src={AppIcons.sys} alt='icon' /></span> {translateThis('Filter by System')}</h3>
                                            <p>{translateThis('Filter by System Text')}</p>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div className='each-topic-sec'>
                                                <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                    <ul>
                                                        {(systems && systems.length > 0) ? systems.map((system) => (
                                                            // eslint-disable-next-line
                                                            <li onClick={() => {
                                                                onSelectSystem(system)
                                                            }} key={system.id} className={selectedSystems.find((s) => s.id === system.id)?.id ? 'active' : ''}><p>{system.systemName}</p></li>
                                                        )) : 'No systems found'}
                                                    </ul>
                                                </div>
                                            </div>

                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div className='eachQn-box accordian-box f-wrp'>
                                    <Accordion className='each-accordian-sec'>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="topicAcc-content"
                                            id="topicAcc-header"
                                        >
                                            <h3><span className='icon'><img src={AppIcons.topic} alt='icon' /></span>{translateThis('Filter by Topic')}</h3>
                                            <p>{translateThis('Filter by Topic Text')}</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='each-topic-sec'>
                                                <div className='topic-items-list no-bg' style={{ padding: '0' }}>
                                                    <ul>
                                                        {(topics && topics.length > 0) ? <>
                                                            {topics.map((topic) => (
                                                                // eslint-disable-next-line
                                                                <li onClick={() => onSelectTopic(topic)} key={topic.id} className={selectedTopics.find((s) => s.id === topic.id)?.id ? 'active' : ''}><p>{topic.topicName}</p></li>
                                                            ))}
                                                        </> : 'No topics found'}
                                                    </ul>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>


                                <div className='button-sec f-wrp'>
                                    <div className='lft-btn-sec'>
                                        <Button className='cancel-btn'>{translateThis("cancel")}</Button>
                                    </div>
                                    <div className='ryt-btn-sec' style={{ position: 'relative' }}>

                                        <LoadingButton
                                            loading={isSubmittingAs}
                                            loadingPosition='start'
                                            startIcon={<></>} // To remove Mui warning
                                            fullWidth
                                            disabled={!isValidForm() || isSubmittingAs}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            className='fill-btn'
                                            onClick={handleSubmit}>
                                            {translateThis("Start test")}
                                        </LoadingButton>
                                        <button className='validateBtn' type='button' onClick={() => validateFields()}>validate</button>

                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Stack>
                <br />
                {isOpenSubscribeDialog && <ConfirmDialog
                    isOpen={isOpenSubscribeDialog}
                    onClose={() => {
                        setOpenSubscribeDialog(false);
                    }}
                    // title=""
                    className={'delete-popup subscription-popup'}
                    description={<SubscriptionPopup />}
                    okayButtonText={'Subscribe'}
                    onConfirm={onSubscribeConfirm}
                />}

                {isOpenNoQuestionsDialog && <ConfirmDialog
                    isOpen={isOpenNoQuestionsDialog}
                    onClose={onCloseNoQuestionsPopup}
                    // title=""
                    className={'delete-popup subscription-popup'}
                    description={<NoQuestionsToShowPopup 
                        onCancel={onCloseNoQuestionsPopup}
                        numberOfQuestionsFound={numberOfQuestionsFound} />}
                    okayButtonText={numberOfQuestionsFound ? translateThis("Continue with test") : translateThis("Refine filters")}
                    cancelButtonText={`${translateThis("Refine filters")}`}
                    onConfirm={numberOfQuestionsFound ? onConfirmProceedWithSelectedFilters : onCloseNoQuestionsPopup}
                />}

            </div>}
        </>
    );
}

CreateNewTestForm.propTypes = {}

export default CreateNewTestForm;