import React from 'react';
import { Button } from '@mui/material';
import './subscriptionPopup.scss';
import { translateThis } from '../../../../helpers/language.helper';
import SVGIcons from '../../../../assets/images/icons/svgIcons';


const NoQuestionsToShowPopup = ({numberOfQuestionsFound = 0, onCancel = ()=>null}) => {
    return (
        <>
            <div className='subscription-popup-wrp f-wrp'>
                <span>{SVGIcons.WhoopsIcon()}</span>
                <h2>{translateThis("Whoopsie! Let's polish those filters!")}</h2>
                {numberOfQuestionsFound ? <><p>{translateThis("Based on your filters the test will be limited to")} <b>{numberOfQuestionsFound}</b> {translateThis("Questions")}</p>
                <p>{translateThis("You can refine your filters by clicking")} <Button type='button' variant='text' onClick={onCancel}>here</Button> </p>
                </>  : <>
                <p>{translateThis("We couldn't find any questions matching your selected filters.")}</p>
                <p style={{fontSize: '14px'}}>{translateThis("Tweak them a bit and get back on track to acing your test!")}</p>    
                </> }           
            </div>
        </>
    )
}

export default NoQuestionsToShowPopup;