import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const SpecialNeedsView = (props) => {
    const { data = {} } = props;
    const { formData = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView specialNeedView f-wrp">
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>Special Needs</b></h2>
                        <div className='gap' />
                        <ul className='bullet-list'>
                            <li>You indicated that your child has special needs. If your child needs extra supervision and care due to their special need, Care 4 Kids may provide an additional payment amount. To request this additional payment amount, please sign the Authorization to Release Information below. This will give your child's doctor or certified health care professional permission to give us the information requested. This information will only be used for this program and not shared with others.</li>
                            <li>Take this form to your child's doctor or certified health care professional to be filled out. Ask that the form be returned to Care 4 Kids once completed.</li>
                        </ul>
                        <div className='gap' />
                        <p><b>PARENT/SUPERVISING ADULT AUTHORIZATION TO RELEASE INFORMATION</b></p>
                        <p>I hereby authorize the release of the requested medical/psychiatric information to the State of Connecticut Office of Early Childhood Care 4 Kids program for:</p>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <div className='flex-box'>
                        <p>Patient’s Name: <b>{answerGetter('special_needs_form_question_patientName', formData)?.answer || '-'}</b></p>
                        <p>Print Name of Parent or Supervising Adult: <b>{answerGetter('special_needs_form_question_adultName', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />


                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'right', textAlign: 'center' }}>
                            <h3>{data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''}</h3>
                            <p>Parent | Guardian signature</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

SpecialNeedsView.propTypes = {
    data: PropTypes.any
}

export default SpecialNeedsView;