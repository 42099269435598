import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './TestimonialsList.scss';
import AppIcons from "../../../assets/images/icons";





function TestimonialsList() {


  const TestimonialsListing = [
    {
      testimg: AppIcons.profileGirl,
      testdiscription: <p>Holaaa buenas tardes<br />Ha sido de gran felicidad poder estar tomando las pruebas de su plataforma. Ha sido una maravilla poder ser parte de su gran familia. Las preguntas, las correcciones, las explicaciones de cada una, de verdad que me ha encantado.<br />Muchas gracias 😊</p>,
      name: 'Lisa C.',
      star: AppIcons.star,
    },
    // {
    //   testimg: AppIcons.profile,
    //   testdiscription: <p>XMed Pro cambió la forma en que abordo mis estudios médicos.<br />Increíble!</p>,
    //   name: 'Gabriel Mitchell',
    //   star: AppIcons.star,
    // },


  ];




  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        modules={[Navigation]}
        navigation
        loop
        // autoplay={{
        //   delay: 7000,
        //   disableOnInteraction: false,
        // }}
        // pagination={{
        //   dynamicBullets: false,
        //   dynamicMainBullets: 0,
        // }}
        className='testimonial-item-wrp'
        breakpoints={{
          10: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        }}>

        {TestimonialsListing.map((element, key) => (
          <SwiperSlide key={key} className="each-testimonials-items f-wrp">
            <div className="test-con-wrp f-wrp">
              <h2>{element.testdiscription}</h2>
              {/* <b>{element.names}</b> */}
              {/* <p>{element.place}</p> */}
            </div>
            <div className="test-prof-img f-wrp">
              <img src={element.testimg} alt="testimonial" />
              <h4>{element.name}</h4>
              <ul className="rating">
                <li><img src={element.star} alt="star" /></li>
                <li><img src={element.star} alt="star" /></li>
                <li><img src={element.star} alt="star" /></li>
                <li><img src={element.star} alt="star" /></li>
                <li><img src={element.star} alt="star" /></li>
              </ul>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

    </>
  );
}

export default TestimonialsList;