import * as React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import './videoPlayer.scss';


const VideoPlayer = (props) => {
    const { videoUrl = '' } = props;
    return (
        <>
            <div className='tab-sec-wrp f-wrp'>
                <ReactPlayer
                    className='react-player'
                    url={videoUrl}
                    controls
                    width='100%'
                    height='calc(100vh - 120px)'
                    style={{ minHeight: '350px', maxHeight: '600px' }}
                    // playing
                    // playsinline
                    // volume={0.5}
                    // muted
                    // onReady={(e) => {
                    //     const player = e.getInternalPlayer();
                    //     player.setAttribute('muted', 'true');
                    // }}
                />
            </div>

        </>
    )
}
VideoPlayer.propTypes = {
    videoUrl: PropTypes.string,
}
export default VideoPlayer;