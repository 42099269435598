/**
@author Geethananth M
Use this file to write functions that can be used across the app
* */

import dayjs from 'dayjs';
import uniqid from 'uniqid';
import ApiCaller from '../services/api/general';

/**
 * Returns a unique random string
 * @param {string} prefix 
 * @param {string} suffix 
 * @returns string the generated string
 */
export const getUniqueId = (prefix = '', suffix = '') => uniqid(prefix, suffix);


/**
 * Round of the number to nearest 
 * @param {Number} num 
 * @returns Number
 */
export const roundOff = (num) => Math.round((num + Number.EPSILON) * 100) / 100;


/**
 * Returns a currency formatted string
 * @param {string | number} input 
 * @param {boolean} includeUSDSign
 * @returns string the generated string
 */
export const usdFormatter = (input, includeUSDSign = false) => {
    const numberAs = Math.floor(input * 100) / 100;
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    const replacer = includeUSDSign ? '$' : '';
    return formatter.format(numberAs).replace("$", replacer);
}

/**
 * Formats the birthday to age
 * @param {string} birthday 
 */
export const getAgeFromBirthday = (birthday) => {
    let result = '';
    if (birthday) {
        const months = dayjs().diff(dayjs(birthday), 'month');
        if (months < 12) {
            result = `${months} months`;
        } else {
            const years = parseInt((months / 12), 10);
            if (years === 1) {
                result = `1 year`;
            } else {
                result = `${years} years`;
            }
        }
    }
    return result;
}


/**
* Download a file using its url
* @param {string} fileURL;
* @param {string} fileName;
* @returns void
*/
export const downloadFileByURL = async (fileURL, fileName = 'file') => {
    try {
        const a = document.createElement('a')
        a.href = fileURL
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    } catch (e) {
        console.log(e)
        // return {success:false, message: 'Could not download the PDF at this time. Please try again later'};
    }
}

/**
 * Return the current facility URL
 * @returns String the current facility slug
 */
export const getCurrentFacilityURL = ()=> {
    const facilitySlug =  window.location.pathname.split('/')[1];
    return facilitySlug || '';
    
}


export const getURLExtension = ( url ) => url.split(/[#?]/)[0].split('.').pop().trim();

/**
 * Just to check the auth token validation by calling random API
 */

export const authCheck =()=> {
    const apiCaller = new ApiCaller('auth');
    apiCaller.getData(('auth-check'))
}

/**
 * Converts the seconds to HH min format
 * @param {*} sec 
 * @returns string
 */
export const convertSecondsToHoursMins = (sec)=> {
    // calculate hours, minutes and seconds
    const hrs = Math.floor(sec / 3600);
    const min = Math.floor((sec - (hrs * 3600)) / 60);
    const seconds = sec - (hrs * 3600) - (min * 60);

    // format hours, minutes and seconds
    const hrsFormatted = hrs < 10 ? `0${hrs}` : hrs;
    const minFormatted = min < 10 ? `0${min}` : min;
    const secondsFormatted = seconds < 10 ? `0${seconds}` : seconds;

    // return formatted string
    if (hrsFormatted === '00' && minFormatted === '00') {
        return `${secondsFormatted} seconds`
    }
    return `${hrsFormatted}h ${minFormatted} min`;
}