import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './index.scss';
import { translateThis } from '../../../../helpers/language.helper';
import AppTable from '../../../../components/table';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import AppIcons from '../../../../assets/images/icons';
import SVGIcons from '../../../../assets/images/icons/svgIcons';







const SelectStudentPopup = ({
    students = [],
    selectedIds = [],
    onSelectStudents = () => null,
    customEmailIds = [],
    onEnterCustomEmailIds = () => null
}) => {
    const apiCaller = new ApiCaller('university/student-list');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState(students);
    const [filteredDataArr, setFilteredDataArr] = useState(students);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        if (students.length === 0) {
            // getDataArr()
        }
        return () => {
            // setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data?.result.map((res, i) => ({ ...res, slNo: i + 1 })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
    * The string search
    * @param {String} searchTerm 
    */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }

    const tableColumns = [

        {
            field: 'name',
            sortable: false,
            headerName: 'Name',
            flex: 1,

        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1.3,
            minWidth: 150,
        },
        {
            field: 'isSubscribed',
            headerName: 'Subscription',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => row?.isSubscribed ? 'Yes' : 'No'
            // renderCell: ({ row }) => <AppPopOverMenu options={[
            //     {
            //         label: `${translateThis('View')}`,
            //         onClick: () => {
            //             setOpenViewMessageDialog(true);
            //             setSelectedMessage(row);
            //         }
            //     },

            // ]} />
        }
    ]

    return (

        <div className='select-student-popup-wrp f-wrp'>
            <div className='header'>
                <h3>{translateThis('Students')}</h3>
                <p>{translateThis('Enter the emails separated by commas')}</p>
            </div>
            <div className='email-search-box f-wrp'>
                <textarea value={customEmailIds} onChange={(e)=>onEnterCustomEmailIds(e.target.value)} type='text' className='email-input' />
            </div>
            <div className='select-student-table panel-table-sec provider-table PC-Tables f-wrp'>
                <p>{translateThis('Select the student(s) from the list')} {selectedIds.length > 0 ? `(${selectedIds.length} selected)` : ''}</p>
                <div className='PC-Table'>
                    <AppTable
                        checkboxSelection
                        selectedIds={selectedIds}
                        onSelectRows={(ids) => onSelectStudents(ids)}
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={filteredDataArr}
                    />
                </div>

                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id} className={`${selectedIds.includes(element.id) ? 'option-selected' : 'option-deselected'}`}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <h4>{element.isSubscribed ? <SVGIcons.StatusActiveIcon style={{ width: '18px', height: '18px' }} /> : <SVGIcons.StatusInactiveIcon style={{ width: '18px', height: '18px' }} />}</h4>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                        {(element.name) ? <h4 className='question'>
                                            {element.name}
                                        </h4> : '-'}
                                        {(element.email) ? <p>
                                            {element.email}
                                        </p> : '-'}

                                    </div>

                                    <Link className={`full-li-btn ${selectedIds.includes(element.id) ? 'selected-student' : 'not-selected'}`} onClick={() => {
                                        const selIds = selectedIds.includes(element.id) ? selectedIds.filter((id) => id !== element.id) : [...selectedIds, element.id];
                                        onSelectStudents(selIds)
                                        // setSelectedQn(element);
                                        // onViewOpen()
                                    }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }

            </div>


        </div>
    )
}

SelectStudentPopup.propTypes = {
    selectedIds: PropTypes.array,
    students: PropTypes.array,
    customEmailIds: PropTypes.string,
    onEnterCustomEmailIds: PropTypes.func,
}
export default SelectStudentPopup;

