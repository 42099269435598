import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import ApiCaller from '../../../../services/api/general';


function FlagQuestionButton(props) {
    const { question, test } = props;
    const [isFlagged, setIsFlagged] = useState();

    const onGetQuestion = () => {
        if (question?._id) {
            const apiCaller = new ApiCaller('myExams');
            apiCaller.getData(`exam-question/flag-status/${test.testId}/${question._id}`).then(({ isFlagged }) => {
                setIsFlagged(isFlagged)
            }).catch(() => null)
        }
    }

    const onFlagQnClick = (questionId) => {
        if (questionId) {
            const apiCaller = new ApiCaller('myExams');
            const apiEndPoint = isFlagged ? 'unflag-question' : 'flag-question';
            apiCaller.customPutData(apiEndPoint, { questionId, testId: test.testId }).finally(() => {
                onGetQuestion();
            }).catch(() => null)
        }

    }
    return (
        <>
            <Button onClick={() => onFlagQnClick(question._id)} className={`usmle-flag-btn ${(isFlagged)? 'flagged' : 'unflagged'} `}>
                <span className='checkBox' />
                <span className='flagIcon'>{SVGIcons.UsmleFlag()}</span>
                <p>{translateThis('Mark')}</p>
            </Button>
        </>
    )
}
FlagQuestionButton.propTypes = {
    test: PropTypes.any,
    question: PropTypes.any
}

export default FlagQuestionButton;