import { useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

// @mui
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// css
import '../../../../assets/css/form.scss';
// Helpers
import { translateThis } from '../../../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';



// ----------------------------------------------------------------------


const JoinAClass = () => {
    const navigate = useNavigate();
    const facilityURL = getCurrentFacilityURL()
    const [formData, setFormData] = useState({
        createClassCode: ""
    });

    const onSubmit = () => {
        if (formData) {
            navigate(`/${facilityURL}/classes`, { replace: true });

        }

    };

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })
    }


    return (
        <div className='join-class-form f-wrp'>
            <Stack className='standard-form questionForm full-wrp' spacing={3}>
                <Formik
                    initialValues={formData}
                    validate={() => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={(_, { setSubmitting }) => {
                        onSubmit()
                        setSubmitting(false);
                    }}>
                    {({
                        handleSubmit
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                        >

                            <TextField
                                key={`create_class_Code`}
                                name='createClassCode'
                                id={`create_class_Code`}
                                value={formData?.createClassCode}
                                translationKey={translateThis('Enter the class code...')}
                                type='text'
                                placeholder={translateThis('Enter the class code...')}
                                className='custom-textfield'
                                onChange={(e) => {
                                    setFormValue('createClassCode', e.target.value)
                                }}
                            />

                            <div className='button-sec f-wrp'>
                                <div className='lft-btn-sec'>
                                    <Button className='cancel-btn'>{translateThis("cancel")}</Button>
                                </div>
                                <div className='ryt-btn-sec' style={{ position: 'relative' }}>
                                    <LoadingButton
                                        // loading={isSubmittingAs}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        // disabled={!isValidForm() || isSubmittingAs}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='fill-btn'
                                        onClick={onSubmit}
                                    >
                                        {translateThis("Confirm")}
                                    </LoadingButton>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Stack>
        </div>

    );
}

JoinAClass.propTypes = {}

export default JoinAClass;