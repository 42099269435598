import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { translateThis } from '../../../helpers/language.helper';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';
import TextInput from '../../../components/inputs/textInput/textInput';
import { contactUs } from '../../../services/api/contactUs';
import './index.scss';
import { showSnackbar } from '../../../components/snackbar/index';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import RichTextEditor from '../../../components/rich-text-editor';
import FileUploader from '../../../components/awsS3ImageUploader/file.uploader';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';



export default function AiDevelopment() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [aiDev, setAiDev] = useState(false);
    const [openWIPDialog, setOpenWIPDialog] = React.useState(false);
    const [images, setImages] = useState([]);
    const [formData, setFormData] = useState({
        moduleTitle: '',
        modulePurpose: '',
        name: '',
        email: '',
        phoneNumber: '',
        query: ''
    })

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.name && formData.email && formData.query) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            contactUs(formData).then((data) => {
                if (data.success) {
                    navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsLoading(false));

        }

    };

   
    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Ai Development")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content ai-module-wrp'>
                                {aiDev ? <>
                                    <Grid container className='standard-form questionForm' spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <div className='eachQn-box f-wrp'>
                                                <h3>{translateThis('Module Title/Use case')}</h3>
                                                <TextInput
                                                    name='name'
                                                    value={formData.moduleTitle}
                                                    translationKey='Name'
                                                    type='text'
                                                    isRequired
                                                    placeholder={translateThis('Type here')}
                                                    className='custom-textfield'
                                                    getValue={(moduleTitle, value) => setFormValue(moduleTitle, value)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <div className='eachQn-box f-wrp'>
                                                <h3>{translateThis('Module purpose')}</h3>
                                                <TextInput
                                                    name='Module purpose'
                                                    value={formData.modulePurpose}
                                                    translationKey='Module purpose'
                                                    type='text'
                                                    isRequired
                                                    placeholder={translateThis('Type here')}
                                                    className='custom-textfield'
                                                    getValue={(modulePurpose, value) => setFormValue(modulePurpose, value)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className='eachQn-box f-wrp'>
                                                <h3>{translateThis('Upload training files')}</h3>
                                                <div className='form-img-uploader f-wrp'>
                                                    <FileUploader
                                                        acceptOnly='image/*'
                                                        uploadIcon={SVGIcons.UploadIcon()}
                                                        uploadCaption={`${translateThis('Upload a file')}`}
                                                        showPreview
                                                        onUploadEnd={(file) => setImages((prev) => {
                                                            const newImgArr = [...prev];
                                                            newImgArr.push(file);
                                                            return [...newImgArr];
                                                        })}
                                                    />
                                                </div>
                                                <div className='added-question-list-items f-wrp'>
                                                    <div className='flex-box'>
                                                        {images.map((img, i) => (
                                                            <span key={`${i}-${img.fileKey}`} className='image-blk'><img src={img.tempURL} alt={img.fileKey} />
                                                                <IconButton onClick={() => setImages((prev) => {
                                                                    const newImgArr = [...prev].filter((imgAs) => img.fileKey !== imgAs.fileKey);
                                                                    return [...newImgArr];
                                                                })}>
                                                                    <Delete />
                                                                </IconButton>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className='eachQn-box f-wrp'>
                                                <h3>{translateThis('Train AI')}</h3>
                                                <RichTextEditor defaultValue={formData?.description} onChange={(value) => setFormValue('Type here', JSON.stringify(value))} />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <div className='button-sec f-wrp' style={{ paddingBottom: '30px' }}>
                                        <div className='lft-btn-sec'>
                                            <Button className='cancel-btn'>{translateThis("cancel")}</Button>
                                        </div>
                                        <div className='ryt-btn-sec'>
                                            <LoadingButton
                                                // loading={isSubmitting}
                                                loadingPosition='start'
                                                startIcon={<></>} // To remove Mui warning
                                                fullWidth
                                                disabled={!isValidForm()}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className='fill-btn'
                                                onClick={onSubmit}>
                                                {translateThis("submit")}
                                            </LoadingButton>
                                        </div>
                                    </div>

                                </> : <div className='empty-placeholder ai-create-box f-wrp'>
                                    <div className='placeholder-con'>
                                        <>
                                            <span className='wip-icon main-icon'>
                                                <SVGIcons.AiIcon />
                                            </span>
                                            <button type='button' className='ai-create-btn' onClick={() => setOpenWIPDialog(true)}>{`${translateThis('Create module/use case')}`}</button>
                                            {openWIPDialog && <ConfirmDialog
                                                isOpen={openWIPDialog}
                                                onClose={() => {
                                                    setOpenWIPDialog(false);
                                                }}
                                                cancelButtonText='Close'
                                                className={'delete-popup subscription-popup wip-popup'}
                                                description={<>
                                                    <span className='wip-icon'>
                                                        <SVGIcons.WorkInProgress />
                                                    </span>
                                                    <h1>{translateThis('Work in Progress')}</h1>
                                                    <p>We are working on this and will resolve it shortly.</p>
                                                </>}
                                                okayButtonText={`${translateThis('Close')}`}
                                                onConfirm={() => {
                                                    setOpenWIPDialog(false);
                                                }}
                                            />}
                                        </>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}
