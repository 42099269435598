import { getRecoil, setRecoil } from 'recoil-nexus';
import instance from './axios';
import { setAuthToken } from '../../helpers/auth.helper';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';

const axiosInstance = instance;

/**
 * login
 * @param {Object} data email and password
 */
export const login = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/signin', data);
        const authToken = result.data?.authToken;
        if (authToken) {
            setAuthToken(authToken);
        }
        return ({ success: true, message: "Logged in successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
 * Register
 * @param {Object} data the form data with email password name
 */
export const register = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/signup', data);
        const authToken = result.data?.tokens?.access?.token;
        if (authToken) {
            setAuthToken(authToken);
        }
        return ({ success: true, message: "Registered successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
 * Forgot password
 * @param {Object} data the form data with email password name
 */
export const forgotPassword = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/forgot-password', data);
        return ({ success: result.status === 200, message: result.data?.message });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
 * Reset password
 * @param {Object} data the form data with email password name
 */
export const resetPassword = async (data) => {
    try {
        const result = await axiosInstance.put('/auth/reset-password', data);
        return ({ success: result.status === 200, message: result.data?.message });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
 * Get profile
 */
export const getProfile = async () => {
    try {
        const result = await axiosInstance.get('/auth/me');
        const { data = null } = result;
        const appState = getRecoil(appConfigAtomState);
        setRecoil(appConfigAtomState, { ...appState, profile: data });
        return data;
    } catch (e) {
        return null
    }
}


/**
 * Update profile
 * @param {Object} data the form data
 */
export const updateProfile = async (data) => {
    try {
        const result = await axiosInstance.patch('/auth/me', data);
        return ({ success: result.status === 200, message: "Profile updated successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
 * Update Provider
 * @param {String} id the provider universityID
 * @param {Object} data the form data
 */
export const updateProviderUniversity = async (id, data) => {
    try {
        const result = await axiosInstance.patch(`/university/update/${id}`, data);
        return ({ success: result.status === 200, message: "Profile updated successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}
