import * as React from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider } from '@mui/material';
import './revisionBox.scss';
import { translateThis } from '../../../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';


const RevisionBox = ({ questions = [], qnIndex, onQnNumberClick, onEndRevision }) => {
    const navigate = useNavigate()
    return (
        <>
            <Box className='RevisionBox-wrapper f-wrp'>
                <div className='each-box-items button-wrp f-wrp'>
                    <Button onClick={() => onEndRevision()} className='end-button'>{translateThis('End revision')}</Button>
                </div>
                <Divider />
                <div className='each-box-items f-wrp'>
                    <p><b>{translateThis('Questions answered')}:</b> {questions.filter((q) => q.answerId).length}/{questions.length}</p>
                </div>
                <Divider />
                <div className='each-box-items f-wrp'>
                    <div className='color-ref-box'>
                        <h4>{translateThis('Color references')}</h4>
                        <ul className='reference-list'>
                            <li className='answered'><p>{translateThis('Correct')}</p></li>
                            <li className='Incorrect'><p>{translateThis('Incorrect')}</p></li>
                            {/* <li className='unanswered'><p>{translateThis('Not seen')}</p></li> */}
                            <li className='omitted'><p>{translateThis('Omitted')}</p></li>
                        </ul>
                    </div>
                </div>

                <div className='each-box-items f-wrp'>
                    <div className='color-ref-box'>
                        <h4>{translateThis('Question charter')}</h4>
                        <ul className='question-items'>
                            {questions.map((q, i) => <li key={q._id} className={`${qnIndex === i ? 'active' : ''} questionNumber ${q.answerId === q.actualAnswerId ? 'answered' :
                                (q.answerId) ? 'Incorrect' : 'omitted'}`}>
                                <Button onClick={() => onQnNumberClick(i)}>{i + 1}</Button>
                            </li>)}
                        </ul>
                    </div>
                </div>

            </Box>
            <Box className='RevisionBox-wrapper revisionBox-Mob f-wrp'>
                <div className='ref-info-box'>
                    <div className='each-box-items f-wrp'>
                        <p><b>{translateThis('Questions answered')}:</b> {questions.filter((q) => q.answerId).length}/{questions.length}</p>
                    </div>
                    <div className='each-box-items f-wrp'>
                        <div className='color-ref-box'>
                            <h4>{translateThis('Color references')}</h4>
                            <ul className='reference-list'>
                                <li className='answered'><p>{translateThis('Correct')}</p></li>
                                <li className='Incorrect'><p>{translateThis('Incorrect')}</p></li>
                                {/* <li className='unanswered'><p>{translateThis('Not seen')}</p></li> */}
                                <li className='omitted'><p>{translateThis('Omitted')}</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='each-box-items f-wrp'>
                    <div className='color-ref-box'>
                        <h4>{translateThis('Question charter')}</h4>
                        <ul className='question-items'>
                            {questions.map((q, i) => <li key={q._id} className={`${qnIndex === i ? 'active' : ''} questionNumber ${q.answerId === q.actualAnswerId ? 'answered' :
                                (q.answerId) ? 'Incorrect' : 'omitted'}`}>
                                <Button onClick={() => onQnNumberClick(i)}>{i + 1}</Button>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </Box>
        </>
    )
}
RevisionBox.propTypes = {
    questions: propTypes.array,
    qnIndex: propTypes.any,
    onQnNumberClick: propTypes.any,
    onEndRevision: propTypes.any,
}

export default RevisionBox;