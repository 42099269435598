import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { translateThis } from '../../../../helpers/language.helper';
import AppLayout from '../../../../layouts/app/AppLayout';
import UserDrawer from '../../../../components/drawer/drawer';
import TextInput from '../../../../components/inputs/textInput/textInput';
import ApiCaller from '../../../../services/api/general';
import { getCurrentFacilityURL, getUniqueId } from '../../../../helpers/helper.functions';
import { showSnackbar } from '../../../../components/snackbar/index';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';

const EditTopicPage = () => {
    const location = useLocation();
    const TopicData = location.state?.data || {};
    const navigate = useNavigate();
    const apiCaller = new ApiCaller('topics');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formKey, setFormKey] = useState(getUniqueId('topic'));
    const [topic, setTopic] = useState(TopicData?.topicName || '');
    const [systems, setSystems] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState(TopicData?.systems ? TopicData?.systems.map((s) => s.systemId) : []);

    /**
    the legendary use effect function
    This will run very first on render
    */
    useEffect(() => {
        getSystems()
        // eslint-disable-next-line
    }, []);

    // get systems from api
    const getSystems = () => {
        setIsLoading(true);
        const apiCaller = new ApiCaller('systems')
        apiCaller.getList()
            .then((data) => {
                setSystems(data?.result);
            })
            .catch(() => showSnackbar().failure('Failed to fetch results'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (topic) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            name: topic,
            topicId: TopicData?.id,
            systemIds: selectedSystems
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.putData('', postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Topic updated successfully!")
                    navigate(`/${getCurrentFacilityURL()}/manage`)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    /**
     * On select a system
     * @param {String} id 
     */
    const onSelectSystem = (id) => {
        setSelectedSystems((prv) => {
            if (prv.includes(id)) {
                prv = prv.filter((p) => p !== id)
            } else {
                prv.push(id)
            }
            return [...prv]
        })

    }

    /**
     * The signup form elements
     */

    return (
        <AppLayout
            pageName={"Edit topic"}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <div className='main-category-page'>
                        <div className='main-category-header'>
                            <h2>{translateThis("Edit Topic")}</h2>
                            <HeaderRightUserInfo />
                        </div>
                    </div>
                    <Stack className='standard-form questionForm' spacing={3}>
                        <form key={formKey}>
                            <div className='eachQn-box f-wrp'>
                                <h3>{translateThis('Edit topic')}</h3>
                                <TextInput
                                    key={`createQN_Question_Creator_add_title`}
                                    name={`createQN_Question_Creator_add_title`}
                                    id={`createQN_Question_Creator_add_title`}
                                    value={topic}
                                    translationKey={translateThis('Write your topic title here')}
                                    type='text'
                                    placeholder={translateThis('Write your topic title here')}
                                    className='custom-textfield maxWidth_550'

                                    getValue={(_, value) => {
                                        setTopic(value)
                                    }}
                                    useDefaultValidation
                                />

                            </div>



                            <div className='eachQn-box f-wrp'>
                                <h3>{translateThis('Assign systems to this topic')}</h3>
                                <br />
                                <div className='each-topic-sec f-wrp'>
                                    <div className='topic-items-list'>
                                        <h6>{translateThis('Systems')}</h6>
                                        {systems && <ul>
                                            {systems.map((system) => (
                                                // eslint-disable-next-line
                                                <li onClick={() => onSelectSystem(system.id)} key={system.systemName} className={`${selectedSystems.includes(system.id) ? 'active' : ''}`}>
                                                    <Typography variant='div' >
                                                        <p>{system.systemName}</p></Typography>
                                                </li>
                                            ))}
                                        </ul>}
                                    </div>
                                </div>
                            </div>

                            <div className='button-sec f-wrp'>
                                <div className='lft-btn-sec'>
                                    <Button onClick={() => navigate(`/${getCurrentFacilityURL()}/manage`)} className='cancel-btn'>{translateThis("cancel")}</Button>
                                </div>
                                <div className='ryt-btn-sec'>

                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='fill-btn'
                                        onClick={onSubmit}>
                                        {translateThis("Update")}
                                    </LoadingButton>

                                </div>
                            </div>
                        </form>
                    </Stack>
                </Box>
            </Box>
        </AppLayout>
    )
}


EditTopicPage.propTypes = {};

export default EditTopicPage;