import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './TestimonialsList.scss';
import AppIcons from "../../../assets/images/icons";





function TestimonialsList() {


  const TestimonialsListing = [
    {
      testimg: AppIcons.profileGirl,
      testdiscription: <p>Hiii good afternoon, It has been a great joy to be able to take the tests on your platform. It has been wonderful to be part of your great family. The questions, the corrections, and the explanations for each one have truly delighted me. Thanks a lot 😊</p>,
      name: 'Lisa C.',
      star: AppIcons.starBlk,
    },
    {
      testimg: AppIcons.profile,
      testdiscription: <p>XMed Pro changed the way I approach my medical studies. Amazing!</p>,
      name: 'Gabriel Mitchell',
      star: AppIcons.starBlk,
    },
    {
      testimg: AppIcons.profile,
      testdiscription: <p>We tried it and it seems very similar to real exam, Very good questions and explanations.</p>,
      name: 'PIMG member',
      star: AppIcons.starBlk,
    },
    {
      testimg: AppIcons.profile,
      testdiscription: <p>I was doing some questions last night and definitely loved it!!! Highly recommended to anyone who wants to try another question bank as a complementary resource!!</p>,
      name: 'Michel Daud',
      star: AppIcons.starBlk,
    },
  ];




  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        modules={[Navigation]}
        navigation
        loop
        // autoplay={{
        //   delay: 7000,
        //   disableOnInteraction: false,
        // }}
        // pagination={{
        //   dynamicBullets: false,
        //   dynamicMainBullets: 0,
        // }}
        className='Gentestimonial-item-wrp'
        breakpoints={{
          10: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}>

        {TestimonialsListing.map((element, key) => (
          <SwiperSlide key={key} className="each-testimonials-items f-wrp">
            <div className="each-testi-box f-wrp">
              <div className="testimonials-prof-img f-wrp">
                <span className="prof-img">
                  <img src={element.testimg} alt="testimonial" />
                </span>
                <div className="profile-info">
                  <h4>{element.name}</h4>
                  <ul className="rating">
                    <li><img src={element.star} alt="star" /></li>
                    <li><img src={element.star} alt="star" /></li>
                    <li><img src={element.star} alt="star" /></li>
                    <li><img src={element.star} alt="star" /></li>
                    <li><img src={element.star} alt="star" /></li>
                  </ul>
                </div>
              </div>
              <div className="test-con-wrp f-wrp">
                <h2>{element.testdiscription}</h2>
                {/* <b>{element.names}</b> */}
                {/* <p>{element.place}</p> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

    </>
  );
}

export default TestimonialsList;