import { useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';


// components

import { showSnackbar } from '../../../../components/snackbar';
import RichTextEditor from '../../../../components/rich-text-editor';

// Helpers
import { getAuthDetailsByKey } from '../../../../helpers/auth.helper';
import { translateThis } from '../../../../helpers/language.helper';

import './index.scss';
// Services
import ApiCaller from '../../../../services/api/general';


// ........................................................................




const CreateReport = (props) => {
    const apiCaller = new ApiCaller('inbox');
    const {
        optionalReason = '',
        question,
        onClose = () => null
    } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        message: `{"blocks":[{"key":"35ipj","text":"${optionalReason}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        subject: `${question?.question}`
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.email && formData.subject) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmitForm = () => {
        const postData = {
            email: getAuthDetailsByKey('email'),
            phoneNumber: getAuthDetailsByKey('phone'),
            name: `${getAuthDetailsByKey('name')} ${getAuthDetailsByKey('lastName')}`,
            message: formData.message,
            type: 'question-report',
            status: 'pending',
            subject: formData.subject,
            additionalInfo: {
                referenceId: question._id
            }
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            const successMessage = "Question reported successfully"
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(successMessage)
                    onClose();
                } else {
                    showSnackbar().failure(data.message)
                }
            }).finally(() => setIsSubmitting(false));
        }
    };


    return (
        <>
            {question &&
                <div className='create-report f-wrp'>
                    <Stack className='standard-form form-full-width panel-form' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '0' }}>
                                <div className='popup-head f-wrp'>
                                    <span style={{ fontWeight: '700' }}>{translateThis("Report question")}</span>
                                    <p>{question?.questionNumber ? `#${question?.questionNumber}` : ''} {optionalReason}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>                            
                                <RichTextEditor defaultValue={`{"blocks":[{"key":"35ipj","text":"${optionalReason}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`} onChange={(value) => setFormValue('message', JSON.stringify(value))} />
                            </Grid>
                        </Grid>
                    </Stack>

                    <br />

                    <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                        <div className='lft-btn-sec'>
                            <Button onClick={onClose} className='cancel-btn'>{translateThis("cancel")}</Button>
                        </div>
                        <div className='ryt-btn-sec pln-btn-style'>

                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmitForm}>
                                {translateThis("submit")}
                            </LoadingButton>

                        </div>
                    </div>
                </div>}
        </>
    );
}

CreateReport.propTypes = {
    onClose: propTypes.func,
    question: propTypes.any,
    optionalReason: propTypes.string,
}

export default CreateReport;











