import React from 'react';
import { Box } from '@mui/material';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';
import AdminProfile from './formPages/profile.form';
import TabLayout from '../../../components/tabs/index';
import ProviderAccountDesign from './formPages/settings';
import { accessGuard } from '../../../helpers/auth.helper';
import HeaderRightUserInfo from '../components/headerRightUserInfo';

export default function AdminProfilePage() {

    const tabNames = [
        {name: <b>{translateThis("Profile")}</b>, icon:'', hasAccess: true},
         {name:<b>{translateThis("Settings")}</b>, icon:'', hasAccess: accessGuard('designSettings')}  
        ].filter((d)=>d.hasAccess);
    const tabContents = [
        <span key={'profile'}><AdminProfile /></span>,  
        <span key={'to_Subjects'}><ProviderAccountDesign /></span>,
    ];

    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>

                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Profile")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content'>
                                <div className='profileBox-info maxWidth_767'>
                                <TabLayout
                                        tabContents={tabContents}
                                        tabNames={tabNames}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}
