/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import ContextMenu from '../dropdowns/contextMenu';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

const TextHighlighter = ({ id, children }) => {
    const [highlightedTexts, setHighlightedTexts] = useState(
        JSON.parse(localStorage.getItem(id)) || []
    );

    useEffect(() => {
        localStorage.setItem(id, JSON.stringify(highlightedTexts));
        highlightSelectedTexts();
    }, [highlightedTexts]);

    /**
     * Removes the string from array
     * @param {String} str 
     * @param {Array} arr 
     * @returns 
     */
    const removeStringFromArray = (str, arr) => {
        if (arr.some(element => element.includes(str))) {
            return arr.map(element => {
                if (element.includes(str)) {
                    return element.replace(str, '').trim();
                } else {
                    return element;
                }
            }).filter(element => element !== '');
        } else {
            arr.push(str);
            return arr;
        }
    }

    const handleSelection = (e) => {
        const selection = window.getSelection()
        const text = selection.toString()
        if (text.length > 2) {
            const alreadyAdded = JSON.parse(localStorage.getItem(id)) || []
            const isAlreadyAdded = alreadyAdded.includes(text)
            if (isAlreadyAdded) {
                try {
                    // Remove highlight
                    const elements = document.querySelectorAll(`#highlighter-${id} span[style*="yellow"][data-text="${text}"]`)
                    elements.forEach((element) => {
                        element.style.backgroundColor = ''
                        element.removeAttribute('style')
                    })
                    setHighlightedTexts((prevHighlightedTexts) => {
                        const filtered = prevHighlightedTexts.filter((str) => !str.includes(text))
                        localStorage.setItem(id, JSON.stringify(filtered))
                        highlightSelectedTexts(filtered)
                        return filtered
                    })
                } catch (e) {
                    // console.error(e);
                }

            } else {
                try {
                    const range = selection.getRangeAt(0);
                    const textNode = range.startContainer;
                    if (textNode.nodeType === Node.TEXT_NODE) {
                        const text = textNode.textContent.substring(range.startOffset, range.endOffset);
                        const newNode = document.createElement('span');
                        newNode.style.backgroundColor = 'yellow';
                        newNode.dataset.text = text;
                        newNode.appendChild(document.createTextNode(text));

                        // Create a new range that only includes the text node
                        const newRange = document.createRange();
                        newRange.selectNodeContents(textNode);

                        // Surround the text node with the new node
                        newRange.surroundContents(newNode);
                    }
                    setHighlightedTexts((prevHighlightedTexts) => {
                        const filtered = prevHighlightedTexts.filter((str) => !str.includes(text));
                        const arr = [...filtered, text];
                        localStorage.setItem(id, JSON.stringify(arr));
                        highlightSelectedTexts(arr);
                        return arr;
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    const highlightSelectedTexts = (arr = []) => {
        const divArr = document.querySelectorAll(`#highlighter-${id} .readonly-editor`)
        let wordsToFindArr = JSON.parse(localStorage.getItem(id)) || []
        wordsToFindArr = [...new Set([...wordsToFindArr, ...arr])] // use Set to remove duplicates

        if (wordsToFindArr) {
            divArr.forEach((div) => {
                const walker = document.createTreeWalker(div, NodeFilter.SHOW_TEXT_NODE, null, false)
                let node

                while ((node = walker.nextNode()) !== null) {
                    if (!node.parentNode) continue // skip if node has no parent

                    let text = node.textContent

                    wordsToFindArr.forEach((sentence) => {
                        const regex = new RegExp(escapeRegExp(sentence), 'gi')
                        text = text.replace(regex, (match) => {
                            return `<span style="background-color: yellow">${match}</span>`
                        })
                    })

                    const newNode = document.createElement('span')
                    newNode.innerHTML = text
                    node.parentNode.replaceChild(newNode, node)
                }
            })
        }

        function escapeRegExp(str) {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
        }
    }

    const highlightSelectedTextsBACKTWO = () => {
        const divArr = document.querySelectorAll('.readonly-editor'); // select all divs with class "my-class"
        const wordsToFind = JSON.parse(localStorage.getItem(id)) || []; // array of words to find

        // Remove existing highlights
        divArr.forEach((div) => {
            const highlightedSpans = div.querySelectorAll('span[style*="background-color: yellow"]');
            highlightedSpans.forEach((span) => {
                const text = span.textContent;
                span.parentNode.replaceChild(document.createTextNode(text), span);
            });
        });

        if (wordsToFind && wordsToFind.length > 0) {
            divArr.forEach((div) => {
                const text = div.textContent;
                let newText = text;

                wordsToFind.forEach((sentence) => {
                    const regex = new RegExp(escapeRegExp(sentence), 'gi');
                    newText = newText.replace(regex, (match) => {
                        return `<span style="background-color: yellow">${match}</span>`;
                    });
                });

                div.innerHTML = newText;
            });
        }

        function escapeRegExp(str) {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }
        // setHighlightedTexts((prevHighlightedTexts) => [...prevHighlightedTexts])
    }


    const highlightSelectedTextsBACKUP = () => {
        const divArr = document.querySelectorAll(`#highlighter-${id} .readonly-editor`); // select all divs with class "my-class"
        const wordsToFind = JSON.parse(localStorage.getItem(id)) || []; // array of words to find

        // Remove existing highlights
        divArr.forEach((div) => {
            const highlightedSpans = div.querySelectorAll('span[style*="background-color: yellow"]');
            highlightedSpans.forEach((span) => {
                span.outerHTML = span.textContent; // remove the span element
            });
        });

        if (wordsToFind && wordsToFind.length > 0) {
            divArr.forEach((div) => {
                const walker = document.createTreeWalker(div, NodeFilter.SHOW_TEXT, null, false);
                let node;

                while ((node = walker.nextNode())) {
                    let text = node.textContent;

                    wordsToFind.forEach((sentence) => {
                        const regex = new RegExp(escapeRegExp(sentence), 'gi');
                        text = text.replace(regex, (match) => {
                            return `<span style="background-color: yellow">${match}</span>`;
                        });
                    });

                    node.parentNode.innerHTML = node.parentNode.textContent.replace(node.textContent, text);
                }
            });

            function escapeRegExp(str) {
                return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            }
            // setHighlightedTexts((prevHighlightedTexts) => [...prevHighlightedTexts])
        }
    }

    

    const clearAllSelection = () => {
        try {
            localStorage.removeItem(id);
            setHighlightedTexts([])
            highlightSelectedTexts();
        } catch (e) {
        }

    }

    return (
        <>
            <ContextMenuTrigger  holdToDisplay={4000} id={`context-${id}`}>
                <div
                id={`highlighter-${id}`}
                    // onContextMenuCapture={handleContextMenu}
                    // onContextMenu={handleContextMenu}
                    onSelect={handleSelection}
                    onMouseUp={handleSelection}
                    // onClick={()=>setShowMenu(false)}
                    // onDoubleClick={handleSelection}
                    style={{ userSelect: 'text' }}
                >{children}

                </div>
            </ContextMenuTrigger>
            <ContextMenu hideOnLeave id={`context-${id}`}>
                <MenuItem data={{ foo: 'bar' }} onClick={clearAllSelection}>
                    <span className='context-pop' >Clear all selections</span>
                </MenuItem>

            </ContextMenu>
        </>

    );
};

export default TextHighlighter;