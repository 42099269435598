import React from 'react';
// import './subscriptionPopup.scss';
import { translateThis } from '../../../../../helpers/language.helper';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';

const ExamCreatedSuccessfully = () => { 
    return (
        <>
            <div className='subscription-popup-wrp f-wrp'>
                <span style={{maxWidth: "250px"}}>{SVGIcons.ExamCreated()}</span>
                <h2>{translateThis("Exam created successfully!")}</h2>
                <p>{translateThis("The exam is ready for users to dive in and showcase their skills.")}</p>
            </div>
        </>
    )
}

export default ExamCreatedSuccessfully;