import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { DATE_FORMAT } from '../../constants';



const ParentCommunicationFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp parentCommunicationView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />

                <h2 style={{ marginBottom: '0' }}>DAILY COMMUNICATION</h2>
                <h3>Parent communication</h3>
                <div className='gap' />
                <div className="flex-box padding_btm15">
                    <p>Child name: <b>{child?.firstName}&nbsp;{child?.lastName}</b></p>
                </div>
                <div className="flex-box padding_btm15">
                    <p>Communication date: <b>{dayjs(data.logDate).format(DATE_FORMAT)}</b></p>
                    <p>Submission date: <b>{dayjs(data.submissionDate).format(DATE_FORMAT)}</b></p>
                </div>
                <div className='gap' />
                <div className="auto-flex padding_btm15">
                    <span>Incidents before childcare:</span>
                    <p><b>{answerGetter('incidentsAtHome', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>Food:</span>
                    <p><b>{answerGetter('food', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>Diaper change:</span>
                    <p><b>{answerGetter('diaperChange', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>Mood:</span>
                    <p><b>{answerGetter('mood', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>Sleep:</span>
                    <p><b><b>{answerGetter('natureOfSleep', formData)?.answer || '-'} </b></b></p>

                </div>
                <div className="auto-flex">
                    <span>Wake up time: </span>
                    <p><b>{answerGetter('wakeUpTime', formData)?.answer || '-'} </b></p>

                </div>
                <div className="basic-details padding_btm15">
                    <div className='gap' />
                    <div className="auto-flex padding_btm15">
                        <span>Comments:</span>
                        <p><b>{answerGetter('comments', formData)?.answer || '-'}</b></p>
                    </div>
                </div>


                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                <p>Parent | Guardian signature</p>
                                <p>Submission date and time: <b>{data.additionalInfo?.parentSignedDate}</b></p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                <p>Provider signature</p>
                                <p>Submission date and time: <b>{data.additionalInfo?.providerSignedDate}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap' />
            </div>
        </>
    )
}

ParentCommunicationFormView.propTypes = {
    data: PropTypes.any
}
export default ParentCommunicationFormView;