import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
// import CountdownTimer from "react-component-countdown-timer";
import ReactPlayer from 'react-player/youtube';
import AppLayout from '../../layouts/app/AppLayout';
import './home.scss';
import { translateThis } from '../../helpers/language.helper';
import AppIcons from '../../assets/images/icons';
import DrawerAppBar from './mainHeader/mainHeaderDrawer';
import TestimonialsList from './TestimonialsList/TestimonialsList';
import ContactUsForm from './contactForm/contactUsForm';
import MainFooter from './mainFooter';
import SVGIcons from '../../assets/images/icons/svgIcons';
// import XmedTutorial from '../../assets/images/XmedTutorial.mp4';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';



const RepDomHomePage = () => {

    // custom home functions here
    const pageName = "Dominicana";

    const [windowSize, setWindowSize] = useState([
        window.innerHeight,
    ]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const offerItems = [
        {
            image: AppIcons.landingOffer7,
            label: 'Simulación de examen',
            content: 'Herramientas interactivas de preparación para exámenes.'
        },
        {
            image: AppIcons.landingOffer8,
            label: 'Contenido por expertos',
            content: 'Conocimiento seleccionado por médicos para médicos.'
        },
        {
            image: AppIcons.landingOffer9,
            label: 'Nuevo contenido',
            content: 'Hasta más de 100 nuevas preguntas cada mes.'
        },
        {
            image: AppIcons.landingOffer4,
            label: 'Optimizado para móviles',
            content: 'Aprendizaje sin interrupciones, en cualquier momento y en cualquier lugar.'
        },
        {
            image: AppIcons.landingOffer5,
            label: 'Seguimiento de avance',
            content: 'Seguimiento personalizado de tu progreso y rendimiento.'
        },
        {
            image: AppIcons.landingOffer10,
            label: 'Posicionamiento entre pares',
            content: 'Medi cómo performas con tus pares'
        },
    ];

    const planItems = [
        {
            class: 'current-plan',
            promo: 'Promo pre-lanzamiento',
            planPer: 'por mes',
            oldPrice: '$59',
            newPrice: '$29',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'MENSUAL',
            features: <ul><li>{translateThis('Acceso a todas las preguntas y características.')}</li></ul>,
            button: 'Subscríbete',
        },
        {
            class: 'current-plan',
            promo: 'Promo pre-lanzamiento',
            planPer: 'por trismestre',
            oldPrice: '$139',
            newPrice: '$59',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'TRIMESTRAL',
            features: <ul><li>{translateThis('Acceso a todas las preguntas y características.')}</li></ul>,
            button: 'Subscríbete',
        },
        {
            class: 'current-plan',
            promo: 'Promo pre-lanzamiento',
            planPer: 'por semestre',
            oldPrice: '$249',
            newPrice: '$99',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'SEMESTRAL',
            features: <ul><li>{translateThis('Acceso a todas las preguntas y características.')}</li></ul>,
            button: 'Subscríbete',
        },
        {
            class: 'best-plan',
            promo: 'Promo pre-lanzamiento',
            planPer: 'por año',
            oldPrice: '$319',
            newPrice: '$169',
            validity: 'Promo from Feb 16th to March 15th',
            period: 'ANUAL',
            features: <ul><li>{translateThis('Acceso a todas las preguntas y características.')}</li></ul>,
            button: 'Subscríbete',
        },
    ];

    const facilityURL = getCurrentFacilityURL()




    return (
        <AppLayout
            pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName='home-main-wrp RepDomLanding-main-wrp f-wrp'
        >
            <div className='content-main-wrapper f-wrp'>
                <div className='landing-main-nav f-wrp'>
                    <DrawerAppBar />
                </div>
                <div className='main-banner-wrp f-wrp' style={{ minHeight: `${windowSize}px` }}>
                    <span className='banner-BG'>
                        <img className='bannerPC' src={AppIcons.RDlandingBanner} alt='X-med banner' />
                        <img className='bannerMOB' src={AppIcons.RDlandingBanner} alt='X-med banner' />
                    </span>
                    <div className='banner-con-sec f-wrp'>
                        <div className='banner-con-box'>
                            <h1>{translateThis('Objectivo: ENURM Dominicana')}</h1>
                            {/* <h4>{translateThis('XMed is the smart move')}</h4> */}
                            <p>{translateThis('Mantente dos pasos adelante y únete a la mejor comunidad médica en')}<b>{translateThis('República Dominicana. XMed es el camino inteligente.')}</b></p>
                            <Link to={`/${facilityURL}/my-portal`}>{translateThis('Access XMed')}</Link>
                        </div>
                    </div>
                    <div className='scroll-wrapper f-wrp'>
                        <p>{translateThis('RDaboutText')}</p>
                    </div>
                </div>

                <div className='about-main-wrp f-wrp' id='about'>
                    <div className='about-section-wrp hide f-wrp'>
                        <div className='container'>
                            <div className='about-main-header f-wrp'>
                                <h1>{translateThis('RDAbout')}</h1>
                                <p>{translateThis('RDaboutText2')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='offer-section-wrp f-wrp'>
                        <div className='container'>
                            <div className='offer-main-header f-wrp'>
                                <h1>{translateThis('RDofferHead')}</h1>
                                <p>{translateThis('RDofferText')}</p>
                            </div>
                            <div className='offer-con-wrp f-wrp'>
                                <div className='row'>
                                    {offerItems.map((element, key) => (
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12' key={key}>
                                            <div className='each-offer-box f-wrp'>
                                                <span><img src={element.image} alt='offer' /></span>
                                                <h4>{translateThis(element.label)}</h4>
                                                <p>{translateThis(element.content)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='appDemo-wrapper f-wrp' id='características'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <div className='app-demo-con f-wrp'>
                                    <div className='appDemo-main-header f-wrp'>
                                        <h1>{translateThis('RDappDemoHead')}</h1>
                                        <p>{translateThis('RDappDemoText')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                                <div className='video-wrp f-wrp'>
                                    <span>
                                        {/* <ReactPlayer width={'100%'} height={'100%'} file playsinline controls url={'https://youtu.be/xBICEAPX9og'} /> */}
                                        <ReactPlayer
                                            className='react-player'
                                            url='https://youtu.be/xBICEAPX9og'
                                            width='100%'
                                            height='calc(100vh - 140px)'
                                            style={{ minHeight: '350px', maxHeight: '600px' }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='testimonial-main-wrp f-wrp' id='testimonios'>
                    <div className='testimonials-head f-wrp'>
                        <h1>{translateThis('Lo que dicen nuestros estudiantes')}</h1>
                    </div>
                    <div className='testimonial-wrapper f-wrp'>
                        <div className='testimonials-section-wrp f-wrp'>
                            <div className='testimonials-main-header f-wrp'>
                                {/* <h1>{translateThis('Lo que dicen nuestros estudiantes')}</h1> */}
                            </div>
                        </div>
                        <div className='testimonial-sec f-wrp'>
                            <TestimonialsList />
                        </div>
                    </div>
                </div>
                <div className='plan-section-wrp f-wrp' id='plans'>
                    <div className='container'>
                        <div className='plan-main-header f-wrp'>
                            <h1>{translateThis('RDChoose your plan')}</h1>
                            <p>{translateThis('RDplanText')}</p>
                        </div>
                        <div className='plans-sec-wrp f-wrp'>
                            <div className='row'>
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    modules={[Navigation]}
                                    navigation
                                    loop
                                    className='childListForm'
                                    breakpoints={{
                                        300: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        700: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        900: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1200: {
                                            slidesPerView: 4,
                                            spaceBetween: 20,
                                        },
                                    }}>
                                    {planItems.map((element, key) => (
                                        <SwiperSlide key={key} className="f-wrp">
                                            <div className={`each-plan-box f-wrp ${element.class} `}>
                                                <div className='promo-box'>
                                                    <span>{translateThis(element.promo)}</span>
                                                </div>
                                                <div className='price-box'>
                                                    <h3>{element.oldPrice}</h3> <h1>{element.newPrice}</h1>
                                                    <p>{translateThis(element.planPer)}</p>
                                                    {/* <span>{translateThis(element.validity)}</span> */}
                                                </div>

                                                <div className='plan-details'>
                                                    <h3>{translateThis(element.period)}</h3>
                                                    {element.features}
                                                </div>
                                                <div className='foot-btn-sec'>
                                                    <Button className='plan-btn'><span>{translateThis(element.button)}</span></Button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
                <div id='contact' className='f-wrp' />
                <div className='contact-section-blk f-wrp' id='contacto'>
                    <div className='container'>
                        <div className='contact-con-block f-wrp'>
                            <div className='row'>
                                <div className='col-lg-7 col-md-6 col-sm-12 col-xs-12'>
                                    <div className='contact-con-box f-wrp'>
                                        <span className='whiteLogo'><img src={AppIcons.whiteLogo} alt='logo' /></span>
                                        <h3>{translateThis('Ponte en contacto')}</h3>
                                        <h1>{translateThis('¿Listo para dar el próximo paso?')}</h1>
                                        <p>{translateThis('¡Contáctanos! Estamos aquí para servir a la comunidad médica en la República Dominicana.')}</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-6 col-sm-12 col-xs-12'>
                                    <div className='contact-form-block f-wrp'>
                                        <ContactUsForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='faq-section-wrapper f-wrp' id="preguntas-frecuentes">
                    <div className='faq-main-sec f-wrp'>
                        <div className='container'>
                            <div className='faq-main-header f-wrp'>
                                <h1>{translateThis('RDFAQ’s')}</h1>
                                <p>{translateThis('RDfaqText')}</p>
                            </div>
                            <div className='faq-con-wrp f-wrp'>
                                <div className='faq-con-list f-wrp'>
                                    <ul>
                                        <li><Link to={`/${facilityURL}/preguntas-frecuentes`}>{translateThis('¿Cómo mejora XMed Pro mi experiencia de aprendizaje?')} <SVGIcons.RightArrowIcon /></Link></li>
                                        <li><Link to={`/${facilityURL}/preguntas-frecuentes`}>{translateThis('¿Qué diferencia a XMed Pro de otras plataformas de educación médica?')} <SVGIcons.RightArrowIcon /></Link></li>
                                        <li><Link to={`/${facilityURL}/preguntas-frecuentes`}>{translateThis('¿Puedo acceder a XMed Pro en diferentes dispositivos?')} <SVGIcons.RightArrowIcon /></Link></li>
                                    </ul>
                                    <Link to={`/${facilityURL}/preguntas-frecuentes`} className='faq-plan-btn'>{translateThis('Explora más preguntas')} <SVGIcons.RightArrowIcon /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='main-foot-wrp f-wrp'>
                    <MainFooter />
                </div>

            </div>
        </AppLayout>
    )
}

export default RepDomHomePage;