import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import AppLayout from '../../layouts/app/AppLayout';
import './home.scss';
import { translateThis } from '../../helpers/language.helper';
import AppIcons from '../../assets/images/icons';
import DrawerAppBar from './mainHeader/mainHeaderDrawer';
import TestimonialsList from './TestimonialsList/TestimonialsList';
import ContactUsForm from './contactForm/contactUsForm';
import MainFooter from './mainFooter';
import SVGIcons from '../../assets/images/icons/svgIcons';
import ChatBot from '../../components/chatBot/chatBot';




const HomePageGeneral = () => {

    // custom home functions here
    const pageName = "Home";

    const [windowSize, setWindowSize] = useState([
        window.innerHeight,
    ]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const countryListItems = [
        {
            link: '/usmle',
            image: AppIcons.usa,
            label: 'United States \nUSMLE',

        },
        {
            link: '/dominicana',
            image: AppIcons.repDom,
            label: 'Republica Dominicana \nENURM',

        },
        {
            link: '#',
            image: AppIcons.argentina,
            label: 'Argentina \nEUNACOM',
            content: '(Coming soon)'
        },
        {
            link: '#',
            image: AppIcons.peru,
            label: 'Perú \nENAM',
            content: '(Coming soon)'
        },
        {
            link: '#',
            image: AppIcons.Bolivia,
            label: 'Bolivia \nENAM',
            content: '(Coming soon)'
        },
        {
            link: '#',
            image: AppIcons.Ecuador,
            label: 'Ecuador \nENAM',
            content: '(Coming soon)'
        },

    ];


    const [active, setActive] = useState('o1');
    return (
        <AppLayout
            pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName='home-main-wrp homeGeneral-main-wrp f-wrp'
        >
            <div className='content-main-wrapper f-wrp'>
                <div className='landing-main-nav f-wrp'>
                    <DrawerAppBar />
                </div>
                <div className='main-banner-wrp f-wrp' style={{ minHeight: `${windowSize}px` }}>
                    <span className='banner-BG'>
                        <img className='bannerPC' src={AppIcons.generalLandingBanner} alt='X-med banner' />
                        <img className='bannerMOB' src={AppIcons.generalLandingBanner} alt='X-med banner' />
                    </span>

                    <span className='bannerFoot f-wrp' style={{ minHeight: `${windowSize - 100}px` }}>
                        {/* <img src={AppIcons.bannerFooter} alt='footer-banner' /> */}
                        <div className='banner-con-sec f-wrp'>
                            <div className='banner-con-box'>
                                <h1>{translateThis('GenBannerMainText')}</h1>
                                <p>{translateThis('GenBannerTitle')}</p>
                            </div>
                        </div>
                    </span>

                </div>

                <div className='xmed-countryList-wrp f-wrp'>
                    <div className='container'>
                        <div className='xmed-countryList-header f-wrp'>
                            <p>{translateThis('xmed-countryListText')}</p>
                            <h1>{translateThis('xmed-countryListTitle')}</h1>
                        </div>

                        <div className='xmed-countryList-mainWrp f-wrp'>
                            <div className='general-home-search'>
                                <input className='general-home-input' placeholder={translateThis('Buscar')} />
                            </div>
                            <div className='countryList-con-wrp f-wrp'>
                                <div className='row'>
                                    {countryListItems.map((element, key) => (
                                        <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12' key={key}>
                                            <Link to={element.link} className='countryList-box f-wrp'>
                                                <span className='icon-img'><img src={element.image} alt='country icon' /></span>
                                                <div className='list-con-box'>
                                                    <h4>{translateThis(element.label)} <span>{translateThis(element.content)}</span></h4>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='about-main-wrp f-wrp' id='about'>
                    <div className='container'>
                        <div className='about-con-wrp f-wrp'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='about-intro-img f-wrp'>
                                        <span><img src={AppIcons.aboutImg} alt='about' /></span>
                                    </div>
                                </div>
                                <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='about-con-box f-wrp'>
                                        <div className='each-about-box f-wrp'>
                                            <h5>{translateThis('About our platform')}</h5>
                                            <h1>{translateThis('abt-text1')} <span>{translateThis('abt-text2')}</span></h1>
                                            <p>{translateThis('abt-para')}</p>
                                        </div>
                                        <div className='abt-flex-box'>
                                            <div className='each-flex-box'>
                                                <span className='icon-img'><img src={AppIcons.abtGlobe} alt='country icon' /></span>
                                                <div className='flex-con-box'>
                                                    <h4>{translateThis('6+ Countries')}</h4>
                                                </div>
                                            </div>
                                            <div className='each-flex-box'>
                                                <span className='icon-img'><img src={AppIcons.abtClock} alt='country icon' /></span>
                                                <div className='flex-con-box'>
                                                    <h4>{translateThis('Expert curated content')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='abt-flex-list'>
                                            <ul>
                                                <li>{translateThis('Regular Updates but with longer text')}</li>
                                                <li>{translateThis('Affordable Pricing but with longer text')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='offer-main-wrapper f-wrp' id='services'>
                    <div className='container'>
                        <div className='offer-main-header f-wrp'>
                            <h1>{translateThis('GenOfferHead')}</h1>
                            <p>{translateThis('GenOfferText')}</p>
                        </div>
                        <div className='offer-main-content f-wrp'>
                            <div className='offer-flex-wrp f-wrp'>

                                <Link className={`each-offer-items ${active === 'o1' ? 'expanded' : 'closed'}`} onClick={() => setActive('o1')}>
                                    <div className='offer-lft-box'>
                                        <span className='icon-img'><img src={AppIcons.offerLaptop} alt='icon' /></span>
                                        <div className='list-con-box'>
                                            <h4>{translateThis('genSimulated exams')}</h4>
                                            <span>{translateThis('genSimulated exams text')}</span>
                                        </div>
                                        <span className='number'>
                                            {translateThis('01')}
                                        </span>
                                    </div>
                                    <ul>
                                        <li className='vertical-list o1'>
                                            <span className='number'>
                                                {translateThis('01')}
                                            </span>
                                            <h4>{translateThis('genSimulated exams')}</h4>
                                        </li>
                                    </ul>
                                </Link>


                                <Link className={`each-offer-items ${active === 'o2' ? 'expanded' : 'closed'}`} onClick={() => setActive('o2')}>
                                    <div className='offer-lft-box'>
                                        <span className='icon-img'><img src={AppIcons.landingOffer9} alt='icon' /></span>
                                        <div className='list-con-box'>
                                            <h4>{translateThis('New questions')}</h4>
                                            <span>{translateThis('Up to a 100+ new questions every month.')}</span>
                                        </div>
                                        <span className='number'>
                                            {translateThis('02')}
                                        </span>
                                    </div>
                                    <ul>
                                        <li className='vertical-list o2'>
                                            <span className='number'>
                                                {translateThis('02')}
                                            </span>
                                            <h4>{translateThis('genNew questions')}</h4>
                                        </li>
                                    </ul>
                                </Link>


                                <Link className={`each-offer-items ${active === 'o3' ? 'expanded' : 'closed'}`} onClick={() => setActive('o3')}>
                                    <div className='offer-lft-box'>
                                        <span className='icon-img'><img src={AppIcons.landingOffer4} alt='icon' /></span>
                                        <div className='list-con-box'>
                                            <h4>{translateThis('genMobile ready')}</h4>
                                            <span>{translateThis('Seamless learning, anytime, anywhere.')}</span>
                                        </div>
                                        <span className='number'>
                                            {translateThis('03')}
                                        </span>
                                    </div>
                                    <ul>
                                        <li className='vertical-list o3'>
                                            <span className='number'>
                                                {translateThis('03')}
                                            </span>
                                            <h4>{translateThis('genMobile ready')}</h4>
                                        </li>
                                    </ul>
                                </Link>

                                <ul>
                                    <li className='explore-box-list o4'>
                                        <div className='explore-box'>
                                            <span className='number'>
                                                {translateThis('04')}
                                            </span>
                                            <h4>{translateThis('Expert-curated content')}</h4>
                                            <p>{translateThis('genExpert ready text')}</p>
                                            <div className='btn-wrp'>
                                                <Link to={'/xmedpro/my-portal'}>{translateThis('Explore now')}</Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>


                                <Link className={`each-offer-items ${active === 'o5' ? 'expanded' : 'closed'}`} onClick={() => setActive('o5')}>
                                    <div className='offer-lft-box'>
                                        <span className='icon-img'><img src={AppIcons.landingOffer5} alt='icon' /></span>
                                        <div className='list-con-box'>
                                            <h4>{translateThis('Progress tracking')}</h4>
                                            <span>{translateThis('Personalized progress tracking.')}</span>
                                        </div>
                                        <span className='number'>
                                            {translateThis('05')}
                                        </span>
                                    </div>
                                    <ul>
                                        <li className='vertical-list o5'>
                                            <span className='number'>
                                                {translateThis('05')}
                                            </span>
                                            <h4>{translateThis('Progress tracking')}</h4>
                                        </li>
                                    </ul>
                                </Link>


                                <Link className={`each-offer-items ${active === 'o6' ? 'expanded' : 'closed'}`} onClick={() => setActive('o6')}>
                                    <div className='offer-lft-box'>
                                        <span className='icon-img'><img src={AppIcons.landingOffer10} alt='icon' /></span>
                                        <div className='list-con-box'>
                                            <h4>{translateThis('genPeer Ranking')}</h4>
                                            <span>{translateThis('Check how you rank versus your peers.')}</span>
                                        </div>
                                        <span className='number'>
                                            {translateThis('06')}
                                        </span>
                                    </div>
                                    <ul>
                                        <li className='vertical-list o6'>
                                            <span className='number'>
                                                {translateThis('06')}
                                            </span>
                                            <h4>{translateThis('genPeer Ranking')}</h4>
                                        </li>
                                    </ul>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='appDemo-main-wrapper f-wrp' id='demo'>
                    <div className='container'>
                        <div className='appDemo-main-header f-wrp'>
                            <div className='appDemo-lft-sec'>
                                <h1>{translateThis('App')}</h1>
                                <span><img src={AppIcons.appDemo} alt='country icon' /></span>
                            </div>
                            <div className='appDemo-ryt-sec'>
                                <p>{translateThis('AppText')}</p>
                                <h1>{translateThis('demo')}</h1>
                            </div>
                        </div>

                        <div className='appDemo-wrapper f-wrp'>
                            <div className='video-wrp f-wrp'>
                                <span>
                                    {/* <ReactPlayer width={'100%'} height={'100%'} file playsinline controls url={'https://youtu.be/xBICEAPX9og'} /> */}
                                    <ReactPlayer
                                        className='react-player'
                                        url='https://youtu.be/xBICEAPX9og'
                                        width='100%'
                                        height='calc(100vh - 120px)'
                                        style={{ minHeight: '350px', maxHeight: '600px' }}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='testimonial-main-wrp f-wrp' id='Testimony'>
                    <span className='testimony-bg-img'><img src={AppIcons.testimonialBGIcon} alt='testimonial icon' /></span>
                    <div className='container'>
                        <div className='testimonial-wrapper f-wrp'>
                            <div className='testimonials-section-wrp f-wrp'>
                                <div className='testimonials-main-header f-wrp'>
                                    <h1 className='left-head'>{translateThis('What')} <img src={AppIcons.whatImg} alt='Testimony icon' /></h1>
                                    <h1 className='right-head'>{translateThis('they say')}</h1>
                                </div>
                            </div>
                            <div className='testimonial-sec f-wrp'>
                                <TestimonialsList />
                            </div>
                        </div>
                    </div>
                </div>

                <div id='contact' className='f-wrp' />
                <div className='contact-section-blk f-wrp' id='Contact'>
                    <div className='container'>
                        <div className='contact-con-block f-wrp'>
                            <div className='row'>
                                <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='contact-form-block f-wrp'>
                                        <ContactUsForm />
                                    </div>
                                </div>
                                <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='contact-con-box f-wrp'>
                                        <h1>{translateThis('Get in touch')}</h1>
                                        <p>{translateThis('We believe your questions are the key to meaningful learning')}</p>
                                        <span><img src={AppIcons.regLogo} alt='logo' /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='faq-section-wrapper f-wrp' id="support">
                    <div className='container'>
                        <div className='faq-main-header f-wrp'>
                            <h1>{translateThis('FAQ’s')}</h1>
                            <p>{translateThis('faqText')}</p>
                        </div>
                        <div className='faq-con-wrp f-wrp'>
                            <div className='faq-con-list f-wrp'>
                                <ul>
                                    <li><Link to={'/xmedpro/faq'}>{translateThis('How does XMed Pro enhance my learning experience?')} <SVGIcons.RightArrowIcon /></Link></li>
                                    <li><Link to={'/xmedpro/faq'}>{translateThis('What sets XMed Pro apart from other medical education platforms?')} <SVGIcons.RightArrowIcon /></Link></li>
                                    <li><Link to={'/xmedpro/faq'}>{translateThis('Can I access XMed Pro on different devices?')} <SVGIcons.RightArrowIcon /></Link></li>
                                </ul>
                                <Link to={'/xmedpro/faq'} className='faq-plan-btn'>{translateThis('Explore more questions here')} <SVGIcons.RightArrowIcon /></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='main-foot-wrp f-wrp'>
                    <MainFooter />
                </div>
            </div>
            <ChatBot />
        </AppLayout>
    )
}

export default HomePageGeneral;