import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { getFileByKey } from '../../services/api/aws';
import AppIcons from '../../assets/images/icons';



const S3ImageViewer = (props) => {
    const { fileKey = '' } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [imgURL, setImgURL] = useState('');


    /**
     * Legendary useEffect function
     * Runs on every render once
     */
    useEffect(() => {
        if (fileKey) {
            getImg()
        }
        return () => {
            setImgURL('')
        };
        // eslint-disable-next-line
    }, [fileKey]);

    /**
     * Gets the image URL from server
     * @returns Void
     */
    const getImg = () => {
        if (fileKey) {
            setIsLoading(true)
            getFileByKey(fileKey).then(({ data }) => {
                if (data?.imgURL) {
                    setImgURL(data?.imgURL);
                }
            }).catch(() => {
                setImgURL('')
            }).finally(() => setIsLoading(false))
        }

    }





    return (
        isLoading ? <CircularProgress size={20} /> : 
        (imgURL) ? <img src={imgURL} alt='prop' /> : <span><img src={AppIcons.logoPlaceholder} alt='logo' /></span>
    );
};


S3ImageViewer.propTypes = {
    fileKey: PropTypes.string
};


export default S3ImageViewer;
