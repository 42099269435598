import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

// @mui
import { Button, Stack, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import FileUploader from '../../../../components/awsS3ImageUploader/file.uploader';
// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';


// Helpers
import { translateThis } from '../../../../helpers/language.helper';

// Services
import ApiCaller from '../../../../services/api/general';
import { getUniqueId, getCurrentFacilityURL } from '../../../../helpers/helper.functions';






// ----------------------------------------------------------------------


const CreateNewSubjectForm = () => {
    const navigate = useNavigate()
    const apiCaller = new ApiCaller('subject')
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formKey, setFormKey] = useState(getUniqueId('subject'));
    const [subject, setSubject] = useState('');
    const [systems, setSystems] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [file, setFile] = useState({});

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        getSystems()
        // eslint-disable-next-line
    }, []);

    // get systems from api
    const getSystems = () => {
        setIsLoading(true);
        const apiCaller = new ApiCaller('systems')
        apiCaller.getList()
            .then((data) => {
                setSystems(data?.result);
            })
            .catch(() => showSnackbar().failure('Failed to fetch results'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (subject) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            name: subject,
            logo: file?.fileKey,
            systemIds: selectedSystems
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    // navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                    setSubject('')
                    setFile('')
                    setSelectedSystems([])
                    setFormKey(getUniqueId())
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    /**
     * On select a system
     * @param {String} id 
     */
    const onSelectSystem = (id) => {
        setSelectedSystems((prv) => {
            if (prv.includes(id)) {
                prv = prv.filter((p) => p !== id)
            } else {
                prv.push(id)
            }
            return [...prv]
        })

    }

    /**
     * The signup form elements
     */


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='standard-form questionForm' spacing={3}>
                    <form key={formKey}>
                        <div className='eachQn-box f-wrp'>
                            <h3>{translateThis('Add subject')}</h3>
                            <TextInput
                                key={`createQN_Question_Creator_add_title`}
                                name={`createQN_Question_Creator_add_title`}
                                id={`createQN_Question_Creator_add_title`}
                                value={subject}
                                translationKey={translateThis('Write your subject title here')}
                                type='text'
                                placeholder={translateThis('Write your subject title here')}
                                className='custom-textfield maxWidth_550'

                                getValue={(_, value) => {
                                    setSubject(value)
                                }}
                                useDefaultValidation
                            />

                        </div>

                        <div className='eachQn-box f-wrp'>
                            <h3>{translateThis('Select an icon')}</h3>
                            <div className='form-img-uploader icon-uploader f-wrp'>
                                <FileUploader
                                    uploadIcon={SVGIcons.PlusIcon()}
                                    uploadCaption='Upload'
                                    acceptOnly='image/*'
                                    onUploadEnd={(file) => setFile(file)}
                                    showPreview
                                />
                                <br />
                                {file?.tempURL && <span className={`image-blk`}>
                                    <IconButton onClick={() => setFile('')}>
                                        <Delete />
                                    </IconButton>
                                    <img src={file?.tempURL} alt='images' />

                                </span>}
                            </div>

                        </div>

                        <div className='eachQn-box f-wrp'>
                            <h3>{translateThis('Assign systems to this subject')}</h3>
                            <br />
                            <div className='each-topic-sec f-wrp'>
                                <div className='topic-items-list'>
                                    <h6>{translateThis('Systems')}</h6>
                                    {systems && <ul>
                                        {systems.map((system) => (
                                            // eslint-disable-next-line
                                            <li onClick={() => onSelectSystem(system.id)} key={system.systemName} className={`${selectedSystems.includes(system.id) ? 'active' : ''}`}>
                                                <Typography variant='div' >
                                                    <p>{system.systemName}</p></Typography>
                                            </li>
                                        ))}
                                    </ul>}
                                </div>
                            </div>
                        </div>

                        <div className='button-sec f-wrp'>
                            <div className='lft-btn-sec'>
                                <Button onClick={() => navigate(`/${getCurrentFacilityURL()}/create-new-item`)} className='cancel-btn'>{translateThis("cancel")}</Button>
                            </div>
                            <div className='ryt-btn-sec'>

                                <LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition='start'
                                    startIcon={<></>} // To remove Mui warning
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='fill-btn'
                                    onClick={onSubmit}>
                                    {translateThis("submit")}
                                </LoadingButton>

                            </div>
                        </div>
                    </form>
                </Stack>
                <br />


            </div>}
        </>
    );
}

CreateNewSubjectForm.propTypes = {
    childId: propTypes.string
}

export default CreateNewSubjectForm;