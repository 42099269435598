import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { useNavigate } from 'react-router-dom';
import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';

import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import AppPopOverMenu from '../../../../components/appPopOverMenu/index';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import AppIcons from '../../../../assets/images/icons';
import { translateThis } from '../../../../helpers/language.helper';

import { DATE_FORMAT } from '../../../../constants';



const UniversityCreatedExamsList = () => {
    const apiCaller = new ApiCaller('universityTests');
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data?.result.map((r) => ({ ...r, id: r._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * Resource delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Exam deleted successfully`);
                    } else {
                        showSnackbar().failure(`Failed to delete exam`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
   * Resource update
   */
    const updateData = () => {
        if (selectedId) {
            apiCaller.customPutData(selectedId, { status: selectedStatus === 'DRAFT' ? 'ACTIVE' : 'DRAFT' })
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Exam updated successfully`);
                    } else {
                        showSnackbar().failure(`Failed to update exam`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenPublishDialog(false);
                    setSelectedId('');
                    setSelectedStatus('');
                    getDataArr();
                })
        }

    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 350,
            // maxWidth: 350,
            flex: 4,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 4,
            // maxWidth: 270,
            minWidth: 270,
            renderCell: ({ row }) => <>{row?.dateType === 'within range' ? <div>
                <span>Start: {row?.startDate ? dayjs(row?.startDate).format(DATE_FORMAT) : ''} {row?.startTime} </span><br />
                <span>End: {row?.endDate ? dayjs(row?.endDate).format(DATE_FORMAT) : ''} {row?.endTime} </span>
            </div> : <div><span>{row?.dateType}</span></div>}</>
        },
        {
            field: 'duration',
            headerName: 'Duration',
            flex: 1,
            width: 130,
            minWidth: 130,
            // maxWidth: 130,
            renderCell: ({ row }) => <div>
                <span>{row?.duration} {row?.durationMetric} </span>
            </div>
        },
        {
            field: 'numberOfQuestions',
            headerName: "Q's",
            flex: 1,
            width: 90,
            minWidth: 90,
            // maxWidth: 90,
            renderCell: ({ row }) => row?.questionIds.length > 0 ? row?.questionIds.length : row.numberOfQuestions
        },
        {
            field: 'isSelfAssessment',
            headerName: "Self Assessment",
            flex: 2,
            width: 150,
            minWidth: 150,
            // maxWidth: 150,
        },
        {
            field: 'status',
            headerName: "Status",
            flex: 1,
            width: 110,
            minWidth: 110,
            maxWidth: 110,
        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 0.6,
            width: 110,
            minWidth: 110,
            // maxWidth: 110,
            sortable: false,
            renderCell: ({ row }) => <AppPopOverMenu options={[
                {
                    label: `${translateThis('View')}`,
                    onClick: () => navigator(`/${getCurrentFacilityURL()}/exam-logs/${row.id}`)
                },
                {
                    label: `${translateThis('Edit')}`,
                    onClick: () => navigator(`/${getCurrentFacilityURL()}/edit-exam/${row.id}`)
                },
                {
                    label: `${row.status === 'DRAFT' ? translateThis('Publish') : translateThis('Unpublish')}`,
                    onClick: () => {
                        setSelectedId(row.id)
                        setSelectedStatus(row.status)
                        setOpenPublishDialog(true)
                    }
                },
                {
                    label: `${translateThis('Delete')}`,
                    onClick: () => {
                        setSelectedId(row.id)
                        setOpenDeleteDialog(true)
                    }
                }

            ]} />
        }
    ]

    return (
        <div className='block-table-wrp f-wrp'>
            <div className='panel-table-sec provider-table PC-Table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn'>
                                            <span>{(element.status === 'ACTIVE' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                                                (element.status === 'DRAFT' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</span>

                                            <AppPopOverMenu
                                                icon={SVGIcons.StatusMobArrow()}
                                                options={[
                                                    {
                                                        label: `${translateThis('View')}`,
                                                        onClick: () => null
                                                    },
                                                    {
                                                        label: `${translateThis('Edit')}`,
                                                        onClick: () => navigator(`/${getCurrentFacilityURL()}/edit-exam/${element.id}`)
                                                    },
                                                    {
                                                        label: `${element.status === 'DRAFT' ? translateThis('Publish') : translateThis('Unpublish')}`,
                                                        onClick: () => {
                                                            setSelectedId(element.id)
                                                            setSelectedStatus(element.status)
                                                            setOpenPublishDialog(true)
                                                        }
                                                    },
                                                    {
                                                        label: `${translateThis('Delete')}`,
                                                        onClick: () => {
                                                            setSelectedId(element.id)
                                                            setOpenDeleteDialog(true)
                                                        }
                                                    }

                                                ]} />
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>

                                        <div className='flex-wrp text-overflow-elips'>
                                            <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 105px)', overflow: 'hidden' }}>
                                                {(element.name) ? <h4 style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                                    <span style={{fontWeight: 'inherit', maxWidth: 'max-content'}}>{element.name}</span>
                                                    {(element?.duration) ? <span className='time-pill'>{element?.duration} {element?.durationMetric}</span> : '-'}
                                                </h4> : '-'}
                                                {element?.dateType === 'within range' ? <>
                                                    <span>{translateThis("Start")}: {element?.startDate ? dayjs(element?.startDate).format(DATE_FORMAT) : ''} {element?.startTime} </span>
                                                    <span>{translateThis("End")}: {element?.endDate ? dayjs(element?.endDate).format(DATE_FORMAT) : ''} {element?.endTime} </span>
                                                </> : <div><span>{element?.dateType}</span></div>}
                                            </div>
                                            <div className='flex-wrp auto-flex'>
                                                <p style={{ color: '#4C483E' }}>{translateThis("Q's")}: {(element.numberOfQuestions) ? <span>{(element.numberOfQuestions)} </span> : '-'}</p>
                                                <p style={{ color: '#4C483E' }}>{translateThis('Self assess')}: {element.isSelfAssessment ? (element.isSelfAssessment) : '-'}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <button type='button' className='full-li-btn' onClick={() => null} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }

            </div>

            {openDeleteDialog ? <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete Exam`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this exam?\nThis action is permanent and cannot be undone.`}
                okayButtonText={`Delete exam`}
                onConfirm={deleteData}
            /> : null}

            {openPublishDialog ? <ConfirmDialog
                isOpen={openPublishDialog}
                onClose={() => {
                    setOpenPublishDialog(false);
                    setSelectedId('');
                    setSelectedStatus('');
                }}
                title={`update Exam`}
                className={'delete-popup'}
                description={`Are you sure you want to update this exam?\nThis action is permanent and cannot be undone.`}
                okayButtonText={`update exam`}
                onConfirm={updateData}
            /> : null}
        </div>
    )
}
UniversityCreatedExamsList.propTypes = {}

export default UniversityCreatedExamsList;
