import React from 'react'
import PropTypes from 'prop-types';
import Key from '../key/Key'
import './keypad.css'

const Keypad = (props) => {
  const {
    keys: rows = [],
    handleClick,
    currentOperation,
    trigUnit,
    memory
  } = props
  // console.log(props)
  return (
  <section className="Keypad">
    {rows.map((keys, index) => (
      <div className="Keypad-row" key={`row${index}`}>
        {keys.map(keyProps => (
          <Key
            key={keyProps.id}
            handleClick={handleClick}
            active={
              currentOperation === keyProps.id ||
              (keyProps.id === 'memoryRecall' && memory !== null)
            }
            trigUnit={trigUnit}
            {...keyProps}
          />
        ))}
      </div>
    ))}
  </section>
)}
Keypad.propTypes = {
  keys: PropTypes.any,
  handleClick: PropTypes.any,
  currentOperation: PropTypes.any,
  trigUnit: PropTypes.any,
  memory: PropTypes.any,
}
export default Keypad
