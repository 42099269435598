import React from 'react';
import { getRecoil } from 'recoil-nexus';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { appConfigAtomState } from '../../../../state/reducerAtoms/app.atom';
import AppPopOverMenu from '../../../../components/appPopOverMenu';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import Iconify from '../../../../components/iconify/Iconify';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import LogoutButton from '../../../../components/buttons/logout.button';

import { logoutUser } from '../../../../helpers/auth.helper';
import { switchLanguage, translateThis } from '../../../../helpers/language.helper';

const HeaderRightUserInfo = () => {
    const appState = getRecoil(appConfigAtomState);
    const profile = appState?.profile || {};
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [myPortalInitialState, setMyPortalState] = useRecoilState(appConfigAtomState);

    /**
     * Invokes when drawer open
     * @returns Fun
     */
    const switchDarkMode = async () => setMyPortalState((prv) => {
        let classToAdd = 'dark-bg';
        if (prv.isDarkModeOn) {
            classToAdd = 'light-mode';
        }
        document.body.classList.remove('light-mode', 'dark-bg');
        document.body.classList.add(classToAdd);
        return { ...prv, ...{ isDarkModeOn: !prv.isDarkModeOn } }
    });

    return (
        <>
            {profile ? <div className='header-basic-info stud-profile-wrp'>
                <div style={{marginRight: '20px'}} className='language-box'>
                    <AppPopOverMenu
                        icon={SVGIcons.GlobalIcon()}
                        options={[
                            {
                                label: 'English',
                                onClick: () => switchLanguage('en')
                            },
                            {
                                label: 'Spanish',
                                onClick: () => switchLanguage('es')
                            },
                        ]}
                    />
                    <p>{myPortalInitialState.selectedLang === 'en' ? 'English' : 'Spanish'}</p>
                </div>
                {profile?.profileImg?.tempURL && <span><img src={profile?.profileImg?.tempURL} alt={profile?.name} /></span>}
                <div className='header-basic-con'>
                    <h4>{profile?.name}</h4>
                    <p>{profile?.email}</p>
                </div>
                <AppPopOverMenu
                    className='stud-popup'
                    icon={<Iconify style={{ color: '#fff' }} icon={'eva:more-vertical-fill'} />}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    options={[
                        {
                            label: <span className='stud-menu'>{SVGIcons.ProfileIcon()} {translateThis('Profile')}</span>,
                            onClick: () => {
                                navigator(`/${getCurrentFacilityURL()}/my-profile`, { replace: true })

                            }
                        },
                        {
                            label: <span className='stud-menu'>{SVGIcons.AppearanceIcon()} {myPortalInitialState?.isDarkModeOn ? `${translateThis('Light mode')}` : `${translateThis('Dark mode')}`}</span>,
                            onClick: () => {
                                switchDarkMode();
                            }
                        },
                        // {
                        //     label: <span className='stud-menu'>{SVGIcons.SecurityIcon()} Security</span>,
                        //     onClick: () => {
                        //         navigator(`/${getCurrentFacilityURL()}/my-profile`, { replace: true })

                        //     }
                        // },
                        // {
                        //     label: <span className='stud-menu'>{SVGIcons.AppearanceIcon()} Appearance</span>,
                        //     onClick: () => {
                        //         navigator(`/${getCurrentFacilityURL()}/my-profile`, { replace: true })

                        //     }
                        // },
                        // {
                        //     label: <span className='stud-menu'>{SVGIcons.CurrencyIcon()} Payments</span>,
                        //     onClick: () => {
                        //         navigator(`/${getCurrentFacilityURL()}/my-profile`, { replace: true })

                        //     }
                        // },
                        {
                            label: <span className='stud-menu'>{SVGIcons.PlansIcon()} {translateThis('Plans')}</span>,
                            onClick: () => {
                                navigator(`/${getCurrentFacilityURL()}/plans`, { replace: true })

                            }
                        },
                        {
                            label: <span className='stud-menu logout'>{SVGIcons.LogoutIcon()} <LogoutButton /></span>,
                            onClick: () => { setOpenDeleteDialog(true) }
                        },
                    ]
                    }

                />
            </div> : null}
            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                title="Confirm logout"
                className={'delete-popup logout-popup'}
                description={<span>Are you sure you want to logout?</span>}
                okayButtonText={'Logout'}
                onConfirm={() => logoutUser('Logged out successfully!')}
            />}

        </>
    );
}

export default HeaderRightUserInfo;
