import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { translateThis } from '../../helpers/language.helper';
import AppIcons from '../../assets/images/icons/index';
import AppLayout from '../../layouts/app/AppLayout';

import { getCurrentFacilityURL } from '../../helpers/helper.functions';
import SetNewPasswordForm from './forms/setNewPassword.form';
import ProviderLogo from '../../components/logo/facilityLogo';

const SetNewPasswordPage = () => {
    const currentFacilityURL = getCurrentFacilityURL();
    const pageName = 'Set new password';
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            pageName={pageName}
            bodyClassName='ResetPasswordPage auth-layout'
        >

            <div className='auth-page-layout f-wrp'>
                <div className='container-fluid'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                            <Link to={`/${currentFacilityURL}`} className='auth-logo'><ProviderLogo /> </Link>
                            <div className='auth-form'>
                                <Stack className='form-tile'>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>{translateThis("setTheNewPassword")}</Typography>
                                    <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>{translateThis("mustBeAtLeast8Characters")}</Typography>
                                </Stack>
                                <SetNewPasswordForm />
                            </div>
                        </div>
                        <div className='form-img-wrp'>
                            <span><img src={AppIcons.registerBG} alt='Forgot password' /></span>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default SetNewPasswordPage;