/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types';
import './Key.css'

const Key = ({ type, id, handleClick, active, trigUnit, label, ...props }) => {
  const text = id === 'trigUnit' ? (trigUnit === 'deg' ? 'rad' : 'deg') : label
  return (
    <button
      type="button"
      id={id}
      className={`Key ${type} ${active ? 'active' : ''}`}
      onClick={event => handleClick(event, { type })}
      {...props}>
      <span className="Key-label" dangerouslySetInnerHTML={{ __html: text }} />
    </button>
  )
}
Key.propTypes = {
  type: PropTypes.any,
  id: PropTypes.any,
  handleClick: PropTypes.any,
  active: PropTypes.any,
  trigUnit: PropTypes.any,
  label: PropTypes.any,
}
export default Key
