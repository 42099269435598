import React from 'react'
import PropTypes from 'prop-types';
import Keypad from '../keypad/Keypad'
import Display from '../display/Display'
import './App.css'

const CalcApp = ({ displayValue, mode, trigUnit, ...keypadProps }) => (
  <div className="CalcApp">
    <div className={`calculator ${mode}`}>
      <Display trigUnit={trigUnit} value={displayValue} mode={mode} />
      <Keypad trigUnit={trigUnit} {...keypadProps} />
    </div>
  </div>
)
CalcApp.propTypes = {
  displayValue: PropTypes.any,
  mode: PropTypes.any,
  trigUnit: PropTypes.any,
}

export default CalcApp
