import React from 'react';
import PropTypes from 'prop-types';
// import { TimePicker } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


const TimeInput = (props) => {
    const {
        label = 'Select time',
        onChange = () => null,

    } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker','MobileTimePicker',]}>
                <MobileTimePicker 
                 
                    label={label}
                    views={['hours', 'minutes']}
                    // format="hh:mm"
                    onChange={(value) => onChange(value)}
                    {...props}
                />
            </DemoContainer>
        </LocalizationProvider>

    )
}
TimeInput.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func
}

export default TimeInput;