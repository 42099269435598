import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { switchLanguage, translateThis } from '../../helpers/language.helper';
import AppIcons from '../../assets/images/icons/index';
import AppLayout from '../../layouts/app/AppLayout';
import LoginForm from './forms/login.form';

import './auth.scss';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';
import ProviderLogo from '../../components/logo/facilityLogo';

const LoginPage = () => {
    const currentFacilityURL = getCurrentFacilityURL();
    if (currentFacilityURL === 'dominicana') {
        switchLanguage('es', false)
    }
    
    const pageName = 'Login';
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            pageName={pageName}
            bodyClassName='login-wrp auth-layout'
        >
            <div className='auth-page-layout log-in f-wrp'>
                <div className='container-fluid'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                            <Link to={`/${currentFacilityURL}`} className='auth-logo'><ProviderLogo/> </Link>
                            
                            <div className='auth-form'>
                                <Stack className='form-tile'>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>{translateThis("loginHeader")}</Typography>
                                    <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>{translateThis("loginText")}</Typography>
                                </Stack>
                                <LoginForm />
                            </div>
                        </div>
                        <div className='form-img-wrp'>
                            <span><img src={AppIcons.loginBG} alt='login' /></span>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default LoginPage;