import * as React from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import AppLoader from '../../../../components/appLoader';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import VideoPlayer from '../../../../components/videoPlayer';
import { translateThis } from '../../../../helpers/language.helper';
import '../index.scss';
import AppLoader from '../../../../components/appLoader/index';



const ClassesDetailsVideos = (props) => {
    const navigate = useNavigate();
    const { isLoading, videos = [], isJoined = false, onJoinClass = () => null } = props;
    const [classVideos, setClassVideos] = React.useState('');

    const onCancelClick = () => {
        navigate(`/${getCurrentFacilityURL()}/classes`)
    }
    return (

        <div className='exam-preview-main-wrapper classes-preview-main-wrapper'>
            {isLoading ? <AppLoader /> : <Stack className='exam-preview-wrp classes-video-wrp' spacing={3} style={{ border: 'none' }}>
                {(isJoined) ? <>
                    <h3>{translateThis('Video content')}:</h3>
                    {videos.length > 0 ? <><div className='video-con-box f-wrp'>
                        <div className={`video-list-box f-wrp ${classVideos && 'player-open'}`}>
                            <ul>
                                {videos.map((element, key) => (
                                    <li key={key}>
                                        <button type='button' onClick={() => {
                                            setClassVideos(element.link)
                                        }}><SVGIcons.YouTubePlayIcon /> <p>{element.name}</p></button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {classVideos && <div className='video-player-box f-wrp'>
                            <VideoPlayer videoUrl={classVideos} />
                        </div>}
                    </div>
                        <div className='button-sec f-wrp' style={{ paddingTop: '0px', paddingBottom: '15px', justifyContent: 'flex-end' }}>
                            <div className='lft-btn-sec'>
                                <LoadingButton
                                    // loading={isResuming}
                                    // disabled={!data?.isValidToAttend}
                                    type="submit"
                                    className='fill-btn'
                                    onClick={onCancelClick}
                                >
                                    {translateThis("End")}
                                </LoadingButton>
                            </div>
                        </div>
                    </>



                        : <span>No videos uploaded yet!</span>}
                </> : <div>
                    <span>Please start the class to see videos</span>
                    <br />
                    <br />

                </div>}
            </Stack>}
        </div>
    );
}

ClassesDetailsVideos.propTypes = {
    isLoading: propTypes.bool,
    isJoined: propTypes.bool,
    onJoinClass: propTypes.func,
    videos: propTypes.array
}

export default ClassesDetailsVideos;