import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../index.scss';

import { translateThis } from '../../../../helpers/language.helper';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';

import AppIcons from '../../../../assets/images/icons';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar';
import AppLoader from '../../../../components/appLoader';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';




const ClassesListPage = ({ requestType }) => {
    const apiCaller = new ApiCaller(`class/student`)
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    // const [selectedClass, setSelectedClass] = useState(null);
    const selectedView = 'list';
    const facilityURL = getCurrentFacilityURL();

    useEffect(() => {
        getDataArr()
        // eslint-disable-next-line
    }, []);

    /**
     * Get Assigned Exam from Api
     */
    const getDataArr = () => {
        apiCaller.getList({ query: `type=${requestType}` }).then((data) => setDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))
    }


    const paraRef = useRef([]);
    const [copied, setCopied] = useState(false);
    const handleCopy = (code, key) => {
        navigator.clipboard.writeText(code).then(() => {
            setCopied(prev => ({ ...prev, [key]: true }));
            setTimeout(() => {
                setCopied(prev => ({ ...prev, [key]: false }));
            }, 1000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return (

        <div className='exam-list-wrapper studClass-list-wrp f-wrp'>
            {selectedView === 'list' ? <div className='row'>
                {isLoading ? <AppLoader /> : (!isLoading && dataArr.length > 0) ? <>
                    <div className='btn-wrp f-wrp'>
                        <Link className='add-btn' onClick={() => null} >{translateThis('Join a class')}</Link>
                    </div>
                    {dataArr.map((eachClass, key) => (
                        <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12' key={key}>
                            <div className={`each-examList-box f-wrp `}>
                                <Link to={`/${facilityURL}/classes-details/${eachClass._id}`}
                                    state={{ eachClass }} className='examList-click' />
                                <span className='cover-img'><S3ImageViewerWithFallback key={`file-${eachClass?.coverImage}`} fileKey={eachClass?.coverImage} /></span>
                                <h4>{eachClass?.title}</h4>
                                <p>{eachClass?.shortDescription}</p>
                                {eachClass?.code && <p className='link-text'>
                                    <span ref={paraRef.current[key]}>{eachClass?.code}</span>
                                    <button type='button' onClick={() => handleCopy(eachClass?.code, key)}><SVGIcons.CopyIcon />
                                        {copied[key] && (
                                            <i className="tooltip">{translateThis('Link copied to clipboard')}</i>
                                        )}
                                    </button>
                                </p>}
                            </div>
                        </div>
                    ))
                    }</> : <div className='NoItem-box f-wrp'>
                    <span><img src={AppIcons.noClasses} alt='No Classes' /></span>
                    <h3>{translateThis('No classes yet')}</h3>
                    <p>{translateThis('join classes text')}</p>
                    <Link className='box-btn' onClick={() => null} >{translateThis('Join a class')}</Link>

                </div>}
            </div> : null}
        </div >

    )
}
ClassesListPage.propTypes = {
    requestType: PropTypes.string,
}
export default ClassesListPage;