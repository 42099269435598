import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import '../../../messages/index.scss';
import { DATE_TIME_MESSAGE_RECEIVED_FORMAT } from '../../../../constants/index';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';



const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime)
const InboxView = ({ data }) =>
(
    
    <div className='inbox-view-wrp f-wrp'>
        <div className='inbox-view-con f-wrp'>
            <div className='prof-sec'>
                {/* <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div> */}
                <div className='prof-basic'>
                    <h4>{data?.name || '-'}</h4>
                    <p><b>{data?.email || ''}  {data?.phoneNumber || ''}</b></p>
                    <p>{data?.createdAt ? `${dayjs(data?.createdAt).format(DATE_TIME_MESSAGE_RECEIVED_FORMAT)} (${dayjs(data?.createdAt).fromNow()})` : ''} </p>
                </div>
            </div>
            {data?.question && <div className='f-wrp'>             
                <p style={{marginTop: '5px'}}><strong>Question Number:</strong> <span>{data?.question?.questionNumber}</span></p>
                <strong>Question:</strong> <span>{data?.question?.question}</span>

            </div>}
            <div className='f-wrp'>                
                <span>{data?.subject}</span>

            </div>
            <div className='inbox-content f-wrp' style={{paddingTop: '15px'}}>
                <strong>User report: </strong>
                <RichTextViewer richText={data?.message} />

            </div>
        </div>
    </div>
)


InboxView.propTypes = {
    data: PropTypes.any
}
export default InboxView;

