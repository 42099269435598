import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import MenuList from '@mui/material/MenuList';
import { MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import AppIcons from '../../../assets/images/icons';
import { translateThis } from '../../../helpers/language.helper';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import whatsappBlk from '../../assets/images/icons/Whatsapp-foot.svg';



const MainFooter = () => (
    <div className="project-main-footer f-wrp">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-3 col-sm-12 col-xs-12">
                    <div className="foot-logo-wrp f-wrp">
                        <div className="logo-wrp f-wrp">
                            <span>
                                <img src={AppIcons.landingLogo} alt="xmed Logo" />
                                <p>{translateThis('XMed República Dominicana está diseñado para médicos en la República Dominicana. © 2024 XMedPro. Todos los derechos reservados.')}</p>    
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-9 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="each-foot-link align-class1 f-wrp">
                                <h4 className="foot-sub-head">{translateThis('Compañía')}</h4>
                                <MenuList>
                                    <MenuItem>
                                        <NavHashLink smooth to={'#about'} style={{ width: '100%' }} >
                                        {translateThis('Sobre nosotros')}
                                        </NavHashLink>
                                    </MenuItem>
                                    {/* <MenuItem><Link to={'/'}>{translateThis('Testimonios')}</Link></MenuItem> */}
                                    {/* <MenuItem><Link to={'/'}>{translateThis('Precios')}</Link></MenuItem> */}
                                    <MenuItem><Link to={'#'}>{translateThis('Trabaja con nosotros')}</Link></MenuItem>
                                </MenuList>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

                            <div className="each-foot-link align-class2 f-wrp">
                                <h4 className="foot-sub-head">{translateThis('Recursos')}</h4>
                                <MenuList>
                                    <MenuItem><Link to={'#'}>{translateThis('Políticas de privacidad')}</Link></MenuItem>
                                    <MenuItem><Link to={'#'}>{translateThis('Términos de uso')}</Link></MenuItem>
                                    <MenuItem><Link to={'#'}>{translateThis('Asistencia')}</Link></MenuItem>
                                </MenuList>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

                            <div className="each-foot-link align-class3 f-wrp">
                                <h4 className="foot-sub-head">{translateThis('Contacto')}</h4>
                                <MenuList>

                                    <MenuItem>
                                        <NavHashLink smooth to={'#contacto'} style={{ width: '100%' }} >
                                        {translateThis('Contáctanos')}
                                        </NavHashLink>
                                    </MenuItem>
                                </MenuList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default MainFooter;