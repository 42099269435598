import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import AppIcons from '../../../assets/images/icons/index';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
// import UserDrawer from '../../../components/drawer/drawer';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import ProviderLogo from '../../../components/logo/facilityLogo';


export default function DashboardHomePage() {
    const apiCaller = new ApiCaller('university');
    const [data, setData] = useState({
        totalUsers: 0,
        subscribedUsers: 0,
        newUsers: 0,
        conversations: 0,
        churnUsers: 0,
        utilization: 0,
        correctness: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getData ()
       
    // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData('dashboard')
            .then(({result}) => {
                setData(result);
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const usersItems = [
        {
            image: AppIcons.dashItem_1,
            icon: AppIcons.dashIcon_1,
            title: 'TOTAL USERS',
            type: 'Users',
            valueGetter: 'totalUsers'
        },
        {
            image: AppIcons.dashItem_2,
            icon: AppIcons.dashIcon_2,
            title: 'SUBSCRIBED USERS',
            type: 'Users',
            valueGetter: 'subscribedUsers'
        },
    ];

    const subscriptionsItems = [
        {
            image: AppIcons.dashItem_3,
            icon: AppIcons.dashIcon_3,
            title: 'NEW USERS',
            type: 'New users this week',
            valueGetter: 'newUsers'
        },
        {
            image: AppIcons.dashItem_4,
            icon: AppIcons.dashIcon_4,
            title: 'CONVERSIONS',
            type: 'New subscriptions this week',
            valueGetter: 'conversations'
        },
        {
            image: AppIcons.dashItem_5,
            icon: AppIcons.dashIcon_5,
            title: 'CHURN',
            type: 'Subscriber lost this week',
            valueGetter: 'churnUsers'
        },
    ];

    const analyticsItems = [
        {
            image: AppIcons.dashItem_6,
            icon: AppIcons.dashIcon_6,
            title: 'UTILIZATION',
            type: 'of total questions were used',
            valueGetter: 'utilization'
        },
        {
            image: AppIcons.dashItem_7,
            icon: AppIcons.dashIcon_7,
            title: 'CORRECTNESS',
            type: 'Questions answered correctly',
            valueGetter: 'correctness'
        },

    ];




    return (

        <div className='dashboard-main-wrapper f-wrp'>
            <div className='main-category-page'>
                <div className='main-category-header'>
                    <h2>{translateThis("home")}</h2>
                </div>
            </div>
            <div className='dashboard-header f-wrp'>
                <span><ProviderLogo/></span>
            </div>
            <div className='dashboard-content-wrapper f-wrp'>
                <div className='dashboard-items-wrp'>
                    <h3>{translateThis('Users')}</h3>
                    <Grid container spacing={3}>
                        {usersItems.map((element, key) => (
                            <Grid item xs={12} sm={12} md={6} key={key}>
                                <div className={`${element.title} each-dashboard-items f-wrp`}>
                                    <span className='bg-wrp'><img src={element.image} alt="user" /></span>
                                    <h3><span><img src={element.icon} alt="user" /></span>{translateThis(element.title)}</h3>
                                    <p><b>{translateThis(data[element.valueGetter])}</b> {translateThis(element.type)}</p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className='dashboard-items-wrp'>
                    <h3>{translateThis('Subscriptions')}</h3>
                    <Grid container spacing={3}>
                        {subscriptionsItems.map((element, key) => (
                            <Grid item xs={12} sm={12} md={4} key={key}>
                                <div className={`${element.title} each-dashboard-items f-wrp`}>
                                    <span className='bg-wrp'><img src={element.image} alt="user" /></span>
                                    <h3><span><img src={element.icon} alt="user" /></span>{translateThis(element.title)}</h3>
                                    <p><b>{translateThis(data[element.valueGetter])}</b> {translateThis(element.type)}</p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className='dashboard-items-wrp'>
                    <h3>{translateThis('Analytics')}</h3>
                    <Grid container spacing={3}>
                        {analyticsItems.map((element, key) => (
                            <Grid item xs={12} sm={12} md={6} key={key}>
                                <div className={`${element.title} each-dashboard-items f-wrp`}>
                                    <span className='bg-wrp'><img src={element.image} alt="user" /></span>
                                    <h3><span><img src={element.icon} alt="user" /></span>{translateThis(element.title)}</h3>
                                    <p><b>{translateThis(data[element.valueGetter] || 0)}%</b> {translateThis(element.type)}</p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>

    );
}
