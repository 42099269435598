import React from 'react';
import { Link } from 'react-router-dom';
import '../index.scss';
import { translateThis } from '../../../../helpers/language.helper';


const UnSubPopup = () => (
    <>
        <div className='subscription-popup-wrp unsub-popup-wrp f-wrp'>
            <h2>{translateThis('Subscription assistance')}</h2>
            <p>{translateThis("As we're transitioning to a new payment system to ensure the best service, unsubscribing directly through the platform is momentarily unavailable.")}</p>
            <p>{translateThis("Email us at")} hello@xmedpro.com {translateThis("or reach out on WhatsApp for smooth assistance with unsubscribing.")}</p>
            <div className='unsub-btn-wrp f-wrp'>
                <Link className='fill-btn email' to='#'
                    onClick={(e) => {
                        window.location = 'mailto:hello@xmedpro.com';
                        e.preventDefault();
                    }}>{translateThis("Email us")}</Link>
                <Link className='fill-btn whatsapp' to={'https://wa.me/14245771140'} target='_blank' >{translateThis("Whats app")}</Link>
            </div>
        </div>
    </>
)
export default UnSubPopup;