import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { translateThis } from '../../../../helpers/language.helper';
import AppLayout from '../../../../layouts/app/AppLayout';
import UserDrawer from '../../../../components/drawer/drawer';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';


const ViewSystemPage = () => {
    const apiCaller = new ApiCaller('systems');
    const { id } = useParams();
    const location = useLocation();
    const systemData = location.state?.data || {};
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    /**
    the legendary use effect function
    This will run very first on render
    */
    useEffect(() => {
        getData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    {isLoading ? <span>Loading...</span> : systemData ? <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("View System")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content maxWidth_991'>

                                <div className='eachQn-box f-wrp'>
                                    <h3>{translateThis('Title')}</h3>
                                    <p>{systemData?.systemName}</p>
                                </div>

                                <div className='eachQn-box eachQn-box-view f-wrp'>
                                    <h3>{translateThis('Subjects')}</h3>
                                    <div className='each-topic-sec f-wrp'>
                                        {/* <div className='topic-items-list' >
                                            <ul>
                                                {(systemData && systemData?.subjects && systemData?.subjects.length > 0) ? systemData?.subjects.map((sys) => (
                                                    <li key={sys?.subjectId} className='active'>
                                                        {sys.logo && <img alt={sys.subjectName} style={{ width: '100px', height: '100px' }} src={sys.logo} />}
                                                        <p>{sys.subjectName}</p></li>
                                                )) : null}
                                            </ul>
                                        </div> */}
                                        <div className='flex-box assignSubBox'>
                                            {(systemData && systemData?.subjects && systemData?.subjects.length > 0) ? systemData?.subjects.map((sys) => (
                                                <li key={sys?.subjectId} className='image-blk active'>
                                                    {sys.logo && <img alt={sys.subjectName} src={sys.logo} />}
                                                    <h3>{sys.subjectName}</h3></li>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='eachQn-box eachQn-box-view f-wrp'>
                                    <div className='each-topic-sec f-wrp'>
                                        <div className='topic-items-list'>
                                            <h6>{translateThis('Topics')}</h6>
                                            <ul>
                                                {systemData?.topic ? systemData?.topic.map((d) => (
                                                    <li key={d?._id} className='active'><p>{d.topicName}</p></li>
                                                )) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='button-sec f-wrp' style={{ paddingBottom: '50px' }}>
                                    <div className='lft-btn-sec'>
                                        <Button onClick={() => navigate(`/${getCurrentFacilityURL()}/manage`)} className='cancel-btn'>{translateThis("cancel")}</Button>
                                    </div>
                                    <div className='ryt-btn-sec'>
                                        <Button onClick={() => navigate(`/${getCurrentFacilityURL()}/edit-system/${systemData?.id}`, { state: { data: systemData } })} className='fill-btn'> {translateThis("Edit")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box> : <span>{translateThis('No Data Found')}</span>}
                </Box>
            </Box>
        </AppLayout>
    )
}


ViewSystemPage.propTypes = {};

export default ViewSystemPage;