import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getRecoil } from 'recoil-nexus';
import { useRecoilState } from 'recoil';
import { Box, AppBar, Button } from '@mui/material';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { appConfigAtomState } from '../../../../state/reducerAtoms/app.atom';

import './index.scss';


import { translateThis } from '../../../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';

import SVGIcons from '../../../../assets/images/icons/svgIcons';
import ReportQuestionButton from './reportQuestion.button';
import FlagQuestionButton from './flagQuestion.button';



const TestHeader = (props) => {

    const { data = {}, onClickItem, qnIndex, onNavigate, questions = [], test = {},
        isCalcVisible = false,
        isLabVisible = false,
        isExplanationVisible = false,
        getHeaderHeight = () => null,
        activeQuestion = {},
    } = props;

    const onClickEach = (componentToShow) => {
        onClickItem(componentToShow);
    }
    // const decreaseButton = () => {
    //     // set css variable value
    //     document.documentElement.style.setProperty("--font-size-12", "13px",)
    //     document.documentElement.style.setProperty("--font-size-14", "14px",)
    //     document.documentElement.style.setProperty("--font-size-15", "15px",)
    //     document.documentElement.style.setProperty("--font-size-16", "14px",)
    // }
    // const normalButton = () => {
    //     // set css variable value
    //     document.documentElement.style.setProperty("--font-size-12", "14px",)
    //     document.documentElement.style.setProperty("--font-size-14", "16px",)
    //     document.documentElement.style.setProperty("--font-size-15", "18px",)
    //     document.documentElement.style.setProperty("--font-size-16", "16px",)
    // }
    // const increaseButton = () => {
    //     // set css variable value
    //     document.documentElement.style.setProperty("--font-size-12", "16px",)
    //     document.documentElement.style.setProperty("--font-size-14", "18px",)
    //     document.documentElement.style.setProperty("--font-size-15", "20px",)
    //     document.documentElement.style.setProperty("--font-size-16", "18px",)
    // }

    const initialFontSizes = {
        fontSize1: 13,
        fontSize2: 14,
        fontSize3: 15,
        fontSize4: 16,
    };

    const [fontSizes, setFontSizes] = useState(initialFontSizes);
    useEffect(() => {
        document.documentElement.style.setProperty('--font-size-12', `${fontSizes.fontSize1}px`);
        document.documentElement.style.setProperty('--font-size-14', `${fontSizes.fontSize2}px`);
        document.documentElement.style.setProperty('--font-size-15', `${fontSizes.fontSize3}px`);
        document.documentElement.style.setProperty('--font-size-16', `${fontSizes.fontSize4}px`);
    }, [fontSizes]);
    const increaseButton = () => {
        setFontSizes(prevSizes => ({
            fontSize1: Math.min(prevSizes.fontSize1 + 1, 32),
            fontSize2: Math.min(prevSizes.fontSize2 + 1, 32),
            fontSize3: Math.min(prevSizes.fontSize3 + 1, 32),
            fontSize4: Math.min(prevSizes.fontSize4 + 1, 32),
        }));
    }
    const normalButton = () => {
        setFontSizes(initialFontSizes);
    }
    const decreaseButton = () => {
        setFontSizes(prevSizes => ({
            fontSize1: Math.max(prevSizes.fontSize1 - 1, 10),
            fontSize2: Math.max(prevSizes.fontSize2 - 1, 10),
            fontSize3: Math.max(prevSizes.fontSize3 - 1, 10),
            fontSize4: Math.max(prevSizes.fontSize4 - 1, 10),
        }));
    }

    const [isShrink, setIsShrink] = useState(false);
    const shrinkButton = () => {
        setIsShrink(!isShrink);
    }

    if(isShrink){
        document.body.classList.add('Qn-Shrinked');
    } else {
        document.body.classList.remove('Qn-Shrinked');
    }

    const headerHeight = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            getHeaderHeight(headerHeight.current.clientHeight);
        }, 1500);
        // eslint-disable-next-line
    }, []);

    const currentFacilityURL = getCurrentFacilityURL();
    const appState = getRecoil(appConfigAtomState);

    const [myPortalInitialState, setMyPortalState] = useRecoilState(appConfigAtomState);
    const switchDarkMode = async () => setMyPortalState((prv) => {
        let classToAdd = 'dark-bg';
        if (prv.isDarkModeOn) {
            classToAdd = 'light-mode';
        }
        document.body.classList.remove('light-mode', 'dark-bg');
        document.body.classList.add(classToAdd);
        return { ...prv, ...{ isDarkModeOn: !prv.isDarkModeOn } }
    });
    const onNav = (to) => {
        onNavigate(to);
    }
    return (
        <>
            <Box sx={{ display: 'flex' }} className="Test-main-header" ref={headerHeight}>
                {(data.testTheme !== 'usmle') ? <AppBar className='app-portal-header' position="relative">
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <div className='header-tools'>
                            <Typography variant="h5" noWrap component="div">
                                {data?.name || ''} <span style={{ fontSize: '13px' }} />
                                {(activeQuestion && activeQuestion.questionNumber) && <span style={{ display: 'flex' }}> <span className='pc-title'>{translateThis('Question ID')}</span> <span className='mob-title'>{translateThis('Qid')}</span> : {activeQuestion.questionNumber}</span>}
                            </Typography>
                            {/* {(activeQuestion && activeQuestion.questionNumber) && <Typography variant="body1" noWrap component="div">
                                Question ID: {activeQuestion.questionNumber}
                            </Typography>} */}
                            {(activeQuestion && activeQuestion._id) && <ReportQuestionButton question={activeQuestion} />}

                        </div>


                        <div className='header-tools'>
                            <div className={`each-tool-items`}>
                                <Button className='toolButton shrink-btn' onClick={() => shrinkButton()}>
                                    <span>{isShrink ? SVGIcons.ExpandIcon() : SVGIcons.ShrinkIcon()}</span>
                                    <p>{isShrink ? translateThis('Expand') : translateThis('Compact')}</p>
                                </Button>
                            </div>
                            <div className={`each-tool-items ${isLabVisible ? 'active' : ''}`}>
                                <Button className='toolButton' onClick={() => onClickEach('lab')}>
                                    <span>{SVGIcons.ValuesIcon()}</span>
                                    <p>{translateThis('Values')}</p>
                                </Button>
                            </div>
                            <div className={`each-tool-items ${isCalcVisible ? 'active' : ''}`}>
                                <Button onClick={() => onClickEach('calc')} className='toolButton'>
                                    <span>{SVGIcons.CalcIcon()}</span>
                                    <p>{translateThis('Calc')}</p>
                                </Button>
                            </div>
                            <div className='each-tool-items'>
                                <div className='toolButton'>
                                    <div className='font-btn'>
                                        <Button onClick={decreaseButton} className='font-decrease'>{translateThis('A-')}</Button>
                                        <Button onClick={normalButton} className='font-normal'>{translateThis('A')}</Button>
                                        <Button onClick={increaseButton} className='font-increase'>{translateThis('A+')}</Button>
                                    </div>
                                    <p>{translateThis('Zoom')}</p>
                                </div>
                            </div>
                            <div className={`each-tool-items ${isExplanationVisible ? 'active' : ''}`}>
                                <Button className='toolButton' onClick={() => onClickEach('explanation')}>
                                    <span>{SVGIcons.InfoIcon()}</span>
                                    <p>{translateThis('Explanation')}</p>
                                </Button>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar> : <AppBar className='app-portal-header usmleView-header' position="relative">
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <div className='header-tools'>
                            <Typography variant="h5" noWrap component="div" style={{ display: 'flex', flexDirection: 'column' }}>
                                {data?.name || ''}
                                {(activeQuestion && activeQuestion.questionNumber) && <span style={{ fontSize: '12px', display: 'flex' }}><span className='pc-title'>{translateThis('Question ID')}</span> <span className='mob-title'>{translateThis('Qid')}</span>: {activeQuestion.questionNumber}</span>}
                            </Typography>
                            <div className='usmle-flag-btnBox'>
                                <FlagQuestionButton question={activeQuestion} test={test} />
                            </div>
                        </div>

                        <div className='header-tools'>
                            <div className='usmle-nav-btns'>
                                <Button disabled={qnIndex === 0} onClick={() => onNav('previous')} className='pln-btn'>
                                    <span>{SVGIcons.UsmleNavLeft()}</span>
                                    <p>{translateThis('Previous')}</p>
                                </Button>
                                <Button disabled={qnIndex === (questions.length - 1)} onClick={() => onNav('next')} className='pln-btn'>
                                    <span>{SVGIcons.UsmleNavRight()}</span>
                                    <p>{translateThis('Next')}</p>
                                </Button>
                            </div>

                        </div>


                        <div className='header-tools'>
                            <div className={`each-tool-items ${isLabVisible ? 'active' : ''}`}>
                                <Button className='toolButton' onClick={() => onClickEach('lab')}>
                                    <span>{SVGIcons.LabValuesIcon()}</span>
                                    <p>{translateThis('Lab values')}</p>
                                </Button>
                            </div>
                            <div className={`each-tool-items ${isCalcVisible ? 'active' : ''}`}>
                                <Button onClick={() => onClickEach('calc')} className='toolButton'>
                                    <span>{SVGIcons.CalculatorIcon()}</span>
                                    <p>{translateThis('Calculator')}</p>
                                </Button>
                            </div>
                            <div className={`each-tool-items `}>
                                <Button onClick={() => switchDarkMode()} className='toolButton'>
                                    <span>{SVGIcons.ReverseColorIcon()}</span>
                                    <p>{translateThis('Reverse Color')}</p>
                                </Button>
                            </div>

                            <div className='each-tool-items'>
                                <div className='toolButton'>
                                    <div className='font-btn'>
                                        <Button onClick={decreaseButton} className='font-decrease'>{translateThis('A')}</Button>
                                        <Button onClick={normalButton} className='font-normal'>{translateThis('A')}</Button>
                                        <Button onClick={increaseButton} className='font-increase'>{translateThis('A')}</Button>
                                    </div>
                                    <p>{translateThis('Text Zoom')}</p>
                                </div>
                            </div>
                            <div className={`each-tool-items ${isExplanationVisible ? 'active' : ''}`}>
                                <Button className='toolButton' onClick={() => onClickEach('explanation')}>
                                    <span>{SVGIcons.InfoIcon()}</span>
                                    <p>{translateThis('Explanation')}</p>
                                </Button>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>}
            </Box>
        </>
    )
}

TestHeader.propTypes = {
    data: PropTypes.any,
    isCalcVisible: PropTypes.bool,
    isLabVisible: PropTypes.bool,
    isExplanationVisible: PropTypes.bool,
    getHeaderHeight: PropTypes.any,
    activeQuestion: PropTypes.any,
    questions: PropTypes.any,
    qnIndex: PropTypes.any,
    onNavigate: PropTypes.any,
    test: PropTypes.any,
    onClickItem: PropTypes.any
}
export default TestHeader;