import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import '../home.scss';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';


const PrivacyPolicy = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqItems = [
        {
            panelCount: 'panel1',
            title: 'policyIntroduction',
            content: <><p>{translateThis('Welcome to XMed Pro, your trusted partner in USMLE preparation. XMed Pro is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our software and services. By using XMed Pro, you agree to the practices described in this Privacy Policy.')}</p>
            </>,
        },
        {
            panelCount: 'panel2',
            title: 'Information We Collect',
            content: <>
                <h4>{translateThis('Personal Information')}</h4>
                <p>{translateThis('When you register for XMed Pro, we may collect the following personal information:')}</p>
                <ul>
                    <li>{translateThis('privacyName')}</li>
                    <li>{translateThis('privacyEmail address')}</li>
                    <li>{translateThis('privacyPhone number')}</li>
                    <li>{translateThis('Other details such as: professional background, specialty, school attended, etc.')}</li>
                    <li>{translateThis('Payment information (if applicable)')}</li>
                </ul> <br/>
                <h4>{translateThis('Usage Data')}</h4>
                <p>{translateThis('We collect information on how you interact with our software, including:')}</p>
                <ul>
                    <li>{translateThis('IP address')}</li>
                    <li>{translateThis('Browser type and version')}</li>
                    <li>{translateThis('Pages visited')}</li>
                    <li>{translateThis('Time spent on pages')}</li>
                    <li>{translateThis('Referring website addresses')}</li>
                    <li>{translateThis('Diagnostic data for error reporting')}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel3',
            title: 'How We Use Your Information',
            content: <>
            <p>{translateThis('XMed Pro uses the information we collect for various purposes, including:')}</p>
                <ul>
                    <li>{translateThis('Providing and maintaining our services')}</li>
                    <li>{translateThis('Processing your transactions')}</li>
                    <li>{translateThis('Communicating with you regarding updates, offers, and support')}</li>
                    <li>{translateThis('Improving our software and services')}</li>
                    <li>{translateThis('Analyzing usage patterns to enhance user experience')}</li>
                    <li>{translateThis('Ensuring compliance with legal obligations')}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel4',
            title: 'Sharing Your Information',
            content: <>
            <p>{translateThis('We do not sell or rent your personal information to third parties. We may share your information with:')}</p>
                <ul>
                    <li>{translateThis('Service providers who assist us in operating our services (e.g., payment processors, cloud storage providers)')}</li>
                    <li>{translateThis('Law enforcement or regulatory bodies if required by law')}</li>
                    <li>{translateThis('Third parties with your explicit consent')}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel5',
            title: 'Data Security',
            content: <p>{translateThis('XMed Pro takes the security of your personal information seriously. We employ industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. These measures include encryption, firewalls, and secure server environments.')}</p>,
        },
        {
            panelCount: 'panel6',
            title: 'Data Retention',
            content: <p>{translateThis('We retain your personal information for as long as necessary to provide you with our services, comply with legal obligations, resolve disputes, and enforce our agreements. When your information is no longer needed, we will securely delete or anonymize it.')}</p>,
        },
        {
            panelCount: 'panel7',
            title: 'Your Rights',
            content: <>
            <p>{translateThis('You have the following rights regarding your personal information:')}</p>
                <ul>
                    <li>{translateThis('Access: You can request a copy of the personal information we hold about you.')}</li>
                    <li>{translateThis('Correction: You can request corrections to any inaccurate or incomplete information.')}</li>
                    <li>{translateThis('Deletion: You can request the deletion of your personal information, subject to legal requirements.')}</li>
                    <li>{translateThis('Objection: You can object to the processing of your personal information under certain circumstances.')}</li>
                    <li>{translateThis('Portability: You can request a copy of your information in a structured, commonly used, and machine-readable format.')}</li>
                </ul>
                <p>{translateThis('To exercise these rights, please contact us at')}&nbsp;<Link style={{color: 'inherit'}} to={'mailto: hello@xmedpro.com'}>{translateThis('hello@xmedpro.com')}</Link>.</p>
            </>,
        },
        {
            panelCount: 'panel8',
            title: "Children's Privacy",
            content: <p>{translateThis('XMed Pro does not knowingly collect or solicit personal information from children under the age of 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information promptly.')}</p>,
        },
        {
            panelCount: 'panel9',
            title: 'Changes to This Privacy Policy',
            content: <p>{translateThis('We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. Your continued use of XMed Pro after any changes to this Privacy Policy signifies your acceptance of the revised terms.')}</p>,
        },
        {
            panelCount: 'panel10',
            title: 'Contact Us',
            content: <p>{translateThis('If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:')}&nbsp;<Link style={{color: 'inherit'}} to={'mailto: hello@xmedpro.com'}>{translateThis('hello@xmedpro.com')}</Link> </p>,
        },
        {
            panelCount: 'panel11',
            title: 'Appendix: Legal Requirements',
            content: <>
            <p>{translateThis('This Privacy Policy conforms to the requirements of the following US regulations:')}</p>
                <ul>
                    <li>{translateThis('California Consumer Privacy Act (CCPA)')}</li>
                    <li>{translateThis('General Data Protection Regulation (GDPR) for applicable users')}</li>
                    <li>{translateThis('Health Insurance Portability and Accountability Act (HIPAA) where applicable')}</li>
                </ul>
            </>,
        },

    ];

    return (
        <>
            <div className='faq-accordion-wrp f-wrp'>
                {faqItems.map((element, key) => (
                    <Accordion className='each-accordian-sec' expanded={expanded === element.panelCount} onChange={handleChange(element.panelCount)} key={key}>
                        <AccordionSummary
                            // expandIcon={SVGIcons.ChevronDownIcon()}
                            aria-controls={`${element.panelCount}bh-content`}
                            id={`${element.panelCount}bh-header`}
                        >
                            {expanded === element.panelCount ? <span>{SVGIcons.MinusIcon()}</span> : <span>{SVGIcons.PlusIcon()}</span>}
                            <h4>{translateThis(element.title)}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>{element.content}</div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default PrivacyPolicy;