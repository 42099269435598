import React from 'react';
import './profileWelcomePopup.scss';
import AppIcons from '../../../assets/images/icons';
import { translateThis } from '../../../helpers/language.helper';

const ProfileWelcomePopup = () => {
    return (
        <>
            <div className='subscription-popup-wrp welcome-popup-wrp f-wrp'>
                <span><img src={AppIcons.profileComplete} alt='subscribe' /></span>
                <h2>{translateThis('Welcome aboard!')}</h2>
                <p>{translateThis('Excited to have you on board! Enhance your experience by completing your profile, it takes just a few seconds and it helps us tailor our services just for you!')}</p>
            </div>
        </>
    )
}

export default ProfileWelcomePopup;