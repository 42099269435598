import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// Components
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';


import { translateThis } from '../../../../../helpers/language.helper';
import AppTable from '../../../../../components/table/index';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';

import { DATE_FORMAT } from '../../../../../constants/index';
import { getCurrentFacilityURL } from '../../../../../helpers/helper.functions';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import AppIcons from '../../../../../assets/images/icons';
import AppPopOverMenu from '../../../../../components/appPopOverMenu';




const PreviousAttemptsTable = ({ exam, onCancelClick }) => {
    const apiCaller = new ApiCaller('universityExams');
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getData(`get-completed-attempts/${exam._id}`)
            .then((data) => applyDataArr(data.completedAttempts.map((res, i) => ({ ...res, slNo: i + 1, id: res.attemptId })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'slNo',
            headerName: 'S.No',
            minWidth: 80,
            maxWidth: 80,
            flex: 0.5,
        },
        {
            field: 'noOfQuestions',
            headerName: "Q's",
            minWidth: 40,
            maxWidth: 40,
            flex: 0.5,
        },
        {
            field: 'totalCorrectPercentage',
            headerName: 'Score',
            minWidth: 80,
            maxWidth: 80,
            flex: 0.8,
            renderCell: ({ row }) => <>
                <span className='score'>{(row.totalCorrectPercentage && row.totalCorrectPercentage !== 'NaN') ? row.totalCorrectPercentage : 0}%</span>
            </>
        },

        {
            field: 'startTime',
            headerName: 'Date',
            flex: 1,
            minWidth: 135,
            maxWidth: 135,
            renderCell: ({ row }) => dayjs(row.startTime).format(DATE_FORMAT)
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            flex: 3,
            minWidth: 225,
            renderCell: ({ row }) => {
                const subjects = row?.questions.map((q) => q.populatedSubjects).flat()
                return (subjects && subjects.length > 0) ? <div className='flex area'>
                    <span className='chip-sub' style={{ maxWidth: '150px' }}  > {`${subjects[0].subjectName}`}</span>
                    {(subjects.length > 1) ? <Tooltip title={<>{subjects.map((sub) => <span style={{ fontSize: '14px' }} key={sub?.subjectId}> {`${sub?.subjectName},`}</span>)}</>} placement="top">
                        <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                    </Tooltip> : null}
                </div> : '-'
            }
        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 1,
            width: 115,
            minWidth: 115,
            maxWidth: 115,
            sortable: false,
            renderCell: ({ row }) => <>
                <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    <Link className='label-button' to={`/${getCurrentFacilityURL()}/exam-view/${row.id}`}>{translateThis('View')}</Link>
                    {/* {row.status !== 'FINISH' && <Link className='label-button' to={`/${getCurrentFacilityURL()}/start-test/${row.id}`}>{translateThis('Resume')}</Link>} */}
                </div>
            </>
        },
    ]

    return (
        <div className='main-category-content'>
            <div className='block-table-wrp prevAttempt-table f-wrp'>
                <Button className='closebtn' onClick={onCancelClick} variant='text' type='button'  ><SVGIcons.BackIcon /> {translateThis('Back')} </Button>
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                    {/* <Link className='label-button' to={`/${getCurrentFacilityURL()}/exam-view}`}>{translateThis('View')}</Link> */}
                    {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                        <div className='mob-table-wrp childView prevTest f-wrp'>
                            <div className='mob-table-search'>
                                <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                            </div>
                            {filteredDataArr.length > 0 ? <ul>
                                {filteredDataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box' style={{width: '65px'}}>
                                            <div className='status-with-btn'>
                                                <span className='score'>{(element.totalCorrectPercentage && element.totalCorrectPercentage !== 'NaN') ? element.totalCorrectPercentage : 0}%</span>
                                                {element.status !== 'FINISH' ?
                                                    <AppPopOverMenu icon={SVGIcons.StatusMobArrow()} options={[
                                                        {
                                                            label: <>{element.status !== 'FINISH' && <Link className='label-button' to={`/${getCurrentFacilityURL()}/start-test/${element.id}`}>{translateThis('Resume')}</Link>}</>,
                                                            onClick: () => {
                                                                navigator(`/${getCurrentFacilityURL()}/start-test/${element.id}`)
                                                            }
                                                        },
                                                    ]} /> : null}
                                            </div>
                                            <span>{element.slNo}</span>
                                        </div>
                                        <div className='each-sec-box' style={{width: 'calc(100% - 65px)'}}>
                                            <h4>{dayjs(element.createdAt).format(DATE_FORMAT)}</h4>
                                            {(element?.questions.map((q) => q.populatedSubjects).flat().length > 0) ? <div className='flex-wrp' style={{ display: 'block' }}>
                                                <p className='subjectList'> <span>{translateThis('Subject')}: </span>{element?.questions.map((q) => q.populatedSubjects).flat().map((sub) => <i key={sub?.subjectId}> {`${sub?.subjectName},`}</i>)}</p>
                                            </div> : <span>{translateThis('Subject')}: - </span>}
                                            <div className='flex-wrp'>
                                                <span style={{ flex: '1' }}>{translateThis('Qs')}: {element.noOfQuestions ? element.noOfQuestions : '-'}</span>
                                            </div>
                                        </div>
                                        <Link className='full-li-btn' to={`/${getCurrentFacilityURL()}/exam-view/${element.id}`} onClick={() => { navigator(`/${getCurrentFacilityURL()}/exam-view/${element.id}`) }} />
                                    </li >
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.placeholderTable} alt='empty' />
                                    <h4>{translateThis('File not found')}</h4>
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}
PreviousAttemptsTable.propTypes = {
    exam: PropTypes.any,
    onCancelClick: PropTypes.any,
}

export default PreviousAttemptsTable;
