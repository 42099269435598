import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { translateThis } from '../../helpers/language.helper';
import { getAuthRoleName } from '../../helpers/auth.helper';
import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/snackbar/index';

import AppLayout from '../../layouts/app/AppLayout';
import { DATE_FORMAT, STUDENT_ROLE, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../constants/index';
// import SVGIcons from '../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../studentPortal/components/headerRightUserInfo';
import UserDrawer from '../../components/drawer/drawer';
import './index.scss';
import AppPopOverMenu from '../../components/appPopOverMenu';
import RichTextViewer from '../../components/rich-text-editor/TextViewer';
import InboxView from './inboxView';
import ModalPopUp from '../../components/modal/modal.popup';
import AppButton from '../../components/buttons/app.button';
import NewMessage from './newMessage';
import AppIcons from '../../assets/images/icons';
import SVGIcons from '../../assets/images/icons/svgIcons';




const MyMessagesList = () => {
    const apiCaller = new ApiCaller('inbox');
    const [openViewMessageDialog, setOpenViewMessageDialog] = useState(false);
    const [openCreateMessageDialog, setOpenCreateMessageDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({});
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [

        {
            field: 'subject',
            headerName: 'Subject',
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 1.3,
            minWidth: 160,
            renderCell: ({ row }) => <RichTextViewer richText={row.message} />,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 0.7,
            minWidth: 160,
            renderCell: ({ row }) => dayjs(row.createdAt).format(DATE_FORMAT)
        },
        {
            field: 'id',
            headerName: 'Action',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => <AppPopOverMenu options={[
                {
                    label: `${translateThis('View')}`,
                    onClick: () => {
                        setOpenViewMessageDialog(true);
                        setSelectedMessage(row);
                    }
                },

            ]} />
        },
    ]

    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Inbox")}</h2>
                                <HeaderRightUserInfo /> 
                            </div>
                            <div className='main-category-content'>
                                <div className={`block-table-wrp parent-inbox-wrp f-wrp ${getAuthRoleName() === STUDENT_ROLE ? 'parent-inbox-btn' : null}`}>

                                    <div className='panel-table-sec user-table inbox-table provider-table PC-Table f-wrp'>
                                        {getAuthRoleName() === STUDENT_ROLE ? <div className='inbox-button'>
                                            <AppButton
                                                style={{ background: 'var(--accent-accent-700-Clr)' }}
                                                onClick={() => setOpenCreateMessageDialog(true)}
                                                startIcon={<Add fontSize='small' />} btnText={translateThis('New Message')} />
                                        </div> : null}
                                        <AppTable
                                            columns={tableColumns}
                                            isLoading={isLoading}
                                            data={dataArr}
                                        />

                                        {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                                            <div className='mob-table-wrp childView f-wrp'>
                                                <div className='mob-table-search'>
                                                    <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                                                </div>

                                                {filteredDataArr.length > 0 ? <ul>
                                                    {filteredDataArr.map((element) => (
                                                        <li key={element.id}>
                                                            <div className='each-sec-box' style={{ width: '50px' }}>
                                                                <div className='status-with-btn'>
                                                                    <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                                                    <h4 style={{ fontWeight: 'normal' }}>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                                                </div>
                                                            </div>
                                                            <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                                                <div className='flex-wrp auto-flex flex-gap5 text-overflow-elips' style={{ maxWidth: 'calc(100% - 0px)', overflow: 'hidden' }}>
                                                                    {(element.subject) ? <span style={{ display: 'block' }}>{translateThis('Subject')}: {(element.subject)} </span> : <span style={{ display: 'block' }}>{translateThis('Subject')} - </span>}
                                                                </div>
                                                                <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 0px)', overflow: 'hidden' }}>
                                                                    {(element.message) ? <span className='rich-text-msg'><RichTextViewer richText={element.message} /></span> : '-'}
                                                                </div>


                                                            </div>

                                                            <button type='button' className='full-li-btn' onClick={() => {
                                                                setOpenViewMessageDialog(true);
                                                                setSelectedMessage(element);
                                                            }} />
                                                        </li >
                                                    ))}
                                                </ul> : <div className='empty-placeholder f-wrp'>
                                                    <div className='placeholder-con'>
                                                        <img src={AppIcons.placeholderTable} alt='empty' />
                                                        <h4>{translateThis("You haven't send any messages yet")}</h4>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        }

                                        {openViewMessageDialog && <ModalPopUp
                                            isOpen={openViewMessageDialog}
                                            onClose={() => {
                                                setOpenViewMessageDialog(false);
                                            }}
                                            className={'modal-popup inbox-popup'}
                                            children={<div>

                                                <InboxView data={selectedMessage} />
                                            </div>}

                                        />}
                                        {openCreateMessageDialog && <ModalPopUp
                                            isOpen={openCreateMessageDialog}
                                            onClose={() => {
                                                setOpenCreateMessageDialog(false);
                                            }}
                                            className={'modal-popup inbox-popup new-message-popup'}
                                            children={<div>

                                                <NewMessage
                                                    onClose={() => setOpenCreateMessageDialog(false)}
                                                    onSuccess={() => {
                                                        setOpenCreateMessageDialog(false);
                                                        getDataArr();
                                                    }}
                                                />
                                            </div>}

                                        />}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout >
    )
}
MyMessagesList.propTypes = {}

export default MyMessagesList;
