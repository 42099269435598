import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link, useParams } from 'react-router-dom';
import { Box, Divider, Grid, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { translateThis } from '../../../../../helpers/language.helper';
import AppTable from '../../../../../components/table/index';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';

import AppLayout from '../../../../../layouts/app/AppLayout';
import { DATE_FORMAT } from '../../../../../constants/index';
import { convertSecondsToHoursMins, getCurrentFacilityURL } from '../../../../../helpers/helper.functions';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../../../components/headerRightUserInfo';
import UserDrawer from '../../../../../components/drawer/drawer';
// import './previousTestList.scss';
import AppIcons from '../../../../../assets/images/icons';
import ModalPopUp from '../../../../../components/modal/modal.popup';
import { AppDoughnutChart } from '../../../../../components/charts/donut.chart';
// import SubjectPerformanceTable from './performanceTable/subjectPerformanceTable';
// import SystemPerformanceTable from './performanceTable/systemPerformanceTable';
// import TopicPerformanceTable from './performanceTable/topicPerformanceTable';
// import PreviousAttemptsQuestionList from './qnAnswerPopup/previousAttemptsQuestionList';
import RichTextViewer from '../../../../../components/rich-text-editor/TextViewer';
import AppLoader from '../../../../../components/appLoader';
import QuestionAndAnswerPopup from './questionAndAnswerPopup';




const UnivExamDetailsPage = () => {
    const apiCaller = new ApiCaller('universityExams');
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);
    const [isStudentAttemptsLoading, setIsStudentAttemptsLoading] = useState(false);
    const [isQuestionsLoadClicked, setIsQuestionsLoadClicked] = useState(false);
    const [isStudentAttemptsClicked, setIsStudentAttemptsClicked] = useState(false);
    const [data, setData] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [studentAttempts, setStudentAttempts] = useState([]);

    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedQn, setSelectedQn] = React.useState(null);

    const onViewClose = () => {
        setViewOpen(false);
        setSelectedQn(null);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getData()
        return () => {
            setData(null)
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(`get-exam-details-university/${id}`)
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get questions from api on demand
    const getQuestions = () => {
        setIsQuestionsLoading(true);
        setIsQuestionsLoadClicked(true);
        apiCaller.getData(`get-exam-question-list-university/${id}`)
            .then((data) => setQuestions(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsQuestionsLoading(false));
    }

    // get attempt from api on demand
    const getStudentAttempts = () => {
        setIsStudentAttemptsLoading(true);
        setIsStudentAttemptsClicked(true);
        apiCaller.getData(`get-exam-student-attempt-list-university/${id}`)
            .then((data) => setStudentAttempts(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsStudentAttemptsLoading(false));
    }



    /**
     * Columns array to show 
     */
    const questionTableColumns = [
        {
            field: 'questionNumber',
            headerName: 'Q-ID',
            flex: 0.4,
            minWidth: 70,
        },
        {
            field: 'question',
            headerName: 'Question',
            flex: 1,
            minWidth: 305,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question?.question} /></>
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            minWidth: 160,
            flex: 2,
            valueFormatter: ({ value }) => (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
        },
        {
            field: 'populatedSystems',
            headerName: 'Systems',
            minWidth: 160,
            flex: 2,
            valueFormatter: ({ value }) => (value && value.length > 0) ? value.map((sub) => `${sub?.systemName}`) : '-'
        },
        {
            field: 'attempts',
            headerName: 'Attempts',
            flex: 0.4,
            minWidth: 90,
        },
        {
            field: 'correctPercentage',
            headerName: 'Correct',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}><SVGIcons.StatusActiveIcon style={{ width: '21px', height: '21px' }} /> &nbsp; {(row.correctPercentage) ? row.correctPercentage : '0'}%</span>
            </>
        },
        {
            field: 'incorrectPercentage',
            headerName: 'Incorrect',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>
                <span className='incorrect' style={{ display: 'flex' }}><SVGIcons.CrossIcon style={{ width: '14px', height: '21px' }} /> &nbsp; {(row.incorrectPercentage) ? row.incorrectPercentage : '0'}%</span>
            </>
        },
        {
            field: 'omittedPercentage',
            headerName: 'Omitted',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}><SVGIcons.StatusOmittedIcon style={{ width: '21px', height: '21px' }} /> &nbsp; {(row.omittedPercentage && row.omittedPercentage !== 'NaN') ? row.omittedPercentage : '0'}%</span>
            </>
        },
        {
            field: 'Time ',
            headerName: 'Avg. Time',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => <>{(row.avgTimeTaken && row.avgTimeTaken !== 'NaN') ? row.avgTimeTaken : '0'} {translateThis('sec')}</>
        },
        {
            field: 'action ',
            headerName: '',
            flex: 0.3,
            minWidth: 30,
            renderCell: ({ row }) => <Link onClick={() => {
                setSelectedQn(row?.question);
                onViewOpen()
            }}><SVGIcons.ChevronRightIcon style={{ width: '15px' }} /></Link>
        },
    ]

    const studentColumnsQn = [
        {
            field: 'attemptNumber',
            headerName: 'Attempts',
            width: 90,
            headerAlign: 'center',
            align: 'center',
        }, {
            field: 'name',
            headerName: 'Name',
            minWidth: 50,
            flex: 2,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}>{row.name} {row.lastName || ''}</span>
            </>
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 180,
            flex: 1.9,

        },
        {
            field: 'dateTaken',
            headerName: 'Date taken',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}>{dayjs(row.dateTaken).format(DATE_FORMAT)}</span>
            </>
        },
        {
            field: 'correctPercentage',
            headerName: 'Correct',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}><SVGIcons.StatusActiveIcon style={{ width: '21px', height: '21px' }} /> &nbsp; {(row.correctPercentage) ? row.correctPercentage : '0'}%</span>
            </>
        },
        {
            field: 'incorrectPercentage',
            headerName: 'Incorrect',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <>
                <span className='incorrect' style={{ display: 'flex' }}><SVGIcons.CrossIcon style={{ width: '14px', height: '21px' }} /> &nbsp; {(row.incorrectPercentage) ? row.incorrectPercentage : '0'}%</span>
            </>
        },
        {
            field: 'omittedPercentage',
            headerName: 'Omitted',
            flex: 0.5,
            minWidth: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex' }}><SVGIcons.StatusOmittedIcon style={{ width: '21px', height: '21px' }} /> &nbsp; {(row.omittedPercentage && row.omittedPercentage !== 'NaN') ? row.omittedPercentage : '0'}%</span>
            </>
        },
        {
            field: 'Time ',
            headerName: 'Avg. Time',
            flex: 0.5,
            minWidth: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => <>{(row.averageTime && row.averageTime !== 'NaN') ? row.averageTime : '0'} {translateThis('sec')}</>
        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) => <>
                <Link className='table-link-icon' to={`/${getCurrentFacilityURL()}/exam-logs/attempt-view/${row.id}`} type='button' onClick={() => null}>{SVGIcons.ChevronRightIcon()}</Link>
            </>
        }
    ]

    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Exam results")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            {!isLoading ? <div className='main-category-content prev-attempts-view exam-result-wrp'>
                                <div className='block-table-wrp user-table f-wrp'>
                                    <div className='back-btn-list'>
                                        <div className='left-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/exam-logs`}><SVGIcons.BackIcon /> {translateThis('Back')}</Link>
                                        </div>
                                        <div className='centre-btn-sec'>
                                            {/* <h3 style={{ margin: '0' }}>{data?.name || ''}</h3> */}
                                        </div>
                                        <div className='right-btn-sec'>
                                            {/* <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.PrintIcon /> {translateThis('Print')}</Link> */}
                                            {/* <Link className='pln-button' onClick={onViewOpen} to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.QnListIcon /> {translateThis('Question list')}</Link> */}
                                            {/* <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.TestEyeIcon /> {translateThis('Test Revision')}</Link> */}
                                        </div>
                                    </div>

                                    <Stack className='exam-preview-wrp' spacing={3} style={{ border: 'none', padding: '20px 0px 20px 0px' }}>
                                        <div className='exam-basic-info'>
                                            {/* <span className='exam-logo'><img src={AppIcons.previewIcon} alt='logo' /></span> */}
                                            <h1>{data?.name || ''}</h1>
                                            <p>{data?.introduction || ''}</p>
                                            <h3 style={{ marginTop: '20px' }}>{translateThis("Basic info")}</h3>
                                            <ul className='basic-list'>

                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.CalendarPlusIcon} alt='icon' /><b>{translateThis("Creation")}: </b><span>{dayjs(data?.createdAt).format(DATE_FORMAT)}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.Date} alt='icon' /><b>{translateThis("Date")}: </b><span>
                                                            {data?.dateType === 'within range' ? `Start: ${dayjs(data?.startDate).format(DATE_FORMAT)},${data?.startTime} - 
                                                            End: ${dayjs(data?.endDate).format(DATE_FORMAT)},${data?.endTime} ` : data?.dateType}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.selfAssessPlaceholder} alt='icon' /><b>{translateThis("AccessType")}: </b><span>{data.isSubscriptionNeeded ? 'Subscription required' : 'Public'}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.Time} alt='icon' /><b>{translateThis("Available time")}: </b><span>{data.duration}&nbsp;{data.durationMetric}{data.duration > 1 ? 's' : ''}</span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.testMode} alt='icon' /><b>{translateThis("Question pool")}: </b><span>{data.questionPool}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.number} alt='icon' /><b>{translateThis("Questions")}: </b><span>{data?.numberOfQuestions || ''}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.Difficulty} alt='icon' /><b>{translateThis("Difficulty")}: </b><span>{data?.difficulty || ''}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.score} alt='icon' /><b>{translateThis("Scoring")}: </b><span>{data?.questionScore || ''}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.StatusLockIcon} alt='icon' /><b>{translateThis("Status")}: </b><span>{data?.status || ''}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.Attempts} alt='icon' /><b>{translateThis("Attempts")}: </b><span>{data?.numberOfAttempts || ''}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.TimeTakenIcon} alt='icon' /><b>{translateThis("Times taken")}: </b><span>{data?.timesTaken || ''}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.StudGroupIcon} alt='icon' /><b>{translateThis("Enrolled")}: </b><span>{data?.enrolledCount || 'Anyone with the link'}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.Students} alt='icon' /><b>{translateThis("Takers")}: </b><span>{data?.studentsAttempted || '0'}</span>
                                                    </div>
                                                </li>
                                                {/* <li>
                                                    <div className='each-basic-items'>
                                                        <img className='icons' src={AppIcons.Students} alt='icon' /><b>{translateThis("Students")}: </b><span>{data?.numberOfStudents || 'NA'}</span>
                                                    </div>
                                                </li> */}
                                            </ul>
                                        </div>

                                        <div className='eachExam-detail-wrp'>
                                            <h3>{translateThis("Exam Details")}</h3>
                                            <RichTextViewer richText={data?.description} />
                                        </div>
                                        <div className='eachExam-detail-wrp'>
                                            <h3>{translateThis("Prepare for the exam")}</h3>
                                            <p>{translateThis("Create a structured study plan that allocates sufficient time to the following suggested topics")}</p>

                                            {(data?.populatedTopics && data?.populatedTopics.length > 0) ? <ul className='chip-list'>
                                                {data?.populatedTopics.map((topic) => <li key={topic.id}><span className='chip'>{topic.topicName}</span></li>)}
                                            </ul> : <p>{translateThis("No topics found")}</p>}
                                        </div>
                                    </Stack>

                                    <div className='header-wrp f-wrp' style={{ paddingTop: '0', paddingBottom: '20px' }}>
                                        <h3>{translateThis('Overall performance')}</h3>
                                    </div>

                                    <div className='prev-view-boxes stud-home f-wrp' style={{ paddingBottom: '20px' }}>
                                        <div className='statistics-info-wrapper '>
                                            <Grid className='basic-info-grid' container spacing={1}>

                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Student performance')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Performance')}:</b> {translateThis('a pie graph depicting correct, incorrect, and omitted responses, alongside counts and percentages. This includes repeated questions.')}</span>} placement="top">
                                                            <span className='tool-button'>{SVGIcons.InfoI()}</span>
                                                        </Tooltip></h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            <span className='graph-img'>
                                                                <h2 className='graph-label'>{translateThis('Correct')}</h2>
                                                                <AppDoughnutChart data={{
                                                                    labels: [`${translateThis('Correct')}`, `${translateThis('Incorrect')}`, `${translateThis('Omitted')}`],
                                                                    datasets: [
                                                                        {
                                                                            label: '',
                                                                            total: `${data?.overallPerformance?.correctPercentage}`,
                                                                            totalScore: `${data?.overallPerformance?.correctPercentage}`,
                                                                            fontStyle: 'bold 26px sans-serif',
                                                                            data: [`${data?.overallPerformance?.correctPercentage}`, `${data?.overallPerformance?.incorrectPercentage}`, `${data?.overallPerformance?.omittedPercentage}`],
                                                                            backgroundColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }} />
                                                            </span>
                                                            <div className='graph-content'>
                                                                {/* <span>{translateThis('From')} {data?.overallPerformance?.totalQuestions} {translateThis('questions, you answered')}:</span> */}
                                                                {/* <span>{translateThis('Based on')} {data?.studentsAttempted} {translateThis('takers’ best attempt')}:</span> */}
                                                                <span>{translateThis('Calculated on the best attempt of')} {data?.studentsAttempted} {translateThis('students')}</span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.correct} alt='graph' /> &nbsp; {data?.overallPerformance?.correctPercentage}% {translateThis('Correct')}</p>
                                                                        <span>{data?.overallPerformance?.totalCorrectQuestions} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.incorrect} alt='graph' /> &nbsp; {data?.overallPerformance?.incorrectPercentage}% {translateThis('Incorrect')}</p>
                                                                        <span>{data?.overallPerformance?.totalIncorrectQuestions} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.omitted} alt='graph' /> &nbsp; {data?.overallPerformance?.omittedPercentage}% {translateThis('Omitted')}</p>
                                                                        <span>{data?.overallPerformance?.totalOmittedQuestions} {translateThis('questions')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <div className='each-statistics-box statistics'>
                                                        <h3>{translateThis('Statistics')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Statistics')}:</b> {translateThis('calculated based on the total times taken, this includes repeated attempts by takers')}</span>} placement="top">
                                                            <span className='tool-button'>{SVGIcons.InfoI()}</span>
                                                        </Tooltip></h3>
                                                        <div className='basic-detail-box no-chart'>
                                                            <div className='graph-content'>
                                                                {/* <span>{translateThis('In')} {data?.statistics?.totalAttempts} {translateThis('attempts')}:</span> */}
                                                                <span>{translateThis('Total times taken')}</span>
                                                                <p>{data?.timesTaken} {translateThis('attempts')}</p>
                                                                <div className='flex-box'>
                                                                    <ul className='median-list'>
                                                                        <li>
                                                                            <span>{translateThis('Mean score')}</span>
                                                                            <p>{Math.round(data?.statistics?.meanScore)}%</p>
                                                                        </li>
                                                                        <li>
                                                                            <span>{translateThis('Median score')}</span>
                                                                            <p>{Math.round(data?.statistics?.medianScore)}%</p>
                                                                        </li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>
                                                                            <span>{translateThis('Mean average time per question')}</span>
                                                                            <p>{Math.round(data?.statistics?.meanAvgTimePerQuestion)} {translateThis('seconds/question')}</p>
                                                                        </li>
                                                                        <li>
                                                                            <span>{translateThis('Mean average time per exam')}</span>
                                                                            <p>{convertSecondsToHoursMins(data?.statistics?.medianAvgTimePerExam)}</p>
                                                                        </li>
                                                                    </ul>

                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </div>

                                    <Divider />

                                    <div className='eachQn-box accordian-box f-wrp' style={{ paddingTop: '20px', border: 'none', paddingLeft: '0' }}>
                                        <Accordion className='each-accordian-sec' onChange={(e, expanded) => {
                                            if (expanded) {
                                                getQuestions()
                                            }
                                        }}>
                                            <AccordionSummary
                                                expandIcon={SVGIcons.ChevronDownIcon()}
                                                aria-controls="questionDetail-content"
                                                id="questionDetail-header"
                                            >
                                                <div className='header-wrp f-wrp'>
                                                    <h3>{translateThis('Question details')} </h3>
                                                    {/* <Button type='button' onClick={getQuestions}>View</Button> */}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {isQuestionsLoadClicked && <div className='panel-table-sec  provider-table f-wrp' style={{ paddingTop: '20px' }}>
                                                    <AppTable
                                                        columns={questionTableColumns}
                                                        isLoading={isQuestionsLoading}
                                                        data={questions || []}
                                                    />
                                                </div>}
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>

                                    <Divider />

                                    <div className='eachQn-box accordian-box f-wrp' style={{ paddingTop: '20px', border: 'none', paddingLeft: '0' }}>
                                        <Accordion className='each-accordian-sec' onChange={(e, expanded) => {
                                            if (expanded) {
                                                getStudentAttempts()
                                            }
                                        }}>
                                            <AccordionSummary
                                                expandIcon={SVGIcons.ChevronDownIcon()}
                                                aria-controls="AttemptList-content"
                                                id="AttemptList-header"
                                            >
                                                <div className='header-wrp f-wrp'>
                                                    <h3>{translateThis('Attempt list')}</h3>
                                                    {/* <Button type='button' onClick={getQuestions}>View</Button> */}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {isStudentAttemptsClicked && <div className='panel-table-sec  provider-table f-wrp' style={{ paddingTop: '20px' }}>
                                                    <AppTable
                                                        columns={studentColumnsQn}
                                                        isLoading={isStudentAttemptsLoading}
                                                        data={studentAttempts || []}
                                                    />
                                                </div>}
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </div> : <AppLoader />}
                        </div>
                    </Box>
                </Box>
            </Box>
            {(viewOpen && selectedQn) ? <ModalPopUp
                className='preview-popup questionAndAnswer-Popup'
                isOpen={viewOpen}
                onClose={onViewClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                <QuestionAndAnswerPopup question={selectedQn} />
            </ModalPopUp> : null}
        </AppLayout>
    )
}
UnivExamDetailsPage.propTypes = {}

export default UnivExamDetailsPage;
