import * as React from 'react';
import { Box } from '@mui/material';

import { translateThis } from '../../../../../helpers/language.helper';

const SerumTab = () => {
    
    return (
        <>
            
            <Box className='SerumTab-wrapper'>
                    <div className='SerumTab-content f-wrp'>
                        <table>
                            <thead>
                                <th>{translateThis('serum')}</th>
                                <th>{translateThis('Reference range')}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>{translateThis('Alanine aminotransferase (ALT)')}</p></td>
                                    <td><p>{translateThis('10-40 U/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Alanine aminotransferase (ALT)')}</p></td>
                                    <td><p>{translateThis('12-38 U/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Alkaline phosphatase')}</p></td>
                                    <td><p>{translateThis('10-40 U/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('BilirubinTotalDirect')}</p></td>
                                    <td><p>{translateThis('0.1-1.0 mg/dL 0.0-0.3 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Calcium')}</p></td>
                                    <td><p>{translateThis('8.4-10.2 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Cholesterol Total Normal High HDL LDL')}</p></td>
                                    <td><p>{translateThis('<200 mg/dL >240mg/dL 40-60 mg/dL <160 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Triglycerides Normal Borderline')}</p></td>
                                    <td><p>{translateThis('<150 mg/dL 151-199 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Alkaline phosphatase')}</p></td>
                                    <td><p>{translateThis('8.4-10.2 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Cortisol 0800 h 1600 h 2000 h')}</p></td>
                                    <td><p>{translateThis('5-23 μg/dL 3-15 μg/dL <50% of 0800 h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Creatine kinase Male Female')}</p></td>
                                    <td><p>{translateThis('25-90 U/L 10-70 U/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Creatinine')}</p></td>
                                    <td><p>{translateThis('0.6-1.2 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Urea nitrogen')}</p></td>
                                    <td><p>{translateThis('7-18 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Electrolytes, serum Sodium (Na+) Potassium (K+) Chloride (CI) Bicarbonate (HCO3) Magnesium (Mg2+)')}</p></td>
                                    <td><p>{translateThis('136-146 mEq/L 3.5-5.0 mEq/L 95-105 mEq/L 22-28 mEq/L 1.5-2.0 mEq/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Ferritin Male Female')}</p></td>
                                    <td><p>{translateThis('20-250 ng/mL 10-120 ng/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Follicle-stimulating hormone Male Female premenopause midcycle peak postmenopause')}</p></td>
                                    <td><p>{translateThis('4-25 mIU/mL 4-30 mIU/mL 10-90 mIU/mL 40-250 mIU/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Glucose Fasting Random, non-fasting')}</p></td>
                                    <td><p>{translateThis('70-110 mg/dL <140 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Growth hormone- arginine stimulation Fasting Provocative stimuli')}</p></td>
                                    <td><p>{translateThis('<5 ng/mL >7 ng/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Iron Male Female')}</p></td>
                                    <td><p>{translateThis('65-175 μg/dL 50-170 μg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Total iron-binding capacity')}</p></td>
                                    <td><p>{translateThis('250-400 μg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Transferrin')}</p></td>
                                    <td><p>{translateThis('200-360 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Lactate dehydrogenase')}</p></td>
                                    <td><p>{translateThis('45-200 U/L')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Luteinizing hormone Male Female follicular phase midcycle postmenopause')}</p></td>
                                    <td><p>{translateThis('6-23 mIU/mL 5-30 mIU/mL 75-150 mIU/mL 30-200 mIU/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Osmolality')}</p></td>
                                    <td><p>{translateThis('275-295 mOsmol/kg H2O')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Intact parathyroid hormone (PTH)')}</p></td>
                                    <td><p>{translateThis('10-60 pg/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Phosphorus (inorganic)')}</p></td>
                                    <td><p>{translateThis('3.0-4.5 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Prolactin (hPRL) Male Female')}</p></td>
                                    <td><p>{translateThis('<17 ng/mL <25 ng/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Proteins Total Albumin Globulin')}</p></td>
                                    <td><p>{translateThis('6.0-7.8 g/dL 3.5-5.5 g/dL 2.3-3.5 g/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Troponin I')}</p></td>
                                    <td><p>{translateThis('<0.04 ng/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('TSH')}</p></td>
                                    <td><p>{translateThis('0.4-4.0 μU/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Thyroidal iodine (1231) uptake')}</p></td>
                                    <td><p>{translateThis('8%-30% of administered dose/24 h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Thyroxine (T4)')}</p></td>
                                    <td><p>{translateThis('5-12 μg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Free (T4)')}</p></td>
                                    <td><p>{translateThis('0.9-1.7 ng/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Triiodothyronine (T3) (RIA)')}</p></td>
                                    <td><p>{translateThis('100-200 ng/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Triiodothyronine (T3) resin uptake')}</p></td>
                                    <td><p>{translateThis('25%-35%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Uric acid')}</p></td>
                                    <td><p>{translateThis('3.0-8.2 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Immunoglobulins IgA IgE IgG IgM')}</p></td>
                                    <td><p>{translateThis('76-390 mg/dL 0-380 IU/mL 650-1500 mg/dL 50 300 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Gases, arterial blood (room air) pH PCO2 P02')}</p></td>
                                    <td><p>{translateThis('7.35-7.45 33-45 mm Hg 75-105 mm Hg')}</p></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                
            </Box>

        </>
    )
}

export default SerumTab;