import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// Css
import './index.scss'
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import ProviderPortalHeader from '../components/header';
import { usdFormatter } from '../../../helpers/currency.helper';
import { DATE_TIME_FORMAT } from '../../../constants/index';


const ProviderPaymentSection = () => {
    const apiCaller = new ApiCaller('payment/provider-payments')
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 2,
            minWidth: 170,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'parentId',
            headerName: 'Parent',
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            minWidth: 120,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT)

        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            minWidth: 100,
            valueFormatter: ({ value }) => usdFormatter(value)
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <span className={`payment-status status-${status}`}>{status}  </span>
            }
        },

    ]


    return (
        <>
            <ProviderPortalHeader header='Payments' />
            <div style={{ width: '100%', background: '#fff', borderRadius: '24px', padding: '24px' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                </div>
            </div>
        </>
    )
}

export default ProviderPaymentSection;