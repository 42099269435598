import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const C4KRedeterminationView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView f-wrp">

                <div className='prof-sec'>
                    {/* <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div> */}
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div>
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="lft-section">
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.c4k} alt='profile' /></span>
                    </div>
                    <div className="ryt-section" style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>Care 4 Kids Redetermination</b></h2>
                    </div>
                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>SECTION 1: Head of Household Information</b></h3>
                    <p>The head of household is the parent or adult legally responsible for the child(ren) and currently receiving Care 4 Kids benefits. If the parent is under the age of <b>18</b> and living with an adult, the adult is considered the applicant and must fill out and sign this Redetermination.</p>
                    <div className='gap' />
                    <div className='auto-flex'>
                        <p>C4K Case Number: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_caseNumber', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='flex-box'>
                        <p>First Name: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_firstName', formData)?.answer || '-'}</b></p>
                        <p>M.I.: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_MI', formData)?.answer || '-'}</b></p>
                        <p>Last Name: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_lastName', formData)?.answer || '-'}</b></p>
                        <p>Date of Birth: <b>{answerGetter('c4k_redetermination_sectionOne_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>Street Address: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_address', formData)?.answer || '-'}</b></p>
                        <p>Floor/Apartment number: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_floorNumber', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>City: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_city', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_state', formData)?.answer || '-'}</b></p>
                        <p>Zipcode: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_zipcode', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>Primary Phone: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_primaryPhone', formData)?.answer || '-'}</b></p>
                        <p>Work Phone: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_workPhone', formData)?.answer || '-'}</b></p>
                        <p>Social Security Number (optional): <b>{answerGetter('c4k_redetermination_sectionOne_form_question_socialSecurityNumber', formData)?.answer || '-'}</b></p>
                        <p>E-mail Address: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_email', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='check-box inline'>
                        <p>Gender:  </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_Gender", formData)?.answer === 'F (Female)' ? 'checked' : ''}>F (Female)</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_Gender", formData)?.answer === 'M (Male)' ? 'checked' : ''}>M (Male)</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>Marital Status:  </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Married' ? 'checked' : ''}>Married</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Single' ? 'checked' : ''}>Single</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Separated' ? 'checked' : ''}>Separated</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Divorced' ? 'checked' : ''}>Divorced</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>Does your household have assets that exceed $1 million in value? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN1", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN1", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>Is this Application for child care assistance for a foster child? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN2", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN2", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>Are you living in a temporary housing situation? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN3", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN3", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>Have you moved 3 or more times in the past year? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN4", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN4", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>Are you an active member of the United States Military? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    {answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5", formData)?.answer === 'Yes' ?
                        <div className='check-box inline'>
                            <p>(If YES, check box to the right) </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5_1", formData)?.answer === 'Active Duty U.S. Military' ? 'checked' : ''}>Active Duty U.S. Military</li>
                                <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5_1", formData)?.answer === 'National Guard Military Reserve' ? 'checked' : ''}>National Guard Military Reserve</li>
                            </ul>
                        </div> : null}

                    <div className='check-box inline'>
                        <p>Do you have an impairment that requires an accommodation or extra help completing this redetermination? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_6", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_6", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <p>Primary language spoken in your home <b>{answerGetter('c4k_redetermination_sectionOne_form_question_primaryLanguage', formData)?.answer || '-'}</b></p>
                    <div className='check-box inline'>
                        <ul>
                            <li className='checked'><b>Marque aquí si desea recibir cartas y formularios en español. (Check here to receive letters and forms in Spanish)</b></li>
                        </ul>
                    </div>

                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>SECTION 2: Information on the other parent living in your home</b></h3>
                    <p>You MUST list your spouse, civil union partner or other legal parent of your children that live in your home.</p>
                    <div className='gap' />
                    <div className='flex-box'>
                        <p>First Name, Middle Initial, Last Name: <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_Name', formData)?.answer || '-'}</b></p>
                        <p>Date of Birth: <b>{answerGetter('c4k_redetermination_sectionTwo_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                        <p>Relationship to Applicant: <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_relationship', formData)?.answer || '-'}</b></p>
                        <p>Social Security Number (optional): <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_socialSecurityNumber', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline'>
                        <p>Gender:  </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_Gender", formData)?.answer === 'F (Female)' ? 'checked' : ''}>F (Female)</li>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_Gender", formData)?.answer === 'M (Male)' ? 'checked' : ''}>M (Male)</li>
                        </ul>
                    </div>
                    <div className='auto-flex'>
                        <div className='check-box inline'>
                            <p>Is this person a parent of a child living in the home?  </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_1", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_1", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_1", formData)?.answer === 'Yes' ?
                            <p>Name of Child : <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_generalQN_1_1', formData)?.answer || '-'}</b></p> : null}

                    </div>

                    <div className='check-box inline'>
                        <p>Is the adult listed above an active member of the United States Military? </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    {answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5", formData)?.answer === 'Yes' ?
                        <div className='check-box inline'>
                            <p>(If YES, check box to the right) </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5_1", formData)?.answer === 'Active Duty U.S. Military' ? 'checked' : ''}>Active Duty U.S. Military</li>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5_1", formData)?.answer === 'National Guard Military Reserve' ? 'checked' : ''}>National Guard Military Reserve</li>
                            </ul>
                        </div> : null}
                </div>

                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>SECTION 3: Children information</b></h3>
                    <p>Please list all children under the age of 18 that live in the home. To be eligible for child care, children must be under age 13. Children with special needs may be eligible under age 19.</p>
                    <p style={{ fontSize: '13px' }}><b>KEY: A (Asian) B (Black/African Descent) C (White) N (American Indian/Alaskan Native) P (Native Hawaiian/Other Pacific Islander) NA (I prefer not to answer)</b></p>
                    <div className='gap' />
                    <div className='each-child'>
                        <div className='auto-flex'>
                            <p>First Name, Middle Initial, Last Name: <b>{answerGetter('c4k_redetermination_sectionThree_form_question_Name', formData)?.answer || '-'}</b></p>
                            <p>Date of Birth: <b>{answerGetter('c4k_redetermination_sectionThree_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                            <p>Relationship to Applicant: <b>{answerGetter('c4k_redetermination_sectionThree_form_question_relationship', formData)?.answer || '-'}</b></p>
                            <p>Social Security Number (optional): <b>{answerGetter('c4k_redetermination_sectionThree_form_question_socialSecurityNumber', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='check-box inline'>
                            <p>Child Care Needed?  </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_careNeeded", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_careNeeded", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>Gender:  </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Gender", formData)?.answer === 'F (Female)' ? 'checked' : ''}>F (Female)</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Gender", formData)?.answer === 'M (Male)' ? 'checked' : ''}>M (Male)</li>
                            </ul>
                        </div>

                        <div className='check-box inline'>
                            <p>Race (select all that apply): </p>
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                                <li>N</li>
                                <li>P</li>
                                <li>NA</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>Is child Hispanic/Latino? </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_hispanicLatino", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_hispanicLatino", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_hispanicLatino", formData)?.answer === 'NA' ? 'checked' : ''}>NA</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>Citizenship Status? </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Citizenship", formData)?.answer === 'Citizen' ? 'checked' : ''}>Citizen</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Citizenship", formData)?.answer === 'Permanent Resident' ? 'checked' : ''}>Permanent Resident</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Citizenship", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                            </ul>
                        </div>

                        <div className='check-box inline'>
                            <p>Is child up to date with shots? (immunizations) </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Shots", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Shots", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                    </div>

                    <div className='gap' />
                    <hr />
                    <div className='gap' />
                    <div className='each-child'>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>Do any of the children listed above have special needs? </p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_1", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_1", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                </ul>
                            </div>
                            {answerGetter("c4k_redetermination_sectionThree_form_QN_1", formData)?.answer === 'Yes' ?
                                <p>If YES, provide the name(s) of the child(ren) : <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_2', formData)?.answer || '-'}</b></p> : null}
                        </div>

                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>Do you share joint custody with any of the children listed above?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_3", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_3", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                </ul>
                            </div>
                            {answerGetter("c4k_redetermination_sectionThree_form_QN_3", formData)?.answer === 'Yes' ?
                                <p>If YES, provide the name(s) of the child(ren) : <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_4', formData)?.answer || '-'}</b></p> : null}
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>Do any of the children listed above have their own children living in your home?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                                </ul>
                            </div>
                            {answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'Yes' ?
                                <p>If YES, list the names of the minor parents (under age 18) and the name(s) of their child(ren):</p> : null}

                        </div>
                        {answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'Yes' ?
                            <div className='flex-box'>
                                <p>Parent(s) Under Age 18: <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_6', formData)?.answer || '-'}</b></p>
                                <p>Child(ren) of Parent Under Age 18: <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_7', formData)?.answer || '-'}</b></p>
                            </div> : null}

                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>SECTION 4: Work/training activity and income information</b></h3>
                    <p>Fill out the information below for all parents in the home. <b>If there are more than 2 activities, make a copy of this page or download and print another copy of this page from the Care 4 Kids website at www.ctcare4kids.com.</b></p>
                    <div className='gap' />

                    <div className='each-child'>
                        <p>Complete the following information about your work/training activity.</p>
                        <div className='gap' />
                        <p>Name of parent in the home: &nbsp;<b>{answerGetter('c4k_redetermination_sectionFour_form_QN_1', formData)?.answer || '-'}</b></p>
                        <div className='check-box inline'>
                            <p>Type of Activity:</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Work') ? 'checked' : ''}>Work</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('High School') ? 'checked' : ''}>High School</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Self-Employed') ? 'checked' : ''}>Self-Employed</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Training or Education approved by JFES') ? 'checked' : ''}>Training or Education approved by JFES</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Higher Education') ? 'checked' : ''}>Higher Education</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('GED/Adult Education') ? 'checked' : ''}>GED/Adult Education</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Workforce Development/Training program') ? 'checked' : ''}>Workforce Development/Training program</li>

                            </ul>
                        </div>
                        <div className="flex-box">
                            <p>Name of Employer/Business/Program/School: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_3', formData)?.answer || '-'}</b></p>
                            <p>Employer Industry/Type of Work: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_4', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Address: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_5', formData)?.answer || '-'}</b></p>
                            <p>City: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_6', formData)?.answer || '-'}</b></p>
                            <p>State: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_7', formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_8', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Start Date: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_9', formData)?.answer || '-'}</b></p>
                            <p>Phone: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_10', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Hours per week you work or participate in a training activity:  <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_11', formData)?.answer || '-'}</b></p>
                            <p>Days per week you work or attend a training activity: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_12', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='check-box inline' style={{ flexDirection: 'column' }}>
                            <p>How frequently do you get paid?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                            </ul>
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>How much do you get paid before taxes are deducted (gross income)?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Hourly' ? 'checked' : ''}>Hourly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Annually' ? 'checked' : ''}>Annually</li>
                                </ul>
                            </div>

                            <p>Amount : <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_15', formData)?.answer || '-'}</b></p>
                        </div>

                        <div className='auto-flex'>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>If you are self-employed, how much do you get paid before taxes and expenses are deducted (gross income)?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Hourly' ? 'checked' : ''}>Hourly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Annually' ? 'checked' : ''}>Annually</li>
                                </ul>
                            </div>

                            <p>Amount : <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_17', formData)?.answer || '-'}</b></p>
                        </div>

                        <div className='auto-flex'>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>If you are self-employed, what are your expenses (dollar amount)?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Hourly' ? 'checked' : ''}>Hourly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Annually' ? 'checked' : ''}>Annually</li>
                                </ul>
                            </div>

                            <p>Amount : <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_19', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='check-box inline'>
                            <p>Daily roundtrip commute from child care setting to work/activity?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === 'None' ? 'checked' : ''}>None</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === '1-30 minutes' ? 'checked' : ''}>1-30 minutes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === '31-60 minutes' ? 'checked' : ''}>31-60 minutes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === 'More than 60 minutes' ? 'checked' : ''}>More than 60 minutes</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>Do you take public transportation?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_21", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_21", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <ul>
                                <li className='checked'><b>Unable to provide care due to significant physical or mental condition, disability or impairment that is expected to last for at least one calendar month. (Verification will be required)</b></li>
                            </ul>
                        </div>

                    </div>
                    <p><b>If the other parent in the household is working or in a training activity, or if you have a second activity, complete the information:</b></p>
                </div>

                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>SECTION 5: Child support paid and additional income information</b></h3>
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>Does anyone living in your home pay child support?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_1", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_1", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_1", formData)?.answer === 'Yes' ?
                            <div className="details-section">
                                <div className="lft-section">
                                    <p>If Yes, submit verification of child support paid.</p>
                                    <p>How much is paid? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_2', formData)?.answer || '-'}</b></p>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>How often?</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>Does anyone living in your home receive a DCF stipend?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_4", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_4", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_4", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>If Yes, who receives it? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_5', formData)?.answer || '-'}</b></p>
                                        <p>How much is paid? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_6', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>How often?</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>Does anyone living in your home receive unemployment compensation?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_8", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_8", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_8", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>If Yes, who receives it? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_9', formData)?.answer || '-'}</b></p>
                                        <p>How much is paid? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_10', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>How often?</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>Does anyone living in your home receive Social Security Income?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_12", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_12", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_12", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>If Yes, who receives it? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_13', formData)?.answer || '-'}</b></p>
                                        <p>How much is paid? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_14', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>How often?</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>Do you get child care assistance from another source?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_16", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_16", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_16", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>If Yes, from whom? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_17', formData)?.answer || '-'}</b></p>
                                        <p>How much is paid? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_18', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>How often?</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>Does anyone living in your home receive any other income ?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                            </ul>
                            {answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'Yes' ?
                                <p>&nbsp; &nbsp; &nbsp;What type of income? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_21', formData)?.answer || '-'}</b></p> : null}

                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>If Yes, from whom? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_22', formData)?.answer || '-'}</b></p>
                                        <p>How much is paid? <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_23', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>How often?</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Weekly' ? 'checked' : ''}>Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>Bi-Weekly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>Semi-Monthly</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Monthly' ? 'checked' : ''}>Monthly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                </div>

                <div className='each-section-box'>
                    <h3><b>SECTION 6: Parents rights and responsibilities</b></h3>
                    <div className='gap' />
                    <div className='parents-rights-sec'>
                        <p>Please read the following section carefully. If there is anything you do not understand, call <b>Care 4 Kids</b> at <b>1-888-214-5437</b>.</p>
                        <ul>
                            <li> When you have read this section, <b>please sign below</b>.</li>
                            <li>You have the right to file an Application, withdraw an Application, or discontinue your participation in Care 4 Kids at any time.</li>
                            <li>You have the right to be treated fairly by Care 4 Kids without regard to race, color, religion, sex or sexual orientation, marital status, national origin, ancestry, age, political beliefs, or disability.</li>
                            <li>You have the right to request forms and notices in Spanish. All non-English speaking participants have the right to the services of an interpreter.</li>
                            <li>You have the right to ask for a review of any decision made by Care 4 Kids on your Application. You have the right to speak to a supervisor or mediator and the right to request a hearing from the State of Connecticut.</li>
                        </ul>
                        <br />
                        <p><b>I understand and agree that:</b> </p>
                        <ul>
                            <li>I must report changes in my situation to Care 4 Kids within 10 days of the change for the following: change in address, household income over 85% of the State Median Income, if the child receiving Care 4 Kids benefits is no longer in the home, change child care provider, and loss of employment or stopping an approved activity. For the current State Median Income Chart, please visit the Care 4 Kids website www.ctcare4kids.com.</li>
                            <li>Care 4 Kids may verify the information I have given on this form. I understand that if I am eligible for Care 4 Kids, benefits will not begin any earlier than 15 days before the date the Application is received.</li>
                            <li>With my signature, I hereby give voluntary consent for the Department of Social Services (DSS) to share with the Office of Early Childhood (OEC) confidential information retained by DSS about myself and minor household members, to be used by the OEC to determine eligibility and the level of benefits for the Child Care Assistance Program (CCAP). The OEC will obtain confidential information from DSS only under circumstances allowed by state and federal law. I understand that the OEC may share this confidential information with the CCAP administrator, Care 4 Kids. Confidential information obtained from DSS will be used solely for the purpose of CCAP eligibility and benefits and will not be disseminated outside the OEC or the CCAP administrator, or in violation of federal or state law. I understand that my DSS benefits will not be affected by this consent, and I may revoke this authorization at any time by sending a written request to the OEC, 450 Columbus Boulevard, Suite 303, Hartford, CT 06103. This authorization automatically expires one year from the date of application.</li>
                            <li>The Department of Labor will share unemployment compensation and wage information for applicants and household members for determination of eligibility for Care 4 Kids. The Connecticut Office of Early Childhood (OEC) may disclose to its contractor confidential information from the Department of Labor concerning unemployment compensation benefits and quarterly wage information pertaining to individuals who have signed the Application, only as necessary, to determine eligibility for the Care 4 Kids program.</li>
                            <li>The information on this form is confidential. The OEC or its contractor will only use this information to administer a State of Connecticut program. Information may be shared with others as permitted by law.</li>
                            <li>Care 4 Kids will disclose information about my eligibility for Care 4 Kids to my provider.</li>
                            <li>Care 4 Kids may be required to provide information about program applicants and participants to law enforcement officials.</li>
                            <li>The child care arrangement is between my provider and me. The OEC and Care 4 Kids are not responsible for the child care arrangement.</li>
                            <li>The State of Connecticut may conduct unscheduled visits to verify any household, employer, or provider circumstances.</li>
                            <li>Care 4 Kids may not pay the full amount charged by my provider. I am responsible for paying all additional provider charges.</li>
                            <li>I have the right to choose any eligible child care provider that meets all applicable health, training, and licensing requirements.</li>
                            <li>I understand that if I am eligible for Care 4 Kids, benefits will not start until all information is received and verified.</li>
                            <li>I may be required to repay any benefits received in error, including administrative errors. I may be subject to criminal prosecution for fraud if I knowingly supply any false information to Care 4 Kids or fail to report changes on time. I also may be disqualified from the program. In order to remain eligible, I must cooperate with the Care 4 Kids and State of Connecticut quality control process.</li>
                        </ul>
                        <br />
                        <p><b>PLEASE READ AND SIGN: I have read my rights and responsibilities or have had them read to me in a language I understand.<br />
                            I certify, under penalty of perjury, that all of the information provided is true and correct to the best of my knowledge.</b></p>
                    </div>


                    <div className="details-section sign-blk" style={{ alignItems: 'center' }}>
                        <div className="lft-section">
                            <div className="signature-blk" style={{ float: 'left' }}>
                                <div style={{ float: 'left', textAlign: 'center' }}>
                                    <h2 style={{ fontSize: '42px' }}>{answerGetter('c4k_redetermination_sectionSix_form_QN_applicantSign', formData)?.answer || '-'}</h2>
                                    <p>Applicant signature</p>
                                    <p>Date: <b>{
                                        (data.additionalInfo && data.additionalInfo.parentSignedDate && answerGetter('c4k_redetermination_sectionSix_form_QN_applicantSign', formData)?.answer)
                                            ? data.additionalInfo.parentSignedDate
                                            : ''
                                    }</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div className="signature-blk" style={{ float: 'left' }}>
                                <div style={{ float: 'right', textAlign: 'center' }}>
                                    <h2 style={{ fontSize: '42px' }}>{answerGetter('c4k_redetermination_sectionSix_form_QN_responsibleSign', formData)?.answer || '-'}</h2>
                                    <p>Signature of other legally responsible adult living with you</p>
                                    <p>Date: <b>{
                                        (data.additionalInfo && data.additionalInfo.parentSignedDate && answerGetter('c4k_redetermination_sectionSix_form_QN_responsibleSign', formData)?.answer)
                                            ? data.additionalInfo.parentSignedDate
                                            : ''
                                    }</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

C4KRedeterminationView.propTypes = {
    data: PropTypes.any
}

export default C4KRedeterminationView;