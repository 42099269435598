
import EmergencyNumbersView from '../components/formPreviews/emergencyNumbersView';
import ChildEnrollmentView from '../components/formPreviews/childEnrollmentView';
import ParentProviderAgreementView from '../components/formPreviews/parentProviderAgreementView';
import WrittenPermissionView from '../components/formPreviews/writtenPermissionView';
import EarlyChildhoodHealthAssessmentRecordView from '../components/formPreviews/earlyChildhoodHealthAssessmentRecordView';
import SummerParentProviderAgreementView from '../components/formPreviews/summerParentProviderAgreementView';
import C4KApplicationView from '../components/formPreviews/C4KApplicationView';
import C4KRedeterminationView from '../components/formPreviews/C4KRedeterminationView';
import ProviderCommunicationFormView from '../components/formPreviews/providerCommunicationFormView';
import ParentCommunicationFormView from '../components/formPreviews/parentCommunicationFormView';
import NapTimeCommunicationFormView from '../components/formPreviews/napTimeCommunicationView';
import AccidentFormView from '../components/formPreviews/accidentFormView';
import DisabilityVerificationView from '../components/formPreviews/disabilityVerificationView';
import SpecialNeedsView from '../components/formPreviews/specialNeedsView';
import MedicalExemptionView from '../components/formPreviews/medicalExemptionView';
import SelfEmploymentView from '../components/formPreviews/selfEmploymentView';


/**
 * Gets the question for array of questions
 * @param {string} questionId string
 * @param {Array} dataArr the data array
 * @returns Object
 */
export const answerGetter = (questionId = '', dataArr = []) => {
    let question = {
        answer: '',
        rawAnswer: ''
    };
    if (dataArr && dataArr.length > 0) {
        question = dataArr.find((q) => q.questionId === questionId);
    }
    return question;
}

/**
 * When a user clicks the form view 
 * @param {string} formName the form name to get preview
 * @param {object} formProps 
 * @returns JSX
 */
export const getDynamicFormPreview = (formName, formProps = {}) => {
    let selectedPreview = <></>
    switch (formName) {
        case 'child-enrollment':
            selectedPreview = <ChildEnrollmentView {...formProps} />;
            break;
        case 'emergency-number':
            selectedPreview = <EmergencyNumbersView {...formProps} />;
            break;
        case 'written-permission':
            selectedPreview = <WrittenPermissionView {...formProps} />;
            break;
        case 'c4k-application':
            selectedPreview = <C4KApplicationView {...formProps} />;
            break;
        case 'c4k-redetermination':
            selectedPreview = <C4KRedeterminationView {...formProps} />;
            break;
        case 'parent-provider-agreement':
            selectedPreview = <ParentProviderAgreementView {...formProps} />;
            break;
        case 'early-childhood-health-assessment-record':
            selectedPreview = <EarlyChildhoodHealthAssessmentRecordView {...formProps} />;
            break;
        case 'summer-pp-agreement':
            selectedPreview = <SummerParentProviderAgreementView {...formProps} />;
            break;
        case 'provider-communication':
            selectedPreview = <ProviderCommunicationFormView {...formProps} />;
            break;
        case 'parent-communication':
            selectedPreview = <ParentCommunicationFormView {...formProps} />;
            break;
        case 'nap-time':
            selectedPreview = <NapTimeCommunicationFormView {...formProps} />;
            break;
        case 'provider_accident_form':
            selectedPreview = <AccidentFormView {...formProps} />;
            break;
        case 'disability-verification':
            selectedPreview = <DisabilityVerificationView {...formProps} />;
            break;
        case 'self-employment':
            selectedPreview = <SelfEmploymentView {...formProps} />;
            break;
        case 'special-needs':
            selectedPreview = <SpecialNeedsView {...formProps} />;
            break;
        case 'medical-exemption':
            selectedPreview = <MedicalExemptionView {...formProps} />;
            break;
        default:
            break;
    }
    return selectedPreview;
}