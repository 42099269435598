import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './private.route';

// pages
// import HomePage from '../pages/home/home';
import FacilityHomePage from '../pages/home/facilityHome';
import LoginPage from '../pages/auth/login';
import RegisterPage from '../pages/auth/register';
import ForgotPasswordPage from '../pages/auth/forgotPassword';
import PasswordResetMailSuccess from '../pages/auth/passwordResetMailSuccess';
import Page404 from '../pages/pageNotFound';
import ContactUsPage from '../pages/contactUs';
import MyPortal from '../pages/myPortal/index';
import PaymentSuccess from '../pages/payment/successPage';
import PdfPreviewPage from '../pages/pdfRender';
// import ResetPasswordPage from '../pages/auth/resetPassword';
import SetNewPasswordPage from '../pages/auth/setNewPassword';
import CreateNewQuestionPage from '../features/providerPortal/mainCategoryTopics/createNewQuestionPage';
import CreateNewSubjectPage from '../features/providerPortal/mainCategoryTopics/createNewSubjectPage';
import CreateNewTopicPage from '../features/providerPortal/mainCategoryTopics/createNewTopicPage';
import CreateNewSystemPage from '../features/providerPortal/mainCategoryTopics/createNewSystemPage';
import MainCategoryTopics from '../features/providerPortal/mainCategoryTopics';
import BlockSectionPage from '../features/providerPortal/blockSection';
import ViewTopicPage from '../features/providerPortal/blockSection/topicBlock/viewTopicPage';
import EditTopicPage from '../features/providerPortal/blockSection/topicBlock/editTopicPage';
import ViewSystemPage from '../features/providerPortal/blockSection/systemBlock/viewSystemPage';
import EditSystemPage from '../features/providerPortal/blockSection/systemBlock/editSystemPage';
import ViewSubjectPage from '../features/providerPortal/blockSection/subjectBlock/viewSubjectPage';
import EditSubjectPage from '../features/providerPortal/blockSection/subjectBlock/editSubjectPage';
import ViewQuestionPage from '../features/providerPortal/blockSection/questionBlock/viewQuestionPage';
import EditQuestionPage from '../features/providerPortal/blockSection/questionBlock/editQuestionPage';
import UserSectionPage from '../features/providerPortal/userSection';
import UserTabSectionPage from '../features/providerPortal/userSection/userTabSectionPage';
import DashboardHomePage from '../features/providerPortal/homePage';
import AdminProfilePage from '../features/providerPortal/profile';
import AdminForgotPasswordPage from '../features/providerPortal/profile/forgotPasswordPage';
import AdminPasswordResetPage from '../features/providerPortal/profile/passwordResetPage';
import AdminSetNewPasswordPage from '../features/providerPortal/profile/setNewPasswordPage';
import { STUDENT_ROLE } from '../constants/index';
import CreateNewTestPage from '../features/studentPortal/createTestSection/createNewTestPage';
import TestLayoutPage from '../features/studentPortal/TestTemplate/index';
import PreviousTestTable from '../features/studentPortal/previousTests/previousTestList';
import PreviousTestView from '../features/studentPortal/previousTests/previousTestView';
import StatsSectionPage from '../features/studentPortal/stats';
import ExamSectionPage from '../features/studentPortal/exams';
import GraphsSectionPage from '../features/studentPortal/graphsSection';
import StudentProfilePage from '../features/studentPortal/profile';
// import ProfilePlansPage from '../features/studentPortal/profile/profilePlans';
import ResultPage from '../features/studentPortal/TestTemplate/result';
import RevisionPage from '../features/studentPortal/TestTemplate/revision/revisionPage';
// import InitiatePayment from '../pages/payment/initiatePayment';
import FAQPage from '../pages/home/faqPage';
import ProfilePlansRealPage from '../features/studentPortal/profile/profilePlansReal';
import MyMessagesList from '../features/messages/list';
import PaymentCard from '../features/studentPortal/profile/paymentCard';
import CreateNewExamForm from '../features/providerPortal/mainCategoryTopics/createNewExamForm';
import ExamPreview from '../features/providerPortal/mainCategoryTopics/examPreview';
import UnivExamView from '../features/studentPortal/exams/examTabs/previousAttempts/previousAttemptsView';
import SelfAssessmentSelectedExamView from '../features/studentPortal/exams/selfAssessment/previousAttempts/selfAssessmentSelectedExamView';
import RepDomHomePage from '../pages/homeRepDom/home';
import RepDomFAQPage from '../pages/homeRepDom/faqPage';
import HomePageGeneral from '../pages/homeGeneral/home';
import ExamLayoutPage from '../features/studentPortal/ExamTemplate/index';
import UniversityExamPage from '../features/providerPortal/exams';
import UnivExamDetailsPage from '../features/providerPortal/exams/examTabs/examResults';
import UsmleViewLayout from '../features/studentPortal/TestTemplate/usmleView';
import UnivExamStudentAttemptView from '../features/providerPortal/exams/examTabs/examResults/attemptView';
import PrivacyPolicyXmed from '../pages/home/privacyPolicyXmed';
import TermsAndConditionsXmed from '../pages/home/termsAndConditionsXmed';
import CreateClasses from '../features/providerPortal/blockSection/classesBlock/createClasses';
import ClassesSectionPage from '../features/studentPortal/classes';
import ClassesDetailPage from '../features/studentPortal/classes/classesDetails';
import AiDevelopment from '../features/providerPortal/aiDevelopment';



// ----------------------------------------------------------------------

export default function Router() {
  const routes = [
    {
      path: '/',
      element: <HomePageGeneral />,
    },
    {
      path: 'usmle/faq',
      element: <FAQPage />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyXmed />,
    },
    {
      path: 'terms-and-conditions',
      element: <TermsAndConditionsXmed />,
    },
    {
      path: 'usmle/xmed-rd',
      element: <RepDomHomePage />,
    },
    {
      path: '/pdf-preview/:formId',
      element: <PdfPreviewPage />,
    },
    {
      path: '/:facility',
      children: [
        {
          path: 'privacy-policy',
          element: <PrivacyPolicyXmed />,
        },
        {
          path: 'terms-and-conditions',
          element: <TermsAndConditionsXmed />,
        },
        {
          path: '',
          element: <FacilityHomePage />,
        },
        {
          path: 'chumma',
          element: <RepDomHomePage />,
        },
        {
          path: 'faq',
          element: <FAQPage />,
        },
        {
          path: 'preguntas-frecuentes',
          element: <RepDomFAQPage />,
        },
        {
          path: 'contact-us',
          element: <ContactUsPage />,
        },
        {
          path: 'my-portal',
          element: <PrivateRoute><MyPortal /></PrivateRoute>
        },
        {
          path: 'payment-success',
          element: <PaymentSuccess />
        },
        {
          path: 'dashboard',
          element: <PrivateRoute><DashboardHomePage /></PrivateRoute>
        },
        {
          path: 'create-new-item',
          element: <PrivateRoute><MainCategoryTopics /></PrivateRoute>
        },
        {
          path: 'create-exam',
          element: <PrivateRoute><CreateNewExamForm /></PrivateRoute>
        },
        {
          path: 'edit-exam/:id',
          element: <PrivateRoute><CreateNewExamForm /></PrivateRoute>
        },
        {
          path: 'exam-preview',
          element: <PrivateRoute><ExamPreview /></PrivateRoute>
        },
        {
          path: 'create-question',
          element: <PrivateRoute><CreateNewQuestionPage /></PrivateRoute>
        },
        {
          path: 'create-new-subject',
          element: <PrivateRoute><CreateNewSubjectPage /></PrivateRoute>
        },
        {
          path: 'create-new-topic',
          element: <PrivateRoute><CreateNewTopicPage /></PrivateRoute>
        },
        {
          path: 'create-new-system',
          element: <PrivateRoute><CreateNewSystemPage /></PrivateRoute>
        },
        {
          path: 'manage',
          element: <PrivateRoute><BlockSectionPage /></PrivateRoute>
        },
        {
          path: 'users',
          element: <PrivateRoute><UserSectionPage /></PrivateRoute>
        },
        {
          path: 'user-profile/:id',
          element: <PrivateRoute><UserTabSectionPage /></PrivateRoute>
        },        
        {
          path: 'view-topic/:id',
          element: <PrivateRoute><ViewTopicPage /></PrivateRoute>
        },
        {
          path: 'edit-topic/:id',
          element: <PrivateRoute><EditTopicPage /></PrivateRoute>
        },
        {
          path: 'view-system/:id',
          element: <PrivateRoute><ViewSystemPage /></PrivateRoute>
        },
        {
          path: 'edit-system/:id',
          element: <PrivateRoute><EditSystemPage /></PrivateRoute>
        },
        {
          path: 'view-subject/:id',
          element: <PrivateRoute><ViewSubjectPage /></PrivateRoute>
        },
        {
          path: 'edit-subject/:id',
          element: <PrivateRoute><EditSubjectPage /></PrivateRoute>
        },
        {
          path: 'question/:id',
          element: <PrivateRoute><ViewQuestionPage /></PrivateRoute>
        },
        {
          path: 'edit-question/:id',
          element: <PrivateRoute><EditQuestionPage /></PrivateRoute>
        },
        {
          path: 'profile',
          element: <PrivateRoute><AdminProfilePage /></PrivateRoute>
        },
        {
          path: 'my-profile',
          element: <PrivateRoute><StudentProfilePage /></PrivateRoute>
        },
        {
          path: 'plans',
          element: <PrivateRoute><ProfilePlansRealPage /></PrivateRoute>
        },
        {
          path: 'payment-card',
          element: <PrivateRoute><ProfilePlansRealPage /></PrivateRoute>
        },
        {
          path: 'plans/:id',
          element: <PrivateRoute><PaymentCard /></PrivateRoute>
        },
        // {
        //   path: 'plans/:id',
        //   element: <PrivateRoute><InitiatePayment /></PrivateRoute>
        // },
        {
          path: 'test/:id',
          element: <PrivateRoute><ResultPage /></PrivateRoute>
        },
        {
          path: ':type-review/:id', // exam-review and test-review both routes will come here
          element: <PrivateRoute><RevisionPage /></PrivateRoute>
        },
        {
          path: 'user-forgot-password',
          element: <PrivateRoute><AdminForgotPasswordPage /></PrivateRoute>
        },
        {
          path: 'user-password-reset',
          element: <PrivateRoute><AdminPasswordResetPage /></PrivateRoute>
        },
        {
          path: 'user-set-password',
          element: <PrivateRoute><AdminSetNewPasswordPage /></PrivateRoute>
        },
        {
          path: 'create-practice',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><CreateNewTestPage /></PrivateRoute>
        },
        {
          path: 'start-test/:id',
          element: <PrivateRoute><TestLayoutPage /></PrivateRoute>
        },
        {
          path: 'usmle-view/:id',
          element: <PrivateRoute><UsmleViewLayout /></PrivateRoute>
        },
        {
          path: 'my-practices',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><PreviousTestTable /></PrivateRoute>
        },
        {
          path: 'previous-tests-view',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><PreviousTestView /></PrivateRoute>
        },
        {
          path: 'stats',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><StatsSectionPage /></PrivateRoute>
        },
        {
          path: 'classes',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><ClassesSectionPage /></PrivateRoute>
        },
        {
          path: 'classes-details/:id',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><ClassesDetailPage /></PrivateRoute>
        },
        {
          path: 'exams',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><ExamSectionPage /></PrivateRoute>
        },
        {
          path: 'exam-logs',
          element: <PrivateRoute ><UniversityExamPage /></PrivateRoute>
        },
        {
          path: 'exam-logs/:id',
          element: <PrivateRoute ><UnivExamDetailsPage /></PrivateRoute>
        },
        {
          path: 'exam-logs/attempt-view/:id',
          element: <PrivateRoute ><UnivExamStudentAttemptView /></PrivateRoute>
        },
        {
          path: 'start-exam/:id',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><ExamLayoutPage /></PrivateRoute>
        },
        {
          path: 'exam-view/:id',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><UnivExamView /></PrivateRoute>
        },
        {
          path: 'self-assessment-view',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><SelfAssessmentSelectedExamView /></PrivateRoute>
        },
        {
          path: 'inbox',
          element: <PrivateRoute><MyMessagesList /></PrivateRoute>
        },
        {
          path: 'ai-development',
          element: <PrivateRoute><AiDevelopment /></PrivateRoute>
        },
        {
          path: 'graphs',
          element: <PrivateRoute allowOnly={STUDENT_ROLE} ><GraphsSectionPage /></PrivateRoute>
        },
        {
          path: 'demo-test',
          element: <PrivateRoute><TestLayoutPage /></PrivateRoute>
        },
        {
          path: 'create-classes',
          element: <PrivateRoute><CreateClasses /></PrivateRoute>
        },
        {
          path: 'edit-class/:id',
          element: <PrivateRoute><CreateClasses /></PrivateRoute>
        },
        {
          path: 'auth',
          children: [
            { element: <Navigate to="login" />, index: true },
            { path: 'register', element: <RegisterPage /> },
            { path: 'login', element: <LoginPage /> },
            { path: 'forgot-password', element: <ForgotPasswordPage /> },
            {path: 'reset-password', element: <SetNewPasswordPage />},
            {path: 'new-password', element: <SetNewPasswordPage />},

            {path: 'xmed-rd', element: <RepDomHomePage />},
            
            { path: 'reset-password-email-sent', element: <PasswordResetMailSuccess /> }
            // {path: 'reset-password', element: <ResetPassword />}
          ]
        }
      ]

    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes = [];

  return useRoutes(routes.concat(privateRoutes));
}
