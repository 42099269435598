import { useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button, FormControlLabel, Typography, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Save } from '@mui/icons-material';


// components


// Helpers
import { translateThis } from '../../../../helpers/language.helper';

import '../index.scss';
// Services
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';




// ........................................................................




const AddEditSubAdminForm = (props) => {


    const {
        onSuccess = () => null,
        onClose = () => null,
        initialData = {}
    } = props;
    const url = initialData?._id ? `/${initialData?._id}` : '';
    const apiCaller = new ApiCaller(`university/sub-admin${url}`);
    const userId = initialData?._id || '';
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [accessArr, setAccessArr] = useState(initialData?.accesses || []);
    const [formData, setFormData] = useState({
        name: initialData?.name || '',
        lastName: initialData?.lastName || '',
        email: initialData?.email ? initialData?.email : '',
        mobileNo: initialData?.mobileNo ? initialData?.mobileNo : '',
        password: ``,
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.email && formData.name) {
            if (userId) {
                isValid = true
            } else {
                isValid = !!formData.password;
            }

        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmitForm = () => {
        const postData = {...formData, accesses: accessArr}
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            const successMessage = "Admin created successfully!."
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(successMessage)
                    onSuccess();
                } else {
                    showSnackbar().failure(data.message)
                }
            }).finally(() => setIsSubmitting(false));
        }
    };

    const accesses = [
        {
            id: 'home',
            label: 'Home'
        },
        {
            id: 'create',
            label: 'Create'
        },
        {
            id: 'manage',
            label: 'Manage'
        },
        {
            id: 'messages',
            label: 'Messages'
        },
        {
            id: 'exams',
            label: 'Exams'
        },
        {
            id: 'users',
            label: 'Users'
        },
        {
            id: 'adminUsers',
            label: 'Admin Users'
        },
        {
            id: 'modifyAdminUsers',
            label: 'Add/Edit Admin Users'
        },
        {
            id: 'designSettings',
            label: 'Design settings'
        },
    ];

    const onSelectAccess = (access, isAdded) => {
        setAccessArr((accessArr)=>{
            const filteredAccessArr = accessArr.filter((a)=>a!==access.id);
            if (isAdded) {
                filteredAccessArr.push(access.id);
            }
            return filteredAccessArr;            
        })
    }


    return (
        <>
            <div className='add-new-message-layout f-wrp'>
                <Stack className='new-message standard-form form-full-width panel-form' spacing={3}>
                    <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                        <Grid item xs={6} sm={6} md={6} style={{ marginTop: '0' }}>
                            <TextInput
                                label={translateThis('Name')}
                                name='name'
                                id='contact_form_subject'
                                value={formData.name}
                                getValue={(name, value) => setFormValue(name, value)}
                                isRequired
                                type='text'
                                placeholder={translateThis('Name')}
                                className='custom-textfield'

                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextInput
                                label={translateThis('Last name')}
                                name='lastName'
                                id='contact_form_subject'
                                value={formData.lastName}
                                getValue={(name, value) => setFormValue(name, value)}
                                isRequired
                                type='text'
                                placeholder={translateThis('Last name')}
                                className='custom-textfield'

                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <TextInput
                                label={translateThis('Email')}
                                name='email'
                                id='contact_form_subject'
                                value={formData.email}
                                getValue={(name, value) => setFormValue(name, value)}
                                isRequired
                                type='email'
                                placeholder={translateThis('Email')}
                                className='custom-textfield'

                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextInput
                                label={translateThis('Password')}
                                name='password'
                                id='contact_form_subject'
                                value={formData.password}
                                getValue={(name, value) => setFormValue(name, value)}
                                isRequired
                                type='password'
                                placeholder={userId ? 'Update password' : translateThis('Password')}
                                className='custom-textfield'

                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextInput
                                label={translateThis('Phone')}
                                name='mobileNo'
                                id='contact_form_subject'
                                value={formData.mobileNo}
                                getValue={(name, value) => setFormValue(name, value)}
                                isRequired={false}
                                type='text'
                                placeholder={translateThis('Phone')}
                                className='custom-textfield'

                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='subtitle1'>Access management</Typography>
                            <Grid container spacing={1}>
                                {accesses.map((access)=><Grid key={access.id} item xs={3} sm={3} md={3} >
                                    <Stack className='additional-btn' variant='div' spacing={3} style={{ marginTop: '0' }}>
                                        <FormControlLabel control={<Checkbox  checked={accessArr.includes(access.id)} color='primary' onChange={(e) => onSelectAccess(access, e.target.checked)} />} label={access.label} />
                                    </Stack>
                                </Grid>) }
                            </Grid>

                        </Grid>

                    </Grid>
                </Stack>

                <br />

                <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                    <div className='lft-btn-sec'>
                        <Button onClick={onClose} className='cancel-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec pln-btn-style'>

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<Save />} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmitForm}>
                            {userId ? translateThis("Update") : translateThis("Create")}
                        </LoadingButton>

                    </div>
                </div>
            </div>
        </>
    );
}

AddEditSubAdminForm.propTypes = {
    onClose: propTypes.func,
    initialData: propTypes.any,
    onSuccess: propTypes.func
}

export default AddEditSubAdminForm;











