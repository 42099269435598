/* eslint-disable arrow-body-style */
import * as React from 'react';
import { Box } from '@mui/material';

import { translateThis } from '../../../../../helpers/language.helper';

const BloodTab = () => {
    
    return (
        <>
            
            <Box className='SerumTab-wrapper'>
                    <div className='SerumTab-content f-wrp'>
                        <table>
                            <thead>
                                <th>{translateThis('Hematologic')}</th>
                                <th>{translateThis('Reference range')}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>{translateThis('Erythrocyte count Male Female')}</p></td>
                                    <td><p>{translateThis('4.3-5.9 million/mm3 3.5-5.5 million/mm3')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Erythrocyte sedimentation rate (Westergren) Male Female')}</p></td>
                                    <td><p>{translateThis('0-15 mm/h 0-20 mm/h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Hematocrit Male Female')}</p></td>
                                    <td><p>{translateThis('41%-53% 36%-46%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Hemoglobin, blood Male Female')}</p></td>
                                    <td><p>{translateThis('13.5-17.5 g/dL 12.0-16.0 g/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Hemoglobin A1c')}</p></td>
                                    <td><p>{translateThis('≤6%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Hemoglobin, plasma')}</p></td>
                                    <td><p>{translateThis('<4 mg/dL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Leukocyte count (WBC)')}</p></td>
                                    <td><p>{translateThis('4500-11,000/mm3')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Neutrophils, segmented')}</p></td>
                                    <td><p>{translateThis('54%-62%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Neutrophils, bands')}</p></td>
                                    <td><p>{translateThis('3%-5%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Eosinophils')}</p></td>
                                    <td><p>{translateThis('1%-3%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Basophils')}</p></td>
                                    <td><p>{translateThis('0%-0.75%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Lymphocytes')}</p></td>
                                    <td><p>{translateThis('25%-33%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Monocytes')}</p></td>
                                    <td><p>{translateThis('3%-7%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('CD4+ T-lymphocyte count')}</p></td>
                                    <td><p>{translateThis('≥500/mm3')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Platelet count')}</p></td>
                                    <td><p>{translateThis('150,000-400,000/mm3')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Reticulocyte count')}</p></td>
                                    <td><p>{translateThis('0.5%-1.5%')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('D-Dimer')}</p></td>
                                    <td><p>{translateThis('≤250 ng/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Partial thromboplastin time (PTT) (activated)')}</p></td>
                                    <td><p>{translateThis('25-40 seconds')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Prothrombin time (PT)')}</p></td>
                                    <td><p>{translateThis('11-15 seconds')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Mean corpuscular hemoglobin (MCH')}</p></td>
                                    <td><p>{translateThis('25-35 pg/cell')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Mean corpuscular hemoglobin concentration (MCHC)')}</p></td>
                                    <td><p>{translateThis('31%-36% Hb/cell')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Mean corpuscular volume (MCV)')}</p></td>
                                    <td><p>{translateThis('80-100 μm3')}</p></td>
                                </tr>
                                <tr>
                                    <td><p><b>{translateThis('Volume')}</b><br />{translateThis('Plasma Male Female')}</p></td>
                                    <td><p>{translateThis('25-43 mL/kg 28-45 mL/kg')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Red cell Male Female')}</p></td>
                                    <td><p>{translateThis('20-36 mL/kg 19-31 mL/kg')}</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                
            </Box>

        </>
    )
}

export default BloodTab;