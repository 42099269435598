import { useState } from 'react';
import { Box, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { translateThis } from '../../../../helpers/language.helper';

import AppIcons from '../../../../assets/images/icons';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import AppLayout from '../../../../layouts/app/AppLayout';
import UserDrawer from '../../../../components/drawer/drawer';

import './index.scss';
import ModalPopUp from '../../../../components/modal/modal.popup';
import StudentListPopup from './examPreviewPopup/studentListPopup';
import QnPoolListPopup from './examPreviewPopup/qnPoolListPopup';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import ExamCreatedSuccessfully from './examPreviewPopup/examCreatedSuccessfully';
import DiscardExamPopup from './examPreviewPopup/discardExamPopup';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';


const ExamPreview = () => {
    const [isOpenQuestionPool, setOpenQuestionPool] = useState(false);
    const [openSelectedStudentList, setOpenSelectedStudentList] = useState(false);
    const [openDiscardExamPopup, setOpenDiscardExamPopup] = useState(false);
    const [openExamCreatedSuccessfullyPopup, setOpenExamCreatedSuccessfullyPopup] = useState(false);

    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header' style={{ marginBottom: '20px' }}>
                                <h2>{translateThis("Exams")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content exam-preview-main-wrapper'>
                                {/* {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>} */}

                                <Stack className='exam-preview-wrp maxWidth991' spacing={3}>
                                    <div className='exam-basic-info'>
                                        <span className='exam-logo'><img src={AppIcons.previewIcon} alt='logo' /></span>
                                        <h1>Biology <span>VI</span></h1>
                                        <p>Elevate your confidence by accessing a scoring system  crafted by experts in their fields.</p>
                                        <h3 style={{ marginTop: '20px' }}>{translateThis("Basic info")}</h3>
                                        <ul className='basic-list'>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.Date} alt='icon' /><b>{translateThis("Date")}: </b><span>Start: Oct 17, 10:30 - End: Oct 17, 11:30</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.Time} alt='icon' /><b>{translateThis("Available time")}: </b><span>150 min</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.testMode} alt='icon' /><b>{translateThis("Question pool")}: </b><span>{translateThis("Random")} ({translateThis("Pre-selected pool")})</span>
                                                    <Button onClick={() => { setOpenQuestionPool(true) }} className='eye-btn'>{SVGIcons.EyeOpen()}</Button>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.Difficulty} alt='icon' /><b>{translateThis("Difficulty")}: </b><span>{translateThis("Hard")}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.Students} alt='icon' /><b>{translateThis("Students")}: </b><span>50</span>
                                                    <Button onClick={() => { setOpenSelectedStudentList(true) }} className='eye-btn'>{SVGIcons.EyeOpen()}</Button>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.number} alt='icon' /><b>{translateThis("Questions")}: </b><span>80</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.score} alt='icon' /><b>{translateThis("Scoring")}: </b><span>{translateThis("Auto")}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='each-basic-items'>
                                                    <img className='icons' src={AppIcons.Attempts} alt='icon' /><b>{translateThis("Attempts")}: </b><span>01</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='eachExam-detail-wrp'>
                                        <h3>{translateThis("Exam Details")}</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit sit gravida pretium, sollicitudin quam onsectetur adipiscing elit blandit sit gravida pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit sit gravida pretium, sollicitudin quam onsectetur adipiscing elit blandit sit gravida pretium.</p>
                                    </div>
                                    <div className='eachExam-detail-wrp'>
                                        <h3>{translateThis("Prepare for the exam")}</h3>
                                        <p>{translateThis("Create a structured study plan that allocates sufficient time to the following suggested topics")}</p>
                                        <ul className='chip-list'>
                                            <li><span className='chip'>Molecular Biology</span></li>
                                            <li><span className='chip'>Ecology</span></li>
                                            <li><span className='chip'>Evolution</span></li>
                                            <li><span className='chip'>Cell Biology</span></li>
                                            <li><span className='chip'>Genetics</span></li>
                                            <li><span className='chip'>Physiology</span></li>
                                        </ul>
                                    </div>

                                </Stack>
                                <div className='button-sec maxWidth991 f-wrp'>
                                    <div className='lft-btn-sec'>
                                        <Button className='cancel-btn red-btn' onClick={() => { setOpenDiscardExamPopup(true) }}><span>{SVGIcons.DeleteBlueIcon()}</span>{translateThis("Discard exam")}</Button>
                                    </div>
                                    <div className='ryt-btn-sec'>
                                        <LoadingButton
                                            // loading={}
                                            // disabled={}
                                            type="submit"
                                            className='pln-btn'
                                        // onClick={}
                                        >
                                            {translateThis("Back to edit")}
                                        </LoadingButton>
                                        <div className='loadingBtn2'>
                                            <LoadingButton
                                                // loading={}
                                                // disabled={}
                                                type="submit"
                                                className='fill-btn'
                                                onClick={() => { setOpenExamCreatedSuccessfullyPopup(true) }}
                                            >
                                                {translateThis("Publish")}
                                            </LoadingButton>
                                        </div>

                                    </div>
                                </div>


                                {isOpenQuestionPool && <ModalPopUp
                                    isOpen={isOpenQuestionPool}
                                    onClose={() => {
                                        setOpenQuestionPool(false);
                                    }}
                                    className={'modal-popup preview-popup provider-portal select-student-popup'}
                                    children={<div>
                                        <QnPoolListPopup />
                                    </div>}

                                />}

                                {openSelectedStudentList && <ModalPopUp
                                    isOpen={openSelectedStudentList}
                                    onClose={() => {
                                        setOpenSelectedStudentList(false);
                                    }}
                                    className={'modal-popup preview-popup provider-portal select-student-popup'}
                                    children={<div>
                                        <StudentListPopup />
                                    </div>}

                                />}

                                {openDiscardExamPopup && <ConfirmDialog
                                    isOpen={openDiscardExamPopup}
                                    onClose={() => {
                                        setOpenDiscardExamPopup(false);
                                    }}
                                    // title=""
                                    className={'publish-popup subscription-popup'}
                                    description={<DiscardExamPopup />}
                                    okayButtonText={'Discard exam'}
                                    cancelButtonText={'cancel'}
                                    onConfirm={() => {
                                        setOpenDiscardExamPopup(false);
                                    }}
                                />}

                                {openExamCreatedSuccessfullyPopup && <ConfirmDialog
                                    isOpen={openExamCreatedSuccessfullyPopup}
                                    onClose={() => {
                                        setOpenExamCreatedSuccessfullyPopup(false);
                                    }}
                                    // title=""
                                    className={'delete-popup subscription-popup'}
                                    description={<ExamCreatedSuccessfully />}
                                    okayButtonText={'Continue'}
                                    onConfirm={() => {
                                        setOpenExamCreatedSuccessfullyPopup(false);
                                    }}
                                />}
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>

    );
}

ExamPreview.propTypes = {}

export default ExamPreview;