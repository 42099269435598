import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Resizable from 'react-resizable-layout';

import './index.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppLayout from '../../../../layouts/app/AppLayout';
import TestHeader from '../../components/testHeader/index';
import TestFooter from '../../components/testFooter';
import RealModeQuestion from '../Questions/realModeQuestion';
import LabValueTab from '../labValueTab/labValueTab';
// import Calculator from '../calculator/Calculator';
import ApiCaller from '../../../../services/api/general';
import RevisionBox from '../revisionBox/revisionBox';
import ExplanationBox from '../explanationBox/explanationBox';
import CalcContainer from '../calc/calc';
import { translateThis } from '../../../../helpers/language.helper';
// import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';


const RevisionPage = () => {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [testDetails, setTestDetails] = useState({});
    const [formattedQuestions, setFormattedQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showCalc, setShowCalc] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showLab, setShowLab] = useState(false);
    const [showRevision, setShowRevision] = useState(true);
    const [forceFullyClosed, setForceFullyClosed] = useState(false);

    const [footerHeight, setFooterHeight] = useState(0);
    const [headerHeight, setHeadHeight] = useState(0);

    // const elementRef = useRef(null);
    const headHeight = useRef(null);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);



    useEffect(() => {
        getExam();
        // eslint-disable-next-line
    }, [id])

    const getExam = () => {
        const apiToCall = type === 'test' ? 'tests' : 'universityExams/get-university-exam-revision';
        const apiCaller = new ApiCaller(apiToCall);
        apiCaller.getData(id).then((data) => {
            if (data.testDetails && data.questions) {
                setTestDetails(data.testDetails);
                formatQuestions(data.questions);
            }
        }).finally(() => setIsLoading(false))
    }

    /**
     * Format the questions based on ui req
     * @param {Array} questions 
     */
    const formatQuestions = (questions = []) => {
        const formatted = questions.map((question) => ({ ...question, status: 'unanswered' }));
        setFormattedQuestions(formatted);
        setCurrentQuestionIndex(0);
    }

    /**
     * Navigate through question
     * @param {number} to 
     */
    const navigateQuestion = (to) => {
        if (to === 'previous' && currentQuestionIndex > 0) {
            setCurrentQuestionIndex((prev) => prev - 1);
        } else if (to === 'next' && currentQuestionIndex < formattedQuestions.length - 1) {
            setCurrentQuestionIndex((prev) => prev + 1);
        } else if (formattedQuestions[to]) {
            setCurrentQuestionIndex(to);
        }
        setShowExplanation(false);
    }

    const onEndRevision = () => {
        navigate(-1)

    }

    /**
     * 
     * @param {String} item 
     */
    const onClickHeaderItem = (item) => {
        switch (item) {
            case 'calc':
                setShowCalc((prev) => !prev);
                break;
            case 'revision':
                setShowRevision((prev) => !prev);
                break;
            case 'lab':
                setShowLab((prev) => {
                    if (!prev) { // lab is going to visible hide explanation
                        setShowExplanation(false);
                    }
                    return !prev;
                });
                setForceFullyClosed((prev) => !prev)
                break;

            case 'explanation':
                setShowExplanation((prev) => {
                    if (!prev) { // explanation is going to visible hide lab
                        setShowLab(false);
                    }
                    return !prev;
                });
                setForceFullyClosed((prev) => !prev)
                break;

            default:
                break;
        }
    }
    return (
        <div className={`${(testDetails?.testTheme === 'usmle') ? 'usmle-main-wrapper' : null}`}>
            <AppLayout
                // pageName={pageName}
                customHeader={<TestHeader data={testDetails}
                    onNavigate={navigateQuestion}
                    qnIndex={currentQuestionIndex}
                    questions={formattedQuestions}
                    isCalcVisible={showCalc}
                    isLabVisible={showLab}
                    isExplanationVisible={showExplanation}
                    getHeaderHeight={setHeadHeight}
                    activeQuestion={formattedQuestions[currentQuestionIndex]?.questionId}
                    onClickItem={(item) => onClickHeaderItem(item)} />
                }
                customFooter={isLoading ? <></> : <TestFooter
                    onFinishTest={() => null}
                    testDetails={testDetails}
                    timer={0}
                    getFooterHeight={setFooterHeight}
                    remainingTime={0}
                    qnIndex={currentQuestionIndex}
                    questions={formattedQuestions}
                    isValidToSubmit={false}
                    onNavigate={navigateQuestion}
                    onEndRevision={onEndRevision}
                    onSubmitAnswer={() => null}
                    isSubmitting={false}
                    isRevisionMode
                    isFinishing={false} />}
                bodyClassName={`question-main-wrapper f-wrp ${testDetails.mode} ${testDetails.timer}`}
            >
                {isLoading ? <span>Loading...</span> : <Box sx={{ display: 'flex' }} className="question-main-structure revision-main-structure" style={{ height: `${windowSize[1] - footerHeight - headerHeight}px` }}>
                    {/* <Box className='question-count-wrp'>
                        <QuestionNumberDrawer
                            onQnNumberClick={navigateQuestion}
                            questions={formattedQuestions}
                            currentQuestionIndex={currentQuestionIndex}
                        />
                    </Box> */}
                    <Box className='side-value-wrapper revision-side-wrp revision-mob-sideWrp'>
                        <button className='revision-close-btn' type='button' onClick={() => onClickHeaderItem('revision')} > {showRevision ? <ChevronRightIcon /> : <ChevronLeftIcon />}</button>
                        {showRevision && <RevisionBox onQnNumberClick={navigateQuestion} qnIndex={currentQuestionIndex} questions={formattedQuestions} />}
                    </Box>
                    <Resizable
                        key={formattedQuestions[currentQuestionIndex]._id}
                        axis='x' width={'100%'} reverse 
                        initial={`${showLab && '460px'} ${showExplanation && '350px'}`}
                        // min={300}
                        >
                        {({
                            position: x,
                            isDragging: dragging,
                            separatorProps
                        }) => <Box component="main" className={`${dragging ? 'dragging' : 'not-dragging' } question-main-body revision-main-wrapper ${showLab ? 'labShowed' : 'labClosed'} ${((showExplanation || formattedQuestions[currentQuestionIndex]?.questionId) && !forceFullyClosed) ? 'expShowed' : 'expClosed'}`} sx={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Box className='question-main-wrapper' width={`calc(100% - ${x}px)`} style={{ width: `calc(100% - ${x}px)` }}>

                                    <RealModeQuestion
                                        test={{ ...testDetails, testId: testDetails._id }}
                                        questions={formattedQuestions.map((question) => ({ ...question.questionId, answerId: question?.answerId, actualAnswerId: question?.actualAnswerId, status: (question?.actualAnswerId === question?.answerId) ? 'correct' : (!question?.answerId) ? 'omitted' : 'unanswered', isFlagged: question?.isFlagged }))}
                                        selectedAnswer={{}}
                                        qnIndex={currentQuestionIndex}
                                        onNavigate={navigateQuestion}
                                        onSelectAnswer={() => null}
                                        onSubmitAnswer={() => null}
                                        isSubmitting={false}
                                        isRevisionMode
                                    />
                                    {((showExplanation || formattedQuestions[currentQuestionIndex]?.questionId) && !forceFullyClosed) && <div className='mob-explanationBox f-wrp'>
                                        <ExplanationBox
                                            onClose={() => onClickHeaderItem('explanation')}
                                            question={formattedQuestions[currentQuestionIndex].questionId} />
                                    </div>}
                                    {(formattedQuestions[currentQuestionIndex]?.timeTakenInSeconds) ? <span className='qn-timer'>{translateThis('You took')} {formattedQuestions[currentQuestionIndex]?.timeTakenInSeconds} {translateThis('seconds to answer this question')}</span> : null}
                                </Box>
                                <hr id="splitter" {...separatorProps} />
                                <Box className='side-value-wrapper' width={x} size={x} style={{ width: `${x}px` }}>
                                    {showLab && <LabValueTab onClose={() => onClickHeaderItem('lab')} />}
                                    {((showExplanation || formattedQuestions[currentQuestionIndex]?.questionId) && !forceFullyClosed) && <ExplanationBox
                                        onClose={() => onClickHeaderItem('explanation')}
                                        question={formattedQuestions[currentQuestionIndex].questionId} />}
                                    {/* showExplanation && */}
                                    {/* <CalculatorOld /> */}

                                    {showCalc && <div id="calculator-wrapper">
                                        {/* <Calculator onClose={() => onClickHeaderItem('calc')} />  */}
                                        <CalcContainer onClose={() => onClickHeaderItem('calc')} />
                                    </div>}

                                </Box>
                                <Box className={`side-value-wrapper revision-side-wrp ${showRevision ? 'open' : 'close'}`}>
                                    <button className='revision-close-btn' type='button' onClick={() => onClickHeaderItem('revision')} > {showRevision ? <ChevronRightIcon /> : <ChevronLeftIcon />}</button>
                                    {showRevision && <RevisionBox onEndRevision={onEndRevision} onQnNumberClick={navigateQuestion} qnIndex={currentQuestionIndex} questions={formattedQuestions} />}
                                </Box>
                            </Box>
                        }
                    </Resizable>
                </Box>}
            </AppLayout>


        </div>
    )
}
export default RevisionPage;