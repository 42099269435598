import * as React from 'react';
import { useRecoilValue } from 'recoil';

import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import '../index.scss';
import { getAuthId, getAuthRoleName } from '../../../../helpers/auth.helper';
import { showSnackbar } from '../../../../components/snackbar/index';

import { myPortalState } from '../../../../state/reducerAtoms/myPortal.atom';
import { updateProviderUniversity } from '../../../../services/api/auth';
import './settings.scss';
import FileUploader from '../../../../components/awsS3ImageUploader/file.uploader';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import ProviderLogo from '../../../../components/logo/facilityLogo';


const ProviderAccountDesign = () => {
    const { facilityInfo } = useRecoilValue(myPortalState);
    const [design, setDesign] = React.useState(facilityInfo?.design);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [img, setImg] = React.useState('');

    const authRoleType = getAuthRoleName();

    const onDataChange = (_, value) => {
        setDesign(value)

    }

    const onSubmit = () => {
        if (getAuthId() && facilityInfo?.universityId) {
            const postData = {
                design
            }
            // A new logo is updated, lets save it
            if (img?.fileKey) {
                postData.logo = img?.fileKey;
            }
            setIsSubmitting(true)
            updateProviderUniversity(facilityInfo?.universityId, postData)
                .then(() => showSnackbar().success('Settings updated successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    if(authRoleType === 'admin'){
                        window.location.reload()
                    }}
                )
                // .finally(() => window.location.reload())
        }

    }
    return (
        <>
            <div className='panel-form-sec forms-main-wrapper account-design f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>

                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>


                    <Grid item xs={12} sm={6} md={4}>
                            <div className='profile-img-con university-logo-upload'>
                                <div style={{height: '100px', width: '100%'}} className="profile-logo-section">
                                    {img?.tempURL ? <img src={img?.tempURL} alt='provider-logo' /> : <ProviderLogo/>}
                                </div>
                                <div className={`form-img-uploader f-wrp`}>
                                    <FileUploader
                                        acceptOnly='image/*'
                                        uploadIcon={SVGIcons.EditIcon()}
                                        uploadCaption=''
                                        showPreview
                                        onUploadEnd={(file) => setImg(file)}
                                    />
                                </div>
                                <div className='red-txt' style={{paddingTop: '5px', textAlign: 'center'}}>
                                    <p><b>Logo</b></p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className='profile-img-con university-logo-upload'>
                                <div style={{height: '100px', width: '100%'}} className="profile-logo-section">
                                    {img?.tempURL ? <img src={img?.tempURL} alt='provider-logo' /> : <ProviderLogo/>}
                                </div>
                                <div className={`form-img-uploader f-wrp`}>
                                    <FileUploader
                                        acceptOnly='image/*'
                                        uploadIcon={SVGIcons.EditIcon()}
                                        uploadCaption=''
                                        showPreview
                                        onUploadEnd={(file) => setImg(file)}
                                    />
                                </div>
                                <div className='red-txt' style={{paddingTop: '5px', textAlign: 'center'}}>
                                    <p><b>Logo with tag</b></p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className='profile-img-con university-logo-upload'>
                                <div style={{height: '100px', width: '100%'}} className="profile-logo-section">
                                    {img?.tempURL ? <img src={img?.tempURL} alt='provider-logo' /> : <ProviderLogo/>}
                                </div>
                                <div className={`form-img-uploader f-wrp`}>
                                    <FileUploader
                                        acceptOnly='image/*'
                                        uploadIcon={SVGIcons.EditIcon()}
                                        uploadCaption=''
                                        showPreview
                                        onUploadEnd={(file) => setImg(file)}
                                    />
                                </div>
                                <div className='red-txt' style={{paddingTop: '5px', textAlign: 'center'}}>
                                    <p><b>Login icon</b></p>
                                </div>
                            </div>
                        </Grid>

                        {/* <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#0F0A01', display: 'block' }}>Change Portal colors</span>
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for background</span>
                            </div>
                        </Grid> */}
                        {/* <Grid onClick={() => onDataChange('design', { ...design, ...{ theme: 'light-bg' } })} item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className={`clr-box light ${design?.theme === 'light-bg' ? 'selected' : ''}`}>
                                <span>Light</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ theme: 'dark-bg' } })} item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className={`clr-box dark ${design?.theme === 'dark-bg' ? 'selected' : ''}`}>
                                <span>Dark</span>
                            </div>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for highlights and buttons, this will affect University Portal and Student Portal appearance.</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'blue-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_1 ${design?.buttonTheme === 'blue-clr' ? 'selected' : ''}`}>
                                <span>Blue</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'green-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_2 ${design?.buttonTheme === 'green-clr' ? 'selected' : ''}`}>
                                <span>Green</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'orange-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_3 ${design?.buttonTheme === 'orange-clr' ? 'selected' : ''}`}>
                                <span>Orange</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'yellow-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_4 ${design?.buttonTheme === 'yellow-clr' ? 'selected' : ''}`}>
                                <span>Yellow</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'purple-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_5 ${design?.buttonTheme === 'purple-clr' ? 'selected' : ''}`}>
                                <span>Purple</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'deepPurple-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_6 ${design?.buttonTheme === 'deepPurple-clr' ? 'selected' : ''}`}>
                                <span>Deep purple</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'red-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_7 ${design?.buttonTheme === 'red-clr' ? 'selected' : ''}`}>
                                <span>Red</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'violetBlue-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_8 ${design?.buttonTheme === 'violetBlue-clr' ? 'selected' : ''}`}>
                                <span>Violet blue</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'chestnut-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                            <div className={`clr-box btnClr_9 ${design?.buttonTheme === 'chestnut-clr' ? 'selected' : ''}`}>
                                <span>Chestnut</span>
                            </div>
                        </Grid>

                    </Grid>
                    <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                        <div className='ryt-btn-sec'>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmit}
                            >
                                Save changes
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
ProviderAccountDesign.propTypes = {};
export default ProviderAccountDesign;