import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
// import TabLayout from '../../../components/tabs';

import './index.scss';


import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';

import { DATE_TIME_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants/index';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';

import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';


const UserList = () => {

    const apiCaller = new ApiCaller('university/students');
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data?.result.map((res, i) => ({ ...res, slNo: i + 1 })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 135,
            flex: 0.7,
        },
        {
            field: 'createdAt',
            headerName: 'Signup',
            valueGetter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'usedQuestions',
            headerName: 'Q used',
            minWidth: 60,
            flex: 0.5,
        },
        {
            field: 'isSubscribed',
            headerName: 'Subscribed',
            valueGetter: ({ value }) => value ? `${translateThis('Yes')}` : `${translateThis('No')}`,
            minWidth: 100,
            flex: 0.7,
        },
        {
            field: 'id',
            headerName: 'Action',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => <AppPopOverMenu options={[
                {
                    label: `${translateThis('View')}`,
                    onClick: () => {
                        navigator(`/${getCurrentFacilityURL()}/user-profile/${row.id}`)

                    }
                },

            ]} />
        }
    ]

    return (
        <>
            <div className='panel-table-sec provider-table PC-Table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />

                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn'>
                                            <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                            <h4 style={{ fontWeight: 'normal' }}>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>

                                        <div className='flex-wrp text-overflow-elips'>
                                            <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 120px)', overflow: 'hidden' }}>
                                                {(element.name) ? <h4>
                                                    {element.name}
                                                </h4> : '-'}
                                                {(element.email) ? <span style={{ display: 'block' }}>{translateThis('Email')} {(element.email)} </span> : <span style={{ display: 'block' }}>{translateThis('Email')} - </span>}
                                                {(element.phone) ? <span style={{ display: 'block' }}>{translateThis('Phone')} {(element.phone)} </span> : <span style={{ display: 'block' }}>{translateThis('Phone')} - </span>}
                                            </div>
                                            <div className='flex-wrp auto-flex'>
                                                <p style={{ color: '#4C483E' }}>Q Used: {(element.usedQuestions) ? <span>{(element.usedQuestions)} </span> : '-'}</p>
                                                <p style={{ color: '#4C483E' }}>Subscribed: {element.isSubscribed ? 'Yes' : 'No'}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <button type='button' className='full-li-btn' onClick={() => {
                                        navigator(`/${getCurrentFacilityURL()}/user-profile/${element.id}`)
                                    }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }

            </div>


        </>
    )
}

export default UserList;