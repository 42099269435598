import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Stack, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { translateThis } from '../../../../../helpers/language.helper';

import AppIcons from '../../../../../assets/images/icons';
// import SVGIcons from '../../../../../assets/images/icons/svgIcons';

import '../../index.scss';
import RichTextViewer from '../../../../../components/rich-text-editor/TextViewer';
import { getCurrentFacilityURL } from '../../../../../helpers/helper.functions';
import ApiCaller from '../../../../../services/api/general';
import AppLoader from '../../../../../components/appLoader';
import { showSnackbar } from '../../../../../components/snackbar/index';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import ConfirmDialog from '../../../../../components/dialogs/confirm.dialog';
import SubscriptionPopup from '../../../createTestSection/Forms/subscriptionPopup';







const SelectedExam = (props) => {
    const navigate = useNavigate();
    const { exam = {}, onCancelClick = () => null, onPrevExamsClick = () => null } = props
    console.log(exam)
    const apiCaller = new ApiCaller('universityExams')
    const [isLoading, setIsLoading] = React.useState(true);
    const [isResuming, setIsResuming] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [isUserSubscribed, setIsUserSubscribed] = React.useState(false);
    const [isOpenSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);

    React.useEffect(() => {
        getSubscriptionStatus();
        getDataArr()
        // eslint-disable-next-line
    }, []);

    const getSubscriptionStatus = () => {
        const apiCaller = new ApiCaller('auth');
        apiCaller.getData('subscription-status').then(({ subscribed }) => {
            setIsUserSubscribed(subscribed);
        })
    }

    /**
     * Get Assigned Exam from Api
     */
    const getDataArr = () => {
        apiCaller.getData(`get-completed-attempts/${exam._id}`).then((data) => setData(data))
            .catch((e) => showSnackbar().failure('Something went wrong!. Please try again later'))
            .finally(() => setIsLoading(false))
    }

    const onExamStart = () => {
        if (exam.isSubscriptionNeeded) {
            if (isUserSubscribed) {
                handleExamStart();
            } else {
                setOpenSubscribeDialog(true);
            }
        } else {
            handleExamStart();
        }

    }

    const handleExamStart = () => {
        if (data?.isPaused) {
            onResumeExam();
        } else {
            navigate(`/${getCurrentFacilityURL()}/start-exam/${exam._id}`)
        }
    }

    /**
     * On onResumeExam
     */
    const onResumeExam = () => {
        const formData = {
            testId: exam._id,
        }
        setIsResuming(true)
        apiCaller.customPostData('/resume-exam', formData).then(() => {
            navigate(`/${getCurrentFacilityURL()}/start-exam/${exam._id}`)
        }).finally(() => setIsResuming(false));

    }

    /**
     * When user clicks on subscribe
     */
    const onSubscribeConfirm = () => {
        navigate(`/${getCurrentFacilityURL()}/plans`, { replace: true })

    }

    return (

        <div className='exam-preview-main-wrapper'>


            {isLoading ? <AppLoader /> : <Stack className='exam-preview-wrp' spacing={3} style={{ border: 'none' }}>
                <div className='exam-basic-info'>
                    {/* <span className='exam-logo'><img src={AppIcons.previewIcon} alt='logo' /></span> */}
                    <Button className='closebtn' onClick={onCancelClick} variant='text' type='button'  ><SVGIcons.BackIcon /> {translateThis('Back')} </Button>
                    <h1>{exam?.name || ''}</h1>
                    <p>{exam?.introduction || ''}</p>
                    <h3 style={{ marginTop: '20px' }}>{translateThis("Basic info")}</h3>
                    <ul className='basic-list'>
                        <li>
                            <div className='each-basic-items'>
                                <img className='icons' src={AppIcons.Attempts} alt='icon' /><b>{translateThis("Times taken")}: </b><span>{data?.totalAttempts || 0} / {exam?.noOfAttempts}</span>
                            </div>
                        </li>
                        <li>
                            <div className='each-basic-items'>
                                <img className='icons' src={AppIcons.number} alt='icon' /><b>{translateThis("Questions")}: </b><span>{exam.questionCount}</span>
                            </div>
                        </li>
                        <li>
                            <div className='each-basic-items'>
                                <img className='icons' src={AppIcons.Time} alt='icon' /><b>{translateThis("Available time")}: </b><span>{exam.duration}&nbsp;{exam.durationMetric}{exam.duration > 1 ? 's' : ''}</span>
                            </div>
                        </li>
                        {exam?.difficulty && <li>
                            <div className='each-basic-items'>
                                <img className='icons' src={AppIcons.Difficulty} alt='icon' /><b>{translateThis("Difficulty")}: </b><span>{exam?.difficulty || ''}</span>
                            </div>
                        </li>}
                    </ul>
                </div>
                <div className='button-sec f-wrp' style={{ borderBottom: '1px solid var(--Neutral600)', borderTop: '1px solid var(--Neutral600)', paddingBottom: '35px', marginBottom: '24px' }}>
                    <Tooltip
                        title={!data?.isValidToAttend ? data?.inValidToStartReason : ''}
                    ><div className='lft-btn-sec'>

                            <LoadingButton
                                loading={isResuming}
                                disabled={!data?.isValidToAttend}
                                type="submit"
                                className='fill-btn'
                                onClick={onExamStart}
                            >
                                {data?.isPaused ? translateThis("Resume") : translateThis("Start")}
                            </LoadingButton>

                        </div></Tooltip>
                    {data?.totalAttempts > 0 ? <div className='ryt-btn-sec'>
                        <LoadingButton
                            // loading={}
                            // disabled={}
                            type="submit"
                            className='pln-btn'
                            onClick={onPrevExamsClick}
                        >
                            {translateThis("View previous attempts")}
                        </LoadingButton>

                    </div> : null}
                </div>
                <div className='eachExam-detail-wrp'>
                    <h3>{translateThis("Exam Details")}</h3>
                    <RichTextViewer richText={exam?.description} />
                </div>
                <div className='eachExam-detail-wrp'>
                    <h3>{translateThis("Prepare for the exam")}</h3>
                    <p>{translateThis("Create a structured study plan that allocates sufficient time to the following suggested topics")}</p>

                    {(exam?.populatedTopics && exam?.populatedTopics.length > 0) ? <ul className='chip-list'>
                        {exam?.populatedTopics.map((topic) => <li key={topic.id}><span className='chip'>{topic.name}</span></li>)}
                    </ul> : <p>{translateThis("No topics found")}</p>}
                </div>
                {isOpenSubscribeDialog && <ConfirmDialog
                    isOpen={isOpenSubscribeDialog}
                    onClose={() => {
                        setOpenSubscribeDialog(false);
                    }}
                    // title=""
                    className={'delete-popup subscription-popup'}
                    description={<SubscriptionPopup />}
                    okayButtonText={'Subscribe'}
                    onConfirm={onSubscribeConfirm}
                />}
            </Stack>}
        </div>
    );
}

SelectedExam.propTypes = {
    exam: PropTypes.any,
    onCancelClick: PropTypes.func,
    onPrevExamsClick: PropTypes.func
}

export default SelectedExam;