import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const MedicalExemptionView = (props) => {
    const { data = {} } = props;
    const { formData = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView medicalExemptionView f-wrp">
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>Medical Exemption</b></h2>
                        <div className='gap' />
                        <p>According to State statutes (Connecticut General Statutes Sections 19a-7f and 10-204a), no child may be admitted to a licensed child care program or school without proof of immunization or a statement of exemption. Parents or guardians claiming a medical exemption on the basis that a given immunization is medically contraindicated should complete the following statement and attach a letter signed by a physician licensed to practice medicine stating that in the physician’s opinion, such immunization is medically contraindicated and return it to the school or child care facility. The letter must include the child’s name, birth date, the vaccine(s) for which exemption is being filed and the condition that contraindicates vaccination, as well as the physician’s signature and contact information.</p>
                        <div className='gap' />
                        <p><b>To Whom It May Concern:</b></p>
                        <div className='gap' />
                        <p>As the parent(s)/guardian(s) of: <b>{answerGetter('medical_exemption_form_question_studentName', formData)?.answer || '-'}</b></p>
                        <div className='gap' />
                        <p>I/we are submitting the enclosed documentation from a physician that immunization of this child is medically contraindicated. Therefore, this child is exempt from receiving the required immunization as specified by the physician, and shall be permitted to attend a licensed child care program or school except in the case of a vaccine-preventable disease outbreak.</p>
                    </div>
                </div>
                <div className='gap' />

                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{answerGetter('medical_exemption_form_question_signatureName', formData)?.answer || '-'}</h3>
                                <p>Parent | Guardian signature</p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{answerGetter('medical_exemption_form_question_signatureName2', formData)?.answer || '-'}</h3>
                                <p>Parent | Guardian signature</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <div className='flex-box'>
                        <p>Address: <b>{answerGetter('medical_exemption_form_question_Address', formData)?.answer || '-'}</b></p>
                        <p>Telephone: <b>{answerGetter('medical_exemption_form_question_Telephone', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <p>Children with medical exemptions shall be permitted to attend a licensed child care program or school except in the case of an outbreak of vaccine-preventable disease. In the event of an outbreak of vaccine preventable disease, all susceptible children will be excluded from child care or school settings based on public health officials’ determination that the child care facility or school is a significant site for disease exposure, transmission and spread into the community. Children without proof of immunity, including children with religious and medical exemptions shall be excluded from these settings for this reason and will not be able to return until (1) the danger of the outbreak has passed as determined by public health officials, (2) the child becomes ill with the disease and completely recovers, or (3) the child is immunized.</p>
                </div>
            </div>
        </>
    )
}

MedicalExemptionView.propTypes = {
    data: PropTypes.any
}

export default MedicalExemptionView;