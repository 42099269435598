import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { AppBar, Box, CssBaseline } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';
import NotificationBell from '../../../components/notification/notificationBell';
import UserDrawer from '../../../components/drawer/drawer';


const ProviderPortalHeader = ({ header = '' }) => {
    const { pageHeader = '' } = useRecoilValue(myPortalState);
    return (
        <>
            <Box className='panel-main-header'>
                <CssBaseline />
                <AppBar className='app-portal-header' position="relative">
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <Typography variant="h4" noWrap component="div">
                            {header || pageHeader}
                        </Typography>
                        
                        <div className='left-sec'>
                            <div className='mobile-menu-sec'>
                                <UserDrawer />
                            </div>
                        </div>
                        <div className='header-notification'>
                            <NotificationBell />
                        </div>
                    </Toolbar>
                </AppBar>

            </Box>

        </>

    )
}

ProviderPortalHeader.propTypes = {
    header: PropTypes.string,
}

export default ProviderPortalHeader;