import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
// import TabLayout from '../../../components/tabs';

import './index.scss';


import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';

import { DATE_TIME_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants/index';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';

import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppButton from '../../../components/buttons/app.button';

import AddEditSubAdminForm from './forms/addEditAdmin.form';
import ModalPopUp from '../../../components/modal/modal.popup';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { accessGuard } from '../../../helpers/auth.helper';


const SubAdminsList = () => {

    const apiCaller = new ApiCaller('university/sub-admin');
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCreateAdminDialog, setOpenCreateAdminDialog] = useState(false);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data?.result.map((res, i) => ({ ...res, slNo: i + 1, id: i + 1 })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedUser) {
            apiCaller.deleteData(selectedUser._id)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('User deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete user")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedUser('');
                    getDataArr();
                })
        }

    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            renderCell: ({ row }) => `${row.name} ${row?.lastName || ''}`,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'mobileNo',
            headerName: 'Phone',
            minWidth: 135,
            flex: 0.7,
        },
        {
            field: 'createdAt',
            headerName: 'Created at',
            valueGetter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => accessGuard('modifyAdminUsers') ? <AppPopOverMenu icon={<>{row.status === 'INACTIVE' ? SVGIcons.StatusBlockedIcon() : SVGIcons.StatusActiveIcon()}</>} options={[
                {
                    label: <span className='tab-status' style={{ display: 'flex', gap: '5px' }}>{SVGIcons.StatusActiveIcon()} Active </span>,
                    onClick: () => {
                        apiCaller.customPostData(`/${row._id}`, { status: 'ACTIVE' }).then(() => getDataArr())
                    }
                },
                {
                    label: <span className='tab-status' style={{ display: 'flex', gap: '5px' }}>{SVGIcons.StatusBlockedIcon()} Inactive </span>,
                    onClick: () => {
                        apiCaller.customPostData(`/${row._id}`, { status: 'INACTIVE' }).then(() => getDataArr())
                    }
                },
            ]} /> : null

        },
        {
            field: 'id',
            headerName: 'Action',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => accessGuard('modifyAdminUsers') ? <AppPopOverMenu options={[
                {
                    label: 'Edit',
                    onClick: () => {
                        setSelectedUser(row);
                        setOpenCreateAdminDialog(true);
                    }
                },
                {
                    label: 'Delete',
                    onClick: () => {
                        setSelectedUser(row);
                        setOpenDeleteDialog(true);
                    }
                },

            ]} /> : null
        }
    ]


    return (
        <>
            <div className='sub-admin-users f-wrp'>
                {accessGuard('modifyAdminUsers') && <div className='inbox-button f-wrp' style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '24px' }}>
                    <AppButton
                        style={{ background: 'var(--accent-accent-700-Clr)', padding: '5px 30px' }}
                        onClick={() => setOpenCreateAdminDialog(true)}
                        startIcon={<Add fontSize='small' />} btnText='New Admin' />
                </div>}
                <div className='panel-table-sec user-table provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                </div>

                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn'>
                                            <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                            <h4 style={{ fontWeight: 'normal' }}>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>

                                        <div className='flex-wrp text-overflow-elips'>
                                            <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 120px)', overflow: 'hidden' }}>
                                                {(element.name) ? <h4>
                                                    {element.name}
                                                </h4> : '-'}
                                                {(element.email) ? <span style={{ display: 'block' }}>{translateThis('Email')} {(element.email)} </span> : <span style={{ display: 'block' }}>{translateThis('Email')} - </span>}
                                                {(element.phone) ? <span style={{ display: 'block' }}>{translateThis('Phone')} {(element.phone)} </span> : <span style={{ display: 'block' }}>{translateThis('Phone')} - </span>}
                                            </div>
                                            <div className='flex-wrp auto-flex'>
                                                <p style={{ color: '#4C483E' }}>Q Used: {(element.usedQuestions) ? <span>{(element.usedQuestions)} </span> : '-'}</p>
                                                <p style={{ color: '#4C483E' }}>Subscribed: {element.isSubscribed ? 'Yes' : 'No'}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <button type='button' className='full-li-btn' onClick={() => {
                                        navigator(`/${getCurrentFacilityURL()}/user-profile/${element.id}`)
                                    }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }

                {openDeleteDialog && <ConfirmDialog
                    isOpen={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedUser(null);
                    }}
                    title={`Delete admin`}
                    className={'delete-popup'}
                    description={`Are you sure you want to delete this admin?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={'Delete admin'}
                    onConfirm={deleteData}
                />}

                {openCreateAdminDialog && <ModalPopUp
                    isOpen={openCreateAdminDialog}
                    onClose={() => {
                        setOpenCreateAdminDialog(false);
                        setSelectedUser(null)
                    }}
                    className={'modal-popup inbox-popup new-message-popup'}
                    children={<div>

                        <AddEditSubAdminForm
                            initialData={selectedUser}
                            onClose={() => {
                                setOpenCreateAdminDialog(false)
                                setSelectedUser(null);
                            }}
                            onSuccess={() => {
                                setOpenCreateAdminDialog(false);
                                getDataArr();
                                setSelectedUser(null);
                            }}
                        />
                    </div>}

                />}

            </div>


        </>
    )
}

export default SubAdminsList;