import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import '../home.scss';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';

const FAQAccount = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqItems = [
        {
            panelCount: 'panel1',
            title: 'How do I delete my XMed Pro account?',
            content: 'To delete your XMed Pro account, please contact us at hello@xmedpro.com.',
        },
        {
            panelCount: 'panel2',
            title: 'How do I reset my password if I forget it?',
            content: 'If your account gets blocked, please contact us at hello@xmedpro.com to request unblocking.',
        },
        {
            panelCount: 'panel3',
            title: 'What happens if my account gets blocked?',
            content: 'If you forget your password, you can reset it by clicking on "Forgot your password?" in the login-in screen.',
        },
        
    ];

    return (
        <>
            <div className='faq-accordion-wrp f-wrp'>
                {faqItems.map((element, key) => (
                    <Accordion className='each-accordian-sec' expanded={expanded === element.panelCount} onChange={handleChange(element.panelCount)} key={key}>
                        <AccordionSummary
                            // expandIcon={SVGIcons.ChevronDownIcon()}
                            aria-controls={`${element.panelCount}bh-content`}
                            id={`${element.panelCount}bh-header`}
                        >
                            {expanded === element.panelCount ? <span>{SVGIcons.MinusIcon()}</span> : <span>{SVGIcons.PlusIcon()}</span>}
                            <h4>{translateThis(element.title)}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{translateThis(element.content)}</p>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default FAQAccount;