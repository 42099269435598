import * as React from 'react';
import { useRecoilState } from 'recoil';

import { Link, useNavigate } from 'react-router-dom';
import { Grid, Button, Stack, InputLabel } from '@mui/material';
import TextInput from '../../../../components/inputs/textInput/textInput';
import '../index.scss';
import { getAuthId } from '../../../../helpers/auth.helper';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import { showSnackbar } from '../../../../components/snackbar/index';
import ProfilePicUploader from '../../../../components/awsS3ImageUploader/profilePic.uploader';

import { myPortalState } from '../../../../state/reducerAtoms/myPortal.atom';
import { getProfile, updateProfile } from '../../../../services/api/auth';
import { translateThis } from '../../../../helpers/language.helper';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

export default function AdminProfile() {
    const userId = getAuthId();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    // eslint-disable-next-line
    const [_, setMyPortalState] = useRecoilState(myPortalState)
    const facilityURL = getCurrentFacilityURL()

    /**
  the legendary use effect function
  This will run very first on render
   */
    React.useEffect(() => {
        getData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getProfile()
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...data };
        setData({ ...formDataAs, ...{ [name]: value } });
    }


    /**
   * updateData
   */
    const updateData = () => {
        if (userId) {
            const postData = {
                name: data.name,
                phone: data.phone,
                profileImg: data?.profileImg?.fileKey
            }
            updateProfile(postData)
                .then(() => showSnackbar().success('Profile updated successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getData();
                })
        }

    }

    const onCancel = () => {
        navigate(`/${facilityURL}/my-portal`)

    }


    return (
        <>

            {(isLoading) ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : (!isLoading && data) ? 
            <div className='profile-wrapper f-wrp'>
                <div className='profile-img-con'>
                    <ProfilePicUploader
                        key={`profile-pic`}
                        onUploadStart={() => null}
                        onUploadEnd={(img) => setFormValue("profileImg", img)}
                        // onRemoveFile={() => setFormValue("profilePic", {fileKey: ''})}
                        onRemoveFile={() => setFormValue("profileImg", '')}
                        defaultImage={data?.profileImg}
                    />
                </div>
                <div className='profile-details child-profile f-wrp'>

                    <form>
                        <div className='formBox f-wrp'>
                            <h3>{translateThis('Basic info')}</h3>
                            <Grid className='profile-form' container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <InputLabel shrink htmlFor="first-Name">
                                        {translateThis('Name')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                    <TextInput
                                        key={`first-Name`}
                                        name='name'
                                        type='text'
                                        value={data?.name}
                                        getValue={setFormValue}
                                        required
                                        placeholder={translateThis('Name')}
                                        className='custom-textfield'
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <InputLabel shrink htmlFor="Phone">
                                        {translateThis('Phone')}
                                    </InputLabel>
                                    <TextInput
                                        key={`adminProfilePhone`}
                                        name='phone'
                                        type='number'
                                        value={data?.phone}
                                        getValue={setFormValue}
                                        required
                                        placeholder={translateThis('Phone')}
                                        className='custom-textfield'
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <InputLabel shrink htmlFor="Email">
                                        {translateThis('Email')}
                                    </InputLabel>
                                    <TextInput
                                        key={`login-email`}
                                        name='email'
                                        type='email'
                                        value={data?.email}
                                        isDisabled
                                        placeholder={translateThis('loginForm_emailPlaceHolder')}
                                        className='custom-textfield'
                                    />
                                </Grid>
                                
                            </Grid>
                        </div>
                        <Stack className='additional-btn f-wrp' variant='div' spacing={3}>
                            <Link to={`/${facilityURL}/user-forgot-password`} >{translateThis('Forgot your password')}</Link>
                        </Stack>
                        <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                            <div className='ryt-btn-sec'>
                                <Button onClick={onCancel} className='cancel-btn'>{translateThis('Cancel')}</Button>
                                <Button onClick={updateData} className='fill-btn'>{translateThis('Save changes')}</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
             : null}

        </>
    );
}