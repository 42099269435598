import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// @mui
// import { Stack, Divider, Button } from '@mui/material';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { translateThis } from '../../../helpers/language.helper';
// components
import { resetPassword } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/snackbar/index';
import TextInput from '../../../components/inputs/textInput/textInput';
import { isLoggedIn } from '../../../helpers/auth.helper';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';



// ----------------------------------------------------------------------

export default function SetNewPasswordForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = isLoggedIn();
    const currentFacilityURL = getCurrentFacilityURL();
    const { from } = location.state || { from: { pathname: `/${currentFacilityURL}/auth/login` } };
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    if (isAuth) {
        navigate(from, { replace: true });
    }
    // state variables for handling input fields values
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    })
    const [validPassword, setValidPassword] = useState({
        eightCharacter: false,
        containNumber: false,
        containUpperCase: false,
        containSpecialChar: false
    });
    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.password && formData.confirmPassword && formData.confirmPassword === formData.password) {
            isValid = true;
        }
        return isValid;
    }

    

    /**
     * On Submitting the form
     */
    const onSubmit = () => {
        if (formData.password === formData.confirmPassword && token) {
            setIsLoading(true);
            resetPassword({ password: formData.password, token }).then((data) => {
                if (data.success) {
                    navigate(`/${getCurrentFacilityURL()}/auth/login`, { replace: true });
                    showSnackbar().success("Password reset successfully!")
                } else {
                    showSnackbar().failure("Password reset failed!");
                }
            }).finally(() => setIsLoading(false));
        } else {
            showSnackbar().failure("Passwords do not match!")
        }

    };


    /**
     * The signup form elements
     */
    const formElements = [

        {
            label: translateThis('loginForm_password'),
            name: 'password',
            type: 'password',
            isRequired: false,
            parentClassName: 'no-padding-btm',
            className: 'custom-textfield',
            placeholder: translateThis('passwordResetForm_passwordPlaceHolder'),
            getValue: (_, value) => validatePassword(value)
        },
        {
            label: translateThis('passwordResetForm_confirmPassword'),
            name: 'confirmPassword',
            type: 'password',
            isRequired: false,
            parentClassName: 'no-padding-btm',
            className: 'custom-textfield',
            placeholder: translateThis('passwordResetForm_confirmPasswordPlaceHolder'),
            getValue: setFormValue
        },
    ];

    /**
    * To validate password requirement
    * @param {String} password 
    */
    const validatePassword = (password) => {
        const rules = {
            eightCharacter: false,
            containNumber: false,
            containUpperCase: false,
            containSpecialChar: false
        }

        if (password.length > 7) { // To check if password contain 8 chars
            rules.eightCharacter = true;
        }
        if (/\d/.test(password)) { // To check if password contain numbers
            rules.containNumber = true;
        }
        if (password !== password.toLowerCase()) { // To check if password contain uppercase
            rules.containUpperCase = true;
        }
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (format.test(password)) { // To check if password contain special chars
            rules.containSpecialChar = true;
        }
        setValidPassword(rules)
        if (rules.containNumber &&
            rules.containSpecialChar &&
            rules.containUpperCase &&
            rules.eightCharacter
        ) {
            setFormValue('password', password)
        } else {
            setFormValue('password', '')
        }
    }

    return (
        <>
            {/* <Stack className='google-auth' spacing={3}>
                <Button className='google-auth-btn'> {SVGIcons.GoogleIcon()} Sign up with Google </Button>
            </Stack>
            <Stack className='divider' direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                <Divider>or</Divider>
            </Stack> */}
            <Stack className='login-form standard-form' spacing={3}>
                {formElements.map((element, key) => (
                    <TextInput
                        key={`register-input-${key}`}
                        {...element}
                        useDefaultValidation
                    />
                ))}
                <Stack className='password-requirement-points' variant='div' spacing={3} style={{ marginTop: '0' }}>
                    <ul>
                        <li className={validPassword.eightCharacter ? 'checked' : ''}>{translateThis('At least 8 characters long')}</li>
                        <li className={validPassword.containNumber ? 'checked' : ''}>{translateThis('1 number')}</li>
                        <li className={validPassword.containUpperCase ? 'checked' : ''}>{translateThis('1 uppercase character')}</li>
                        <li className={validPassword.containSpecialChar ? 'checked' : ''}>{translateThis('1 special symbol')}</li>
                    </ul>
                </Stack>
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    {translateThis('Reset password')}
                </LoadingButton>
            </Stack>

        </>
    );
}
