import { useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import TabLayout from '../../../components/tabs';
import './index.scss';

import { translateThis } from '../../../helpers/language.helper';
import UserDrawer from '../../../components/drawer/drawer';
import ModalPopUp from '../../../components/modal/modal.popup';
import AppLayout from '../../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../components/headerRightUserInfo';

import AppIcons from '../../../assets/images/icons';
import JoinAClass from './joinAClass';
import ClassesListPage from './classesList';


const ClassesSectionPage = () => {
    const [viewOpen, setViewOpen] = useState(false);

    const onViewClose = () => {
        setViewOpen(false);
    };
    const onViewOpen = () => {
        setViewOpen(true);
    };
   

    const tabNames = [{ name: "Classes" }, { name: "Webinars" }];
    const tabContents = [
        <div key={'to_Classes'}>
            <ClassesListPage key={'classes'} requestType='classes' />
            
        </div>,
        <div key={'to_Podcasts'}>
            <ClassesListPage key={'Podcasts'} requestType='podcasts' />
        </div>,
    ];

    return (
        <>
            <AppLayout
                // pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            <div className='main-category-page'>
                                <div className='main-category-header'>
                                    <h2>{translateThis("Classes")}</h2>
                                    <HeaderRightUserInfo />
                                </div>
                                <div className='main-category-content exam-tab-wrp'>
                                <TabLayout
                                        tabContents={tabContents}
                                        tabNames={tabNames}
                                    />
                                </div>
                                {viewOpen ? <ModalPopUp
                                    className='join-class-popup'
                                    isOpen={viewOpen}
                                    onClose={onViewClose}
                                    aria-labelledby="table-view"
                                    aria-describedby="table-view"
                                >
                                    <div className='joinClassPopup f-wrp'>
                                        <h3 style={{ display: 'flex' }}>{translateThis('Join a class')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Enter your unique class code to join a new class')}</b></span>} placement="top">
                                            <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                        </Tooltip></h3>
                                        <JoinAClass />
                                    </div>
                                </ModalPopUp> : null}
                            </div>
                        </Box>
                    </Box>
                </Box>
            </AppLayout>
        </>
    )
}
ClassesSectionPage.propTypes = {
}
export default ClassesSectionPage;