import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, Tooltip } from '@mui/material';


import { translateThis } from '../../../../helpers/language.helper';

import AppIcons from '../../../../assets/images/icons';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';




const BasicInfoPage = () => {
    const { id } = useParams();
    const apiCaller = new ApiCaller('university/students');
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenResetDialog, setOpenResetDialog] = React.useState(false);
    const [data, setData] = useState({});
    const [resetSelectionArr, setResetSelectionArr] = React.useState(['Metrics', 'Tests']);
    useEffect(() => {
        getData()

    }, [id]);

    const getData = () => {
        apiCaller.getData(id).then(({ result }) => {
            setData(result);
        }).finally(() => setIsLoading(false))
            .catch(() => showSnackbar().failure("Something went wrong!. Please try again later."))
    }

    /**
     * On reset account
     */
    const onResetButton = () => {
        if (resetSelectionArr.length > 0) {
            setOpenResetDialog(true);
        } else {
            showSnackbar().warning('Please select a option to reset')
        }
    }
    
    /**
     * On reset account
     */
    const onResetConfirm = () => {
        if (resetSelectionArr.length > 0) {
            if (data?.id) {
                const postData = {
                    userIds: [data?.id],
                    selectedOptions: resetSelectionArr
                }
                const apiCaller = new ApiCaller('analytics/reset-logs-university')
                apiCaller.postData(postData)
                    .then(() => showSnackbar().success('Account reset successfully'))
                    .catch(() => showSnackbar().failure("Something went wrong!."))
                    .finally(() => {
                        setOpenResetDialog(false);
                        setResetSelectionArr([])
                        getData();
                    })
            }
        } else {
            showSnackbar().warning('Please select a option to reset')
        }
    }

    return (

        <div className='basic-info-wrapper'>
            {isLoading ? <span>Loading...</span> : <Grid className='basic-info-grid' container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className='basic-info-box'>
                        <span className='user-image'>{(data?.profileImg) ? <img src={data?.profileImg} alt='user' /> : ''}</span>
                        <h3>{data?.name || ''}</h3>
                        {/* <p>{translateThis('Percentile Rank')}:  5%</p> */}
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div className='each-basic-info-box'>
                        <h3>{translateThis('Basic info')}</h3>
                        <div className='basic-detail-box'>
                            <p><b>{translateThis('Full name')}</b> : {data?.name}</p>
                            <p><b>{translateThis('Email')}</b> : {data?.email}</p>
                            <p><b>{translateThis('Phone')}</b> : {data?.phone}</p>
                            <p><b>{translateThis('Nationality')}</b> : {data?.nationality}</p>

                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div className='each-basic-info-box'>
                        <h3>{translateThis('Student info')}</h3>
                        <div className='basic-detail-box'>
                            <p><b>{translateThis('Medical specialty')}</b> : {data?.fieldOfStudy}</p>
                        </div>
                    </div>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className='formBox resetAccount-blk f-wrp' style={{ marginTop: '20px' }}>
                                <h3 style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{translateThis('Reset account')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}>{translateThis('Reset all selected data from your account, with the possibility (if selected) of keeping the test history for reference')} {/* <b>{translateThis('This action can only be done once')}</b> */} </span>} placement="top">
                                    <span className='tool-button'><img src={AppIcons.info} alt='icon' style={{ width: '16px', height: '16px' }} /></span>
                                </Tooltip></h3>
                                {(data && (data?.canResetMetrics || data?.canResetTests)) ? <Grid className='profile-form' container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel shrink htmlFor="fieldOfStudy">
                                            {translateThis('Select what you want to reset from user account')}
                                        </InputLabel>
                                        {/* <FormLabel className='main-head' id="accidentFormQn_7">{translateThis('accidentFormQn_7')}</FormLabel> */}
                                        <FormGroup
                                            row
                                            aria-labelledby={translateThis('Select what you want to reset from your account')}
                                            // defaultValue={data?.fieldOfStudy}
                                            id="provider_accident_form_accidentFormQn_5_part_of_the_body"
                                            name="provider_accident_form_accidentFormQn_5_part_of_the_body"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setResetSelectionArr((arr) => {
                                                        if (arr.includes(e.target.value)) {
                                                            return arr.filter((a) => a !== e.target.value)
                                                        }
                                                        arr.push(e.target.value);
                                                        return arr
                                                    })
                                                } else {
                                                    setResetSelectionArr((arr) => arr.filter((a)=>a!==e.target.value))
                                                }

                                            }}
                                        // value={data?.fieldOfStudy}
                                        >
                                            {(data) && <FormControlLabel checked disabled value="Metrics" control={<Checkbox />} label={translateThis("Metrics & questions")} />}
                                            {(data) && <FormControlLabel checked disabled value="Tests" control={<Checkbox />} label={translateThis("Test history")} />}
                                        </FormGroup>

                                        <div className='button-sec f-wrp'>
                                            <div className='lft-btn-sec' style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                    onClick={onResetButton} className='fill-btn'>{translateThis('Reset account')}</Button>

                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>: <span>You have already reset your account.</span>}
                            </div>
                </Grid>
            </Grid>}
            {isOpenResetDialog && <ConfirmDialog
                isOpen={isOpenResetDialog}
                onClose={() => {
                    setOpenResetDialog(false);
                }}
                title={`${translateThis('Reset account')}`}
                className={'delete-popup'}
                description={<><span>{`${translateThis('Are you sure you want to reset this user account? All the information will be lost')}`}.</span> {/* <span style={{ color: 'var(--Accent)' }}>{`${translateThis('This action cannot be undone')}`}</span>  */}</>}
                okayButtonText={`${translateThis('Confirm')}`}
                onConfirm={onResetConfirm}
            />}
        </div>

    )
}



BasicInfoPage.propTypes = {};

export default BasicInfoPage;