import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { translateThis } from '../../../../../helpers/language.helper';
import AppTable from '../../../../../components/table/index';
import ApiCaller from '../../../../../services/api/general';
import { showSnackbar } from '../../../../../components/snackbar/index';

import AppLayout from '../../../../../layouts/app/AppLayout';
import { DATE_FORMAT } from '../../../../../constants/index';
import { getCurrentFacilityURL } from '../../../../../helpers/helper.functions';
import ConfirmDialog from '../../../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import HeaderRightUserInfo from '../../../components/headerRightUserInfo';
import UserDrawer from '../../../../../components/drawer/drawer';
// import './previousTestList.scss';
import AppIcons from '../../../../../assets/images/icons';
import ModalPopUp from '../../../../../components/modal/modal.popup';
import { AppDoughnutChart } from '../../../../../components/charts/donut.chart';
import SubjectPerformanceTable from './performanceTable/subjectPerformanceTable';
import SystemPerformanceTable from './performanceTable/systemPerformanceTable';
import TopicPerformanceTable from './performanceTable/topicPerformanceTable';
import PreviousAttemptsQuestionList from './qnAnswerPopup/previousAttemptsQuestionList';
import RichTextViewer from '../../../../../components/rich-text-editor/TextViewer';




const SelfAssessmentSelectedExamView = () => {
    const apiCaller = new ApiCaller('questions');
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [viewOpen, setViewOpen] = React.useState(false);


    const onViewClose = () => {
        setViewOpen(false);
    };

    const onViewOpen = () => {
        setViewOpen(true);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data.map((res, i) => ({ ...res, slNo: i + 1, id: res._id })) || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Question deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'Q-ID',
            headerName: 'Q-ID',
            flex: 0.4,
            minWidth: 70,
            valueFormatter: ({ value }) => {
                return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
            }
        },
        {
            field: 'question',
            headerName: 'Question',
            flex: 1,
            minWidth: 325,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /></>
        },
        {
            field: 'subjects',
            headerName: 'Subjects',
            minWidth: 160,
            flex: 2,
            valueFormatter: ({ value }) => {
                return (value && value.length > 0) ? value.map((sub) => `${sub?.subjectName}`) : '-'
            }
        },
        {
            field: 'result',
            headerName: 'Result',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span><SVGIcons.StatusActiveIcon /></span>
            </>
        },
        {
            field: 'other',
            headerName: 'Others',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex', alignItems: 'center' }}><SVGIcons.StatusActiveIcon /> 55%</span>
            </>
        },
        {
            field: 'time',
            headerName: 'Time',
            minWidth: 100,
            flex: 0.4,
            renderCell: ({ row }) => <>
                <span>138 sec</span>
            </>
        },
        {
            field: 'action ',
            headerName: '',
            flex: 0.3,
            minWidth: 30,
            renderCell: ({ row }) => <Link onClick={() => {
                // setSelectedQn(row);
                onViewOpen()
            }}><SVGIcons.ChevronRightIcon style={{ width: '15px' }} /></Link>
        },
    ]


    return (

        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("prevTestViewHead")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content prev-attempts-view selfAssessment-attempts-view'>
                                <div className='block-table-wrp user-table f-wrp'>
                                    <div className='back-btn-list'>
                                        <div className='left-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/my-practices`}><SVGIcons.BackIcon /> {translateThis('Back')}</Link>
                                        </div>
                                        <div className='right-btn-sec'>
                                            <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.PrintIcon /> {translateThis('Print')}</Link>
                                            {/* <Link className='pln-button' onClick={onViewOpen} to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.QnListIcon /> {translateThis('Question list')}</Link> */}
                                            {/* <Link className='pln-button' to={`/${getCurrentFacilityURL()}/previous-tests-view`}><SVGIcons.TestEyeIcon /> {translateThis('Test Revision')}</Link> */}
                                        </div>
                                    </div>

                                    <div className='prev-view-boxes f-wrp' style={{ paddingBottom: '20px' }}>
                                        <div className='statistics-info-wrapper '>
                                            <Grid className='basic-info-grid' container spacing={1}>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Results')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Results')}:</b> {translateThis('get an accurate prediction of your UMSLE readiness across systems, topics and subjects based on a scoring system.')}</span>} placement="top">
                                                                            <span className='tool-button'>{SVGIcons.InfoI()}</span>
                                                                        </Tooltip></h3>
                                                        <div className='basic-detail-box results-box'>
                                                            <h2>{translateThis('Chance of passing :')} <span>{translateThis('Very High')}</span></h2>
                                                            <div className='result-monitor'>
                                                                <div className='each-monitor-item Low'>
                                                                    <span>{translateThis('Low')}</span>
                                                                </div>
                                                                <div className='each-monitor-item Borderline'>
                                                                    <span>{translateThis('Borderline')}</span>
                                                                </div>
                                                                <div className='each-monitor-item High'>
                                                                    <span>{translateThis('High')}</span>
                                                                </div>
                                                                <div className='each-monitor-item Very-High'>
                                                                    <span>{translateThis('Very High')}</span>
                                                                </div>
                                                                <div className='monitor-indicator'>
                                                                    <span style={{left: '90%'}}>{SVGIcons.MonitorIndicator()}</span>
                                                                </div>
                                                            </div>
                                                            <div className='point-box'>
                                                                <h2>{translateThis('Points to improve:')}</h2>
                                                                <ul>
                                                                    <li><span>{translateThis('Topic 1')}</span></li>
                                                                    <li><span>{translateThis('Topic 2')}</span></li>
                                                                    <li><span>{translateThis('Topic 3')}</span></li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Performance')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Performance')}:</b> {translateThis('a pie graph depicting correct, incorrect, and omitted responses, alongside counts and percentages. This includes repeated questions.')}</span>} placement="top">
                                                                            <span className='tool-button'>{SVGIcons.InfoI()}</span>
                                                                        </Tooltip></h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            <span className='graph-img'>
                                                                <h2 className='graph-label'>{translateThis('Correct')}</h2>
                                                                <AppDoughnutChart data={{
                                                                    labels: [`${translateThis('Correct')}`, `${translateThis('Incorrect')}`, `${translateThis('Omitted')}`],
                                                                    datasets: [
                                                                        {
                                                                            label: '',
                                                                            total: `60`,
                                                                            totalScore: `60`,
                                                                            fontStyle: 'bold 26px sans-serif',
                                                                            data: ['60', '10', '30'],
                                                                            backgroundColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }} />
                                                            </span>
                                                            <div className='graph-content'>
                                                                <span>{translateThis('From')} 40 {translateThis('questions, you answered')}:</span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.correct} alt='graph' /> &nbsp; 60% {translateThis('Correct')}</p>
                                                                        <span>24 {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.incorrect} alt='graph' /> &nbsp; 10% {translateThis('Incorrect')}</p>
                                                                        <span>4 {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.omitted} alt='graph' /> &nbsp; 30% {translateThis('Omitted')}</p>
                                                                        <span>12 {translateThis('questions')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Score Percentile Rank')} <Tooltip title={<span style={{ fontWeight: 'normal', fontSize: '13px' }}><b>{translateThis('Score Percentile Rank')}:</b> {translateThis('Score Percentile Rank text')}</span>} placement="top">
                                                                            <span className='tool-button'>{SVGIcons.InfoI()}</span>
                                                                        </Tooltip></h3>
                                                        <div className='basic-detail-box line-chart'>
                                                            {/* <span className='graph-img'><img src={AppIcons.line48} alt='graph' /></span> */}
                                                            <div className='graph-img line-graph'>
                                                                <div className='PercentileIcon-wrp'>
                                                                    <span className='graph-wrp'>
                                                                        <img src={AppIcons.lineGraph} alt='graph' />
                                                                    </span>
                                                                    <div className={`graph-mark val24`} style={{ width: `24%` }}>
                                                                        <div className='value-box'>
                                                                            <p>24{translateThis('th')} <b>{translateThis('percentile')}</b></p>
                                                                        </div>
                                                                        <p>{translateThis('You')}</p>
                                                                    </div>

                                                                    <div className={`graph-mark markFixed val50`} style={{ width: `50%` }}>
                                                                    <p>{translateThis('50th')}<b>{translateThis('percentile')}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='graph-content'>
                                                            <span><b>{translateThis('Performance rank')}:</b></span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.green} alt='graph' /> &nbsp;<span>54{translateThis('th rank')}</span></p>
                                                                        <span>{translateThis('Your score')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp;<span>48{translateThis('th rank')}</span></p>
                                                                        <span>{translateThis('Median score')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                    <Divider />

                                    <div className='header-wrp f-wrp'>
                                        <h3>{translateThis('Performance analysis')}</h3>
                                    </div>

                                    <div className='performance-detail-wrp f-wrp'>
                                        <div className='each-performance-box'>
                                            <SubjectPerformanceTable />
                                        </div>
                                        <div className='each-performance-box'>
                                            <SystemPerformanceTable />
                                        </div>
                                        <div className='each-performance-box'>
                                            <TopicPerformanceTable />
                                        </div>
                                    </div>

                                    <Divider />

                                    <div className='header-wrp f-wrp'>
                                        <h3>{translateThis('Question details')}</h3>
                                    </div>

                                    <div className='panel-table-sec  provider-table f-wrp' style={{ paddingTop: '20px' }}>
                                        <AppTable
                                            columns={tableColumns}
                                            isLoading={isLoading}
                                            data={dataArr}
                                        />
                                    </div>

                                    {viewOpen ? <ModalPopUp
                                        className='preview-popup previousTestList-popup'
                                        isOpen={viewOpen}
                                        onClose={onViewClose}
                                        aria-labelledby="table-view"
                                        aria-describedby="table-view"
                                    >
                                        <PreviousAttemptsQuestionList />
                                        {/* <Button onClick={onViewClose} className='fill-btn'>Close</Button> */}
                                    </ModalPopUp> : null}




                                    {openDeleteDialog && <ConfirmDialog
                                        isOpen={openDeleteDialog}
                                        onClose={() => {
                                            setOpenDeleteDialog(false);
                                            setSelectedId('');
                                        }}
                                        title={`Delete question`}
                                        className={'delete-popup'}
                                        description={`Are you sure you want to delete this question?\nThis action is permanent and cannot be undone.`}
                                        okayButtonText={'Delete question'}
                                        onConfirm={deleteData}
                                    />}
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    )
}
SelfAssessmentSelectedExamView.propTypes = {}

export default SelfAssessmentSelectedExamView;
