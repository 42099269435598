import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import AppPopOverMenu from '../../../../components/appPopOverMenu';

import { DATE_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../../constants/index';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import AppIcons from '../../../../assets/images/icons';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';





const QuestionBlockTable = () => {
    const apiCaller = new ApiCaller('questions');
    const navigator = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [selectedQn, setSelectedQn] = useState({});

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success('Question deleted successfully');
                    } else {
                        showSnackbar().failure("Failed to delete question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
   * Update question
   */
    const publishQuestion = () => {
        if (selectedId) {
            apiCaller.customPutData(selectedId, { status: selectedQn?.status === 'DRAFT' ? 'PUBLISH' : 'DRAFT' })
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Question ${selectedQn?.status === 'DRAFT' ? 'published' : 'unpublished'} successfully`);
                    } else {
                        showSnackbar().failure("Failed to update question")
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setOpenPublishDialog(false);
                    setSelectedId('');
                    setSelectedQn('');
                    getDataArr();
                })
        }

    }

    /**
         * To apply the filtered data
         * @param {Array} arr 
         */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'questionNumber',
            headerName: 'ID',
            minWidth: 55,
            flex: 0.5,
        },
        {
            field: 'question',
            headerName: 'Question',
            minWidth: 160,
            flex: 2,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question} /><Tooltip title={<><RichTextViewer richText={row?.question} /></>} placement="top">
                <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
            </Tooltip></>
        },
        {
            field: 'populatedSubjects',
            headerName: 'Subjects',
            flex: 2,
            minWidth: 200,
            maxWidth: 200,
            // renderCell: ({ row }) => (row.populatedSubjects && row.populatedSubjects.length > 0) ? row.populatedSubjects.map((sub) => <span className='chip-sub' key={sub?._id}> {`${sub?.subjectName}`}</span>) : '-'
            renderCell: ({ row }) => (row.populatedSubjects && row.populatedSubjects.length > 0) ? <div className='flex area'>
                <span className='chip-sub' style={{ maxWidth: '115px' }}  > {`${row.populatedSubjects[0].subjectName}`}</span>
                {(row.populatedSubjects.length > 1) ? <Tooltip title={<>{row.populatedSubjects.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.subjectName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
        },
        {
            field: 'populatedSystems',
            headerName: 'Systems',
            flex: 2,
            minWidth: 200,
            maxWidth: 200,
            // renderCell: ({ row }) => (row.populatedSystems && row.populatedSystems.length > 0) ? row.populatedSystems.map((sub) => <span className='chip-sys' key={sub?._id}> {`${sub?.systemName}`}</span>) : '-'
            renderCell: ({ row }) => (row.populatedSystems && row.populatedSystems.length > 0) ? <div className='flex area'>
                <span className='chip-sys' style={{ maxWidth: '115px' }}  > {`${row.populatedSystems[0].systemName}`}</span>
                {(row.populatedSystems.length > 1) ? <Tooltip title={<>{row.populatedSystems.map((sub) => <span style={{ fontSize: '14px' }} key={sub?._id}> {`${sub?.systemName},`}</span>)}</>} placement="top">
                    <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
                </Tooltip> : null}
            </div> : '-'
        },
        {
            field: 'createdAt',
            headerName: 'Creation',
            flex: 1,
            minWidth: 100,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_FORMAT)
        },
        {
            field: 'accuracy',
            headerName: 'Accuracy',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => (row.analytics?.university?.correctPercentage && row.analytics?.university?.correctPercentage !== 'NaN') ? `${row.analytics?.university?.correctPercentage}%` : '-'
        },
        {
            field: 'used',
            headerName: 'Used',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => (row.analytics?.university?.used && row.analytics?.university?.used !== 'NaN') ? `${row.analytics?.university?.used}` : '-'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => (row?.status === "PUBLISH" ? <>{translateThis('Published')}</> : <>{translateThis('Draft')}</>)
        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 0.7,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => <>
                <AppPopOverMenu options={[
                    {
                        label: `${translateThis('View')}`,
                        onClick: () => {
                            navigator(`/${getCurrentFacilityURL()}/question/${row.id}`)

                        }
                    },
                    {
                        label: `${translateThis('Delete')}`,
                        onClick: () => {
                            setSelectedId(row.id);
                            setOpenDeleteDialog(true);
                        }
                    },
                    {
                        label: row?.status === "DRAFT" ? `${translateThis('PUBLISH')}` : `${translateThis('UNPUBLISH')}`,
                        onClick: () => {
                            setSelectedQn(row);
                            setSelectedId(row.id);
                            setOpenPublishDialog(true);
                        }
                    }

                ]} />
                <Link className='table-link-icon' to={`/${getCurrentFacilityURL()}/edit-question/${row?._id}`}>{SVGIcons.Pencil2Icon()}</Link>
            </>
        },
    ]

    return (
        <div className='block-table-wrp f-wrp'>
            <div className='panel-table-sec provider-table PC-Table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn'>
                                            <span>{(element.status === 'PUBLISH' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                                                (element.status === 'DRAFT' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</span>

                                            <AppPopOverMenu
                                                icon={SVGIcons.StatusMobArrow()}
                                                options={[
                                                    {
                                                        label: element?.status === "DRAFT" ? 'PUBLISH' : 'UNPUBLISH',
                                                        onClick: () => {
                                                            setSelectedQn(element);
                                                            setSelectedId(element.id);
                                                            setOpenPublishDialog(true);
                                                        }
                                                    },
                                                ]} />

                                            <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                            <h4 style={{ fontWeight: 'normal' }}>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 50px)' }}>
                                        <div className='flex-wrp' style={{ justifyContent: 'space-between' }}>
                                            {(element.question) ? <h4 className='question' style={{ maxHeight: '45px' }}>
                                                <RichTextViewer richText={element.question} />
                                            </h4> : '-'}
                                            <div style={{ position: 'relative', zIndex: '1' }}>
                                                <AppPopOverMenu icon={SVGIcons.MoreHorizontalFill()} options={[
                                                    {
                                                        label: "Edit",
                                                        onClick: () => {
                                                            navigator(`/${getCurrentFacilityURL()}/edit-question/${element?._id}`)
                                                        }
                                                    },
                                                    {
                                                        label: "Delete",
                                                        onClick: () => {
                                                            setSelectedId(element.id);
                                                            setOpenDeleteDialog(true);
                                                        }
                                                    }
                                                ]} />
                                            </div>
                                        </div>


                                        <div className='flex-wrp' >
                                            <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 100px)', overflow: 'hidden' }}>
                                                {(element.populatedSubjects && element.populatedSubjects.length > 0) ? <div className='flex-wrp' style={{ display: 'block', width: '100%' }}>
                                                    <p className='subjectList'><span>{translateThis('Subjects')} </span>
                                                        {element.populatedSubjects.map((sub) => <i key={sub?._id}> {`${sub?.subjectName},`}</i>)}</p>
                                                </div> : <span style={{ display: 'block' }}>{translateThis('Subjects')} - </span>}

                                                {(element.populatedSystems && element.populatedSystems.length > 0) ? <div className='flex-wrp' style={{ display: 'block', width: '100%' }}>
                                                    <p className='subjectList'><span>{translateThis('Systems')} </span>
                                                        {element.populatedSystems.map((sub) => <i key={sub?._id}> {`${sub?.systemName},`}</i>)}</p>
                                                </div> : <span style={{ display: 'block' }}>{translateThis('Systems')} - </span>}
                                            </div>
                                            <div className='flex-wrp auto-flex'>
                                                <p style={{ color: '#4C483E' }}>Used: {(element.analytics?.university?.used && element.analytics?.university?.used !== 'NaN') ? `${element.analytics?.university?.used}%` : '-'}</p>
                                                <p style={{ color: '#4C483E' }}>Accuracy: {(element.analytics?.university?.correctPercentage && element.analytics?.university?.correctPercentage !== 'NaN') ? `${element.analytics?.university?.correctPercentage}%` : '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='button' className='full-li-btn' onClick={() => navigator(`/${getCurrentFacilityURL()}/question/${element.id}`, { state: { data: element } })} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>

            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete question`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this question?\nThis action is permanent and cannot be undone.`}
                okayButtonText={'Delete question'}
                onConfirm={deleteData}
            />}

            {(openPublishDialog && selectedQn) && <ConfirmDialog
                isOpen={openPublishDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setOpenPublishDialog(false);
                    setSelectedId('');
                    setSelectedQn('');
                }}
                title={`${selectedQn?.status === 'DRAFT' ? 'Publish' : 'Unpublish'} question`}
                className={'delete-popup'}
                description={`Are you sure you want to ${selectedQn?.status === 'DRAFT' ? 'publish' : 'unpublish'} this question?.`}
                okayButtonText={`${selectedQn?.status === 'DRAFT' ? 'Publish' : 'Unpublish'}`}
                onConfirm={publishQuestion}
            />}
        </div>
    )
}
QuestionBlockTable.propTypes = {}

export default QuestionBlockTable;
