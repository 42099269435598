import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import '../home.scss';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';

const TermsAndConditions = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqItems = [
        {
            panelCount: 'panel1',
            title: 'Acceptance of Terms',
            content: <><p>{translateThis("By accessing or using XMed Pro's services, including but not limited to our Qbank and any other educational materials (collectively, the 'Service'), you agree to be bound by these Terms and Conditions ('Terms'). If you do not agree to these Terms, you should not use the Service.")}</p>
            </>,
        },
        {
            panelCount: 'panel2',
            title: 'Service Description',
            content: <>
                <p>{translateThis("XMed Pro provides a digital educational service that includes access to question banks, study materials, and other resources designed to assist with medical examinations and educational purposes. Our Service is available through subscription plans and may include access to a specified number of questions and other content.")}</p>
            </>,
        },
        {
            panelCount: 'panel3',
            title: 'Subscription and Payment',
            content: <>
                <ul>
                    <li>{translateThis("Subscription Plans: XMed Pro offers various subscription plans. The specifics of each plan, including duration and access, are detailed on our website and during the subscription process.")}</li>
                    <li>{translateThis("Payment: Payment is required at the time of subscription and will be processed through secure payment gateways. Subscription fees are non-refundable, except as required by law.")}</li>
                    <li>{translateThis("Auto-Renewal: Your subscription may be set to auto-renew at the end of each billing cycle unless you cancel it before the renewal date. You can manage your subscription and auto-renewal settings through your account settings.")}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel4',
            title: 'Free Trials',
            content: <>
                <p>{translateThis("XMed Pro does not offer a free trial period for our Service. Any claims of a free trial period are incorrect. All subscriptions are billed according to the chosen plan.")}</p>
            </>,
        },
        {
            panelCount: 'panel5',
            title: 'Account Responsibilities',
            content: <>
                <ul>
                    <li>{translateThis("Accuracy: You agree to provide accurate and complete information when creating an account and using the Service.")}</li>
                    <li>{translateThis("Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.")}</li>
                    <li>{translateThis("Unauthorized Use: You agree to notify us immediately of any unauthorized use of your account.")}</li>
                </ul>
            </>
        },
        {
            panelCount: 'panel6',
            title: 'Usage Restrictions',
            content: <>
                <p>{translateThis("You agree not to:")}</p>,
                <ul>
                    <li>{translateThis("Use the Service for any unlawful purpose or in violation of any applicable laws.")}</li>
                    <li>{translateThis("Reproduce, distribute, or share any content from the Service without prior written consent from XMed Pro.")}</li>
                    <li>{translateThis("Engage in any activity that disrupts or interferes with the Service.")}</li>
                </ul>

            </>,
        },
        {
            panelCount: 'panel7',
            title: 'Intellectual Property',
            content: <>
                <p>{translateThis("All content and materials provided through XMed Pro, including but not limited to text, graphics, and software, are the intellectual property of XMed Pro or its licensors and are protected by copyright and other intellectual property laws.")}</p>
            </>,
        },
        {
            panelCount: 'panel8',
            title: "Dispute Resolution",
            content: <>
            <ul>
                <li>{translateThis("Dispute Submission: If you have any disputes or issues with our Service, you agree to contact XMed Pro support before initiating any dispute resolution process.")}</li>
                <li>{translateThis("Resolution: Disputes will be handled according to our internal dispute resolution procedures. If a resolution cannot be reached, disputes may be escalated as per the terms specified in our support documentation.")}</li>
            </ul>
        </>,
        },
        {
            panelCount: 'panel9',
            title: 'Termination',
            content: <p>{translateThis("XMed Pro reserves the right to terminate or suspend your access to the Service if you breach these Terms or for any other reason at our discretion. Upon termination, you will lose access to all content and resources provided through the Service.")}</p>,
        },
        {
            panelCount: 'panel10',
            title: 'Limitation of Liability',
            content: <p>{translateThis("To the fullest extent permitted by law, XMed Pro shall not be liable for any indirect, incidental, or consequential damages arising from the use of or inability to use the Service. Our liability for any direct damages is limited to the amount paid for the subscription in question.")}</p>,
        },
        {
            panelCount: 'panel11',
            title: 'Changes to Terms',
            content: <>
                <p>{translateThis("XMed Pro may update these Terms from time to time. Changes will be posted on our website, and your continued use of the Service constitutes acceptance of the revised Terms.")}</p>
            </>,
        },
        {
            panelCount: 'panel12',
            title: 'Contact Information',
            content: <>
                <p>{translateThis("For any questions or concerns regarding these Terms or our Service, please contact us at: XMed Pro Support Email:")}&nbsp;<Link style={{color: 'inherit'}} to={'mailto: hello@xmedpro.com'}>{translateThis('hello@xmedpro.com')}</Link></p>
            </>,
        },

    ];

    return (
        <>
            <div className='faq-accordion-wrp f-wrp'>
                {faqItems.map((element, key) => (
                    <Accordion className='each-accordian-sec' expanded={expanded === element.panelCount} onChange={handleChange(element.panelCount)} key={key}>
                        <AccordionSummary
                            // expandIcon={SVGIcons.ChevronDownIcon()}
                            aria-controls={`${element.panelCount}bh-content`}
                            id={`${element.panelCount}bh-header`}
                        >
                            {expanded === element.panelCount ? <span>{SVGIcons.MinusIcon()}</span> : <span>{SVGIcons.PlusIcon()}</span>}
                            <h4>{translateThis(element.title)}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>{element.content}</div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default TermsAndConditions;