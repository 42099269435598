import React, {memo, useMemo} from 'react'
import { useRecoilValue } from 'recoil';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import S3ImageViewer from '../awsS3ImageViewer/index.';

function ProviderLogo() {
    const {facilityInfo = {}} = useRecoilValue(myPortalState);
    const logo = facilityInfo?.profileLogo || '';

    const S3ImageViewerAs = useMemo(
      () => <S3ImageViewer fileKey={logo} />,
      [logo]
    );
  return (
    <>
    {logo && S3ImageViewerAs}
    </>
  )
}

export default memo(ProviderLogo)