import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';
// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';



const ChildEnrollmentView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp childEnrollmentView f-wrp">
                
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />
                
                <h2>CHILD ENROLLMENT FORM</h2>
                <div className='flex-box'>
                    <p><b>Application date:</b>{answerGetter('child_enrollment_form_DateOFApplication', formData)?.answer || '-'}</p>
                    <p><b>Enrollment date:</b> {answerGetter('child_enrollment_form_date_of_enrollment', formData)?.answer || '-'} </p>
                    <p><b>Last day of enrollment: </b>{answerGetter('child_enrollment_form_last_day_of_enrollment', formData)?.answer || '-'} </p>
                </div>
                <div className='gap' />
                <p>Attention Provider: <b>This information must be kept current at all times and shall be kept file for one year after the child ceases to be enrolled in the family child care home.</b></p>
                <div className='gap' />
                
                <div className="details-section">
                    <div className="lft-section">
                        <p>Child's Name: <b>{answerGetter('child_enrollment_form_question_name', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>Child's address: <b>{answerGetter('child_enrollment_form_question_address', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        <p>Child's Date of birth: <b>{answerGetter('child_enrollment_form_Key_birthday', formData)?.answer || dayjs(child?.birthday).format(DATE_FORMAT) || '-'}</b></p>
                        <div className="flex-box">
                            <p>City: <b>{answerGetter('child_enrollment_form_question_city', formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter('child_enrollment_form_question_zipcode', formData)?.answer || '-'}</b></p>
                        </div>

                    </div>
                </div>
                <div className='gap' />
                {/* <h4>Responsible adult</h4> */}
                <div className="details-section">
                    <div className="lft-section">
                        <p>Parent/Guardian Name: <b>{answerGetter('child_enrollment_form_question_name_2', formData)?.answer || '-'}</b></p>
                        <p>Address: <b>{answerGetter('child_enrollment_form_question_address_2', formData)?.answer || '-'}</b></p>
                        <p>E-mail: <b>{answerGetter('child_enrollment_form_question_email', formData)?.answer || '-'}</b></p>
                        <p>Telephone: <b>{answerGetter('child_enrollment_form_question_cellphone', formData)?.answer || '-'}</b></p>
                        <p>Employer name: <b>{answerGetter('child_enrollment_form_question_EmployerName', formData)?.answer || '-'}</b></p>
                        <p>Employer address: <b>{answerGetter('child_enrollment_form_question_workAddress', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        {/* <p>Role: <b>{answerGetter('child_enrollment_form_Key_role', formData)?.answer || '-'}</b></p> */}
                        <div className="flex-box">
                            <p>City: <b>{answerGetter('child_enrollment_form_question_city_2', formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter('child_enrollment_form_question_zipcode_2', formData)?.answer || '-'}</b></p>
                        </div>
                        <p>Cellphone: <b>{answerGetter('child_enrollment_form_question_telephone', formData)?.answer || '-'}</b></p>
                        <p>Emergency contact: <b>{answerGetter('child_enrollment_form_question_emergencyContact', formData)?.answer || '-'}</b></p>
                        <p>Work number: <b>{answerGetter('child_enrollment_form_question_workTelephone', formData)?.answer || '-'}</b></p>
                        <div className="flex-box">
                            <p>City: <b>{answerGetter('child_enrollment_form_question_city_3', formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter('child_enrollment_form_question_zipcode_3', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <h4>Weekly schedule</h4>
                <div className="details-section">
                    <div className="lft-section">
                        <table>
                            <tbody>
                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                                    .map((day) => <tr key={day}>
                                        <td>{day}</td>
                                        <td><span>From</span>
                                            {answerGetter(`weekly_schedule_${day}_from`, formData)?.answer || '-'}
                                        </td>
                                        <td><span>To</span>
                                            {answerGetter(`weekly_schedule_${day}_to`, formData)?.answer || '-'}</td>
                                    </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="signature-blk">
                    <div style={{ maxWidth: '9cm' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''
                        }</h3>
                        <p>Parent / guardian signature</p>
                        <p>Submission time: <b>{
                            data.additionalInfo && data.additionalInfo.parentSignedDate
                                ? data.additionalInfo.parentSignedDate
                                : ''
                        }</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

ChildEnrollmentView.propTypes = {
    data: PropTypes.any
}
export default ChildEnrollmentView;