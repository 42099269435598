import React, { useState } from 'react';
import AppIcons from '../../assets/images/icons';
import { getAuthRoleName } from '../../helpers/auth.helper';
import { STUDENT_ROLE } from '../../constants/index';
import './chatBot.scss';

const ChatBot = () => {
  // State to toggle the chatbot visibility
  const [isOpen, setIsOpen] = useState(false);

  // State to store user and bot messages
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I help you today?' },
  ]);

  // Handle opening and closing of the chatbot
  const toggleChatbot = () => setIsOpen(!isOpen);

  // Handle sending a message
  const sendMessage = (userMessage) => {
    if (userMessage.trim()) {
      // Add user message to chat
      setMessages([
        ...messages,
        { sender: 'user', text: userMessage },
        { sender: 'bot', text: 'Thankyou for your response, we will get back to you shortly.' }, // Simulate bot response
      ]);
    }
  };

  const toShowChatButton = [
    {
      title: 'xmed',
      allowOnly: [STUDENT_ROLE],
    },

  ].filter((item) => item.allowOnly.includes(getAuthRoleName()));

  return (
    <div>
      {/* Chatbot Trigger Button */}
      {toShowChatButton.map((element, key) => (
        <> {element.title ? <button type='button' key={key} className="chatbot-btn" onClick={toggleChatbot}>
          <img src={AppIcons.logo} alt='XmedPro' />
        </button> : null}</>
      ))}


      {/* Chatbot Popup */}
      {isOpen && (
        <div className="chatbot-popup">
          <div className="chat-header">
            <h3>XmedPro</h3>
            <button type='button' className="close-btn" onClick={toggleChatbot}>
              &times;
            </button>
          </div>

          <div className="chat-content">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`chat-message ${message.sender}-message`}
              >
                <p>{message.text}</p>
              </div>
            ))}
          </div>

          <MessageInput onSendMessage={sendMessage} />
        </div>
      )}
    </div>
  );
};

const MessageInput = ({ onSendMessage }) => {
  const [input, setInput] = useState('');

  const handleChange = (e) => setInput(e.target.value);
  const handleSend = () => {
    if (input.trim()) {
      onSendMessage(input); // Send the message
      setInput(''); // Clear the input field
    }
  };

  // Handle "Enter" key press to trigger send
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSend(); // Trigger send when Enter is pressed
    }
  };

  return (
    <div className="chat-input">
      <input
        type="text"
        value={input}
        onChange={handleChange}
        onKeyDown={handleKeyDown} // Listen for the Enter key press
        placeholder="Type a message..."
      />
      <button type='button' onClick={handleSend}>Send</button>
    </div>
  );
};

export default ChatBot;
