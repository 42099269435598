import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';


const WrittenPermissionView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp writtenPermissionView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />
                {/* <div className='prof-sec'>
                    <div className='prof-img'>
                    <span><img src={AppIcons.registerBG} alt='profile' /></span>
                </div>
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div> */}
                <h3><b>Written permission form</b></h3>

                <div className="details-section">
                    <div className="lft-section">
                        <p>Child’s name: <b>{answerGetter("written_permission_form_question_name", formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>Child’s address: <b>{answerGetter("written_permission_form_question_address", formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        <p>Child’s Date of birth: <b>{answerGetter("written_permission_form_Key_birthday", formData)?.answer || dayjs(child?.birthday).format(DATE_FORMAT) || '-'}</b></p>
                        <div className="flex-box">
                            <p>City: <b>{answerGetter("written_permission_form_question_city", formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter("written_permission_form_question_zipcode", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-box'>
                    <p>Persons permitted to remove the child from the care home on behalf of parent</p>
                    <div className='auto-grid width25'>
                        <div>
                            <p>Name: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianName", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Address &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianAddress", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>City &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianCity", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Zipcode: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianZipcode", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Phone: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianPhone", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Relationship: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianRelationship", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                </div>
                <div className='gap' />
                <div className='each-box'>
                    <p>In an emergency, adults to be contacted if parent cannot be reached and to whom the child can be released.</p>
                    <div className='auto-grid width25'>
                        <div>
                            <p>Name: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianName_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Address &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianAddress_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>City &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianCity_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Zipcode: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianZipcode_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Phone: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianPhone_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>Relationship: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianRelationship_2", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                </div>

                <div className='gap' />
                <div className='each-box'>
                    <p>Child’s Emergency Medical Care Provider:</p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>Name: <b>{answerGetter("written_permission_form_question_medicalCareName", formData)?.answer || '-'}</b></p>
                            <p>Phone: <b>{answerGetter("written_permission_form_question_medicalCarePhone", formData)?.answer || '-'}</b></p>
                            <p>Address: <b>{answerGetter("written_permission_form_question_medicalCareAddress", formData)?.answer || '-'}</b></p>
                        </div>
                        <div className="ryt-section">
                            <div className="flex-box">
                                <p>City: <b>{answerGetter("written_permission_form_question_medicalCareCity", formData)?.answer || '-'}</b></p>
                                <p>Zipcode: <b>{answerGetter("written_permission_form_question_medicalCareZipcode", formData)?.answer || '-'}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap' />



                <div className='auto-flex'>
                    <p style={{ whiteSpace: 'nowrap', paddingRight: '10px', minWidth: '110px' }}>Child’s Physician:</p>
                    <div className="details-wrp" style={{ width: '100%' }}>
                        <div className='flex-box'>
                            <p>Name: <b>{answerGetter("written_permission_form_question_physicianName", formData)?.answer || '-'}</b></p>
                            <p>Phone: <b>{answerGetter("written_permission_form_question_physicianPhone", formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='flex-box'>
                            <p>Address: <b>{answerGetter("written_permission_form_question_physicianAddress", formData)?.answer || '-'}</b></p>
                            <p>City: <b>{answerGetter("written_permission_form_question_physicianCity", formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter("written_permission_form_question_physicianZipcode", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <div className='auto-flex'>
                    <p style={{ whiteSpace: 'nowrap', paddingRight: '10px', minWidth: '110px' }}>Child’s Dentist:</p>
                    <div className="details-wrp" style={{ width: '100%' }}>
                        <div className='flex-box'>
                            <p>Name: <b>{answerGetter("written_permission_form_question_dentistName", formData)?.answer || '-'}</b></p>
                            <p>Phone: <b>{answerGetter("written_permission_form_question_dentistPhone", formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='flex-box'>
                            <p>Address: <b>{answerGetter("written_permission_form_question_dentistAddress", formData)?.answer || '-'}</b></p>
                            <p>City: <b>{answerGetter("written_permission_form_question_dentistCity", formData)?.answer || '-'}</b></p>
                            <p>Zipcode: <b>{answerGetter("written_permission_form_question_dentistZipcode", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <p style={{ textDecoration: 'underline' }}>My family child care provider and or approved substitute, have my permission to:</p>

                <ul>
                    <li>Transport my child for any activity away from the family child care home. The provider is responsible for notifying me of days and times that these activities will occur. <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                        <ul>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_canTransport", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_canTransport", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div></li>
                    <li>Allow my child to participate in any activity away from the child care home. <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                        <ul>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_activity", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_activity", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div></li>
                    <li>Transport my child in case of an emergency to the Emergency Medical Care Provider, Physician or Dentist listed above and or to seek medical attention in an emergency at: <b>{answerGetter("written_permission_form_Key_permission_3", formData)?.answer || '-'} &nbsp;{answerGetter("written_permission_form_Key_permission_3", formData)?.answer === "Yes" ? answerGetter("written_permission_form_permission_3_hospitalName", formData)?.answer || '' : ''} </b></li>
                    <li>Include my child in swimming when recreational swimming is part of the family child care program. <br />I understand it is my responsibility to outline these provisions to the provider.<b>{answerGetter("written_permission_form_Key_permission_4", formData)?.answer || '-'}</b> </li>
                    <li>Arrange for transitioning of my child to and from school including, but not limited to, transportation, exact bus pick up and drop off locations, and supervision to be provided during transitioning. <b>{answerGetter("written_permission_form_Key_permission_5", formData)?.answer || '-'}</b> <br /> I understand that I must provide written permission and instructions specifying these arrangements.  </li>
                </ul>
                <p><b>The provisions outlined on this form have been worked out in consultation with me and my family child care provider. </b>{answerGetter("written_permission_form_Key_permission_6", formData)?.answer || '-'}</p>

                <div className="signature-blk" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''
                        }</h3>
                        <p>Parent / guardian signature</p>
                        <p>Submission time: <b>{
                            data.additionalInfo && data.additionalInfo.submissionDate
                                ? data.additionalInfo.submissionDate
                                : ''
                        }</b></p>
                    </div>
                    <div style={{ paddingLeft: '25px' }}>
                        <p style={{ color: '#4C483E' }}>Attention Provider: <b>This information must be kept current at all time. Carry a copy of this form, the Enrollment form and the Assessment Record during any off-premises activity.</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

WrittenPermissionView.propTypes = {
    data: PropTypes.any
}

export default WrittenPermissionView;