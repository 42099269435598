import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import MenuList from '@mui/material/MenuList';
import { MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import AppIcons from '../../../assets/images/icons';
import { translateThis } from '../../../helpers/language.helper';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import whatsappBlk from '../../assets/images/icons/Whatsapp-foot.svg';



const MainFooter = () => (
    <div className="project-main-footer f-wrp">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-3 col-sm-12 col-xs-12">
                    <div className="foot-logo-wrp f-wrp">
                        <div className="logo-wrp f-wrp">
                            <span><img src={AppIcons.landingLogo} alt="xmed Logo" /></span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-9 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="each-foot-link align-class1 f-wrp">
                                <h4 className="foot-sub-head">{translateThis('Company')}</h4>
                                <MenuList>
                                    <MenuItem>
                                        <NavHashLink smooth to={'#about'} style={{ width: '100%' }} >
                                        {translateThis('About us')}
                                        </NavHashLink>
                                    </MenuItem>
                                    {/* <MenuItem><Link to={'/'}>Testimonials</Link></MenuItem> */}
                                    {/* <MenuItem><Link to={'/'}>Pricing</Link></MenuItem> */}
                                    <MenuItem><Link to={'#'}>{translateThis('Work with us')}</Link></MenuItem>
                                </MenuList>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

                            <div className="each-foot-link align-class2 f-wrp">
                                <h4 className="foot-sub-head">{translateThis('Resources')}</h4>
                                <MenuList>
                                    <MenuItem><Link to={'/privacy-policy'}>{translateThis('Privacy policy')}</Link></MenuItem>
                                    <MenuItem><Link to={'/terms-and-conditions'}>{translateThis('Terms of use')}</Link></MenuItem>
                                    <MenuItem><Link to={'#'}>{translateThis('Assistance')}</Link></MenuItem>
                                </MenuList>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

                            <div className="each-foot-link align-class3 f-wrp">
                                <h4 className="foot-sub-head">{translateThis('Connect')}</h4>
                                <MenuList>

                                    <MenuItem>
                                        <NavHashLink smooth to={'#Contact'} style={{ width: '100%' }} >
                                        {translateThis('Contact us')}
                                        </NavHashLink>
                                    </MenuItem>
                                </MenuList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default MainFooter;