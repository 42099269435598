import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
// import { useResizable } from 'react-resizable-layout';
import Resizable from 'react-resizable-layout';
import './index.scss';

import AppLayout from '../../../layouts/app/AppLayout';
import TestHeader from '../components/testHeader/index';
import TestFooter from '../components/testFooter';
import QuestionNumberDrawer from './drawer/drawer';
import RealModeQuestion from './Questions/realModeQuestion';
import LabValueTab from './labValueTab/labValueTab';
// import Calculator from './calculator/Calculator';
import ApiCaller from '../../../services/api/general';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';
import ExplanationBox from './explanationBox/explanationBox';
import CalcContainer from './calc/calc';
import { translateThis } from '../../../helpers/language.helper';





const TestLayoutPage = () => {
    const apiCaller = new ApiCaller('myExams');
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFinishing, setIsFinishing] = useState(false);
    const [testDetails, setTestDetails] = useState({});
    const [formattedQuestions, setFormattedQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answer, setAnswer] = useState({});
    const [submittedQns, setSubmittedQns] = useState([]);
    const [showCalc, setShowCalc] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showLab, setShowLab] = useState(false);
    const [eachQuestionTimer, setEachQuestionTimer] = useState(0);
    const [examTimer, setExamTimer] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [headerHeight, setHeadHeight] = useState(0);

    // const elementRef = useRef(null);
    const headHeight = useRef(null);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener("beforeunload", unloadCallback);
        };
    }, []);

    // useEffect(() => {
    //     const unloadCallback = (event) => {
    //         event.preventDefault();
    //         event.returnValue = "";
    //         return "";
    //       };
    //       window.addEventListener("beforeunload", unloadCallback);
    //       return () => window.removeEventListener("beforeunload", unloadCallback);
    //   }, []);


    let timer;
    useEffect(() => {
        getExam();

        return () => {
            setEachQuestionTimer(0);
            setExamTimer(0);
            setRemainingTime(0);
            clearInterval(timer)
        }
        // eslint-disable-next-line
    }, [id])

    const getExam = () => {
        apiCaller.getData(id).then((data) => {
            if (data.test && data.questions) {
                setTestDetails(data.test);
                formatQuestions(data.questions, data.test);
                if (data.test) {
                    startTimer(data.test?.testId, data.test?.timer, data.questions.length, data.test?.totalElapsedTime);
                    if (data.test?.timer === 'limit') {
                        setRemainingTime(data.questions.length * 90);
                    }
                }
            }
        }).finally(() => setIsLoading(false))
    }

    /**
     * Starting the question timer
     */
    const startTimer = (testId, timer, noOfQns, totalElapsedTime) => {
        setExamTimer((p) => p + Number(totalElapsedTime))
        setInterval(() => {
            setEachQuestionTimer((prev) => prev + 1);
            setRemainingTime((prev) => prev - 1);
            setExamTimer((prev) => {
                const timeTaken = prev + 1;
                const allowedTime = noOfQns * 90;
                if (timer === "limit" && timeTaken > allowedTime) {
                    onFinishTest(testId);
                }
                return timeTaken;
            });

        }, 1000);

    }

    // eslint-disable-next-line
    function fmtMSS(e) {
        const h = Math.floor(e / 3600).toString().padStart(2, '0');
        const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0');
        const s = Math.floor(e % 60).toString().padStart(2, '0');

        // return h + ':' + m + ':' + s;
        return `${h}:${m}:${s}`;
    }

    /**
     * Format the questions based on ui req
     * @param {Array} questions 
     */
    const formatQuestions = (questions = [], testDetails) => {
        const formatted = questions.map((question) => ({ ...question, status: question.status || 'unanswered' }));
        setFormattedQuestions(formatted);
        let index = 0;
        if (testDetails?.lastSubmittedQuestionId) {
            index = questions.findIndex(obj => obj._id === testDetails?.lastSubmittedQuestionId) ?? 0;
        }
        setCurrentQuestionIndex(index);
        setEachQuestionTimer((formatted.length > 0 && formatted[index]?.timeTakenInSeconds) ? Number(formatted[index]?.timeTakenInSeconds) : 0);

    }

    /**
     * Navigate through question
     * @param {number} to 
     */
    const navigateQuestion = (to) => {
        let newIndex;
        if (to === 'previous' && currentQuestionIndex > 0) {
            newIndex = currentQuestionIndex - 1;
        } else if (to === 'next' && currentQuestionIndex < formattedQuestions.length - 1) {
            newIndex = currentQuestionIndex + 1;
        } else if (formattedQuestions[to]) {
            newIndex = to;
        }

        const timeTaken = Number(formattedQuestions[newIndex]?.timeTakenInSeconds) || 0;

        setCurrentQuestionIndex(newIndex);
        setEachQuestionTimer(timeTaken);
        setShowExplanation(false);
        onSelectAnswer({});
    }

    const onSelectAnswer = (answer = {}) => {
        setAnswer(answer)
        // const question = formattedQuestions[currentQuestionIndex];
        // const questions = formattedQuestions.map((q) => {
        //     if (q._id === question._id) {
        //         return { ...q, status: answer ? 'correct' : 'unanswered', selectedAnswer: answer }
        //     }
        //     return q;
        // })
        // setFormattedQuestions([...questions])
    }

    const onSubmitAnswer = () => {
        const question = formattedQuestions[currentQuestionIndex];
        const formData = {
            testId: testDetails.testId,
            questionId: question._id,
            answerId: answer._id,
            timeTakenInSeconds: `${eachQuestionTimer}`,
        };

        setShowExplanation(false);
        setIsSubmitting(true);

        apiCaller.customPostData('/submit-answer', formData)
            .then(({ data }) => {
                const updatedQuestion = {
                    ...question,
                    status: getQuestionStatus(testDetails.mode, data.isCorrect),
                    isSubmitted: true,
                    timeTakenInSeconds: formData.timeTakenInSeconds,
                    timeTaken: formData.timeTakenInSeconds,
                    showAnswer: testDetails.mode === 'practice',
                    selectedAnswer: answer,
                    actualAnswerId: testDetails.mode === 'practice' ? data.actualAnswerId : '',
                };

                const updatedQuestions = [...formattedQuestions];
                updatedQuestions[currentQuestionIndex] = updatedQuestion;

                setFormattedQuestions(updatedQuestions);

                const submittedQnsAs = [...submittedQns, updatedQuestion];
                setSubmittedQns(submittedQnsAs);
            })
            .finally(() => {
                if (testDetails.mode === 'practice') {
                    onClickHeaderItem('explanation', true);
                } else {
                    onSelectAnswer({});
                    if (currentQuestionIndex === formattedQuestions.length - 1) {
                        // onFinishTest(); // No need to finish the exam
                    } else {
                        navigateQuestion('next');
                    }
                }
                setIsSubmitting(false);
            });
    };

    const getQuestionStatus = (mode, isCorrect) => {
        if (mode === 'practice') {
            return isCorrect ? 'correct' : 'incorrect';
        }
        if (mode === 'real') {
            return 'answered';
        }
        return 'omitted';
    };

    /**
     * On finish test
     */
    const onFinishTest = (testId = '') => {
        const formData = {
            testId: testDetails.testId || testId,
        }
        setIsFinishing(true);
        apiCaller.customPostData('/finish-exam', formData).then(() => {
            navigate(`/${getCurrentFacilityURL()}/test/${testDetails.testId || testId}`);
            // onSelectAnswer({});
            // navigateQuestion('next')
            // const questions = formattedQuestions.map((q) => {
            //     if (q._id === question._id) {
            //         return { ...q, status: answer._id ? 'correct' : 'omitted', selectedAnswer: answer }
            //     }
            //     return q;
            // })
            // setFormattedQuestions([...questions])
        }).finally(() => setIsFinishing(false))

    }

    /**
     * On finish test
     */
    const onPauseExam = (testId = '') => {
        navigate(`/${getCurrentFacilityURL()}/my-practices`);

    }

    /**
     * 
     * @param {String} item 
     */
    const onClickHeaderItem = (item, autoOpenExplanation = false) => {
        switch (item) {
            case 'calc':
                setShowCalc((prev) => !prev);
                break;
            case 'lab':
                setShowLab((prev) => {
                    if (!prev) { // lab is going to visible hide explanation
                        setShowExplanation(false);
                    }
                    return !prev;
                });
                break;

            case 'explanation':
                if (testDetails.mode !== 'real' && (autoOpenExplanation || submittedQns.find((q) => q._id === formattedQuestions[currentQuestionIndex]._id) !== undefined)) {
                    setShowExplanation((prev) => {
                        if (!prev) { // explanation is going to visible hide lab
                            setShowLab(false);
                        }
                        return !prev;
                    });
                }

                // setShowExplanation(false);
                break;

            default:
                break;
        }
    }


    return (
        <div className={`${(testDetails?.testTheme === 'usmle') ? 'usmle-main-wrapper usmle-test-wrp' : null}`}>
            {(formattedQuestions[currentQuestionIndex]?._id) ? <AppLayout
                // pageName={pageName}
                customHeader={<TestHeader data={testDetails}
                    isCalcVisible={showCalc}
                    isLabVisible={showLab}
                    isExplanationVisible={showExplanation}
                    getHeaderHeight={setHeadHeight}
                    onNavigate={navigateQuestion}
                    activeQuestion={formattedQuestions[currentQuestionIndex]}
                    onClickItem={(item) => onClickHeaderItem(item)} />
                }
                customFooter={<TestFooter ref={headHeight}
                    onFinishTest={onFinishTest}
                    onPauseExam={onPauseExam}
                    testDetails={testDetails}
                    getFooterHeight={setFooterHeight}
                    timer={examTimer}
                    remainingTime={remainingTime}
                    qnIndex={currentQuestionIndex}
                    questions={formattedQuestions}
                    isValidToSubmit={(testDetails.mode === 'real') || submittedQns.find((q) => q._id === formattedQuestions[currentQuestionIndex]._id) === undefined}
                    onNavigate={navigateQuestion}
                    onSubmitAnswer={onSubmitAnswer}
                    isSubmitting={isSubmitting}
                    isFinishing={isFinishing} />}
                bodyClassName={`question-main-wrapper f-wrp ${testDetails.mode} ${testDetails.timer}`}
            >
                {isLoading ? <span>Loading...</span> : <Box sx={{ display: 'flex' }} className={`question-main-structure ${footerHeight} ${headerHeight}`} style={{ height: `${windowSize[1] - footerHeight - headerHeight}px` }}>
                    <Box className='question-count-wrp'>
                        <QuestionNumberDrawer
                            onQnNumberClick={navigateQuestion}
                            questions={formattedQuestions}
                            currentQuestionIndex={currentQuestionIndex}
                        />
                    </Box>

                    <Resizable
                        key={formattedQuestions[currentQuestionIndex]._id}
                        axis='x' width={'100%'} reverse
                        initial={`${showLab && '460px'} ${showExplanation && '350px'}`}
                    // min={300}
                    >
                        {({
                            position: x,
                            isDragging: dragging,
                            separatorProps
                        }) => <Box component="main" className={`${dragging ? 'dragging' : 'not-dragging' } question-main-body ${showLab ? 'labShowed' : 'labClosed'} ${showExplanation ? 'expShowed' : 'expClosed'}`} sx={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: 'column' }}>

                                <Box className='question-main-wrapper' width={`calc(100% - ${x}px)`} style={{ width: `calc(100% - ${x}px)` }} >
                                    {((eachQuestionTimer > 0 && !isSubmitting) || (testDetails?.mode === 'practice' && submittedQns.find((q) => q._id === formattedQuestions[currentQuestionIndex]._id)?.timeTaken === undefined)) && <span className='qn-timer mob-version'>{fmtMSS(eachQuestionTimer)}</span>}
                                    <RealModeQuestion
                                        test={testDetails}
                                        questions={formattedQuestions}
                                        selectedAnswer={answer}
                                        qnIndex={currentQuestionIndex}
                                        isExplanationVisible={showExplanation}
                                        onNavigate={navigateQuestion}
                                        onFinishTest={onFinishTest}
                                        onSelectAnswer={onSelectAnswer}
                                        onSubmitAnswer={onSubmitAnswer}
                                        isSubmitting={isSubmitting}
                                    />
                                    {(remainingTime > 0 && testDetails?.timer === 'limit') && <span className='qn-timer mob-version'>{translateThis('Remaining time')}: {fmtMSS(remainingTime)}</span>}
                                    {(showExplanation) && <div className='mob-explanationBox f-wrp'>
                                        <ExplanationBox question={formattedQuestions[currentQuestionIndex]} onClose={() => onClickHeaderItem('explanation')} />
                                    </div>}
                                    {((eachQuestionTimer > 0 && !isSubmitting) || (testDetails?.mode === 'practice' && submittedQns.find((q) => q._id === formattedQuestions[currentQuestionIndex]._id)?.timeTaken === undefined)) && <span className='qn-timer pc-version'>{fmtMSS(eachQuestionTimer)}</span>}
                                    {(testDetails?.mode === 'practice' && formattedQuestions[currentQuestionIndex]?.timeTaken) && <span className='qn-timer'>{translateThis('You took')} {formattedQuestions[currentQuestionIndex]?.timeTaken} {translateThis('seconds to answer this question')}</span>}
                                </Box>
                                <hr id="splitter" {...separatorProps} />
                                <Box className={`side-value-wrapper ${showLab ? 'showLab' : null}`} width={x} size={x} style={{ width: `${x}px` }}>
                                    {showLab && <LabValueTab onClose={() => onClickHeaderItem('lab')} />}
                                    {(showExplanation) &&  <ExplanationBox useHighlighter test={testDetails} question={formattedQuestions[currentQuestionIndex]} onClose={() => onClickHeaderItem('explanation')} />}
                                    {/* showExplanation && */}
                                    {/* <CalculatorOld /> */}

                                    {showCalc && <div id="calculator-wrapper">
                                        {/* <Calculator onClose={() => onClickHeaderItem('calc')} /> */}
                                        <CalcContainer onClose={() => onClickHeaderItem('calc')} />
                                    </div>}

                                </Box>

                            </Box>
                        }
                    </Resizable>

                </Box>}
            </AppLayout> : <span>Loading...</span>}


        </div>
    )
}

export default TestLayoutPage;