import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { DATE_FORMAT } from '../../constants';



const ProviderCommunicationFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp providerCommunicationView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />

                <h2 style={{ marginBottom: '0' }}>DAILY COMMUNICATION</h2>
                <h3>Provider communication</h3>
                <div className='gap' />
                <div className="flex-box padding_btm15">
                    <p>Child name: <b>{child?.firstName}&nbsp;{child?.lastName}</b></p>
                </div>
                <div className="flex-box padding_btm15">
                    <p>Communication date: <b>{dayjs(data.logDate).format(DATE_FORMAT)}</b></p>
                    <p>Submission date: <b>{dayjs(data.submissionDate).format(DATE_FORMAT)}</b></p>
                </div>
                <div className='gap' />

                <div className="basic-details padding_btm15">
                    <div className="auto-flex">
                        <span>Food:</span>
                        <div>
                            <p>Breakfast: <b>{answerGetter('provider_communication_form_question_breakfast', formData)?.answer || '-'}</b></p>
                            <p>Morning snack: <b>{answerGetter('provider_communication_form_question_morningSnack', formData)?.answer || '-'}</b></p>
                            <p>Lunch: <b>{answerGetter('provider_communication_form_question_lunch', formData)?.answer || '-'}</b></p>
                            <p>Afternoon snack: <b>{answerGetter('provider_communication_form_question_afternoonSnack', formData)?.answer || '-'}</b></p>
                            <p>Dinner: <b>{answerGetter('provider_communication_form_question_dinner', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="auto-flex">
                        <span>Diaper change:</span>
                        <div style={{ width: '100%' }}>
                            <div className='flex-box'>
                                <p>1st -First change: <b>{answerGetter('provider_communication_form_change_condition', formData)?.answer || '-'}</b></p>
                                <p>Time: <b>{answerGetter('provider_communication_form_question_firstChangeTime', formData)?.answer || '-'}</b></p>
                                <p>Staff: <b>{answerGetter('provider_communication_form_staffName', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>2nd -Second change: <b>{answerGetter('provider_communication_form_change_condition_2', formData)?.answer || '-'}</b></p>
                                <p>Time: <b>{answerGetter('provider_communication_form_question_secondChangeTime', formData)?.answer || '-'}</b></p>
                                <p>Staff: <b>{answerGetter('provider_communication_form_staffName_2', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>3rd -Third change:: <b>{answerGetter('provider_communication_form_change_condition_3', formData)?.answer || '-'}</b></p>
                                <p>Time: <b>{answerGetter('provider_communication_form_question_thirdChangeTime', formData)?.answer || '-'}</b></p>
                                <p>Staff: <b>{answerGetter('provider_communication_form_staffName_3', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>4th -Fourth change: <b>{answerGetter('provider_communication_form_change_condition_4', formData)?.answer || '-'}</b></p>
                                <p>Time: <b>{answerGetter('provider_communication_form_question_fourthChangeTime', formData)?.answer || '-'}</b></p>
                                <p>Staff: <b>{answerGetter('provider_communication_form_staffName_4', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>5th -Fifth change: <b>{answerGetter('provider_communication_form_change_condition_5', formData)?.answer || '-'}</b></p>
                                <p>Time: <b>{answerGetter('provider_communication_form_question_fifthChangeTime', formData)?.answer || '-'}</b></p>
                                <p>Staff: <b>{answerGetter('provider_communication_form_staffName_5', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>6th -Sixth change: <b>{answerGetter('provider_communication_form_change_condition_6', formData)?.answer || '-'}</b></p>
                                <p>Time: <b>{answerGetter('provider_communication_form_question_sixChangeTime', formData)?.answer || '-'}</b></p>
                                <p>Staff: <b>{answerGetter('provider_communication_form_staffName_6', formData)?.answer || '-'}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="auto-flex">
                        <span>Child needs:</span>
                        <p><b>{
                            answerGetter('provider_communication_form_question_childNeeds', formData)?.rawAnswer
                                ? answerGetter('provider_communication_form_question_childNeeds', formData)
                                    .rawAnswer.map((item) => item).join(', ')

                                : `-`
                        }</b></p>
                    </div>
                </div>
                <div className="auto-flex padding_btm15">
                    <span>Comments:</span>
                    <p><b>{answerGetter('provider_communication_form_comments', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex padding_btm15">
                    <span>Incidents before childcare:</span>
                    <p><b>{answerGetter('provider_communication_form_incident', formData)?.answer || '-'}</b></p>
                </div>
                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                <p>Provider signature</p>
                                <p>Submission date and time: <b>{data.additionalInfo?.providerSignedDate}</b></p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                <p>Parent | Guardian signature</p>
                                <p>Submission date and time: <b>{data.additionalInfo?.parentSignedDate}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

ProviderCommunicationFormView.propTypes = {
    data: PropTypes.any
}
export default ProviderCommunicationFormView;