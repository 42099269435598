import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { PropTypes } from 'prop-types';
import { translateThis } from '../../helpers/language.helper';
import './index.scss';


const  RichTextEditor = (props) => {
const {onChange, defaultValue = '', toolbarOnFocus = true} = props;
function isJsonString(str) {
    try {    
      const parsed = JSON.parse(str);
        if(parsed && parsed.blocks) {
          return true
        }
    } catch (e) {
        return false;
    }
    return false;
}
const defaultValFormatted = (defaultValue && !defaultValue.includes('{"blocks":')) ? `{"blocks":[{"key":"35ipj","text":"${defaultValue}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}` : defaultValue;
const defaultVal = (isJsonString(defaultValFormatted)) ? defaultValFormatted ? EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValFormatted))) : EditorState.createEmpty() : EditorState.createEmpty();
    const [editorState, setEditorState] = React.useState(defaultVal);

    const editor = React.useRef(null);

    /**
     * On change the text
     */
    const onStateChange = () => {
        const value = convertToRaw(editorState.getCurrentContent())
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    return (
        <div className='rich-text-editor-wrp f-wrp'>
            <Editor
            ref={editor}
            placeholder={`${translateThis('Type here')}`}
            // toolbarOnFocus={toolbarOnFocus}
            toolbar={{
                options: ['inline', 'fontSize', 'list', 'history', 'colorPicker', 'link', 'emoji', 'blockType'],
                }}
                editorState={editorState}
                editorStyle={{fontSize: '16px'}}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
                onContentStateChange={onStateChange}
            />
        </div>
    );
}
RichTextEditor.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    toolbarOnFocus: PropTypes.bool
  };
export default RichTextEditor;