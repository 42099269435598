import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { getRecoil } from 'recoil-nexus';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { translateThis } from '../../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';
// import './index.scss';
import AppLayout from '../../../layouts/app/AppLayout';
// import CreateNewQuestionForm from './mainCategoryTopicsQuestions/createNewQuestionForm';
import UserDrawer from '../../../components/drawer/drawer';
import AppIcons from '../../../assets/images/icons';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import ApiCaller from '../../../services/api/general';
import { AppDoughnutChart } from '../../../components/charts/donut.chart';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { appConfigAtomState } from '../../../state/reducerAtoms/app.atom';
import ProfileWelcomePopup from './profileWelcomePopup';


const StudentHomePage = () => {
    const apiCaller = new ApiCaller('analytics/student');
    const [isOpenSubscribeDialog, setOpenSubscribeDialog] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({});
    useEffect(() => {
        getStats();
        // eslint-disable-next-line
    }, []);

    const getStats = () => {
        apiCaller.getData('').then((data) => {
            setStats(data);
        }).finally(() => setIsLoading(false))

    }
    const appState = getRecoil(appConfigAtomState);
    const profile = appState?.profile || {};
    const navigate = useNavigate();

    const onCompleteProfileConfirm = ()=> {
        navigate(`/${getCurrentFacilityURL()}/my-profile`,{replace: true})

    }


    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>
                        <div className='main-category-page stud-home'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Home")}</h2>

                                <HeaderRightUserInfo />
                            </div>

                            {isLoading ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> :
                                <>{(stats.student?.totalTimeTaken === 0) && (stats.student?.usedQns === 0) && (stats.student?.omitted === 0) ?
                                    <div className='dashboard-empty'>
                                        <div className='arrow-box'>
                                            <span><img src={AppIcons.arrow} alt='arrow' /></span>
                                            <p>{translateThis("Click the Create button to create your first test")}.</p>
                                        </div>
                                        <div className='dash-placeholder-box'>
                                            <span><img src={AppIcons.placeHolder} alt='placeholder' /></span>
                                            <h3>{translateThis("A new beginning!")}</h3>
                                            <p style={{paddingBottom: '5px'}}>{translateThis("Start your journey by creating your first test.")}</p>
                                        </div>

                                    </div> : <div className='main-category-content'>

                                        {profile.nationality && profile.fieldOfStudy ? null : <>
                                        <ConfirmDialog
                                            isOpen={isOpenSubscribeDialog}
                                            onClose={() => {
                                                setOpenSubscribeDialog(false);
                                            }}
                                            // title=""
                                            className={'delete-popup subscription-popup complete-profile-popup'}
                                            description={<ProfileWelcomePopup />}
                                            okayButtonText={'Complete my profile'}
                                            cancelButtonText={'Maybe later'}
                                            onConfirm={onCompleteProfileConfirm}
                                        /></>}



                                        <div className='statistics-info-wrapper '>
                                            <Grid className='basic-info-grid' container spacing={1}>
                                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Performance')}</h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            {stats && <span className='graph-img'>
                                                            <h2 className='graph-label'>{translateThis('Correct')}</h2>
                                                                <AppDoughnutChart data={{
                                                                    labels: [`${translateThis('Correct')}`, `${translateThis('Incorrect')}`, `${translateThis('Omitted')}`],
                                                                    datasets: [
                                                                        {
                                                                            label: '',
                                                                            total: `${stats.student?.totalUsedUnique}`,
                                                                            totalScore: `${stats.student?.correctPercentage}`,
                                                                            fontStyle: 'bold 26px sans-serif',
                                                                            data: [stats.student?.correctPercentage, stats.student?.inCorrectPercentage, stats.student?.omittedPercentage],
                                                                            backgroundColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(52, 182, 6, 0.6)', // correct
                                                                                'rgba(230, 42, 42, 0.6)', // incorrect
                                                                                'rgba(244, 117, 0, 0.5)', // omitted
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }} />
                                                            </span>}
                                                            <div className='graph-content'>
                                                                <span>{translateThis('From')} {stats.student?.totalUsedQuestions} {translateThis('questions, you answered')}:</span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.correct} alt='graph' /> &nbsp; {stats.student?.correctPercentage}% {translateThis('Correct')}</p>
                                                                        <span>{stats.student?.correct} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.incorrect} alt='graph' /> &nbsp; {stats.student?.inCorrectPercentage}% {translateThis('Incorrect')}</p>
                                                                        <span>{stats.student?.inCorrect} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.omitted} alt='graph' /> &nbsp; {stats.student?.omittedPercentage}% {translateThis('Omitted')}</p>
                                                                        <span>{stats.student?.omitted} {translateThis('questions')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Performance Percentile Rank')}</h3>
                                                        <div className='basic-detail-box line-chart'>
                                                            {/* <span className='graph-img'><img src={AppIcons.line48} alt='graph' /></span> */}
                                                            <div className='graph-img line-graph'>
                                                                <div className='PercentileIcon-wrp'>
                                                                    <span className='graph-wrp'>
                                                                        <img src={AppIcons.lineGraph} alt='graph' />
                                                                    </span>
                                                                    <div className={`graph-mark val${(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}` : 0}`} style={{ width: `${(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}` : 0}%` }}>
                                                                        <div className='value-box'>
                                                                            <p>{(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ? `${stats?.university?.performancePercentile?.percentileForStudent}` : 0}{translateThis('th')} <b>{translateThis('percentile')}</b></p>
                                                                        </div>
                                                                        <p>{translateThis('You')}</p>
                                                                    </div>

                                                                    <div className={`graph-mark markFixed val50`} style={{ width: `50%` }}>
                                                                    <p>{translateThis('50th')}<b>{translateThis('percentile')}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='graph-content'>
                                                                <span><b>{translateThis('Performance rank')}:</b></span>
                                                                <ul>
                                                                    <li>
                                                                        <span>{translateThis('You rank on the')}</span>
                                                                        <p><img src={AppIcons.green} alt='graph' /> &nbsp;<span>{(stats?.university?.performancePercentile?.percentileForStudent && stats?.university?.performancePercentile?.percentileForStudent !== 'NaN') ?`${stats?.university?.performancePercentile?.percentileForStudent}`:0}{translateThis('th percentile with a score of')} {stats?.student?.correctPercentage}%</span></p>
                                                                    </li>
                                                                    <li>
                                                                        <span>{translateThis('Median score')}</span>
                                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp;<span>{(stats?.university?.performancePercentile?.median && stats?.university?.performancePercentile?.median !== 'NaN') ? `${stats?.university?.performancePercentile?.median}% ${translateThis('correct')}` : 0}</span></p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Question Usage')}</h3>
                                                        <div className='basic-detail-box pie-chart'>
                                                            {stats && <span className='graph-img'>
                                                                <h2 className='graph-label'>{translateThis('Used')}</h2>
                                                                <AppDoughnutChart data={{
                                                                    labels: [`${translateThis('Used')}`, `${translateThis('Unused')}`],
                                                                    datasets: [
                                                                        {
                                                                            label: '',
                                                                            total: `${stats.student?.usedPercentage}`,
                                                                            totalScore: `${stats.student?.usedPercentage}`,
                                                                            fontStyle: 'bold 26px sans-serif',
                                                                            data: [stats.student?.usedPercentage, stats.student?.totalUnusedPercentage],
                                                                            backgroundColor: [
                                                                                '#195994', // Used
                                                                                'rgba(69, 148, 241, 0.6)', // unused
                                                                            ],
                                                                            borderColor: [
                                                                                '#195994', // Used
                                                                                'rgba(69, 148, 241, 0.6)', // unused
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }} />
                                                            </span>}
                                                            <div className='graph-content'>
                                                                <span>{translateThis('From')} {stats.university?.totalQuestionsInBank} {translateThis('questions')}:</span>
                                                                <ul>
                                                                    <li>
                                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp; {stats.student?.usedPercentage}% {translateThis('Used')}</p>
                                                                        <span><b>{translateThis('Questions that appeared at least once in an exam')}</b> {stats.student?.usedQns} {translateThis('questions')}</span>
                                                                    </li>
                                                                    <li>
                                                                        <p><img src={AppIcons.lytBlue} alt='graph' /> &nbsp; {stats.student?.totalUnusedPercentage}% {translateThis('Unused')}</p>
                                                                        <span><b>{translateThis('Questions never included in an exam')}</b> {stats.student?.totalUnusedQuestions} {translateThis('questions')}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                                    <div className='each-statistics-box'>
                                                        <h3>{translateThis('Time per question Percentile Rank')}</h3>
                                                        <div className='basic-detail-box line-chart'>
                                                            {/* <span className='graph-img'><img src={AppIcons.line52} alt='graph' /></span> */}

                                                            <div className='graph-img line-graph'>
                                                                <div className='PercentileIcon-wrp'>
                                                                    <span className='graph-wrp'>
                                                                        <img src={AppIcons.lineGraph} alt='graph' />
                                                                    </span>
                                                                    <div className={`graph-mark val${(stats?.university?.timePercentile?.percentileForStudentTime && stats?.university?.timePercentile?.percentileForStudentTime !== 'NaN') ? `${stats?.university?.timePercentile?.percentileForStudentTime}` : 0}`} style={{ width: `${(stats?.university?.timePercentile?.percentileForStudentTime && stats?.university?.timePercentile?.percentileForStudentTime !== 'NaN') ? `${stats?.university?.timePercentile?.percentileForStudentTime}` : 0}%` }}>
                                                                        <div className='value-box'>
                                                                            <p>{(stats?.university?.timePercentile?.percentileForStudentTime && stats?.university?.timePercentile?.percentileForStudentTime !== 'NaN') ? `${stats?.university?.timePercentile?.percentileForStudentTime}` : 0}{translateThis('th')} <b>{translateThis('percentile')}</b></p>
                                                                        </div>
                                                                        <p>{translateThis('You')}</p>
                                                                    </div>

                                                                    <div className={`graph-mark markFixed val50`} style={{ width: `50%` }}>
                                                                    <p>{translateThis('50th')}<b>{translateThis('percentile')}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='graph-content'>
                                                                <span className='avgSec'>{translateThis('Average time per question is')}: <p>{(stats?.university?.timePercentile?.mean && stats?.university?.timePercentile?.mean !== 'NaN') ? `${stats?.university?.timePercentile?.mean}` : 0} {translateThis('seconds')}</p></span>
                                                                {/* <span><b>{translateThis('Average time per question is')}:</b><p>{(stats?.university?.timePercentile?.mean && stats?.university?.timePercentile?.mean !== 'NaN') ? `${stats?.university?.timePercentile?.mean}` : 0} {translateThis('seconds')}</p></span> */}
                                                                <ul>
                                                                    <li>
                                                                    <span>{translateThis('You rank is')}</span>
                                                                        <p><img src={AppIcons.green} alt='graph' /> &nbsp; {(stats?.university?.timePercentile?.percentileForStudentTime && stats?.university?.timePercentile?.percentileForStudentTime !== 'NaN') ? `${stats?.university?.timePercentile?.percentileForStudentTime}${translateThis('th percentile')}` : 0}</p>

                                                                    </li>
                                                                    <li>
                                                                    <span>{translateThis('Median time is')}</span>
                                                                        <p><img src={AppIcons.blue} alt='graph' /> &nbsp; {(stats?.university?.timePercentile?.median && stats?.university?.timePercentile?.median !== 'NaN') ? `${stats?.university?.timePercentile?.median} ${translateThis('seconds')}` : 0}</p>

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>}</>
                            }
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    )
}



StudentHomePage.propTypes = {};

export default StudentHomePage;