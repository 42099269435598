import React from 'react';
import './subscriptionPopup.scss';
import AppIcons from '../../../../assets/images/icons';
import { translateThis } from '../../../../helpers/language.helper';

const SubscriptionPopup = () => {
    return (
        <>
            <div className='subscription-popup-wrp f-wrp'>
                <span><img src={AppIcons.subIcon} alt='subscribe' /></span>
                <h2>{translateThis('Ready to kick off')}</h2>
                <p>{translateThis('To get started, grab a subscription and lets go!')}</p>
                <div className='subscribe-benefits f-wrp'>
                    <p>{translateThis('You get:')}</p>
                    <ul>
                        <li>{translateThis('Access to all questions')}</li>
                        <li>{translateThis('In-depth explanations')}</li>
                        <li>{translateThis('Flag questions')}</li>
                        <li>{translateThis('Unlimited test creation')}</li>
                        <li>{translateThis('Comprehensive analytics')}</li>
                        <li>{translateThis('Compare to peers')}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SubscriptionPopup;