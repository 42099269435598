import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// @mui
// import { Stack, Divider, Button } from '@mui/material';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { translateThis } from '../../../helpers/language.helper';
// components
import { register } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/snackbar/index';
import TextInput from '../../../components/inputs/textInput/textInput';
import { isLoggedIn } from '../../../helpers/auth.helper';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';



// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = isLoggedIn();
    const currentFacilityURL = getCurrentFacilityURL();
    const { from } = location.state || { from: { pathname: `/${currentFacilityURL}/my-portal` } };
    
    if (isAuth) {
        navigate(from, { replace: true });
    }
    // state variables for handling input fields values
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    })
    const [validPassword, setValidPassword] = useState({
        eightCharacter: false,
        containNumber: false,
        containUpperCase: false,
        containSpecialChar: false
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.email && formData.password && formData.firstName && formData.lastName && isTermsChecked) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            const postData = {
                "email": formData.email,
                "mobileNo": "",
                "password": formData.password,
                "name": `${formData.firstName}`,
                "lastName": `${formData.lastName}`
            }
            register(postData).then((data) => {
                if (data.success) {
                    navigate(from, { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message);
                }
            }).finally(() => setIsLoading(false));

        }

    };

    /**
     * The signup form elements
     */
    const formElements = [
        {
            label: translateThis('firstName'),
            name: 'firstName',
            type: 'text',
            required: true,
            placeholder: translateThis('pleaseEnterYourFirstName'),
            className: 'custom-textfield',
            getValue: setFormValue
        },
        {
            label: translateThis('lastName'),
            name: 'lastName',
            type: 'text',
            required: true,
            placeholder: translateThis('pleaseEnterYourLastName'),
            className: 'custom-textfield',
            getValue: setFormValue
        },
        {
            label: translateThis('loginForm_email'),
            name: 'email',
            type: 'email',
            required: true,
            placeholder: translateThis('pleaseEnterYourEmailAddress'),
            className: 'custom-textfield',
            getValue: setFormValue
        },
        {
            label: translateThis('loginForm_password'),
            name: 'password',
            type: 'password',
            isRequired: false,
            parentClassName: 'no-padding-btm',
            className: 'custom-textfield',
            placeholder: translateThis('loginForm_passwordPlaceHolder'),
            // getValue: setFormValue
            getValue: (_, value) => validatePassword(value)
        },
    ];

     /**
     * To validate password requirement
     * @param {String} password 
     */
     const validatePassword = (password) => {
        const rules = {
            eightCharacter: false,
            containNumber: false,
            containUpperCase: false,
            containSpecialChar: false
        }

        if (password.length > 7) { // To check if password contain 8 chars
            rules.eightCharacter = true;
        }
        if (/\d/.test(password)) { // To check if password contain numbers
            rules.containNumber = true;
        }
        if (password !== password.toLowerCase()) { // To check if password contain uppercase
            rules.containUpperCase = true;
        }
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (format.test(password)) { // To check if password contain special chars
            rules.containSpecialChar = true;
        }
        setValidPassword(rules)
        if (rules.containNumber &&
            rules.containSpecialChar &&
            rules.containUpperCase &&
            rules.eightCharacter
        ) {
            setFormValue('password', password)
        } else {
            setFormValue('password', '')
        }
    }
    return (
        <>
            {/* <Stack className='google-auth' spacing={3}>
                <Button className='google-auth-btn'> {SVGIcons.GoogleIcon()} Sign up with Google </Button>
            </Stack>
            <Stack className='divider' direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                <Divider>or</Divider>
            </Stack> */}
            <Stack className='login-form standard-form' spacing={3}>
                {formElements.map((element, key) => (
                    <TextInput
                        key={`register-input-${key}`}
                        {...element}
                        useDefaultValidation
                    />
                ))}
                <Stack className='password-requirement-points' variant='div' spacing={3} style={{ marginTop: '0' }}>
                    <ul>
                        <li className={validPassword.eightCharacter ? 'checked' : ''}>{translateThis('At least 8 characters long')}</li>
                        <li className={validPassword.containNumber ? 'checked' : ''}>{translateThis('1 number')}</li>
                        <li className={validPassword.containUpperCase ? 'checked' : ''}>{translateThis('1 uppercase character')}</li>
                        <li className={validPassword.containSpecialChar ? 'checked' : ''}>{translateThis('1 special symbol')}</li>
                    </ul>
                </Stack>
                <FormGroup className='custom-checkbox'>
                    <FormControlLabel control={<Checkbox checked={isTermsChecked} onChange={(e)=>setIsTermsChecked(e.target.checked)} />} label={
                        <>{translateThis('regText_1')} <Link to={`/${currentFacilityURL}/terms-and-conditions`}>{translateThis('regText_2')}</Link> {translateThis('regText_3')} <Link to={`/${currentFacilityURL}/privacy-policy`}>{translateThis('regText_4')}</Link></>
                    } />
                </FormGroup>

                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    {translateThis('signUp')}
                </LoadingButton>
            </Stack>
            <Stack className='additional-btn' variant='div' spacing={3}>
                <p>{translateThis("alreadyHaveAnAccount")} <Link to={`/${currentFacilityURL}/auth/login`}>{translateThis("loginButton")}</Link></p>
            </Stack>
            
        </>
    );
}
