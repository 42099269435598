import { useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Send } from '@mui/icons-material';


// components

import RichTextEditor from '../../components/rich-text-editor';

// Helpers
import { getAuthDetailsByKey } from '../../helpers/auth.helper';
import { translateThis } from '../../helpers/language.helper';

import './index.scss';
// Services
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/snackbar';
import TextInput from '../../components/inputs/textInput/textInput';



// ........................................................................




const NewMessage = (props) => {
    const apiCaller = new ApiCaller('inbox');
    const {
        onSuccess = () => null,
        onClose = () => null,
    } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        message: ``,
        subject: ``
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.subject && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmitForm = () => {
        const postData = {
            email: getAuthDetailsByKey('email'),
            phoneNumber: getAuthDetailsByKey('phone'),
            name: `${getAuthDetailsByKey('name')} ${getAuthDetailsByKey('lastName')}`,
            message: formData.message,
            type: 'inbox-message',
            status: 'pending',
            subject: formData.subject,
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            const successMessage = "Message sent successfully!."
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(successMessage)
                    onSuccess();
                } else {
                    showSnackbar().failure(data.message)
                }
            }).finally(() => setIsSubmitting(false));
        }
    };


    return (
        <>
           <div className='add-new-message-layout f-wrp'>
                    <Stack className='new-message standard-form form-full-width panel-form' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '0' }}>
                                <TextInput
                                    label={translateThis('Subject')}
                                    name='subject'
                                    id='contact_form_subject'
                                    value={formData.subject}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    type='text'
                                    placeholder={translateThis('Subject')}
                                    className='custom-textfield'

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>                            
                                <RichTextEditor onChange={(value) => setFormValue('message', JSON.stringify(value))} />
                            </Grid>
                        </Grid>
                    </Stack>

                    <br />

                    <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                        <div className='lft-btn-sec'>
                            <Button onClick={onClose} className='cancel-btn'>{translateThis("cancel")}</Button>
                        </div>
                        <div className='ryt-btn-sec pln-btn-style'>

                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<Send/>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmitForm}>
                                {translateThis("Send")}
                            </LoadingButton>

                        </div>
                    </div>
                </div>
        </>
    );
}

NewMessage.propTypes = {
    onClose: propTypes.func,
    onSuccess: propTypes.func
}

export default NewMessage;











