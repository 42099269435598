import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const AccidentFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView f-wrp" style={{padding: '0'}}>
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0', textAlign: 'center' }}><b>Accident</b></h2>
                    </div>
                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>Details</b></h3>
                    <div className='gap' />
                    <div className='flex-box'>
                        <p>Child’s name: <b>{answerGetter('provider_accident_form_question_firstName', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>Birthday: <b>{answerGetter('provider_accident_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                        <div className='check-box inline'>
                            <p style={{ paddingTop: '0', minWidth: 'auto' }}>Sex:</p>
                            <ul>
                                <li className={answerGetter("provider_accident_form_question_sex", formData)?.answer === 'Male' ? 'checked' : ''}>Male</li>
                                <li className={answerGetter("provider_accident_form_question_sex", formData)?.answer === 'Female' ? 'checked' : ''}>Female</li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex-box'>
                        <p>Childcare address: <b>{answerGetter('provider_accident_form_question_childcareAddress', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>Accident date: <b>{answerGetter('provider_accident_form_accidentDate', formData)?.answer || '-'}</b></p>
                        <p>Accident time: <b>{answerGetter('provider_accident_form_question_accidentTime', formData)?.answer || '-'}</b></p>
                        <p>Number of children present: <b>{answerGetter('provider_accident_form_question_numberOfChildrenPresent', formData)?.answer || '-'}</b></p>
                        <p>Number of staff members present: <b>{answerGetter('provider_accident_form_question_numberOfMembersPresent', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>1. EMS (911) or other Medical professional:  </p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_1", formData)?.answer === 'Not notified' ? 'checked' : ''}>Not notified</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_1", formData)?.answer === 'Notified' ? 'checked' : ''}>Notified</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_1", formData)?.answer === 'Notified' ? <p>Enter time of notification:  <b>{answerGetter('provider_accident_form_accidentFormQn_1_notified', formData)?.answer || '-'}</b></p> : null}
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>2. Location where accident occurred</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Bathroom' ? 'checked' : ''}>Bathroom</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Classroom' ? 'checked' : ''}>Classroom</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Doorway' ? 'checked' : ''}>Doorway</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Field trip' ? 'checked' : ''}>Field trip</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Hallway' ? 'checked' : ''}>Hallway</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Office' ? 'checked' : ''}>Office</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Playground' ? 'checked' : ''}>Playground</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Unknown' ? 'checked' : ''}>Unknown</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Vehicle' ? 'checked' : ''}>Vehicle</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_2_other', formData)?.answer || '-'}</b></p> : null}
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>3. Equipment / Product involved</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Bike' ? 'checked' : ''}>Bike</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Climber' ? 'checked' : ''}>Climber</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Door' ? 'checked' : ''}>Door</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Furniture' ? 'checked' : ''}>Furniture</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Not applicable' ? 'checked' : ''}>Not applicable</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Playground' ? 'checked' : ''}>Playground</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Slide' ? 'checked' : ''}>Slide</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Swing' ? 'checked' : ''}>Swing</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Tricycle' ? 'checked' : ''}>Tricycle</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_3_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>4. Cause of injury</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Fall to surface' ? 'checked' : ''}>Fall to surface{answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Fall to surface' ? <p>Fall to surface: height of fall: <b>Feet: {answerGetter('provider_accident_form_accidentFormQn_4_feet', formData)?.answer || '-'}</b>  <b>Surface type: {answerGetter('provider_accident_form_accidentFormQn_4_feet', formData)?.answer || '-'}</b></p> : null}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Bitten by child' ? 'checked' : ''}>Bitten by child</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Hit by child' ? 'checked' : ''}>Hit by child</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Pushed by child' ? 'checked' : ''}>Pushed by child</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Object' ? 'checked' : ''}>Object</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Insect bite' ? 'checked' : ''}>Insect bite</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Insect sting' ? 'checked' : ''}>Insect sting</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Motor vehicle' ? 'checked' : ''}>Motor vehicle</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Running' ? 'checked' : ''}>Running</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Tripping' ? 'checked' : ''}>Tripping</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_4_other', formData)?.answer || '-'}</b></p> : null}
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>5. Part of body involved</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5", formData)?.answer === 'Left side' ? 'checked' : ''}>Left side</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5", formData)?.answer === 'Right side' ? 'checked' : ''}>Right side</li>
                            <div className='gap' />
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Ankle' ? 'checked' : ''}>Ankle</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Arm' ? 'checked' : ''}>Arm</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Back' ? 'checked' : ''}>Back</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Buttocks' ? 'checked' : ''}>Buttocks</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Chest' ? 'checked' : ''}>Chest</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Ear' ? 'checked' : ''}>Ear</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Eye' ? 'checked' : ''}>Eye</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Foot' ? 'checked' : ''}>Foot</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Genitals' ? 'checked' : ''}>Genitals</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Hand' ? 'checked' : ''}>Hand</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Head' ? 'checked' : ''}>Head</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Leg' ? 'checked' : ''}>Leg</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Mouth' ? 'checked' : ''}>Mouth</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Neck' ? 'checked' : ''}>Neck</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Nose' ? 'checked' : ''}>Nose</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Other face part' ? 'checked' : ''}>Other face part</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Tooth' ? 'checked' : ''}>Tooth</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Wrist' ? 'checked' : ''}>Wrist</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_5_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>6. Type of injury</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Broken bone' ? 'checked' : ''}>Broken bone</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Bruise' ? 'checked' : ''}>Bruise</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Burn' ? 'checked' : ''}>Burn</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Crushing injury' ? 'checked' : ''}>Crushing injury</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Cut' ? 'checked' : ''}>Cut</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Dislocation' ? 'checked' : ''}>Dislocation</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Loss of consciousness' ? 'checked' : ''}>Loss of consciousness</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Puncture' ? 'checked' : ''}>Puncture</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Scrape' ? 'checked' : ''}>Scrape</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Sprain' ? 'checked' : ''}>Sprain</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Swelling' ? 'checked' : ''}>Swelling</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Unknown' ? 'checked' : ''}>Unknown</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_6_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>7. First aid given at facility</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Bandage' ? 'checked' : ''}>Bandage</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Cold pack' ? 'checked' : ''}>Cold pack</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Comfort' ? 'checked' : ''}>Comfort</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Elevation' ? 'checked' : ''}>Elevation</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Pressure' ? 'checked' : ''}>Pressure</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Washing' ? 'checked' : ''}>Washing</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Other' ? 'checked' : ''}>Other</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_5_first_aid_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>8. Child was sent</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Clinic' ? 'checked' : ''}>Clinic</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Home' ? 'checked' : ''}>Home</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Hospital' ? 'checked' : ''}>Hospital</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Not applicable' ? 'checked' : ''}>Not applicable</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === "Doctor's office by:" ? 'checked' : ''}>Doctor's office by:</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === "Doctor's office by:" ?
                            <><li className={answerGetter("provider_accident_form_accidentFormQn_8_by", formData)?.answer === 'Parent Guardian' ? 'checked' : ''}>Parent Guardian</li>
                                <li className={answerGetter("provider_accident_form_accidentFormQn_8_by", formData)?.answer === 'Emergency pick up' ? 'checked' : ''}>Emergency pick up</li>
                                <li className={answerGetter("provider_accident_form_accidentFormQn_8_by", formData)?.answer === 'Ambulance Firefighter Police' ? 'checked' : ''}>Ambulance Firefighter Police</li></> : null}
                    </div>
                    <div className='gap' />
                    <div className='each-section-box'>
                        <p>Comments: <b>{answerGetter('provider_accident_form_comments', formData)?.answer || '-'}</b></p>
                        <p>Notes (optional): <b>{answerGetter('provider_accident_form_notes', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <h3><b>Health contacts</b></h3>

                    <p>The following check up took place at <b>{answerGetter('provider_accident_form_health_contact_question_childcareAddress', formData)?.answer || '-'}</b></p>
                    <p>Check all that apply and document the contact and results of the contact.</p>
                    <div className='check-box'>
                        <ul>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_seen_by_provider", formData)?.answer === 'Child was seen by Provider / Substitute' ? 'checked' : ''}>Child was seen by Provider / Substitute  {answerGetter("provider_accident_form_accidentFormQn_5_seen_by_provider", formData)?.answer === 'Child was seen by Provider / Substitute' ? <p> &nbsp; &nbsp; &nbsp; Date: <b>{answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteTime', formData)?.answer || '-'}</b></p> : null} </li>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_provider", formData)?.answer === 'Spoke with Provider / Substitute' ? 'checked' : ''}>Spoke with Provider / Substitute  {answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_provider", formData)?.answer === 'Spoke with Provider / Substitute' ? <p> &nbsp; &nbsp; &nbsp; Date: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteTime', formData)?.answer || '-'}</b></p> : null} </li>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_physician", formData)?.answer === "Spoke with Child's Physician" ? 'checked' : ''}>Spoke with Child's Physician {answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_physician", formData)?.answer === "Spoke with Child's Physician" ? <p> &nbsp; &nbsp; &nbsp; Date: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianTime', formData)?.answer || '-'}</b></p> : null} </li>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_nurse", formData)?.answer === "Spoke with Nurse in the Doctor's office" ? 'checked' : ''}>Spoke with Child's Physician {answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_nurse", formData)?.answer === "Spoke with Nurse in the Doctor's office" ? <p> &nbsp; &nbsp; &nbsp; Date: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeTime', formData)?.answer || '-'}</b></p> : null} </li>
                        </ul>
                    </div>

                    <p style={{ textAlign: 'center' }}><b>NOTE:</b> Describe action taken by provider, substitute or assistan, including but not limited to transportation to a hospital emergency room, doctor's office or other medical facility.</p>
                    <div className='gap' />
                    <p>Provider /Substitute /Assistant observation or assessment: <b>{answerGetter('provider_accident_form_health_contact_observation', formData)?.answer || '-'}</b></p>
                    <div className='gap' />
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>                            
                            <h3>{answerGetter('provider_communication_form_question_observer_signatureName', formData)?.answer || '-'}</h3>
                            <p>Staff signature</p>
                            <p>Date: <b>{answerGetter('provider_accident_form_health_contact_question_observationDate', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p>Content of communication with nurse in the doctor's office: <b>{answerGetter('provider_accident_form_health_contact_detailsFromNurseInDocOffice', formData)?.answer || '-'}</b></p>
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>
                            <h3>{answerGetter('provider_communication_form_question_observer_signatureName_2', formData)?.answer || '-'}</h3>
                            <p>Staff signature</p>
                            <p>Date: <b>{answerGetter('provider_accident_form_health_contact_question_observationDate_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                    <div className='check-box inline'>
                        <p>Is follow-up needed?</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_follow_up_needed", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_follow_up_needed", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    {answerGetter("provider_accident_form_accidentFormQn_5_follow_up_needed", formData)?.answer === "Yes" ?
                        <>
                            <p>If yes, what needs to be done? <b>{answerGetter('provider_accident_form_health_contact_followNeeded', formData)?.answer || '-'}</b></p>
                            <p>Follow-up was provided as follows: <b>{answerGetter('provider_accident_form_health_contact_followNeeded_1', formData)?.answer || '-'}</b></p>
                        </> : null}
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>
                            <h3>{answerGetter('provider_communication_form_question_observer_signatureName_3', formData)?.answer || '-'}</h3>
                            <p>Staff signature</p>
                            <p>Date: <b>{answerGetter('provider_accident_form_health_contact_question_observationDate_3', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                    <p><b>Parental notification of child's accident</b></p>
                    <div className='check-box inline'>
                        <ul style={{paddingTop: '20px'}}>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_copyGivenToParent", formData)?.answer === 'Copy given to parent' ? 'checked' : ''}>Copy given to parent</li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p>I, <b>{answerGetter('provider_accident_form_health_contact_staffSignature_confirm', formData)?.answer || '-'}</b>, confirm that I was notified that my child, <b>{answerGetter('provider_accident_form_health_contact_question_parentNotification_1', formData)?.answer || '-'}</b>, was involved in an accident during school hours, that resulted in an injury.</p>

                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p><b>Accident</b>- Date: <b>{answerGetter('provider_accident_form_health_contact_question_dateOfAccident', formData)?.answer || '-'}</b>  Time: <b>{answerGetter('provider_accident_form_health_contact_Accident_time', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className="ryt-section">
                            <p><b>Notification</b>- Date: <b>{answerGetter('provider_accident_form_health_contact_question_dateOfNotification', formData)?.answer || '-'}</b>  Time: <b>{answerGetter('provider_accident_form_health_contact_Notification_time', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>
                            <h2 style={{ fontSize: '42px' }}>{answerGetter('provider_accident_form_health_contact_staffSignature_confirm', formData)?.answer || '-'}</h2>
                            <p>Staff signature</p>
                            <p>Submission time: <b>{answerGetter('provider_accident_form_health_contact_submission_confirm_time_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}

AccidentFormView.propTypes = {
    data: PropTypes.any
}

export default AccidentFormView;