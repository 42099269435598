import React, {useState} from 'react';
import propTypes from 'prop-types';
// State management
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import SVGIcons from '../../../../assets/images/icons/svgIcons';

import '../index.scss';
// import { myPortalState } from '../../../../state/reducerAtoms/myPortal.atom';

import { translateThis } from '../../../../helpers/language.helper';



const drawerWidth = 70;
const drawerHeight = 59;
const openedMixin = (theme) => ({
    width: drawerWidth,
    height: `100% !important`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('xs')]: {
        height: drawerHeight,
    },
});

const closedMixin = (theme) => ({
    height: `100%`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0 !important`,
    [theme.breakpoints.up('xs')]: {
        width: `100%`,
        height: `0`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const QuestionNumberDrawer = ({ questions = [], onQnNumberClick, currentQuestionIndex = 0 }) => {
    const theme = useTheme();
    const [myPortalInitialState, setMyPortalState] = useState({isDrawerOpen: true});
    // const { facilityInfo = {} } = myPortalInitialState;
    /**
     * Invokes when drawer open
     * @returns Fun
     */
    const handleDrawerOpen = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: true } }));

    /**
     * Invokes when drawer close
     * @returns Fun
     */
    const handleDrawerClose = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: false } }));

    /**
     * Invokes when user click a nav item
     * Passes the selected nav to my portal
     * @param {Object} item selected nav item
     */
    // const onSelectNavItem = (item) => setMyPortalState((prv) => ({ ...prv, ...{ selectedNavItem: item.id, pageHeader: item.pageHeader } }))


    const questionNumberCount = questions.map((q, i) => ({
        title: translateThis(i + 1),
        pageHeader: translateThis(i + 1),
        className: `test-${q.status}`,
        featureName: i + 1,
        icon: q.isAnswered ? SVGIcons.StarIcon() : null,
        // link: `/${facilityURL}/manage`
    }))
    
    if(myPortalInitialState.isDrawerOpen){
        document.body.classList.add('Qn-numberOpen');
    } else{
        document.body.classList.remove('Qn-numberOpen');
    }

    return (
        <>

            <DrawerHeader className='logo-sec-wrp'>
                {myPortalInitialState.isDrawerOpen ? <>
                    <IconButton onClick={handleDrawerClose} >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton></> : <><IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(myPortalInitialState.isDrawerOpen && { display: 'none' }),
                        }}
                        style={{ margin: '0', width: '100%' }}
                    >
                        <ChevronRightIcon />
                    </IconButton></>}
            </DrawerHeader>
            <Drawer variant="permanent" open={myPortalInitialState.isDrawerOpen} className={`${myPortalInitialState.isDrawerOpen ? "questionDrawerOpen" : 'questionDrawerClose'} question-drawer`} >
                <div>
                    {/* <DrawerHeader className='logo-sec-wrp'>
                        {myPortalInitialState.isDrawerOpen ? <>
                            <IconButton onClick={handleDrawerClose} >
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton></> : <><IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(myPortalInitialState.isDrawerOpen && { display: 'none' }),
                                }}
                                style={{ margin: '0', width: '100%' }}
                            >
                                <MenuIcon />
                            </IconButton></>}
                    </DrawerHeader> */}

                    <List className='question-items'>
                        {/* {myNavItems.filter((item) => features[item.featureName] === true).map((element, key) => ( */}
                        {questionNumberCount.map((element, key) => (
                            <ListItem
                                className={currentQuestionIndex === key ? 'active' : ''}
                                disablePadding sx={{ display: 'block' }}
                                onClick={() => onQnNumberClick(key)}
                                key={key}>
                                <Link to={element.link} className={`${element.className}`}>
                                    <ListItemButton className={`listButton`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                        <ListItemText primary={element.title} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 0 }} />
                                        {element.icon ? <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                            <span>{element.icon}</span>
                                        </ListItemIcon> : null}
                                        <span className='flagIcon'>{SVGIcons.FlagIcon()}</span>
                                    </ListItemButton>
                                </Link>

                            </ListItem>
                        ))}
                    </List>
                </div>

            </Drawer>
        </>

    );
}

QuestionNumberDrawer.propTypes = {
    questions: propTypes.array,
    currentQuestionIndex: propTypes.number,
    onQnNumberClick: propTypes.func
}
export default QuestionNumberDrawer;