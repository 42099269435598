import React, { useState, useEffect } from 'react';
// Components
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import AppPopOverMenu from '../../../../components/appPopOverMenu';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import RichTextViewer from '../../../../components/rich-text-editor/TextViewer';
import { DATE_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../../constants/index';
import ModalPopUp from '../../../../components/modal/modal.popup';
import InboxView from './inboxView';
import { translateThis } from '../../../../helpers/language.helper';
import AppIcons from '../../../../assets/images/icons';




const ReportedQuestionTable = () => {
    const apiCaller = new ApiCaller('inbox');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getCustomList('/reported-questions')
            .then((data) => applyDataArr(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * Resource delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Report deleted successfully`);
                    } else {
                        showSnackbar().failure(`Failed to delete report`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }
    /**
       * Resource update
       */
    const updateData = (id, status) => {
        if (id) {
            const statusAs = status === 'pending' ? 'solved' : 'pending'
            apiCaller.customPutData(id, { status: statusAs })
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Report updated successfully`);
                    } else {
                        showSnackbar().failure(`Failed to update report`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
             * To apply the filtered data
             * @param {Array} arr 
             */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 75,
            maxWidth: 75,
            flex: 0.5,

        },
        {
            field: 'subject',
            headerName: 'Question',
            flex: 2,
            minWidth: 200,
            renderCell: ({ row }) => <><RichTextViewer richText={row?.question?.question ?? row.subject}  /><Tooltip title={<><RichTextViewer richText={row?.question?.question ?? row.subject}  /></>} placement="top">
                <span className='tool-button'><SVGIcons.MoreHorizontalFill style={{ width: '20px', height: '23px' }} /></span>
            </Tooltip></>
        },
        {
            field: 'message',
            headerName: 'Report',
            flex: 2,
            renderCell: ({ row }) => <RichTextViewer richText={row.message} />
        },
        {
            field: 'createdAt',
            headerName: 'Creation',
            flex: 0.7,
            minWidth: 170,
            maxWidth: 170,
            renderCell: ({ row }) => <span>{dayjs(row.createdAt).format(DATE_FORMAT)}</span>
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.6,
            minWidth: 150,
            maxWidth: 150,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 0.6,
            minWidth: 200,
            maxWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            renderCell: ({ row }) => <AppPopOverMenu icon={<>{row.status === 'pending' ? SVGIcons.StatusBlockedIcon() : SVGIcons.StatusActiveIcon()}</>} options={[
                {
                    label: <span className='tab-status' style={{ display: 'flex', gap: '5px' }}>{SVGIcons.StatusActiveIcon()} Solved </span>,
                    onClick: () => {
                        updateData(row._id, row.status);
                    }
                },
                {
                    label: <span className='tab-status' style={{ display: 'flex', gap: '5px' }}>{SVGIcons.StatusBlockedIcon()} Pending </span>,
                    onClick: () => {
                        updateData(row._id, row.status);
                    }
                },
            ]} />

        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 0.5,
            width: 100,
            minWidth: 100,
            sortable: false,

            renderCell: ({ row }) => <>
                <Link className='table-link-icon' to={`#`} type='button' onClick={() => {
                    setSelectedRow(row);
                    setOpenViewDialog(true)
                }}>{SVGIcons.EyeOpen()}</Link>

                <Link className='table-link-icon' to={`#`} type='button' onClick={() => {
                    setSelectedId(row._id);
                    setOpenDeleteDialog(true)
                }}>{SVGIcons.DeleteBlueIcon()}</Link>
            </>
        },
    ]

    return (
        <div className='block-table-wrp f-wrp'>
            <div className='panel-table-sec provider-table PC-Table f-wrp'>
                <AppTable
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                    <div className='mob-table-wrp childView f-wrp'>
                        <div className='mob-table-search'>
                            <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                        </div>
                        {filteredDataArr.length > 0 ? <ul>
                            {filteredDataArr.map((element) => (
                                <li key={element._id}>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn'>
                                            <span>{(element.status === 'pending' ? <span className='tab-status'>{SVGIcons.StatusBlockedIcon()}  </span> : <span className='tab-status'>{SVGIcons.StatusActiveIcon()} </span>)}</span>

                                            <AppPopOverMenu
                                                icon={SVGIcons.StatusMobArrow()}
                                                options={[
                                                    {
                                                        label: <span className='tab-status' style={{ display: 'flex', gap: '5px' }}>{SVGIcons.StatusActiveIcon()} Solved </span>,
                                                        onClick: () => {
                                                            updateData(element._id, element.status);
                                                        }
                                                    },
                                                    {
                                                        label: <span className='tab-status' style={{ display: 'flex', gap: '5px' }}>{SVGIcons.StatusBlockedIcon()} Pending </span>,
                                                        onClick: () => {
                                                            updateData(element._id, element.status);
                                                        }
                                                    },
                                                ]} />

                                            <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                            <h4 style={{ fontWeight: 'normal' }}>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: 'calc(100% - 100px)' }}>
                                        <div className='flex-wrp' style={{ justifyContent: 'space-between' }}>
                                            {(element.question) ? <h4 className='question'>
                                                {element.question.question}
                                            </h4> : '-'}
                                        </div>
                                        <div className='flex-wrp' >
                                            <div className='flex-wrp auto-flex flex-gap5' style={{ maxWidth: 'calc(100% - 0px)', overflow: 'hidden' }}>
                                                {(element.message) ? <span className='rich-text-msg'><RichTextViewer richText={element.message} /></span> : '-'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='each-sec-box' style={{ width: '50px' }}>
                                        <div className='status-with-btn' style={{ height: '25px' }}>
                                            <div style={{ position: 'relative', zIndex: '1' }}>
                                                <Link className='table-link-icon' to={`#`} type='button' onClick={() => {
                                                    setSelectedId(element._id);
                                                    setOpenDeleteDialog(true)
                                                }}>{SVGIcons.DeleteBlueIcon()}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='button' className='full-li-btn' onClick={() => {
                                        setSelectedRow(element);
                                        setOpenViewDialog(true)
                                    }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholderTable} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                }


            </div>
            {(openViewDialog && selectedRow) && <ModalPopUp
                isOpen={openViewDialog}
                onClose={() => {
                    setOpenViewDialog(false);
                }}
                className={'modal-popup inbox-popup'}
                children={<div>

                    <InboxView data={selectedRow} />
                </div>}

            />}

            {openDeleteDialog ? <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Delete report`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this report?\nThis action is permanent and cannot be undone.`}
                okayButtonText={`Delete report`}
                onConfirm={deleteData}
            /> : null}
        </div>
    )
}
ReportedQuestionTable.propTypes = {}

export default ReportedQuestionTable;
