import React from 'react';
import { Box } from '@mui/material';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import AppLayout from '../../../layouts/app/AppLayout';
import UserDrawer from '../../../components/drawer/drawer';
import CreateNewTestForm from './Forms/createNewTestForm';
import HeaderRightUserInfo from '../components/headerRightUserInfo';


const CreateNewTestPage = () =>
(
    <AppLayout
        isLoadHeader={false}
        isLoadFooter={false}
        bodyClassName={`provider-portal f-wrp`}
    >
        <Box sx={{ display: 'flex' }} className="portal-main-structure">
            <UserDrawer />
            <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                <Box className='panel-main-content'>
                    <div className='main-category-page'>
                        <div className='main-category-header'>
                            <h2>{translateThis("Create a test")}</h2>

                            <HeaderRightUserInfo />
                        </div>
                        <div className='main-category-content create-test-page'>
                            <CreateNewTestForm />
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    </AppLayout>
)



CreateNewTestPage.propTypes = {};

export default CreateNewTestPage;