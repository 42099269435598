import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import UserDrawer from '../../../components/drawer/drawer';
import AppLayout from '../../../layouts/app/AppLayout';

import HeaderRightUserInfo from '../components/headerRightUserInfo';
import InitiatePayment from '../../../pages/payment/initiatePayment';
import ApiCaller from '../../../services/api/general';
import config from "../../../config/config";
import AppLoader from '../../../components/appLoader';


export default function PaymentCard() {

    const apiCaller = new ApiCaller('payment/get-key');
    const [stripeKey, setStripeKey] = useState('');

    useEffect(() => {
        getStripeKey();
        return () => {
            setStripeKey('')

        };
        // eslint-disable-next-line
    }, []);

    /**
     * Gets the stripe public key for the university
     */
    const getStripeKey = () => {
        apiCaller.getData('').then((data) => {
            if (data.success) {
                const ActualStripeKey = data?.data?.key;
                const stripeKeyAs = ActualStripeKey || config.stripeKey;
                setStripeKey(stripeKeyAs)
            }
            
        })
    }


    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>

                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Payments")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            {stripeKey ? <div className='main-category-content'>
                                <InitiatePayment stripeKey={stripeKey} />
                            </div> : <AppLoader/>}
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}
