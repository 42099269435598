import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import '../home.scss';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';

const FAQXmed = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqItems = [
        {
            panelCount: 'panel1',
            title: 'What is XMed Pro, and how does it benefit medical students?',
            content: 'XMed Pro is an innovative platform designed to enhance the medical education experience. It offers interactive exam preparation, expert-curated content, collaborative learning communities, mobile accessibility, personalized progress tracking, and dedicated support.',
        },
        {
            panelCount: 'panel2',
            title: 'How does XMed Pro enhance my learning experience?',
            content: "With a range of interactive exam preparation tools and curated knowledge by doctors, studying becomes engaging and insightful. Plus, with over 100 new questions added every month, you'll always find fresh challenges to keep you motivated. Our platform is designed for seamless learning, whether you're on your phone, tablet, or computer, and you can easily track your progress along the way. But that's not all! You can also compare your performance with peers to see how you stack up. So why wait? Join us at XMed Pro and let's ace those exams together!",
        },
        // {
        //     panelCount: 'panel3',
        //     title: 'What sets XMed Pro apart from other medical education platforms?',
        //     content: 'Will update shortly',
        // },
        // {
        //     panelCount: 'panel4',
        //     title: 'Is XMed Pro suitable for international students?',
        //     content: 'Will update shortly',
        // },
        {
            panelCount: 'panel5',
            title: 'What sets XMed Pro apart from other medical education platforms?',
            content: "What sets XMed Pro apart from other medical education platformsText",
        },
        {
            panelCount: 'panel6',
            title: 'Is XMed Pro suitable for international students?',
            content: 'Yes, XMed Pro is suitable for international students.',
        },
        {
            panelCount: 'panel7',
            title: 'How do I contact XMed Pro for further assistance?',
            content: 'For further assistance, please contact us at hello@xmedpro.com.',
        },
        {   
            panelCount: 'panel8',
            title: 'Can I access XMed Pro on different devices?',
            content: 'Yes, you can access XMed Pro on various devices including tablets, phones (both Android and iPhones), PCs, and Macs.',
        },
        {   
            panelCount: 'panel9',
            title: 'Can I access XMed Pro offline?',
            content: 'Currently, XMed Pro is accessible only online. However, we are planning to develop a mobile app in the future that will allow offline operation.',
        },
    ];

    return (
        <>
            <div className='faq-accordion-wrp f-wrp'>
                {faqItems.map((element, key) => (
                    <Accordion className='each-accordian-sec' expanded={expanded === element.panelCount} onChange={handleChange(element.panelCount)} key={key}>
                        <AccordionSummary
                            // expandIcon={SVGIcons.ChevronDownIcon()}
                            aria-controls={`${element.panelCount}bh-content`}
                            id={`${element.panelCount}bh-header`}
                        >
                            {expanded === element.panelCount ? <span>{SVGIcons.MinusIcon()}</span> : <span>{SVGIcons.PlusIcon()}</span>}
                            <h4>{translateThis(element.title)}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{translateThis(element.content)}</p>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default FAQXmed;