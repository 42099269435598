import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { getFileByKey } from '../../services/api/aws';
import AppIcons from '../../assets/images/icons';
import { translateThis } from '../../helpers/language.helper';
import SVGIcons from '../../assets/images/icons/svgIcons';

const S3ImageViewerWithFallback = ({ fileKey = '', fileURL = '', isShowRetry = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [imgURL, setImgURL] = useState(fileURL);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (fileKey && (fileURL === '' || isFailed)) {
      getImg();
    }
    return () => {
      setImgURL('');
    };
    // eslint-disable-next-line
  }, [fileKey, isFailed]);

  /**
   * Retrieves the image URL from the server
   * @returns {void}
   */
  const getImg = async () => {
    if (fileKey) {
      setIsLoading(true);
      try {
        const { data } = await getFileByKey(fileKey);
        if (data?.imgURL) {
          setImgURL(data.imgURL);
        }
      } catch (error) {
        setImgURL('');
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Handles image loading error
   * @returns {void}
   */
  const handleImageError = () => {
    setIsFailed(true);
    setRetryCount((count) => count + 1);
  };

  /**
   * Renders the image component
   * @returns {JSX.Element}
   */
  const renderImage = () => {
    if (isLoading) {
      return <CircularProgress size={20} />;
    }

    if (imgURL) {
      return (
        <img
          src={imgURL}
          key={`auto-load-${fileKey}`}
          onError={handleImageError}
          alt="images"
        />
      );
    }

    return (
      <span>
        <img src={AppIcons.logoPlaceholder} alt="images" />
      </span>
    );
  };

  /**
   * Renders the retry button component
   * @returns {JSX.Element | null}
   */
  const renderRetryButton = () => {
    if (retryCount > 1 && isShowRetry) {
      return (
        <Button
          type="button"
          className="imageReload"
          onClick={getImg}
          variant="text"
        >
          <SVGIcons.RetryIcon />
          {translateThis('Try reloading please')}
        </Button>
      );
    }

    return null;
  };

  return (
    <div
      className="rc"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {renderImage()}
      {renderRetryButton()}
    </div>
  );
};

S3ImageViewerWithFallback.propTypes = {
  fileKey: PropTypes.string,
  isShowRetry: PropTypes.bool,
  fileURL: PropTypes.string,
};

export default S3ImageViewerWithFallback;
