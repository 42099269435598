import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import AppIcons from '../../../assets/images/icons/index';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';

import UserDrawer from '../../../components/drawer/drawer';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import AppLayout from '../../../layouts/app/AppLayout';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';



// const CreateNewQuestion = () =>
export default function MainCategoryTopics() {
    const facilityUrl = getCurrentFacilityURL();

    const mainCategoryTopics = [
        {
            image: AppIcons.createQn,
            mainBG: AppIcons.createQnBG,
            label: translateThis("create a"),
            label2: translateThis("question"),
            className: 'question',
            link: `/${facilityUrl}/create-question`,
            count: '5',
            colValueLg: '6',
            colValueMd: '9',
            colValueSm: '12',
        },
        {
            image: AppIcons.createExam,
            mainBG: AppIcons.createExamBG,
            label: translateThis("create an"),
            label2: translateThis("exam"),
            className: 'exam',
            link: `/${facilityUrl}/create-exam`,
            count: '5',
            colValueLg: '6',
            colValueMd: '9',
            colValueSm: '12',
        },
        {
            image: AppIcons.createSub,
            mainBG: AppIcons.createSubBG,
            label: translateThis("create a"),
            label2: translateThis("subject"),
            className: 'subject',
            link: `/${facilityUrl}/create-new-subject`,
            count: '5',
            colValueLg: '4',
            colValueMd: '4',
            colValueSm: '6',
        },
        {
            image: AppIcons.createSys,
            mainBG: AppIcons.createSysBG,
            label: translateThis("create a"),
            label2: translateThis("system"),
            className: 'system',
            link: `/${facilityUrl}/create-new-system`,
            count: '10',
            colValueLg: '4',
            colValueMd: '4',
            colValueSm: '6',
        },
        {
            image: AppIcons.createTopic,
            mainBG: AppIcons.createTopicBG,
            label: translateThis("create a"),
            label2: translateThis("topic"),
            className: 'topic',
            link: `/${facilityUrl}/create-new-topic`,
            count: '15',
            colValueLg: '4',
            colValueMd: '4',
            colValueSm: '6',
        },
        
    ]

    return (
        <>
            <AppLayout
                // pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <UserDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content' style={{ height: '100%' }}>
                            <div className='main-category-page' style={{ height: '100%' }}>
                                <div className='main-category-header' style={{ marginBottom: '0' }}>
                                    <h2>{translateThis("Create")}</h2>
                                    <HeaderRightUserInfo />
                                </div>
                                <div className='createNewQuestionWrp f-wrp'>
                                    <Grid className='createQnGrid' container spacing={2}>
                                        {mainCategoryTopics.map((element, key) => (
                                            <Grid item xs={12} sm={element.colValueSm} md={element.colValueMd} lg={element.colValueLg} key={key}>
                                                <Link to={element.link} className={`each-flex-box ${element.className}`} >
                                                    <span className='main-img'><img src={element.mainBG} alt={element.label} /></span>
                                                    <div className='content-wrp'>
                                                        <span className='content-img'><img src={element.image} alt={element.label} /></span>
                                                        <div className='content-box'>
                                                            <p>{element.label}</p>
                                                            <h3>{element.label2}</h3>
                                                            <i><b>{translateThis('total')}</b> {element.count}</i>
                                                        </div>
                                                    </div>

                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                            {/* <div className='createNewQuestion f-wrp'>
                                <div className='questionPart_section_1 f-wrp'>
                                    <div className='section-con-wrp f-wrp'>
                                        <h2>{translateThis("doYouWishToCreateANewQuestion")}</h2>
                                        <div className='flex-box'>
                                            <Link to={`/${getCurrentFacilityURL()}/create-question`} className='each-flex-box'>
                                                <span><img src={AppIcons.Question} alt='create question' /></span>
                                                <p>{translateThis("create a")}</p>
                                                <h3>{translateThis("question")}</h3>
                                                <i><b>{translateThis('total')}</b> 100</i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='questionPart_section_2 f-wrp'>
                                    <div className='section-con-wrp f-wrp'>
                                        <h2>{translateThis("orDoYouWishToCreateANew")}</h2>
                                        <div className='flex-box'>
                                            {mainCategoryTopics.map((element, key) => (
                                                <Link to={element.link} className='each-flex-box' key={key}>
                                                    <span><img src={element.image} alt={element.label} /></span>
                                                    <p>{element.label}</p>
                                                    <h3>{element.label2}</h3>
                                                    <i><b>{translateThis('total')}</b> {element.count}</i>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </Box>
                    </Box>
                </Box>
            </AppLayout>
        </>
    );
}




