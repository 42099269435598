import React from 'react';
import { Box } from '@mui/material';
// import AppIcons from '../../../assets/images/icons/index';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import AppLayout from '../../../layouts/app/AppLayout';
import CreateNewQuestionForm from './forms/createNewQuestionForm';
import UserDrawer from '../../../components/drawer/drawer';
import HeaderRightUserInfo from '../components/headerRightUserInfo';


const CreateNewQuestionPage = () =>
// const pageName = 'Create new question';
(
    <AppLayout
        // pageName={pageName}
        isLoadHeader={false}
        isLoadFooter={false}
        bodyClassName={`provider-portal f-wrp`}
    >
        <Box sx={{ display: 'flex' }} className="portal-main-structure">
            <UserDrawer />
            <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                <Box className='panel-main-content'>
                    <div className='main-category-page'>
                        <div className='main-category-header'>
                            <h2>{translateThis("createNewQuestion")}</h2>
                            <HeaderRightUserInfo />
                        </div>
                        <div className='main-category-content'>
                            <CreateNewQuestionForm />
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    </AppLayout>
)



CreateNewQuestionPage.propTypes = {};

export default CreateNewQuestionPage;