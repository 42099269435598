import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const SelfEmploymentView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView selfEmploymentView f-wrp" style={{ padding: '0' }}>
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>Self Employment</b></h3>
                    <div className='flex-box'>
                        <p>Name: <b>{answerGetter('self_employment_sectionOne_form_question_name', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>Case Number: <b>{answerGetter('self_employment_sectionOne_form_question_caseNumber', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <h3><b>Home</b></h3>
                    <div className='auto-flex'>
                        <p>Street: <b>{answerGetter('self_employment_sectionOne_form_question_address', formData)?.answer || '-'}</b></p>
                        <p>City: <b>{answerGetter('self_employment_sectionOne_form_question_city', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter('self_employment_sectionOne_form_question_state', formData)?.answer || '-'}</b></p>
                        <p>Zip Code: <b>{answerGetter('self_employment_sectionOne_form_question_zipcode', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <h3><b>Business</b></h3>
                    <p>Business Name: <b>{answerGetter('self_employment_sectionOne_form_question_business_Name', formData)?.answer || '-'}</b></p>
                    <div className='auto-flex'>
                        <p>Street: <b>{answerGetter('self_employment_sectionOne_form_question_address_1', formData)?.answer || '-'}</b></p>
                        <p>City: <b>{answerGetter('self_employment_sectionOne_form_question_city_1', formData)?.answer || '-'}</b></p>
                        <p>State: <b>{answerGetter('self_employment_sectionOne_form_question_state_1', formData)?.answer || '-'}</b></p>
                        <p>Zip Code: <b>{answerGetter('self_employment_sectionOne_form_question_zipcode_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <p>Type of business (explain): <b>{answerGetter('self_employment_sectionOne_form_question_typeOfBusiness', formData)?.answer || '-'}</b></p>
                    <div className='auto-flex'>
                        <p>Business Owner Name(s) 1: <b>{answerGetter('self_employment_sectionOne_form_question_owner_Name_1', formData)?.answer || '-'}</b></p>
                        <p>Business Owner Name(s) 2: <b>{answerGetter('self_employment_sectionOne_form_question_owner_Name_2', formData)?.answer || '-'}</b></p>
                        <p>Business Phone: <b>{answerGetter('self_employment_sectionOne_form_question_business_Phone', formData)?.answer || '-'}</b></p>
                        <p>Business start date (Month/Year): <b>{answerGetter('self_employment_sectionOne_form_business_start_date', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline'>
                        <p style={{ paddingTop: '0', minWidth: 'auto' }}>Did you file a business tax return last year?</p>
                        <ul>
                            <li className={answerGetter("self_employment_sectionOne_form_business_tax_return", formData)?.answer === 'Yes' ? 'checked' : ''}>Yes</li>
                            <li className={answerGetter("self_employment_sectionOne_form_business_tax_return", formData)?.answer === 'No' ? 'checked' : ''}>No</li>
                        </ul>
                    </div>
                    <i style={{ fontSize: '12px' }}>At <b>application,</b> self-employed individuals must be earning taxable income at the time they initially request assistance.<br />
                        At <b>redetermination,</b> self-employed individuals must be earning an hourly wage that is at or above State of Connecticut’s current minimum wage. This is calculated based on the weekly or monthly income earned divided by the number of hours involved in the work activity. Individuals should submit the verification that shows the current business income.</i>

                    <div className='gap' />
                    <p><b>Instructions</b></p>
                    <ul className='number-list'>
                        <li>You may use the worksheet on the back of this form to verify your self-employment income if:
                            <ul>
                                <li>You did not file a business income tax return last year; or</li>
                                <li>You filed a business tax return last year and expect a significant change in your business earnings this year.</li></ul></li>
                        <li>You must have business records to verify the information given on this form. Business records include items such as invoices, cancelled checks, receipts for materials purchased, bank account information and your business calendar for us to determine your work schedule. <b>Please send copies.</b> We cannot guarantee original forms will be returned to you.
                            <b>Note:</b> This form lists the most common business deductions allowed by the IRS. If you claim other deductions, such as
                            depreciation, you must include a worksheet showing how you calculated the expense.</li>
                        <li>If you filed a business tax return last year, please include copies of forms <b>IRS 1040</b> and <b>Schedule C.</b> Include form 8829 if you
                            claim expenses for the business use of your home.</li>
                        <li>If you own a corporation or partnership, include copies of <b>IRS forms 1120</b> and <b>1065.</b> Your share of profits not distributed to the
                            principal owners will be counted as income.</li>
                        <li>Please include copies of any estimated taxes you paid to the IRS this year. Generally, the IRS requires you to make estimated tax
                            payments if you expect to owe at least $1,000 in taxes.</li>
                        <li>State or federal law may not allow deductions for the cost of depreciation, the purchase of tools or equipment, entertainment
                            expenses, or prior year business losses. If you claim an expense that is not allowed, we will add the amount to the net income
                            reported on this form.</li>
                        <li>We must be able to verify how many hours you work and the work schedule you listed on your application form. Please send us
                            any information you have that verifies your work schedule.</li>
                    </ul>
                    <div className='gap' />
                    <p>If you have any questions or need help completing this form, please call Care 4 Kids at 1-888-214-5437.</p>
                    <div className='gap' />
                </div>
                <div className='each-section-box'>
                    <h3><b>Business income calculation</b></h3>
                    <p>Please round all amounts to the nearest dollar.</p>
                    <div className='gap' />
                    <p><b>Income</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>1a. Gross Income Earned</p>
                            <p>Enter your gross business income before deductions.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>{answerGetter('self_employment_sectionTwo_form_question_answer_1', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>1b. Period Covered</p>
                            <p>Tell us how long it took you to earn this money.</p>
                        </div>
                        <div className="ryt-section">
                            <p>From: <b>{answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_1', formData)?.answer || '-'}</b><br/>To: <b>{answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p><b>Business Expenses</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2a. Car and Truck Expenses</p>
                            <p>You can claim the standard mileage deduction if you use your car or truck for business purposes. Multiply the miles you traveled for work times $0.58 per mile.</p>
                        </div>
                        <div className="ryt-section">
                            <p>Miles: <b>{answerGetter('self_employment_sectionTwo_form_question_answer_3', formData)?.answer || '-'}</b> &nbsp; &nbsp; Total: <b>{answerGetter('self_employment_sectionTwo_form_question_answer_3_1', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2b. Insurance</p>
                            <p>List the amount you pay for business insurance on your business.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_4', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2c. Equipment Rental</p>
                            <p>Enter the cost of renting vehicles, machinery or equipment for your business.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_5', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2d. Supplies</p>
                            <p>Enter the cost of supplies and materials used to operate your business.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_6', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2e. Licenses</p>
                            <p>Enter the cost of any licenses you purchased for your trade or business.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_7', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2f. Telephone</p>
                            <p>Enter your business telephone expense. If you use your home telephone for business, do not deduct the regular monthly rate charged by your telephone company.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_8', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>2g. Employee Salaries</p>
                            <p>Enter the amount you paid to individuals that worked for you. Do not include payments to yourself or any other business owners.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_9', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p><b>Total Business Expenses</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>3. Total Business Expenses</p>
                            <p>Add the total expenses listed in line 2a through line 2g.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_10', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p><b>Net Business Income</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>4. Net Business Income</p>
                            <p>Subtract the total expenses in line 3 from your gross earnings in line 1a.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: {answerGetter('self_employment_sectionTwo_form_question_answer_11', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <p><b>Certification</b></p>
                    <ul className='bullet-list'>
                        <p>You may use the worksheet on the back of this form to verify your self-employment income if:</p>
                        <li>I certify that the information I have provided is true and correct.</li>
                        <li>I understand and agree that Care 4 Kids may independently verify the information given on this form.</li>
                        <li>I understand that Care 4 Kids may contact the Internal Revenue Services or the State Department of Revenue services to verify information concerning my business income.</li>
                    </ul>
                    <div className='gap' />
                    <p>I have read this statement or I have had it read to me in my native language. I also certify that all statements made by me and all the information on this form are true and correct under penalty for false statement as provided in either Section 53a-15b or Section 17b-97 of the Connecticut General Statues.</p>
                </div>
               
            </div >
        </>
    )
}

SelfEmploymentView.propTypes = {
    data: PropTypes.any
}

export default SelfEmploymentView;