import React from 'react';
import { Box } from '@mui/material';

import { translateThis } from '../../../helpers/language.helper';
import './index.scss';
import UserDrawer from '../../../components/drawer/drawer';
import HeaderRightUserInfo from '../components/headerRightUserInfo';
import AppLayout from '../../../layouts/app/AppLayout';
import StudentProfile from './formPages/profile.form';


export default function StudentProfilePage() {

    return (
        <AppLayout
            // pageName={pageName}
            isLoadHeader={false}
            isLoadFooter={false}
            bodyClassName={`provider-portal f-wrp`}
        >
            <Box sx={{ display: 'flex' }} className="portal-main-structure">
                <UserDrawer />
                <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                    <Box className='panel-main-content'>

                        <div className='main-category-page'>
                            <div className='main-category-header'>
                                <h2>{translateThis("Profile")}</h2>
                                <HeaderRightUserInfo />
                            </div>
                            <div className='main-category-content'>
                                <div className='profileBox-info maxWidth_767'>
                                    <StudentProfile />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}
