import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import draftToHtml from 'draftjs-to-html';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { convertToHTML } from 'draft-convert';
import { Delta } from 'quill/core';

class TextHighlight {
    constructor(quill, highlightId) {
        this.quill = quill;
        this.highlightId = highlightId;
        this.quill.on('selection-change', this.onSelectionChange.bind(this));
    }

    onSelectionChange(range) {
        if (range && range.length > 0) {
            // Get existing selections from local storage
            const existingSelections = JSON.parse(localStorage.getItem(this.highlightId)) || [];
    
            // Create a new selection object
            const newSelection = { index: range.index, length: range.length };
    
            // Check if the selection already exists
            const existingIndex = existingSelections.findIndex(selection => 
                selection.index === newSelection.index && selection.length === newSelection.length
            );
    
            if (existingIndex !== -1) {
                // Selection is already highlighted, remove the highlight
                this.quill.formatText(range.index, range.length, { 'background': false });
    
                // Remove the selection from local storage
                existingSelections.splice(existingIndex, 1);
            } else {
                // Selection is new, add it to the selections
                existingSelections.push(newSelection);
                // Apply highlight
                this.quill.formatText(range.index, range.length, { 'background': 'yellow' });
            }
    
            // Save updated selections to local storage
            localStorage.setItem(this.highlightId, JSON.stringify(existingSelections));
        }
    }
}

Quill.register('modules/textHighlight', TextHighlight);

const TextEditor = ({ initialContent, highlightId }) => {
    const editorRef = useRef(null);
    const quillRef = useRef(null); // Store the Quill instance

    useEffect(() => {
        if (highlightId && editorRef.current && !quillRef.current) {
            quillRef.current = new Quill(editorRef.current, {
                readOnly: true,
                // theme: 'snow',

                modules: {
                    toolbar: [],
                    textHighlight: {},
                    clipboard: {
                        matchers: [
                            ['SPAN', (node, delta) => {
                                // Preserve the span and its attributes
                                const attributes = {};
                                // eslint-disable-next-line no-restricted-syntax
                                for (const attr of node.attributes) {
                                    attributes[attr.name] = attr.value;
                                }
                                return delta.compose(new Delta().retain(delta.length(), { 'span': attributes }));
                            }]
                        ]
                    }
                }
            });

            new TextHighlight(quillRef.current, highlightId); // Instantiate TextHighlight

            if (initialContent) {
                const contentState = convertToHTML({
                    // eslint-disable-next-line consistent-return
                    styleToHTML: (style) => {
                        if (style.includes('fontsize')) {
                          return <span style={{fontSize:'12px'}}/>;
                        }
                        if (style === 'STRIKETHROUGH') {
                            return <s/>;
                          }                        
                      }
                })(initialContent);

                quillRef.current.clipboard.dangerouslyPasteHTML(contentState);
                 // Function to convert HTML to Delta
        
            }
            applySavedSelections();
        //    
        }
    }, [initialContent]);

   
    // Load selections from local storage
    const applySavedSelections = () => {        
        // Retrieve saved selections from localStorage
        const savedSelections = JSON.parse(localStorage.getItem(highlightId)) || [];
        
        // Early exit if there are no selections
        if (savedSelections.length === 0) {
            return;
        }    
        const quill = quillRef.current; // Cache the reference for better performance
    
        // Use a traditional for loop for better performance
        // eslint-disable-next-line
        for (let i = 0; i < savedSelections.length; i++) {
            const { index, length } = savedSelections[i];
            if (quill) {
                quill.setSelection(index, length); // Restore each selection
                quill.formatText(index, length, { background: 'yellow' }); // Highlight restored selections
            }
        }
    
        // Clear the selection after applying all highlights
        if (quill) {
            quill.setSelection(null);
        }
    };

    const clearAllSelection = () => {
        try {
            localStorage.removeItem(highlightId);
            if (initialContent) {
                const contentState = convertToHTML({
                    // eslint-disable-next-line consistent-return
                    styleToHTML: (style) => {
                        if (style.includes('fontsize')) {
                          return <span style={{fontSize:'12px'}}/>;
                        }
                        if (style === 'STRIKETHROUGH') {
                            return <s/>;
                          }                        
                      }
                })(initialContent);
                quillRef.current.clipboard.dangerouslyPasteHTML(contentState);
            }

        } catch (e) {
            console.log(e)
        }

    }

    return (
        <>
            <ContextMenuTrigger holdToDisplay={4000} id={`context-q-highlighter--${highlightId}`}>
                <div className='quill-highlighter' ref={editorRef} style={{ width: '100%' }} />
            </ContextMenuTrigger>
            <ContextMenu hideOnLeave id={`context-q-highlighter--${highlightId}`}>
                <MenuItem data={{ foo: 'bar' }} onClick={clearAllSelection}>
                    <span className='context-pop'>Clear all selections</span>
                </MenuItem>
            </ContextMenu>
        </>
    );
};

export default TextEditor;