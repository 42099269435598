/* eslint-disable arrow-body-style */
import * as React from 'react';
import { Box } from '@mui/material';

import { translateThis } from '../../../../../helpers/language.helper';

const UrineBMITab = () => {
    
    return (
        <>
            
            <Box className='SerumTab-wrapper'>
                    <div className='SerumTab-content f-wrp'>
                        <table>
                            <thead>
                                <th>{translateThis('Urine')}</th>
                                <th>{translateThis('Reference range')}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>{translateThis('Calcium')}</p></td>
                                    <td><p>{translateThis('100-300 mg/24 h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Creatinine clearance Male Female')}</p></td>
                                    <td><p>{translateThis('97-137 mL/min 88-128 mL/min')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Osmolality')}</p></td>
                                    <td><p>{translateThis('50-1200 mOsmol/kg H2O')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Oxalate')}</p></td>
                                    <td><p>{translateThis('8-40 μg/mL')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('Proteins, total')}</p></td>
                                    <td><p>{translateThis('<150 mg/24 h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('17-Hydroxycorticosteroids Male Female')}</p></td>
                                    <td><p>{translateThis('3.0-10.0 mg/24 h 2.0-8.0 mg/24 h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p>{translateThis('17-Ketosteroids, total Male Female')}</p></td>
                                    <td><p>{translateThis('8-20 mg/24 h 6-15 mg/24 h')}</p></td>
                                </tr>
                                <tr>
                                    <td><p><b>{translateThis('Body Mass Index (BMI)')}</b></p></td>
                                    <td><p>{translateThis('Adult: 19-25 kg/m2')}</p></td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                
            </Box>

        </>
    )
}

export default UrineBMITab;